import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserDucks from 'ducks/accounts/user';

import LoginFormComponent from 'components/accounts/login/Form';


/**
 * LoginPage -> LoginFormSection
 *
 * Components:
 *    - {@link LoginFormComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - Form:
 *        - postLoginUser
 */
class LoginFormSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.state = { showCompany: false };
  }

  login(data) {
    const { router: { history } } = this.context;
    const { UserDucks } = this.props;
    return UserDucks.postLoginUser(data)
      .then((res) => {
        if(res.value.companies && Array.isArray(res.value.companies) && res.value.companies.length > 1){
          this.setState({ showCompany: true });
        }
        else history.push('/dashboard');
      });
  }

  render() {
    return (
     <div className="ui_card">
        <section>        
          <LoginFormComponent login={this.login} showCompany={this.state.showCompany}/>
        </section>
      </div>
    );
  }
}

const mapActionsToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

const mapStateToProps = () => ({});

LoginFormSection.propTypes = {
  UserDucks: PropTypes.object.isRequired,
};

LoginFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(LoginFormSection);
