import React from 'react';
import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

import { ReactComponent as BlueFavoriteIcon } from 'images/favoriteGreen.svg';
import { ReactComponent as WhiteFavoriteIcon } from 'images/favoriteWhite.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';

class TaskListComponent extends React.Component {

  render() {
    const { taskList, markFavouriteTask, markUnFavouriteTask, selectTask, selected , popupTasks } = this.props;
    return (
    taskList && taskList.length !==0 ? 
    <div className={popupTasks && 'scroll_list mt3'}>
    {taskList && taskList.length && taskList.map(item => {
      return(
          <div className={!popupTasks ? `${styles.task_list_component} back-arrow` : `${styles.tasks_in_popup} ${styles.task_list_component} back-arrow`}>
            <div style={popupTasks ? {width: '80%'} : {width:'90%'}} onClick={() => selectTask(item)}>
              {!popupTasks ?
                <h2 className="ml4">{item.name}</h2>
                :
                <h3 className='textOverflow'>{item.name}</h3>
              }
            </div>
              {+selected === item.id &&
                <CheckIcon height="30" width="30" className="button-role" />
              }
              {markFavouriteTask &&
                (item && item.is_favourite ?
                <BlueFavoriteIcon height="30" width="30" className="mr4 button-role svg-fill"  onClick={() => markUnFavouriteTask(item)}/>
                :
                <WhiteFavoriteIcon height="30" width="30" className="mr4 button-role" onClick={() => markFavouriteTask(item)}/>
              )}           
          </div>
          );})
        }
    </div> :
    <div>
      <div className={styles.task_list_component_empty}  >
        {TRMSGS.noRecordsFound}
      </div>
    </div>
    );
  }
}

export default TaskListComponent;
