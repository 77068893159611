import React from 'react';

import { Field, reduxForm } from 'redux-form';

import Modal from 'react-bootstrap-modal';

import CalenderAtom from 'atoms/CalenderAtom';
import TimePickerAtom from 'atoms/TimePickerAtom';

import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

const EditTimeModalComponent = (props) =>  {
  const { isOpen , toggle, previousJob, nextJob, handleSubmit, initialValues} = props;
  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      backdrop="static"
      className= "edit_assign_popup"
      onHide={() => toggle()}
    >
    <form  onSubmit={handleSubmit(props.updateEnteredTime)}>
      <Modal.Header closeButton style={{'marginTop': '10px'}}>
        <div className={`${styles['heading']}`}>{TRMSGS.editJobTaskTime}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={`f4 ${styles['job_name_length']} mb3`}>{previousJob && previousJob.activity_name} {previousJob && previousJob.job_name} {previousJob.job_name != " " && previousJob.task_name ? '-' : null} {previousJob && previousJob.task_name === "UNALLOCATED TASK" ? `${TRMSGS.unallocatedTask}` : previousJob.task_name}</div>
        <div className={`${styles['date_picker_atom_container']} mt1 mb3 v-top w-100 custom_width_picker`}>
          <div className="flex mt4 mb4 mr4">
            <Field
              component={CalenderAtom}
              values = {initialValues}
              id="start_date"
              name="start_date"
              submitEditedDate={props.submitEditedDate}
              previousJob = {previousJob}
              nextJob = {nextJob}
            />
            <Field
              component={TimePickerAtom}
              values = {initialValues}
              id="end_date"
              name="end_date"
              submitEditedTime = {props.submitEditedTime}
              previousJob = {previousJob}
              nextJob = {nextJob}
            />
          </div>
          <small className ="f7">{TRMSGS.editingTimeAdjustJobTaskTime}</small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button 
          className="br2 pointer w45 pv3 mr3 white btn-color f3"
          type="submit"
        >
          {TRMSGS.save} 
        </button>
        <button 
          className="br2  pointer w45 pv3 ml2 white btn-red f3"
          onClick={() => toggle()}
        >
          {TRMSGS.cancel}
        </button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};


export default reduxForm({
  form: 'EditTimeForm',
  enableReinitialize: true,
})(EditTimeModalComponent);

