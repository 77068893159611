import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserDucks from 'ducks/accounts/user';

import RegisterFormComponent from 'components/accounts/register/Form';

/**
 * RegisterPage -> RegisterFormSection
 *
 * Components:
 *    - {@link RegisterFormComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - Form:
 *        - postRegisterUser
 */
class RegisterFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
  }

  register(data) {
    return this.props.UserDucks.postRegisterUser(data);
  }

  render() {
    return (
      <RegisterFormComponent register={this.register} />
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

RegisterFormSection.propTypes = {
  UserDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(RegisterFormSection);
