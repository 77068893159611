import React from 'react';

import Modal from 'react-bootstrap-modal';
import JobListComponent from 'components/jobs/list/jobList';
import TaskListComponent from 'components/tasks/list/taskList';
import JobAndTaskFilterComponent from './jobFilter';
import TRMSGS from 'constants/Translations/Localization';

import {ReactComponent as ClearIcon} from './clear.svg';

class JobTaskSelectModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      jobs : null,
      tasks : null
    }; 
  }

  componentWillReceiveProps(nextProps, prevProps){
    if((JSON.stringify(prevProps.jobList) !== JSON.stringify(nextProps.jobList)) || (JSON.stringify(prevProps.taskList) !== JSON.stringify(nextProps.taskList))){
      this.setState({jobs : nextProps.jobList, tasks : nextProps.taskList});
    }
  }

  componentDidMount() {
    this.setState({jobs : this.props.jobList, tasks : this.props.taskList});
  }

  componentDidUpdate(prevProps){
      this.props.isOpen === true && !prevProps.isOpen && this.setState({jobs : this.props.jobList, tasks : this.props.taskList});
  }


  render() {
  const {  isOpen, toggleEditModal, jobList, taskList, selectJob, handleTaskSelect, selected, submit, showJob } = this.props;
  const { jobs , tasks } = this.state;
  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      onHide={() => this.toggleEditModal()}
      backdrop="static"
    >
      <Modal.Header className='custom-modal'>
        <Modal.Title id='ModalHeader' style={{fontWeight: '500', fontSize:'20px'}}>
          {TRMSGS.assign}
          <ClearIcon height='12' width='12' className='fr mt2 pointer' onClick={toggleEditModal}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt3 p0'>
        <div class="center ph3-ns">
          <div class="cf ph2-ns">
            {showJob ? 
              <div class="fl w-100 pa2">
                <h2 class="fw4 mt0 mb3" style={{color:'#001e2e'}}>{TRMSGS.jobs}</h2>
                <JobAndTaskFilterComponent 
                  heading={TRMSGS.jobs}
                  list = {jobList}
                  updateList ={(filteredList) => this.setState({jobs : filteredList})}
                />
                <JobListComponent
                  jobList = {jobs}
                  selectJob={selectJob}
                  selected={selected.job && selected.job.id}
                  popupOpen ={true}
                  markUnFavourite={this.props.markUnFavourite}
                  markFavourite={this.props.markFavourite}
                />
              </div>
              :
              <div class="fl w-100 pa2">
                <h2 class="fw4 mt0 mb3" style={{color:'#001e2e'}}>{TRMSGS.tasks}</h2>
                <JobAndTaskFilterComponent 
                  list = {taskList}
                  updateList ={(filteredList) => this.setState({tasks : filteredList})}
                />
                <TaskListComponent
                  taskList = {selected.job && selected.job.id ? tasks : []}
                  selectTask={handleTaskSelect}
                  selected={selected.task && selected.task.id}
                  markUnFavouriteTask={this.props.markUnFavouriteTask}
                  markFavouriteTask={this.props.markFavouriteTask}
                  popupTasks ={true}
                />
              </div>
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="cf">
        <button 
          className="br2 pointer fr w-25 pv3  white btn-color f5 mr4"
          type="submit"
          onClick={submit}
        >
          {TRMSGS.select}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
}

export default JobTaskSelectModal;
