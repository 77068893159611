import React from 'react';

import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Modal from 'react-bootstrap-modal';

import TextareaAtom from 'atoms/Textarea';

import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

/**
 * HeaderSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class AddShiftNameModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data){
    const { submit } = this.props;
    submit(data);
  }


  render() {
    const { isOpen , handleSubmit , closeModal, userPreferenceLanguage } = this.props;
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => closeModal()}
        backdrop="static"
      >
        <Modal.Header closeButton style={{borderBottom :'none'}}>
          <Modal.Title id='ModalHeader' style={{fontSize: '16px'}}>{TRMSGS.addNameToYourShift}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
          <Modal.Body style={{height: '100px', paddingLeft: '10px', paddingRight: '10px'}}>
          <span className={`${styles['shift_label_container']}`}>
            <Field
              component={TextareaAtom}
              id="shift_label"
              name="shift_label"
            />         
          </span>   
          </Modal.Body>
          <Modal.Footer style={{borderTop :'none', paddingRight : '0px'}}>
            <button className={userPreferenceLanguage==='bilingual' ? " br2 pointer mt1 w-25 pv3 mr2 white  btn-color white  btn-color f4 bilingual-button-width " :"br2 pointer mt1 w-25 pv3 mr2 white  btn-color white  btn-color f4"} type='submit'>
              <img
                className="di pl2 mr2 v-btm"
                src={require('./tick.png')} alt="Site Name"
                height="22"
              />
              {TRMSGS.save}
            </button>
            <button className={userPreferenceLanguage==='bilingual' ?"br2 pointer mt1 w-25 pv3 mr2 white  white  btn-color f4 bilingual-button-width" : "br2 pointer mt1 w-25 pv3 mr2 white  white  btn-color f4" } style={{backgroundColor: 'red'}} onClick={() => closeModal()}>
              <img
                className="di pl2 mr2 v-btm"
                src={require('./error.png')} alt="Site Name"
                height="22"
              />
              {TRMSGS.cancel}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'AddShiftNameModal',
})(AddShiftNameModalComponent);
