import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';

import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as PayrollDucks from 'ducks/payrolls/payroll';

import TRMSGS from 'constants/Translations/Localization';


/**
 * DashboardListPage -> TimecardListSection
 *
 * Components:
 *    -
 *
 * State:
 *    - route params
 *
 */
class TimecardOverviewSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      totalRegularTime: 0, totalOvertime : 0, totalDoubleOvertime : 0, totalAdjustmentTime : 0
    };
  }

  componentWillMount(){
    const { dailyTimecardList } = this.props;
    let totalRegularTimeMins = 0 ;
    let doubleOvertimeMins = 0;
    let totalOvertimeMins = 0;
    let totalAdjustmentMins = 0;
    dailyTimecardList && dailyTimecardList.length && dailyTimecardList.forEach((item)=>{
      totalRegularTimeMins = totalRegularTimeMins + item.regular_time;
      totalOvertimeMins = totalOvertimeMins + item.over_time;
      doubleOvertimeMins = doubleOvertimeMins + item.double_over_time;
      totalAdjustmentMins = totalAdjustmentMins + item.total_adjustment_time
    });

    totalRegularTimeMins = totalRegularTimeMins/60;
    totalOvertimeMins = totalOvertimeMins/60;
    doubleOvertimeMins = doubleOvertimeMins/60;
    totalAdjustmentMins =totalAdjustmentMins/60;


    this.setState({totalRegularTime : totalRegularTimeMins, totalOvertime : totalOvertimeMins, totalDoubleOvertime : doubleOvertimeMins, totalAdjustmentTime : totalAdjustmentMins});

  }


  render() {
    const { totalRegularTime , totalOvertime , totalDoubleOvertime , totalAdjustmentTime } = this.state;
    const { showWeeklyList , dailyTimecardList , timecardOverview } = this.props;
   return (
      <article>
        <div className="w-100 white flex justify-between" style={{ backgroundColor: '#077289', fontWeight: '500' }}>
          {dailyTimecardList && dailyTimecardList.length ?
            <p className="pv1 pl3 f4">{TRMSGS.total}</p>
            :
            null
          }
          {dailyTimecardList && dailyTimecardList.length ?
            <p className="pr3 pv1 back-arrow"><img src={require('./expand.png')} alt="arrow" onClick={()=>showWeeklyList()}/></p>
            :
            null
          }
        </div>
        <ul className="list pl0 ml0 center ba b--light-silver br2 mt0">
           {totalRegularTime > 0 ?
            <li className='flex items-center lh-copy pa1 ph0-l br2 mt0' style={{ borderBottom: '1px solid #f2f2f2' , backgroundColor:'white', lineHeight: '4rem'}}>
              <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                <p className="f4">{TRMSGS.regularTime}</p> 
                <p className="f4">{totalRegularTime/60 == 0 ? '00' : parseInt(totalRegularTime/60)}HH  {(totalRegularTime%60) == 0 ? '00' : parseInt(totalRegularTime%60)}MM</p>
              </div>
           </li>
           :
           null
          }
          {totalOvertime > 0 ?
           <li className='flex items-center lh-copy pa1 ph0-l br2 mt0' style={{ borderBottom: '1px solid #f2f2f2' , backgroundColor:'white', lineHeight: '4rem'}}>
              <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                <p className="f4">{TRMSGS.overtime}</p> 
                <p className="f4">{totalOvertime/60 == 0 ? '00' : parseInt(totalOvertime/60)}HH  {(totalOvertime%60) == 0 ? '00' : parseInt(totalOvertime%60)}MM</p>
              </div>
           </li>
           :
           null
         }
          {totalDoubleOvertime > 0 ?
            <li className='flex items-center lh-copy pa1 ph0-l br2 mt0' style={{ borderBottom: '1px solid #f2f2f2' , backgroundColor:'white', lineHeight: '4rem'}}>
            <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
              <p className="f4">{TRMSGS.doubleOvertime}</p>
              <p className="f4">{totalDoubleOvertime/60 == 0 ? '00' : parseInt(totalDoubleOvertime/60)}HH  {(totalDoubleOvertime%60) == 0 ? '00' : parseInt(totalDoubleOvertime%60)}MM</p> 
            </div>
          </li>
          :
          null
        }
         {
          timecardOverview && timecardOverview.timecard_adjustments && timecardOverview.timecard_adjustments.length ? timecardOverview.timecard_adjustments.map((item)=> (
            <li className='flex items-center lh-copy pa1 ph0-l br2 mt0' style={{ borderBottom: '1px solid #f2f2f2' , backgroundColor:'white', lineHeight: '4rem'}}>
              <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                <p className="f4">{item.name}</p>
                <p className="f4">{item.total_time_display.split(':')[0]}HH{item.total_time_display.split(':')[1].split('mm')[0]}MM</p> 
              </div>
            </li>
            ))
          :
          null
         }
        
        </ul>
        {dailyTimecardList && !dailyTimecardList.length ?
            <p style={{fontSize: '1.5rem', textAlign: 'center'}}>{TRMSGS.noRecordsFound}</p>
            :
            null
          }
      </article>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dailyTimecardList: TimecardDucks.dailyTimecardList(state),
  profile: ProfileDucks.profile(state),
  payrollList: PayrollDucks.payrollList(state),
});

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
});


TimecardOverviewSection.propTypes = {
  dailyTimecardList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

TimecardOverviewSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TimecardOverviewSection);
