import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as EyeCloseIcon} from './eye-close.svg';

const EyeCloseIconAtom = props => (
  <EyeCloseIcon {...props} />
);

EyeCloseIconAtom.defaultProps = {
  height: 15,
  width: 15,
};

EyeCloseIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default EyeCloseIconAtom;
