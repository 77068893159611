import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import StorageGateway from 'lib/storage-gateway';

import * as PermissionDucks from 'ducks/access/permission';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as DepartmentDucks from 'ducks/departments/department';
import * as TeamDucks from 'ducks/teams/team';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import { toast } from 'react-toastify';


import Singleton from 'lib/singleton';

import DashboardListSection from 'sections/generics/dashboard/List';
import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import DashboardFilterSection from 'sections/generics/dashboard/Filter';
import FooterSection from 'sections/common/Footer';
import DashboardBiometricFaceSection from 'sections/generics/dashboard/BioMetricFaceList';
import TRMSGS from 'constants/Translations/Localization';

import { ReactComponent as ResetIcon } from './reset.svg';
import EnabledGoBack from './enabledLeftArrow.png';
import DisabledGoBack  from './disabledLeftArrow.png';
import styles from './styles.module.scss';

const cookies = new Cookies();

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.fetchData = this.fetchData.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.getTeams = this.getTeams.bind(this);
    this.state = { isLoading: false, previousData: null, data: null, isLogoutIcon: true, selectedType: 'name', showReloadButton: false};
    this.resetEmployees=this.resetEmployees.bind(this);
    this.searchByFace = this.searchByFace.bind(this);
    this.goBack = this.goBack.bind(this);
    this.startResetTimer = this.startResetTimer.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.stream = null;
    this._timeOut = null;
  }

  componentWillMount() {
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { pathname, query } } = this.props;
    if(this.singleton.employeeAuth) {
      this.singleton.employeeAuth = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      this.singleton.language = null;
      StorageGateway.remove('imageUrl');
      this.props.UserDucks.resetUserPreferedLanguage();
    }
    const searchPreference = cookies.get('search_pref_dashboard', { path: '/' });
    if(searchPreference == 'face'){
      this.setState({selectedType: searchPreference});
      history.push({
        pathname, search: queryString.stringify({ ...query, view: 'face' }),
      });
    }
    this.setState({ isLoading: true });
    this.props.DepartmentDucks.getDepartments({ paginate: false, get_active: true });
    this.props.TeamDucks.getTeams({ paginate: false, get_active: 'true' });
    this.props.ProfileDucks.getProfile();
    this.props.UserDucks.getExtraData();
    this.fetchData({...params, ...query});
  }

  
  componentWillUnmount() {
    this.webcam = null;
    this.setState({cameraEnable : false, cameraConnected : false})
    this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
    clearTimeout(this._timeOut);
  }


  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const { location: { pathname } } = this.props;
    const { router: { history } } = this.context;
    const searchPreference = cookies.get('search_pref_dashboard', { path: '/' });
    const {login_attempt : prevLogin , ...prevRest } = prevQuery;
    const {login_attempt : nextLogin , ...rest } = nextQuery;
    if (JSON.stringify(prevRest) !== JSON.stringify(rest) ) {
      // this.fetchData({});
      this.fetchData(nextQuery);
    }
  }

  searchByFace(val) {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    var isCamera = false;
    if (val === 1) {
      const { view, login_attempt, ...rest } = query;
      this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
      history.push({ pathname, search: queryString.stringify({ ...rest }) });
      this.setState({selectedType: 'name'})
      cookies.set('search_pref_dashboard','name', { path: '/' });
    } else {
      if (!window && !window.navigator) {
        return;
      }
      const supported =  !!(
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        );

      const constraints = {video: true };
      if (!supported) {
        var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        if(isMacLike){
          this.setState({isCameraPermissionOpen: true});
          this.setState({isCameraPermission: 'MACLIKEDEVICE'});
        }
        else{
          this.setState({isCameraPermissionOpen: true});
          this.setState({isCameraPermission: 'UPDATE'});
        }
      }
      if (navigator.mediaDevices !== undefined) {
        navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            this.stream = stream;
            this.setState({cameraConnected: true});
            isCamera = true;
            if(isCamera) {
              history.push({
                pathname, search: queryString.stringify({ ...query, view: 'face' }),
              });
              this.setState({selectedType: 'face'})
              cookies.set('search_pref_dashboard','face', { path: '/' });
            }
          })
          .catch(() => {
            this.setState({isCameraPermissionOpen: true});
            this.setState({isCameraPermission: 'PERMISSION'});
            this.setState({cameraConnected: false});
            isCamera = false;
          });
      }
      clearTimeout(this._timeOut);
    }
  }

  fetchData(param) {
    const{ res, ...params } = param;
    this.getEmployees(params);
    if(params && params.deparment_id ) {
      this.getTeams(params);
    }
  }

  resetEmployees(data,index){
    const { router: { history } } = this.context;
    const { location: { pathname, query }} = this.props;
    this.setState({isfocused: false});
    if(query){
      delete query.name_start;
    }
    history.push({
      pathname,
      search: queryString.stringify({...query, name_start : ''}),
    });
  }

  getEmployees(params){
    const { previousData} = this.state;
    this.setState({isLoading: true});
    if(params && params.name_start){
      this.props.EmployeeDucks.getEmployees({ ...params, activated: true, page_size: 20, name_start: params.name_start})
        .then(()=>{
          const { employeeList } = this.props;
          if(!employeeList.length){
            /*this.props.EmployeeDucks.getEmployees({ ...params, activated: true, page_size: 20, name_start: previousData})
              .then(()=> this.setState({ isLoading: false }));*/
            toast.error(`${TRMSGS.noRecordsFound}`);
            this.setState({ isLoading: false, previousData: params.name_start, showReloadButton: false });
          }
          else {
            this.setState({ isLoading: false, previousData: params.name_start, showReloadButton: false });
          }
          clearTimeout(this._reloadTimeOut);     
        });
    }
    else {
      this.props.EmployeeDucks.getEmployees({ ...params, activated: true, page_size: 20})
        .then(()=>{this.setState({isLoading: false, previousData: null, showReloadButton: false});clearTimeout(this._reloadTimeOut);});
    }
  }
  
  getTeams(params){
    const deptId = params && params.department_id ? params.department_id : '';
    this.props.TeamDucks.getTeams({ paginate: false, department_id: deptId, get_active: 'true' });
  }

  goBack(){
    const { router: { history } } = this.context;
    const { location: { pathname, query }} = this.props;
    const { ...rest } = query;
    if(rest){
      delete rest.name_start;
    }
    history.push({ pathname, search: queryString.stringify(rest) });
    clearTimeout(this._timeOut);
  }

  startResetTimer(){
    this._timeOut = setTimeout(() => {
      clearTimeout(this._timeOut);
      this.goBack(); //to remove name_start from query
    }, 7000);
  }

  reloadScreen(){
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { pathname, query } } = this.props;
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.fetchData({...params,...query});
  }

  clickToReload(){
    this._reloadTimeOut=setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }

  render() {
    const { isLoading, previousData, selectedType } = this.state;
    const { location: { query }, userExtraData } = this.props;
    if (!this.singleton.authorization) {
      return <Redirect to="/accounts/login" />;
    }
    
    return (
      <div>
        <div className="min-vh-100 pb4 dark-grey-bg relative">
          <div className="w-100 margin-list">
            <HeaderSection 
              logoActive = 'true'
              isMenuIcon="isMenuIcon"
              isLogo="isLogo"
              selectedType = {selectedType}
              searchByFace = {this.searchByFace}
              fetchData = {this.fetchData}
            />
            <TimeCardHeader />
          </div>
          <div className={`${styles['reset-back-div']} mb4`} style={{minHeight: '34px', backgroundColor: 'rgb(226, 236, 241)', alignItems:'center'}}>
            <div className={`${styles['reset-div']} ml4`}>            
              <div className={ query.name_start ? `${styles['go-back-enabled']} tr mt2 fr` : `${styles['go-back-disabled']} tr mt2 fr `} onClick={()=>{query.name_start && this.goBack()}}>
                Go Back
              </div>
              <div style={{display: 'inline-block'}}>
                {query.name_start ?
                  <img src={EnabledGoBack} width="20" height="20" onClick={()=>this.goBack()} className={`${styles['back-arrow-cursor-enabled']}`}/>
                  :
                  <img src={DisabledGoBack} width="20" height="20" className={`${styles['back-arrow-cursor-disabled']}`}/>
                }
              </div>
            </div>
            {userExtraData?.company?.name && 
                <div class=" pv2 tc " style={{fontSize:'18px', color:'rgb(9, 129, 154)'}}>
                  <span>{userExtraData?.company?.name}</span>
                </div>
            }
            <div onClick={()=>this.resetEmployees()} className={`${styles['reset-div']} mr4`} style={{cursor: 'pointer'}}>            
              <div className="tr mt2 f6 fr mr3" style={{display: 'inline-block', fontSize: '15px',color: '#09819A'}}>
                {TRMSGS.reset}
              </div>
              <div className='tr fr' style={{display: 'inline-block'}}>
                <ResetIcon height="30" width="30" />
              </div>
            </div>
          </div>
          <div className={`${styles['dashboard-div']} margin-list pa2 pb4`}>
            { selectedType === 'face' ?
              <DashboardBiometricFaceSection isLoading={isLoading} searchByFace = {this.searchByFace} startResetTimer={this.startResetTimer} reloadScreen={this.reloadScreen} clickToReload={this.clickToReload} showReloadButton={this.state.showReloadButton}/>
              : 
              <div>
                <DashboardFilterSection isLoading={isLoading}/>
                <DashboardListSection isLoading={isLoading} className="mb-20" previousData={previousData} startResetTimer={this.startResetTimer} reloadScreen={this.reloadScreen} clickToReload={this.clickToReload} showReloadButton={this.state.showReloadButton}/>
              </div>
            }
          </div>
          <FooterSection />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  employeeList: EmployeeDucks.employeeList(state),
  permissionList: PermissionDucks.permissionList(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
  PermissionDucks: bindActionCreators(PermissionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

DashboardPage.fetchData = ({ store }) => {
  return store.dispatch(PermissionDucks.getPermissions());
};

DashboardPage.propTypes = {
  ProfileDucks: PropTypes.object.isRequired,
};

DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardPage);
