
import React from 'react';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import Cookies from 'universal-cookie';

import styles from './styles.module.scss';

import WorkShiftTimerComponent from 'components/activities/List/workShiftTimer';
import JobTaskTimerComponent from 'components/activities/List/jobtaskTimer';

import {ReactComponent as SwitchIcon} from 'images/switch.svg';
import {ReactComponent as MealIcon} from 'images/meal.svg';
import {ReactComponent as PunchOutIcon} from 'images/punchOut.svg';
import {ReactComponent as JobNotesIcon} from 'images/job-notes.svg';
import TRMSGS from 'constants/Translations/Localization';

const cookies = new Cookies();

class JobShiftComponent extends React.Component {

  componentDidMount(){
    const { activityData } = this.props;
    if(activityData && activityData.length && activityData[activityData.length - 1].job_id){
      cookies.set('job_id', activityData[activityData.length - 1].job_id, { path: '/' });
    }
    else if(activityData && activityData.length && activityData[activityData.length - 1].activity_name === 'meal' && activityData[activityData.length - 2].job_id && !activityData.length && activityData[activityData.length - 1].job_id){
      cookies.set('job_id', activityData[activityData.length - 2].job_id, { path: '/' });
    }
  }


  render() {
    const { punchOut, mealStart , showJobNotes, punchInDetail, sessionDetail, switchJob, isMealStartButtonLoading, isPunchOutButtonLoading, selectedJob, selectedTask, taskList, jobsList, mealBreakDisabled, punchOutDisabled, companyDetail , activityData , jobLabel, userPreferenceLanguage} = this.props;

    const switchShow = (companyDetail.to_be_allocated) && (companyDetail.task_time_allocation) && ((jobsList && jobsList.length > 1) ||  (taskList && taskList.length > 1));
    return (
      <article className="w-100 mb6">
        <article className="br2 w-100 h-50">
          <div className="dt dt--fixed">
            <div className="dt-row">
              <div className="dtc tc">
                <p className={styles['work_shift_text']}>{TRMSGS.workShiftTime}</p>
              </div>
            </div>

            <div className="dt-row">
              <div className={`${styles['work_shift_container']} dtc tc pv2`}>
                { sessionDetail.id ?
                <div>
                 <WorkShiftTimerComponent
                    punchInDetail={punchInDetail}
                    sessionDetail = {sessionDetail}
                  />
                </div> :
                <div>
                  00:00<br />
                </div>
              }
                <span class="w-100 mr2 ml2">
                {TRMSGS.hours}
                </span>
                <span class="w-100 pl3 ml2">
                {TRMSGS.minutes}
                </span>
              </div>
            </div>
        </div>
        </article>

        {activityData && activityData.length && activityData[activityData.length - 1].job_id && activityData[activityData.length - 1].task_id ?
          <div className="w-80-ns w-70-l center">
            <div className={styles['job_shift']}>
              <div className="w-40 tc di center">
                <p className="mt4 pt1 w-100 textOverflow mb4 pb0">
                  {selectedJob} -{selectedTask}
                </p>
                  {this.props.activityData && this.props.activityData.length ?
                  <div>
                    <JobTaskTimerComponent
                      punchInDetail={punchInDetail}
                      sessionDetail = {sessionDetail}
                      activityData = {this.props.activityData}
                    />
                  </div> :
                  <div>
                    00:00<br />
                    <span className="fw4 f4">HH:MM</span><br/>
                  </div>
                }
                  <div className="tc mt0" style={{'margin-top': '-15px'}}>
                    <span className="ml3 mr3">
                      {TRMSGS.hours}
                    </span>
                    <span className="ml1 mr1">
                      {TRMSGS.minutes}
                    </span>
                  </div>
              </div>
            </div>
            <div className={`${styles['job_shift_notes']} w-100 back-arrow tc flex justify-center items-center`} onClick={() => showJobNotes()}>
              <span className="dib mr1"><JobNotesIcon height="30" width="30"/></span>
              <p>{TRMSGS.jobNotes}</p>
            </div>
          </div>
          :
          null
        }
        {jobLabel &&
          <div style={{justifyContent: 'center', display: 'flex'}}>
            <div className={styles['shift_label']}>
              <span className={styles['ellipsis-label']}>{jobLabel}</span>
              <img src={require('./editIcon.png')} alt="logo" style={{marginLeft: '10px', height: '15px', cursor: 'pointer'}} onClick={() => this.props.editJobLabel()}/>
            </div>
          </div>
        }


        <div className={userPreferenceLanguage === 'bilingual' ? `${styles['bilingual-job-shift-button']} w-100 h3` : userPreferenceLanguage === 'spanish' ? `${styles['spanish-job-shift-button']} w-100 h3` : `${styles['job_shift_buttons']} w-100 h3`}>
          {switchShow && 
            <button className="pointer ma1 white br2" onClick={switchJob}>
              <span><SwitchIcon className={`${styles['job_shift_icons']}`}/></span>
              {TRMSGS.switch}
            </button>
          }
          <button disabled={mealBreakDisabled} className="pointer ma1 white br2" onClick={mealStart}>
            <span><MealIcon className={`${styles['job_shift_icons']}`}/></span>
              {TRMSGS.mealBreak} <ButtonLoaderAtom active={isMealStartButtonLoading} />
          </button>
           <button disabled={punchOutDisabled} className="pointer ma1 white br2" onClick={punchOut}>
            <span><PunchOutIcon className={`${styles['job_shift_icons']}`} /></span>
            {TRMSGS.punchout} <ButtonLoaderAtom active={isPunchOutButtonLoading} />
          </button>
        </div>
      </article>
    );
  }
}

export default JobShiftComponent;
