import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Pagination from 'react-js-pagination';
import TRMSGS from 'constants/Translations/Localization';

const PaginationComponent = (props, context) => {
  const { count, location: { pathname, query } } = props;
  const { router: { history } } = context;

  const handlePageChange = page => history.push({
    pathname, search: queryString.stringify({ ...query, page }),
  });

  return (
    <div className="mb2 cf">
      <div className="dib mt3">{TRMSGS.totalRecords}: {count}</div>
      <div className="fr">
        {count > 10 &&
          <Pagination
            activePage={(query && +query.page) || 1}
            itemClass="page-item"
            itemsCountPerPage={10}
            prevPageText="&#8249;"
            nextPageText="&#8250;"
            linkClass="page-link"
            totalItemsCount={count}
            onChange={handlePageChange}
          />
        }
      </div>
    </div>
  );
};

PaginationComponent.defaultProps = {
  count: 0,
};

PaginationComponent.propTypes = {
  count: PropTypes.number,
  location: PropTypes.object.isRequired,
};

PaginationComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PaginationComponent;
