import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDAtom from 'atoms/TableDataCell';
import THAtom from 'atoms/TableHeader';

const ListingListSection = (props) => {
  const { list } = props;
  const headers = [
    {
      key: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'create_date',
      label: 'Create Date',
      sortable: false,
    },
  ];

  return (
    <table className="w-100 center" cellSpacing="0">
      <thead>
        <tr>
          {headers.map(header => (<THAtom key={header.key}>{header.label}</THAtom>))}
        </tr>
      </thead>
      <tbody>
        {list.results.map(item => (
          <tr key={item.id}>
            <TDAtom title={item.id}>{item.id}</TDAtom>
            <TDAtom title={item.name}>{item.name}</TDAtom>
            <TDAtom title={item.description}>{item.description}</TDAtom>
            <TDAtom title={item.create_date}>{item.create_date}</TDAtom>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = () => ({
  list: {
    count: 10,
    results: [
      { id: 1, name: 'Name', description: 'Description', create_date: '1 Jan' },
      { id: 2, name: 'Name', description: 'Description', create_date: '2 Jan' },
      { id: 3, name: 'Name', description: 'Description', create_date: '3 Jan' },
      { id: 4, name: 'Name', description: 'Description', create_date: '4 Jan' },
      { id: 5, name: 'Name', description: 'Description', create_date: '5 Jan' },
      { id: 6, name: 'Name', description: 'Description', create_date: '6 Jan' },
      { id: 7, name: 'Name', description: 'Description', create_date: '7 Jan' },
      { id: 8, name: 'Name', description: 'Description', create_date: '8 Jan' },
      { id: 9, name: 'Name', description: 'Description', create_date: '9 Jan' },
      { id: 10, name: 'Name', description: 'Description', create_date: '10 Jan' },
    ],
  },
});

ListingListSection.propTypes = {
  list: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
)(ListingListSection);
