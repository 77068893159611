import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';

import FilterAtom from 'atoms/filterAtom';
import TRMSGS from 'constants/Translations/Localization';

class FilterComponent extends React.Component {
  constructor() {
    super();
    this.clear = this.clear.bind(this);
  }

  clear() {
    const { location , change} = this.props;
    const { query, pathname } = location;
    const { router: { history } } = this.context;
    const { ...rest } = query;
    if(rest){
      delete rest.search;
      delete rest.page;
    }
    change('search', null);
    history.push({ pathname, search: queryString.stringify(rest) });
  }



  render() {
    const { submit , handleSubmit , search,userPreferenceLanguage } = this.props;
    return (
    <form onSubmit={handleSubmit(submit)}  noValidate className="filter-component mb2 mt2 w-70-l w-90-ns">
      <Field
        component={FilterAtom}
        submit={submit}
        placeholder={this.props.heading=== `${TRMSGS.jobs}` ? `${TRMSGS.enterJobName}` : `${TRMSGS.enterTaskName}`}
        name='search'
        type='text'
        className="pv3 pl2 br2"
        clearIcon = {search ? true : false}
        clear={this.clear}
      />
      <button className={userPreferenceLanguage === 'bilingual' ? "btn-color white custom_border custom_border_bilingual" :  "btn-color ph3 pv3 white custom_border"}>
        {TRMSGS.search}
      </button>
    </form>
    );
  }
}

FilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

FilterComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};


const FilterForm = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(FilterComponent);

const selector = formValueSelector('FilterForm');

export default connect(
  (state) => {
    const search = selector(state, 'search');
    return { search };
  },
)(FilterForm);
