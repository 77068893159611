import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import StorageGateway from 'lib/storage-gateway';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import TaskListSection from 'sections/tasks/list/List';
import FilterSection from 'sections/common/FilterSection';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as RouteDucks from 'ducks/routes';
import * as SessionDucks from 'ducks/activities/session';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';

const cookies = new Cookies();

class TaskListPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.state = { isLoading: true, isBackIcon : false, taskAll : null, allJobs: null, showReloadButton: false};
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData(){
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const { location: { query } } = this.props;
    this.props.ProfileDucks.getProfile()
    .then((res) => {
        this.setState({taskAll : res.value});
        this.fetchData({ ...query, ...res.value});
        /*this.startTimer();*/
     });
  }

  componentWillMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
     this.props.ProfileDucks.getProfile()
     .then((res) => {
        this.fetchData({ ...nextQuery, ...res.value});
        /*this.startTimer();*/
     });
    }
  }

  goToUrl() {
    const { router: { history } } = this.context;
    const { activityData, jobList, taskList} = this.props;
    if (jobList.length === 1 && taskList.length > 1 ) {
      if ((activityData && activityData.length && activityData.length === 0) ||
        (activityData && activityData.length && activityData[activityData.length -1].activity_name && activityData[activityData.length -1].activity_name === 'meal')) {
        this.setState({isBackIcon : false});
      } else {
        history.push('/jobs/shift');      
      } 
    } else if ( jobList.length > 1 && taskList.length > 0) {
      history.push('/jobs/list');
    }
  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }



  

  fetchData(params){
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    this.setState({ isLoading: true });
    const reqObj ={
      job_id : singleton.job_id,
      search : params.search,
      page : params.page || 1 ,
      page_size : params.page_size,
    };
    this.props.TaskDucks.getTasks(reqObj);
    this.props.SessionDucks.getCurrentSession();
    this.props.SessionDucks.getActivityData(params.res ? params.res.value.employee_id : params.employee_id);
    this.props.JobDucks.getJobs({employee_id : params.res ? params.res.value.employee_id : params.employee_id, paginate: false})
    .then((jobsList) => {
      const {activityData} = this.props;
      this.setState({allJobs : jobsList.value});
      if(jobsList && jobsList.value && (jobsList.value).length === 1) {
        if (activityData.length === 0 ||
          (activityData && activityData.length && activityData[activityData.length -1].activity_name && activityData[activityData.length -1].activity_name === 'meal')) {
          this.setState({isBackIcon : false});
        } else {
          this.setState({isBackIcon : true});
        }
      } else if (jobsList && jobsList.value && (jobsList.value).length > 1) {
        this.setState({isBackIcon : true});
      } else {
        this.setState({isBackIcon : false});
      }
        this.setState({isLoading : false, showReloadButton: false});
        clearTimeout(this._reloadTimeOut)
   })
   .catch(() => {
      this.setState({isLoading : false, showReloadButton: false});
      clearTimeout(this._reloadTimeOut)
   });
  }

  reloadScreen(){
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.getData();
  }

  clickToReload(){
    this._reloadTimeOut=setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }

  
  render() {
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    const { taskList, taskCount ,profile} = this.props;
    const { isLoading , isBackIcon , allJobs} = this.state;
    const singleton = new Singleton();
    const { location: { query } } = this.props;
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const Job = allJobs && allJobs.length && allJobs.filter((item) => (item.id && singleton.job_id && parseInt(item.id) === parseInt(singleton.job_id)) || (item.id && query.job_id && parseInt(item.id) === parseInt(query.job_id)));
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
    return (
      <div className="min-vh-100 pb4 dark-grey-bg relative ">
        <div className="w-100 margin-list">
          <HeaderSection
            isBackIcon={isBackIcon}
            goToUrl = {this.goToUrl}
            fetchData={this.fetchData}
          />
          <TimeCardHeader 
            heading={TRMSGS.tasks}
            firstName = {profile && profile.first_name}
            lastName = {profile && profile.last_name}
          />
        </div>
        <p className="job_notes_text w-70-l w-90-ns margin-list job-list textOverflow" style={{paddingTop : '30px'}}>
        {Job && Job.length && Job[0].name ? Job[0].name : null}
        </p>
         <FilterSection
           location={this.props.location}
           userPreferenceLanguage={this.props.userPreferenceLanguage}
         />
        <div className="w-70-l w-90-ns margin-list pa2 pb5 job-margin-responsive">
          <TaskListSection 
            taskList={taskList}
            isLoading={isLoading}
            taskCount = {taskCount}
            location = {this.props.location}
          />
        </div>
        <FooterSection />
      </div>
    );
  }
}

const mapStateToProps = state => 
{
  return({
  location: RouteDucks.location(state),
  taskList: TaskDucks.taskList(state),
  taskCount: TaskDucks.taskCount(state),
  jobList: JobDucks.jobsList(state),
  profile: ProfileDucks.profile(state),
  activityData : SessionDucks.activityData(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),

});
};

const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});


TaskListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TaskListPage);
