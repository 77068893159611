export default {
  URLS: {
	JOBS: '/kiosk/job/',
	MARK_FAVOURITE_JOB : '/kiosk/job/mark-favourite',
	JOB_NOTES: '/document/',
	ACTIVITY : '/activity',
	SHIFT_LABEL : 'job-name',
	KIOSK: '/kiosk',
  },

};

