import React from 'react';

class TimePickerAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }


   handleChange(e) {
    const { input } = this.props;
    input.onChange(e.target.value);
  }

  render() {
    const { value, input , previousJob , nextJob} = this.props;
    return (
      <div className ="pointer di">
        <input
          className="ba br2 b--black-20 pa2 mb1 f5 db w-70 dib"
          aria-describedby="name-desc"
          value={value}
          onChange = {this.handleChange}
          disabled={(previousJob.activity_name && previousJob.activity_name === 'meal') || (nextJob.activity_name && nextJob.activity_name === 'meal')}
          />
      </div>
    );
  }
}

export default TimePickerAtom;