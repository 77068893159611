import React from 'react';

import { reduxForm } from 'redux-form';

import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import Modal from 'react-bootstrap-modal';

const CalenderModalAtom = (props) =>  {
  const { isOpen , toggle } = props;

  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      backdrop="static"
      className= "edit_time_popup"
      onHide={() => toggle()}
    >
    <form>
      <Modal.Body>
       <Calendar
          onChange = {(val) => props.updateTime(val)}
          value={props.input}
        />
      </Modal.Body>
      <Modal.Footer className="tc">
         <button 
          className="br2 pointer w45 pv3 white f3"
          onClick={props.toggle}
          style={{background: '#162d6e', border: '1px solid #162d6e !important'}}
          >
          Done 
        </button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};


export default reduxForm({
  form: 'CalenderModalAtom',
  enableReinitialize: true,
})(CalenderModalAtom);