import React from 'react';
import styles from './styles.module.scss';
import {ReactComponent as JobNotesIcon} from 'images/jobNotes.png';
// import MealIcon from 'images/Meal.png';
// import jobNotesIcon from 'images/jobNotes.png';
// import StarIcon from 'images/star.png';
// import punchOutIcon from 'images/punchOut.png';
// import SwitchIcon from 'images/Switch.png';
import TRMSGS from 'constants/Translations/Localization';

class MealListComponent extends React.Component {
  
  render() {
    return (
      <article className="w-100">
        <article className="br2 mv2 w-100 h-50">
          <div className="dt dt--fixed">
            <div className="dt-row">
              <div className="dtc tc pv2">
                <p className={styles['work_shift_text']}>{TRMSGS.workShiftTime}</p>
              </div>
            </div>

            <div className="dt-row">
              <div className={`${styles['work_shift_container']} dtc tc pv2`}>
                <p>00 : 01</p>
                <span>
                  {TRMSGS.hours}
                </span>
                <span>
                  {TRMSGS.minutes}
                </span>
              </div>
            </div>
        </div>
        </article>

        <div className={styles['job_shift']}>
          <div className="tc di center">
            <p>Job Title 1 - Task Name 5</p>
              <p>00 : 01</p>
              <div className="tc mt0">
                <span>
                  {TRMSGS.hours}
                </span>
                <span className="ml3">
                  {TRMSGS.minutes}
                </span>
              </div>
          </div>

          <div className={`${styles['job_shift_notes']} w-100 pv3 tc`}>
            <span className="dib mr2"><JobNotesIcon height="13" width="15"/></span>
            {TRMSGS.jobNotes}
          </div>
        </div>

        <div className={`${styles['btn_container']} w-100 h4 mt5 mb2`}>
          <div class="fl w-50 tl white">
            <button className="pv3 white btn-color btn_meal flex items-center justify-center">
              {/* <img src={SwitchIcon} height="24" width="24" /> */}
            <span className="ml2 f5">{TRMSGS.switch}</span>
            </button>
          </div>
          <div class="fl w-50 tr white flex justify-end">
            <button className="pv3 white btn-color btn_meal flex items-center justify-center">
              {/* <img src={MealIcon} height="24" width="24" /> */}
              <span className="ml2 f5">{TRMSGS.mealBreak}</span>
            </button>
          </div>

          <button className="w-100 white btn-custom-red pv3 ph2 mt3 btn btn_meal">
            {/* <img src={punchOutIcon} height="20" width="20" /> */}
            <span className="ml2">{TRMSGS.punchout}</span>
          </button>
        </div>
      </article>
    );
  }
}

export default MealListComponent;
