import React from 'react';

import styles from './styles.module.scss';

const FooterSection = (props) => (
  <footer className="absolute pv2 bottom-0 right-0 left-0" style={!props.disableAbsolute ? {position: 'absolute'} : null}>
    <div className={`${styles['footer']} ${styles['footer-text']} pv0 tc`}>
      &copy; {new Date().getFullYear()} VendorVer. All rights reserved.
      VendorVer and myEtimecard are trademarks of VendorVer Inc.
    </div>
    <div className={`${styles['footer-text']} pt2`} style={{textAlign: "center", color: "#3d556b"}}>
    " Best works for resolution:  Min: 1280 x 800 (WXGA) to Max: 2800 x 1752 (WQXGA+). "
    </div>
    <small className="fr mr2 pt2">
      POWERED BY <a className={styles['footer-link']} href="http://www.thoughts2binary.com" rel="noopener noreferrer" target="_blank">T2B GRANITE</a>
    </small>
  </footer>
);

export default FooterSection;