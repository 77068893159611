import React from 'react';
import Modal from 'react-bootstrap-modal';
import UnscheduledCommentModal from './unscheduledCommentModal'

class UnscheduledShiftConfirmationComponent extends React.Component {
   constructor(props) {
     super(props);
     this.toggleCommentPopup = this.toggleCommentPopup.bind(this);
     this.state = { isCommentPopupOpen : false };
    }

    toggleCommentPopup(){
      const { isCommentPopupOpen } = this.state;
      this.setState({ isCommentPopupOpen : !isCommentPopupOpen })
    }
    
   render() {
     const { isOpen, toggle, saveMessage, commentButtonLoading } = this.props;
     const { isCommentPopupOpen } = this.state;
     return (
       <div>
       <Modal
          show={isOpen}
          aria-labelledby="ModalHeader"
          onHide={() => toggle()}
          backdrop="static"
          >
            <Modal.Header closeButton>
               <h3 className="mt0 mb0">
                You are punching in outside your shift. Your shift would be marked as unscheduled and would need manual approval. Are you sure you want to proceed?
                </h3>
            </Modal.Header>
            <Modal.Body>
                <div className="tr custom-button">
                   <button
                      type="submit"
                      className="br2 pointer pv2 ph5 w-100 white btn-color mb3"
                      onClick={() => this.toggleCommentPopup()}
                      >
                       Yes, Continue
                    </button>
                    <button
                      type="submit"
                      className="br2 pointer pv2 ph5 w-100 white btn-color mb2"
                      onClick={() => toggle()}>
                         Cancel
                    </button>
                    
                </div>
            </Modal.Body>
        </Modal>
        { isCommentPopupOpen && 
          <UnscheduledCommentModal 
             isOpen={isCommentPopupOpen}
             toggle={this.toggleCommentPopup} 
             saveMessage={saveMessage}
             commentButtonLoading={commentButtonLoading}
          />
     }
     </div>
        );
    }
}

export default UnscheduledShiftConfirmationComponent;