import React from 'react';

import Keyboard, {KeyboardButton, LatinLayout} from 'react-screen-keyboard';

import Modal from 'react-bootstrap-modal';

import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';


import {ReactComponent as KeyBoardIcon} from './keyboard.svg';
import {ReactComponent as ClearIcon} from './close.svg';
import {ReactComponent as SearchIcon} from './search.svg';



class KeyboardInputAtom extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { value: props.input && props.input.value };
    this.update = this.update.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.clearText = this.clearText.bind(this);
    this.keyPressclear = this.keyPressclear.bind(this);
  }

  update() {
    const { submit, toggle, clear } = this.props;
    const { value } = this.state;
    if (submit) {
      if (value) return submit({search: value});
      else return clear();
    }
    toggle();
  }

  updateSearch (){
    const { submit , clear } = this.props;
    const { value } = this.state;
    if (submit) {
      if (value) return submit({search: value});
      else return clear();
    }
  }

  clearText (event) {
    const { clear } = this.props;
    this.setState({value: ''});
    clear();
  }

  keyPressclear(event) {
    let keyCode = event.which || event.keyCode;
    const { submit } = this.props;
    const { value } = this.state;
    if (submit && !value) {
      if(keyCode === 13 ) {
        return submit({search: value});
      }
    }
  }

  render() {
    const {
      input,
      isOpen,
      label,
      searchIcon,
      toggle,
      inputType,
      toggleInputType,
      eyeIcon,
      isModal,
      dashboard,
      clearIcon,
      meta: { touched, error },
      ...rest
    } = this.props;

    return (
      <div className="mw9">
        <div className="cf">
          <div className='w-100 relative'>
            <input
              {...input}
              {...rest}
              onInput={e => {
                this.setState({ value: e.target.value });
              }}
              className='pa2 w-100 ba b--black-20 br2 truncate'
              value={ this.state.value }
              ref={input => { this.inputNode = input;}}
              onKeyPress={(e) => this.keyPressclear(e)}
            />
            {clearIcon &&
              <ClearIcon
                className="clear-icon"
                height="13" width="10"
                onClick={this.clearText}
              />
            }
            {searchIcon &&
              <SearchIcon height="16" width="16" className="eye-icon"
                onClick={() => this.updateSearch()}
                style={{
                  marginTop: '3px',
                  marginRight: '5px'
                }}
                />
            }
            <KeyBoardIcon
              height="35"
              width="35"
              className={dashboard ? 'dashboardkeyboard-icon' : 'keyboard-icon'}
              onClick={() => { toggle();}}
            />
            {eyeIcon &&
              <span>
                {inputType === 'password'
                  ?(<EyeOpenIconAtom
                    className="eye-icon"
                    height="20" width="20"
                    onClick={this.props.toggleInputType}
                  />)
                  : (<EyeCloseIconAtom
                    className="eye-icon"
                    height="20" width="20"
                    onClick={this.props.toggleInputType}
                  />)
                }
              </span>
            }
            {isOpen &&
              <Modal
                show={isOpen}
                aria-labelledby="ModalHeader"
                onHide={() => toggle()}
                className="keyboard-modal"
              >
                <Modal.Header  className="keyboard-modal-header" closeButton>
                </Modal.Header>
                <Modal.Body className="keyboard-modal-body" >
                  <Keyboard
                    inputNode={this.inputNode}
                    leftButtons={[
                      <KeyboardButton
                        onClick={() => { this.setState({ value: ''}); }}
                        value="Clear"
                      />
                    ]}
                    rightButtons={[
                      <KeyboardButton
                        onClick={() => this.update()}
                        value="Submit"
                        classes="keyboard-submit-button"
                      />
                    ]}
                    layouts={[LatinLayout]}
                  />
                </Modal.Body>
              </Modal>
            }
          </div>
          <small className="dark-red db h1 mt1 mb2">{touched && error}</small>
        </div>
      </div>
    );
  }
}

export default KeyboardInputAtom;
