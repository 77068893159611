import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';

import * as ProfileDucks from 'ducks/accounts/profile';
import * as RouteDucks from 'ducks/routes';

import TablePlaceholderComponent from 'components/common/placeholders/TablePlaceholder';
import PaginationComponent from 'components/common/Pagination';
import Singleton from 'lib/singleton';
import FooterSection from 'sections/common/Footer';
import SideNavSection from 'sections/common/SideNav';
import ListingListSection from 'sections/generics/listing/List';


class ListingPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.state = { isLoading: false };
  }

  componentDidMount() {
    // if (!this.singleton.authorization) {
    this.setState({ isLoading: true });
    this.props.ProfileDucks.getProfile()
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    // }
  }

  render() {
    const { location } = this.props;

    if (!this.singleton.authorization) {
      return <Redirect to="/" />;
    }

    let table = <ListingListSection />;
    if (this.state.isLoading) {
      table = <TablePlaceholderComponent />;
    }
    return (
      <div className="cf min-vh-100 ph2 ph0-m pb4">
        <div className="fl w-20 w-100-m vh-100 overflow-y-scroll bg-light-gray">
          <SideNavSection />
        </div>
        <div className="fl w-80 w-100-m pa3 vh-100 overflow-y-scroll">
          {/*<ListingFilterSection />*/}
          {table}
          <PaginationComponent
            count={10}
            location={location}
          />
          <FooterSection />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
});

ListingPage.propTypes = {
  ProfileDucks: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ListingPage);
