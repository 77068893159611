import React from 'react';

import { NavLink } from 'react-router-dom';

const SideNavItemComponent = (props) => (
  <NavLink
    key={props.item.id}
    to={props.item.to}
    activeClassName='bg-light-red'
    className="flex items-center near-black bb b--black-05 pa1 no-underline"
  >
    <div className="w-20">
      <img
        src="http://mrmrs.github.io/photos/p/2.jpg"
        className="ba b--black-05 w2 h2"
        alt='img'
      />
    </div>
    <div className="w-80">
      <p className="f5 fw5">{props.item.label}</p>
    </div>
  </NavLink>
);

export default SideNavItemComponent;
