import React, { Component } from "react";
import EyeOpenIconAtom from "atoms/EyeOpen";
import EyeCloseIconAtom from "atoms/EyeClose";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import TRMSGS from "constants/Translations/Localization";

class NumericInput extends Component {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = {
      inputValues: props.values,
      inputType: "password",
    };
    this.firstInputRef = React.createRef(); 
  }

  componentDidMount() {
    if(this.firstInputRef.current)
    this.firstInputRef.current.focus();
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({
      inputType: inputType === "password" ? "text" : "password",
    });
  }

  handleInputChange = (index, value) => {
    if (value === '' || /^[0-9]$/i.test(value)) {
      const updatedValues = [...this.state.inputValues];
      updatedValues[index] = value;
  
      this.setState({ inputValues: updatedValues }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.inputValues);
        }
        if (value && index < this.props.length - 1) {
          this[`inputRef${index + 1}`].focus();
        }
      });
    }
  };
  

  
  render() {
    const { inputType, inputValues } = this.state;
    const { pincodeSubmitting, confirmPincodeField, button } = this.props;

    return (
      <>
      <div style={{position:'relative'}}>
        {inputValues.map((value, index) => (
          <input
            key={index}
            ref={(index === 0 && this.props.flag)  ? this.firstInputRef : (el) => (this[`inputRef${index}`] = el)}
            type={inputType === "password" ? "password" : "text"}
            value={value}
            onChange={(e) => this.handleInputChange(index, e.target.value)}
            maxLength={1}
            style={{
              width: "2em",
              height: "2em",
              textAlign: "center",
              fontSize: "1.5em",
              margin: "0 8px",
            }}
            inputMode={"numeric"}
          />
        ))}
        
        <div style={{position:'relative',bottom: '2.7rem',left: '4.5rem'}}>
        {inputType === "password" ? (
            <EyeOpenIconAtom
              className="eye-icon"
              height="20"
              width="20"
              onClick={this.toggleInputType}
            />
          ) : (
            <EyeCloseIconAtom
              className="eye-icon"
              height="20"
              width="20"
              onClick={this.toggleInputType}
            />
          )}
          </div>
          
       
      </div>
       
       {button && (
        <div>
         <div
          style ={{
             marginTop: "2.5rem",
             marginLeft: 'auto',
             marginRight: 'auto'
           }}
         >
           <button
             type="submit"
             className=" br2 pointer ph4 white fw4 btn-color f3 pv3 w-100 ba f4 mt3"
             disabled={pincodeSubmitting?.disable}
           >
             <span className="pl2 pr2 f3 fw5 di v-mid">
               {confirmPincodeField ? TRMSGS.confirm : TRMSGS.login}
             </span>
             <span className="f3 di v-mid">
               <ButtonLoaderAtom active={pincodeSubmitting?.loader} />
             </span>
           </button>
         </div>
         </div>
       )}
     
     </>
    );
  }
}




export default NumericInput;
