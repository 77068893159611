import React from 'react';

import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import Webcam from 'react-webcam';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import * as ProfileDucks from 'ducks/accounts/profile';
import TRMSGS from 'constants/Translations/Localization';

class ActivityBiometricSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      screenshot: null,
      cameraEnable: true,
      imageSubmitting: false,
      errorText: '' 
    };
    this.imageLogin = this.imageLogin.bind(this);
    this.backButtonHandler = this.backButtonHandler.bind(this);
  }

    componentWillUnmount(){
      this.webcam = null;
      this.setState({cameraEnable : false, cameraConnected : false})
      this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
    }

    imageLogin(){
      const { employeeImageLogin } = this.props;
      const imageSrc = this.webcam.getScreenshot();
      if(!imageSrc){
        return toast.error(`${TRMSGS.enableCameraPermission}`);
      }
      this.setState({ screenshot: imageSrc, imageSubmitting: true });
      if (!imageSrc) {
          this.setState({ cameraEnable: false });
          return;
      }
      return employeeImageLogin(imageSrc)
          .catch(err => {
              this.setState({
                  errorText: err.non_field_errors[0],
                  imageSubmitting: false
              });
          });
    };

    backButtonHandler(){
        this.props.backButtonHandler();
    };

    render(){
      const { cameraEnable , imageSubmitting, errorText } = this.state;
      const {src, edit} = this.props.query;

      let text = `${TRMSGS.initiateLoginAttempt}`;
      if(edit){
          text =`${TRMSGS.resetBiometricPassword}`;
      }

      const companyName = this.props.profile.company ? this.props.profile.company.name : null;

      return (
          <div>
              <div className="tc mb4" style={{minHeight: '34px', backgroundColor: 'rgb(226, 236, 241)'}}>
                <div className=" pv2 tc " style={{fontSize:'18px', color:'rgb(9, 129, 154)'}}>
                  <span>{companyName}</span>
                </div>
              </div>
              <h4 className="tc custom-text" style={{marginTop: '15px !important', marginBottom: '5px !important'}}>
                  {
                      src === 'activity-list'
                      ? `${TRMSGS.alignFaceWithCamToResetBiometricPassword}`
                      : `${TRMSGS.alignFaceToSetupFacialRecognition}`
                  }
              </h4>

              {errorText && <h2 className="error-text tc absolute" style={{top:'11%', left:'42%'}}>{errorText}</h2>}

              <div className="cf w-100 flex flex-column items-center">

                  <div className="pa3 pb5">
                      <SectionLoaderAtom active={cameraEnable && !this.webcam} />
                      <span>
                        {cameraEnable && <Webcam
                            audio={false}
                            width='100%'
                            height='auto'
                            className="face-setup-sm"
                            screenshotFormat="image/jpeg"
                            ref={webcam => {this.webcam = webcam;}} 
                            />
                        }
                        <div className="tc w-100">
                          <button
                            type="button"
                            onClick={this.imageLogin}
                            className=" br2 pointer ph4 white fw4 btn-color f3 pv3 w-100 ba f4 mt3">
                            <span className="pl2 pr2 f3 fw5 di v-mid">{TRMSGS.snap}</span>
                            <span className="f3 di v-mid">
                              <ButtonLoaderAtom active={imageSubmitting} />
                            </span>
                          </button>
                        </div>
                      </span>
                  </div>

              </div>
          </div>
        );
    };
}

const mapStateToProps = (state) => ({
    profile: ProfileDucks.profile(state),
});

export default connect(
    mapStateToProps
)(ActivityBiometricSection);
