import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';

import Singleton from 'lib/singleton';

import { server } from 'lib/config';

import * as SessionDucks from 'ducks/activities/session';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';

const cookies = new Cookies();

class QuickModeSuccessPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.loadImage = this.loadImage.bind(this);
    this.sendSessionId = this.sendSessionId.bind(this);
    this.state={loaded: true}
  }

  sendSessionId(){
    const { sessionDetail } = this.props;
    const responseId = cookies.get('responseId', { path: '/' });
    if(responseId && responseId!== '0'){
      // get reference to window inside the iframe
      var wn = document.getElementById('feedback_iframe').contentWindow;
      // postMessage arguments: data to send, target origin
      wn.postMessage(JSON.stringify({session_id: sessionDetail.id , response_id : responseId}), '*');
      cookies.remove('responseId' , { path: '/' });
    }
  }

  componentDidMount() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', {path:'/'});
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 3000);
  }

  loadImage(){
    let image = document.getElementById('face');
    const img =  StorageGateway.get('imageUrl');
    if(image.src == `data:image/jpeg;base64,${img}` ){
      image.className= styles.imageWrapper;
    }
    image.src = `data:image/jpeg;base64,${img}`;
    /*img.src = './avatar.png'*/
  }

  render() {
    let image =StorageGateway.get('imageUrl');
    const { location: {  query }, faceInfo , companyDetail } = this.props;
    const { loaded } = this.state;
    return (
        <div className={(query.type == 'punchin' || query.type == 'jobswitch') ? styles.successWrapper : query.type == 'mealstart' ? styles.mealWrapper : query.type == 'punchout' ? styles.punchoutWrapper : null}>
          <div className={styles.bodyWrapper}>
             {
                companyDetail.feedback ?
                  <iframe style={{display : 'none'}}  onLoad={this.sendSessionId} id='feedback_iframe' width="0" height="0"  src={`${server.feedbackHost}/?oAuthToken=${this.singleton.employeeAuth}&language=${this.singleton.language}&uniqueUuid=${this.uniqueUuid}&employeeId=${this.employeeId}&company_id=${this.companyId}&role_id=${this.roleId}&context=kiosk`}/>  
                  :
                  null
              }
            {image ?
              <div style={{position: 'relative'}}>
                <div className={styles.imageWrapperDiv}>
                 <img
                    className={ styles.loaderWrapper}
                    src={require('./giphy.gif')}
                    onLoad={() => this.loadImage()}
                    id='face'
                  />
                </div>
                <div className={query.type !== 'jobswitch' ? styles.showTick : styles.clockInMark}>
                  <img src={require('./check.png')} alt="logo" height="47px" width="38px" className={styles.checkMark}/>
                </div>
              </div>
              :
              (query.type == 'punchin' || query.type== 'jobswitch')? 
              <img src={require('./punched-In.png')} alt="logo" />
                :
                query.type == 'mealstart' ?
              <img src={require('./mealStart.png')} alt="logo" />
                :
                query.type == 'punchout' ?
              <img src={require('./punchedOut.png')} alt="logo" />
                :
                null
            }
            <h2 className={styles.nameWrapper}>
                {TRMSGS.thanks}, {query.name}!
            </h2>
            <h3 className={styles.activityWrapper}>
              {(query.type == 'punchin')? 
                `${TRMSGS.clockedIn}`
                :
                query.type == 'mealstart' ?
                `${TRMSGS.onBreak}`
                :
                query.type == 'punchout' ?
                `${TRMSGS.clockedOut}`
                :
                query.type == 'jobswitch' ?
                `${TRMSGS.switchJobs}`
                :
                null
              }
            </h3>
          </div>
        </div>
    );
  }
}

QuickModeSuccessPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: ProfileDucks.profile(state),
  location: RouteDucks.location(state),
  faceInfo: ProfileDucks.faceInfoDetail(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  sessionDetail: SessionDucks.sessionDetail(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(QuickModeSuccessPage);
