import React from 'react';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import JobShiftSection from 'sections/jobs/shift/Shift.jsx';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';
import StorageGateway from 'lib/storage-gateway';
import { server } from 'lib/config';

import * as ProfileDucks from 'ducks/accounts/profile';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as JobDucks from 'ducks/jobs/jobs';
import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as SessionDucks from 'ducks/activities/session';
import * as UserDucks from 'ducks/accounts/user';

import ForcePunchOutModalComponent from 'components/activities/Form/forcePunchOutModal';
import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class JobShiftPage extends React.PureComponent {

   constructor(props) {
    super(props);
    this.state = { isLoading: true, isTimeCardIcon: true ,isForcePunchOut : false, showReloadButton: false };
    this.singleton = new Singleton();
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.updateSession = this.updateSession.bind(this);
    this.UpdateforcePunchOut = this.UpdateforcePunchOut.bind(this);
    this.sendSessionId = this.sendSessionId.bind(this);
    this.reloadScreen= this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.getData = this.getData.bind(this);
    this.employeeId = null;
    this.uniqueUuid = null;
    this.companyId = null;
    this.roleId = null;
    this.dept_id = null;
    this.team_id = null;
  }

  getData(){
    const {  location: { query } } = this.props;
     this.props.ProfileDucks.getProfile()
    .then((profileDetails) => {
      this.props.SessionDucks.getActivityData(profileDetails.value.employee_id)
        .then((activityResponse) => {
          this.employeeId = profileDetails.value.employee_id;
          this.uniqueUuid = profileDetails.value.unique_uuid;
          this.companyId = profileDetails.value.company && profileDetails.value.company.id;
          this.roleId = profileDetails.value.role_id;
          this.dept_id = profileDetails.value.department && profileDetails.value.department.dept_id;
          this.team_id = profileDetails.value.department && profileDetails.value.department.team && profileDetails.value.department.team.id;
          const activities = activityResponse && activityResponse.value && activityResponse.value.results && activityResponse.value.results[0] && activityResponse.value.results[0].data;
          let newActivityData = [];
          let activityDetail;
          this.props.SessionDucks.getCurrentSession()
          .then((res) => {
            const allActivity = res && res.value && res.value.all_activity;
            const currentResponse = res && res.value && res.value.current_activity;
            if(currentResponse && currentResponse.length && currentResponse[currentResponse.length - 1].activity_master_name && currentResponse[currentResponse.length - 1].activity_master_name !== 'Meal Period' && activities && activities.length && activities[activities.length - 1] && !activities[activities.length - 1].end_datetime && activities[activities.length - 1].activity_name === 'meal'){
              activities && activities.length && activities.map((item, index) => {
                if(index ===  activities.length - 1){
                    const newItem = {
                    ...item,
                    end_datetime : allActivity && allActivity.length && allActivity[allActivity.length - 1] && allActivity[allActivity.length - 1].end_datetime
                  };
                  return newActivityData.push(newItem);
                }
                  return newActivityData.push(item);
                });
            newActivityData.push(
              {...activities[activities.length - 2], 
                start_datetime: allActivity && allActivity.length && allActivity[allActivity.length - 1] && allActivity[allActivity.length - 1].end_datetime,
                end_datetime: null }
              );
            activityDetail ={
              employee_id : profileDetails.value.employee_id,
              data : newActivityData
            };    
            this.props.SessionDucks.saveActivityData(activityDetail)
            .then(() => {
              this.fetchData({...profileDetails.value, ...query});
             /* this.startTimer();*/
            });}
            else{
              this.fetchData({...profileDetails.value, ...query});
            }
          });
         });});
  }

  componentWillMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    // FIXME: this lifecycle is being called just before component unmounts (should not be happening)
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      this.props.ProfileDucks.getProfile()
        .then((res) => {
          this.fetchData({...nextQuery, ...res.value});
      });
    }
  }

  sendSessionId(){
    const { sessionDetail } = this.props;
    const responseId = cookies.get('responseId', { path: '/' });
    if(responseId && responseId!== '0'){
      // get reference to window inside the iframe
      var wn = document.getElementById('feedback_iframe').contentWindow;
      // postMessage arguments: data to send, target origin
      wn.postMessage(JSON.stringify({session_id: sessionDetail.id , response_id : responseId}), '*');
      cookies.remove('responseId' , { path: '/' });
    }
  }

  UpdateforcePunchOut(){
    this.setState({isForcePunchOut : true});
  }

  updateSession(){
    const { router: { history } } = this.context;
    this.setState({isForcePunchOut : false});
    history.push('/jobs/overview/');
  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.reloadScreen();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }

  fetchData(params){
    this.setState({isLoading : true});

    //singleton.job_id = cookies.get('job_id', { path: '/' });
    this.setState({ isLoading: true });
    this.props.SessionDucks.getActivityData(params.res ? params.res.value.employee_id : params.employee_id);
    this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 })  
    .then((res) => {
      const taskTobeAllocated = res && res.value.task_time_allocation && res.value.to_be_allocated;
      taskTobeAllocated && this.props.JobDucks.getJobs({employee_id : params.res ? params.res.value.employee_id : params.employee_id, paginate: false})
      .then((response) => {
        if(taskTobeAllocated && response && response.value && response.value.length === 1) {
            this.props.TaskDucks.getAllTasks({job_id : response.value[0].id, paginate: false});
        }});
        this.props.SessionDucks.getCurrentSession()
        .then((sessionDetail) => {
          if(Object.keys(sessionDetail).length !== 0 && !sessionDetail.id){
            this.setState({isForcePunchOut : true});
          }
          this.setState({isLoading : false, showReloadButton: false});
          this.props.JobDucks.getJobName({activity_id : sessionDetail.value.current_activity[0].id});
          setTimeout(this._reloadTime);
       })
       .catch(() => {
          this.setState({isLoading : false, showReloadButton: false});
          setTimeout(this._reloadTime);
       });
    });
  }

  reloadScreen(){
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.getData();
  }

  clickToReload(){
    this._reloadTime = setTimeout(() => {
      this.setState({showReloadButton: true});
      setTimeout(this._reloadTime);
    }, 8000);
  }

  render() {
    const {profile , companyDetail } = this.props;
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    const{ isLoading} = this.state;
    const { sessionDetail } = this.props;
    const currentActivity = sessionDetail && sessionDetail.current_activity;      
    if(Object.keys(sessionDetail).length !== 0 && !sessionDetail.id){
      return (
        <div>
          <ForcePunchOutModalComponent
            isOpen={this.state.isForcePunchOut}
            toggle={this.updateSession}
            update={this.updateSession}
          />
        </div>
        );
    }
    else{
      if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
      if(currentActivity && currentActivity.length && currentActivity[currentActivity.length - 1].activity_master_name === 'Meal Period'){
        return <Redirect to='/meals/break/' />;
      }
      if(currentActivity && !currentActivity.length ){
        return <Redirect to='/jobs/overview/' />;
      }
      return (
        <div className="relative">
          <div className="cf min-vh-100 pb4 dark-grey-bg relative">
            <div className="w-100 margin-list">
              {
                companyDetail.feedback ?
                  <iframe style={{display : 'none'}}  onLoad={this.sendSessionId} id='feedback_iframe' width="0" height="0"  src={`${server.feedbackHost}/?oAuthToken=${this.singleton.employeeAuth}&language=${this.singleton.language}&uniqueUuid=${this.uniqueUuid}&employeeId=${this.employeeId}&company_id=${this.companyId}&role_id=${this.roleId}&context=kiosk`}/>  
                  :
                  null
              }
              <HeaderSection 
                isTimeCardIcon="isTimeCardIcon" 
                isShiftNameicon ='isShiftNameicon' 
                currentActivity={currentActivity}
                fetchData={this.fetchData}
              />
              <TimeCardHeader
                heading={TRMSGS.workShift} 
                firstName={profile && profile.first_name}
                lastName={profile && profile.last_name}
              />
            </div>
            <div className="w-70-l w-90-ns margin-list pa2 pb5 sticky-footer">
              <JobShiftSection 
                UpdateforcePunchOut={this.UpdateforcePunchOut}
                isForcePunchOut = {this.state.isForcePunchOut}
                updateSession = {this.updateSession}
              />
            </div>
            <FooterSection />
          </div>
        </div>
      );
    }
  }}

const mapStateToProps = state => {
  return({
    location: RouteDucks.location(state),
    profile: ProfileDucks.profile(state),
    jobsList: JobDucks.jobsList(state),
    activityData : SessionDucks.activityData(state),
    companyDetail: EmployeeDucks.companyDetail(state),
    sessionDetail: SessionDucks.sessionDetail(state),
});
};

const mapActionsToProps = dispatch => ({
   JobDucks: bindActionCreators(JobDucks, dispatch),
   TaskDucks: bindActionCreators(TaskDucks, dispatch),
   SessionDucks: bindActionCreators(SessionDucks, dispatch),
   ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
   EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
   UserDucks: bindActionCreators(UserDucks, dispatch),
});


JobShiftPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobShiftPage);

