export default {
  URLS: {
    MEAL_PERIOD_SESSION: '/kiosk/activity/meal_period/',
    PUNCH_IN_SESSION: '/kiosk/activity/punch_in/',
    CONTINUE_SESSION: '/kiosk/activity/continue/',
    END_SESSION: '/kiosk/activity/end/',
    CURRENT_SESSION: '/kiosk/activity/session/current/',
    MESSAGE_SESSION: '/kiosk/activity/session/message/v2',
    ACTIVITY_SPLIT_LAST: '/kiosk/activity/split/last/',
    ACTIVITY:'/kiosk/',
    ACTIVITY_SPLIT:'/activity-split/',
    ACTIVITY_SPLIT_UPDATE: '/kiosk/activity/split/update/',
    ACTIVITY_SPLIT_SIGN_OFF: '/kiosk/activity/split/sign_off/',
    ACTIVITY_DATA : '/activity/activity-data/',
    ACTVITY_SPLIT_LIST : '/kiosk/activity/task/v2/',
    ACTIVITY_LAST_SPLIT: '/activity/session/last-split/',
    ACTIVITY_LOG : '/t2b/faces/face/add-log/'
  },
};
