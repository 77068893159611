import React from 'react';

import Modal from 'react-bootstrap-modal';
import TRMSGS from 'constants/Translations/Localization';

/**
 * ActivityListPage -> ACtivityListSection -> PunchInModalComponent
 *
 * Props:
 *    - update
 */
const ForcePunchOutModalComponent = (props) => {
  const { isOpen, toggle, update } = props;

  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      onHide={() => toggle('FORCE_PUNCH_OUT')}
      backdrop="static"
    >
      <Modal.Header closeButton className='custom-modal'>
      <Modal.Title id='ModalHeader' style={{fontWeight: '500'}}> {TRMSGS.youHaveBeenPunchedOut} </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="cf">
        <button 
          className="br2 pointer fr w-20 pb2 pt2 ph mr2 white btn-color f4"
          onClick={() => update('FORCE_PUNCH_OUT')}
          >
          {TRMSGS.ok}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ForcePunchOutModalComponent;
