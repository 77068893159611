import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as UserDucks from 'ducks/accounts/user';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import ActivityBiometricSection from 'sections/activities/biometric';
import TRMSGS from 'constants/Translations/Localization';

class ActivityBiometricPage extends React.PureComponent {

    constructor(props) {
      super(props);
      this.state = { isBackIcon: true };
      this.goToUrl = this.goToUrl.bind(this);
      this.backButtonHandler = this.backButtonHandler.bind(this);
      this.employeeImageLoginHandler = this.employeeImageLoginHandler.bind(this);
    }

    backButtonHandler(){
        this.props.history.goBack();
    };

    goToUrl() {
    const { router: { history } } = this.context;
    history.push('/dashboard');
    }

    employeeImageLoginHandler(img){
        const { router: { history } } = this.context;
        const edit = queryString.parse(this.props.location.search);
        if(this.props.location.state) {
            return this.props.EmployeeDucks.postActivate(this.props.location.state)
            .then(() => this.props.UserDucks.postImageAuthentication({images: img}))
            .then(() => history.push('/activity/list'));
        } else if(edit.edit){
            return this.props.UserDucks.resetFacialPassword({images: img})
            .then(() => history.push('/activity/list'));

        }else{
            return this.props.UserDucks.postImageAuthentication({images: img})
            .then(() => history.push('/activity/list'));
        }
    };

    render(){
        const qs = queryString.parse(this.props.location.search);
        {this.props.userPreferenceLanguage ? TRMSGS.setLanguage(this.props.userPreferenceLanguage) : TRMSGS.setLanguage('english')}
        return (
            <div className="relative">
                <div className="cf min-vh-100 pb4 dark-grey-bg relative">
                    <div className="fl w-100">
                        <HeaderSection 
                          isBackIcon="isBackIcon"
                          goToUrl = {this.goToUrl}
                        />
                    </div>
                    <TimeCardHeader heading={TRMSGS.biometricSetup}/>
                    <div className="fl w-100">
                        <ActivityBiometricSection
                            employeeImageLogin={this.employeeImageLoginHandler}
                            backButtonHandler={this.backButtonHandler}
                            webcam = {this.webcam}
                            query={qs}
                        />
                    </div>
                    <FooterSection />
                </div>
            </div>
        );
    };
}

ActivityBiometricPage.contextTypes = {
    router: PropTypes.object.isRequired,
  };

const mapStateToProps = state => ({
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
    EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
    UserDucks: bindActionCreators(UserDucks, dispatch),
});

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(ActivityBiometricPage);
