const {
  REACT_APP_FEEDBACK_BASE_URL,
} = process.env;

const config = {
  // Frontend url config
  server: {
    feedbackHost: REACT_APP_FEEDBACK_BASE_URL
  }
};

module.exports = config;
