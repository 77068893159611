import React from 'react';
import { bindActionCreators } from 'redux';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import StorageGateway from 'lib/storage-gateway';

import ForcePunchOutModalComponent from 'components/activities/Form/forcePunchOutModal';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import MealBreakSection from 'sections/meals/break/MealBreak';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import * as SessionDucks from 'ducks/activities/session';
import * as RouteDucks from 'ducks/routes';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class MealBreakPage extends React.PureComponent {

   constructor(props) {
    super(props);
    this.state = { isLoading: true ,isForcePunchOut : false, showReloadButton: false };
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.singleton = new Singleton();
    this.updateSession = this.updateSession.bind(this);
    this.UpdateforcePunchOut = this.UpdateforcePunchOut.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
  }

  componentWillMount() {
    const { location: { query } } = this.props;
    this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 });
    this.props.ProfileDucks.getProfile()
    .then((res) => {
        this.fetchData({ ...query, res});
        /*this.startTimer();*/
     });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      // this.fetchData({});
      this.fetchData(nextQuery);
    }
  }

   UpdateforcePunchOut(){
    this.setState({isForcePunchOut : true});
  }

  updateSession(){
    const { router: { history } } = this.context;
    this.setState({isForcePunchOut : false});
    history.push('/jobs/overview/');
  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

  fetchData(params){
    this.setState({ isLoading: true });
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const { profile , companyDetail } = this.props;
    Promise.all([
      this.props.SessionDucks.getCurrentSession(),
      this.props.SessionDucks.getActivityData(profile.employee_id),
      profile.employee_id && companyDetail.task_time_allocation && companyDetail.to_be_allocated &&
      this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false})
      .then((res) => {
          if((res.value).length === 1){
            this.props.TaskDucks.getTasks({job_id : res.value[0].id, paginate: false});
          } 
      })     
    ])
    .then(() => {
      this.props.SessionDucks.getCurrentSession()
      .then((res) => {
        if(Object.keys(res.value).length !== 0 && !res.value.id){
          this.setState({isForcePunchOut : true});
        }
        this.setState({isLoading : false, showReloadButton: false});
      });
      clearTimeout(this._reloadTimeOut);
    })
    .catch(() => {
      this.setState({isLoading : false, showReloadButton: false});
      clearTimeout(this._reloadTimeOut);
    }); 
  }

  componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }

  reloadScreen(){
    const { location: { query } } = this.props;
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 });
    this.props.ProfileDucks.getProfile()
    .then((res) => {
        this.fetchData({ ...query, res});
     });
  }

  clickToReload(){
    this._reloadTimeOut=setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }


  render() {
    const { profile , sessionDetail} = this.props;
    const currentActivity = sessionDetail && sessionDetail.current_activity;
    const { isLoading } = this.state;
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    if(Object.keys(sessionDetail).length !== 0 && !sessionDetail.id){
      return (
        <div>
          <ForcePunchOutModalComponent
          isOpen={this.state.isForcePunchOut}
          toggle={this.updateSession}
          update={this.updateSession}
          />
        </div>
        );
    }
    if(currentActivity && !currentActivity.length && sessionDetail.id){
      return <Redirect to='/jobs/overview/' />;
    }

    if(currentActivity && currentActivity.length && !(currentActivity.length && currentActivity[currentActivity.length - 1]).activity_master_name === 'meal'){
      return <Redirect to='/jobs/shift/' />;
    }

    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
    return (
      <div className="min-vh-100 pb4 dark-grey-bg relative">
        <div className="w-100 margin-list">
          <HeaderSection fetchData={this.fetchData} />
          <TimeCardHeader 
            heading={TRMSGS.mealBreak}
            firstName = {profile && profile.first_name}
            lastName = {profile && profile.last_name}
          />
        </div>
        <div className="w-70-l w-90-ns margin-list pa2 pb5">
          <MealBreakSection 
            UpdateforcePunchOut={this.UpdateforcePunchOut}
            isForcePunchOut = {this.state.isForcePunchOut}
            updateSession = {this.updateSession}
          />
        </div>
        <FooterSection />
      </div>
    );
  }
}

const mapStateToProps = state => 
{
  return({
  location: RouteDucks.location(state),
  profile: ProfileDucks.profile(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  activityData : SessionDucks.activityData(state),
  sessionDetail: SessionDucks.sessionDetail(state),
});
};

const mapActionsToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  JobDucks : bindActionCreators(JobDucks, dispatch),
  TaskDucks : bindActionCreators(TaskDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

MealBreakPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MealBreakPage);


