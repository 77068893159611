import React from 'react';

import PropTypes from 'prop-types';

const TableDataCellAtom = props => {
  const { children, className, ...rest } = props;

  return (
    <td
      {...rest}
      className={className}
    >
      {children}
    </td>
  );
};

TableDataCellAtom.defaultProps = {
  className: 'bb b--light-gray mw4 pa2 tr truncate',
};

TableDataCellAtom.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TableDataCellAtom;
