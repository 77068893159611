import React from 'react';

import PropTypes from 'prop-types';

import TimerService from 'lib/TimerService';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import TRMSGS from 'constants/Translations/Localization';
import styles from './styles.module.scss';

class MealReverseTimerComponent extends React.Component {
  constructor(props) {
    super(props);
    this._interval = null;
    this.timerService = new TimerService();
    this.state = {
      duration: this.updateReverseCount(),
    };
  }

  updateReverseCount() {
    const { FirstMealDetail, mealActivity } = this.props;
    if(Boolean(FirstMealDetail) === false) return;

    const mealStartTime = new Date(mealActivity.start_datetime);
    const currentTime = new Date();
    const timeConsumed = (currentTime.getTime() - mealStartTime.getTime())/60000;
    const timerDuration = FirstMealDetail.duration - timeConsumed;
    this.setState({duration: timerDuration,});
    return timerDuration;
  }

  componentDidMount() {
    this._interval = setInterval(() => {
      this.updateReverseCount();
    }, 2500);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    const { isMealEndButtonLoading, mealEndDisabled, userPreferenceLanguage } = this.props;
    const { duration } = this.state;
    return (
      <section className={userPreferenceLanguage === 'bilingual' ? ` ${styles['end_meal_break_bilingual']} ${styles['end_meal_break']}` : `${styles['end_meal_break']}`}>
        <button
          className="`w-70-ns bn white pv3 br2"
          style={!((duration > 0) || mealEndDisabled) ? {cursor : 'pointer' , backgroundColor:'#D83F3F'} : {backgroundColor:'#00000042'}}
          onClick={this.props.onClick}
          disabled={(duration > 0) || mealEndDisabled}>
          {TRMSGS.endMealBreak}
          <ButtonLoaderAtom active={isMealEndButtonLoading} />
        </button>
        {!((duration > 0) || mealEndDisabled) ? <p>{TRMSGS.nowEndYourMealBreak}</p>
          :
          <p>{TRMSGS.endYourMealBreak} {duration > 0 ? this.timerService.reverseCount(duration*60) : null}</p>
        }
      </section>
    );
  }
}

MealReverseTimerComponent.propTypes = {
  mealActivity: PropTypes.object,
  settings: PropTypes.object,
};

MealReverseTimerComponent.contextTypes = {};

export default MealReverseTimerComponent;
