import React from 'react';

import { Switch, Route } from 'react-router-dom';

import JobListPage from './List';
import JobNotesPage from './Notes';
import JobShiftPage from './Shift';
import JobCommentsPage from './Comment';
import JobShiftOverViewPage from './OverView';
import SurveyFeedback from './SurveyFeedback';

export const routes = [
  {
    component: JobListPage,
    exact: true,
    name: 'job.list',
    path: '/jobs/list',
  },
  {
    component: JobNotesPage,
    exact: true,
    name: 'job.notes',
    path: '/jobs/notes',
  },
  {
    component: JobShiftPage,
    exact: true,
    name: 'job.shift',
    path: '/jobs/shift',
  },
  {
    component: JobCommentsPage,
    exact: true,
    name: 'job.comment',
    path: '/jobs/comment',
  },
  {
    component: JobShiftOverViewPage,
    exact: true,
    name: 'job.overview',
    path: '/jobs/overview',
  },
  {
    component: SurveyFeedback,
    exact: true,
    name: 'job.fedback',
    path: '/jobs/feedback',
  },
];

const GenericRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default GenericRoutes;
