import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as EmployeeDucks from 'ducks/employees/employee';

import Cookies from 'universal-cookie';

import BiometricFaceSearchComponent from 'components/generics/dashboard/Form/BiometricFaceSearch';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

const cookies = new Cookies();
class DashboardBiometricFaceListSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.loadDataHandler = this.loadDataHandler.bind(this);
    this.employeeImageLogin = this.employeeImageLogin.bind(this);
    this.setImageLoginError = this.setImageLoginError.bind(this);
    this.state = {
      webcam: false,
      error: null,
    };
  }

  componentDidMount(){
    if(this.props.isLoading){
      this.props.clickToReload();
    }
  }

  employeeImageLogin(data) {
    const { profile } = this.props;
    return this.props.UserDucks.postImageAuthenticationByFace({image: data, company: profile.company.id })
      .then((res) =>{
        cookies.set('loginType', 'Face', { path: '/' });
        cookies.set('documentId' , res.value.image_doc_id, { path: '/' });
        this.checkEmployeeStatus();
      });
  }


  checkEmployeeStatus(){
    const { router: { history } } = this.context;
    this.props.SessionDucks.getCurrentSession()
    .then((response) =>       
    {
      this.props.ProfileDucks.getProfile()
      .then((profile) => {
        if(profile.value.employee_id){
        this.props.EmployeeDucks.getEmployee({ id: profile.value.employee_id });
        this.props.SessionDucks.getActivityData(profile.value.employee_id)
          .then((res) => {
            const activitydata = res && res.value && res.value.results && res.value.results[0] && res.value.results[0].data;
            if ( response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null && response.value.current_activity[response.value.current_activity.length - 1].activity_master_name === 'Meal Period') {
              history.push('meals/break');
              this.setState({submitButtonLoader : false});
            } 

            else if(response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null){
              history.push('jobs/shift');
              this.setState({submitButtonLoader : false});
            } 

            else if((response && response.value.create_date !== null && !(response.value.current_activity !== null && response.value.current_activity.length)) || (activitydata && activitydata.length !== 0)){
              history.push('jobs/overview');
              this.setState({submitButtonLoader : false});
            }
            else if(response && response.value.create_date === null && activitydata && activitydata.length === 0){
              history.push('activity/list');
              this.setState({submitButtonLoader : false});
            }
            else
              history.push('activity/list');
              this.setState({submitButtonLoader : false});
          });
        }
          });
    });
    }

  setImageLoginError(data, val) {
    this.setState({ error: data });
  }

  loadDataHandler(){
    setTimeout(() => {
      this.setState({webcam: true});
    }, 2500);
  }

  render () {
    const { isLoading, searchByFace } = this.props;
    const { webcam } = this.state;
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.props.showReloadButton} reloadScreen={this.props.reloadScreen}/>;
    return (
      <section className="mh3">
        <BiometricFaceSearchComponent
          webcam={webcam}
          loadDataHandler={this.loadDataHandler}
          employeeImageLogin={this.employeeImageLogin}
          setImageLoginError={this.setImageLoginError}
          searchByFace = {searchByFace}
          location={this.props.location}
          userPreferenceLanguage={this.props.userPreferenceLanguage}
        />

      </section>  
    );
  }
}

const mapStateToProps = state => ({
  profile : ProfileDucks.profile(state),
  location: RouteDucks.location(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  ProfileDucks :  bindActionCreators(ProfileDucks, dispatch),
  EmployeeDucks :  bindActionCreators(EmployeeDucks, dispatch),
});

DashboardBiometricFaceListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardBiometricFaceListSection);
