import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import Button from 'atoms/Button';

import styles from './styles.module.scss';

class ButtonGroupAtom extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {value: this.props.active};
  }
/*
  static propTypes = {
    options: PropTypes.array.isRequired,
    active: PropTypes.any.isRequired,
    onToggle: PropTypes.func.isRequired
  };*/

  componentWillReceiveProps(nextProps){
    if (this.props.active !== nextProps.active) {
      this.setState({ value: nextProps.active });
    }
  };

  handleActive(value){
    this.props.onToggle(value);
    if (value !== this.state.value) {
      this.setState({ value });
    }
  };

  render() {
    const { options, active } = this.props;
    return (
      <div className={styles['btn-group']}>
        {options && options.map(item => (
          <Button
            key={item.label}
            onClick={() => this.handleActive(item.value)}
            color={item.value === active ? 'success' : 'inactive'}
            className={item.value === active? 'btn-selected' : 'btn-inactive'}
            grow={false}
            title={item.label}
            style={{display: 'flex'}}
          >
          <div className="flex06 mt2 mb1">
          {item.icon}
          </div>
          <div className={`${styles['btn-group-content']} flex1 mt2 pt1`}>
           {item.label}
           </div>
          </Button>
        ))}
      </div>
    );
  }
}        

export default ButtonGroupAtom;