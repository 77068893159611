import React from 'react';
import Modal from 'react-bootstrap-modal';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import TextareaAtom from 'atoms/Textarea';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { toast } from 'react-toastify';

class UnscheduledCommentModal extends React.Component {
   constructor(props) {
     super(props);
     this.submit = this.submit.bind(this);
    }

    submit(data){
      const { saveMessage } = this.props;
      if(data.message && (data.message).trim()){
        saveMessage(data);
      }
      else{
        toast.error('Please enter a valid reason to punch in');
      }
      
    }
    
   render() {
     const { handleSubmit, isOpen, toggle, commentButtonLoading } = this.props;
     return (
       <Modal
          show={isOpen}
          aria-labelledby="ModalHeader"
          onHide={() => toggle()}
          backdrop="static"
          >
            <Modal.Header closeButton>
               <h3 className="mt0 mb2">
                Please specify the reason for this unscheduled punch in
                </h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(this.submit)}>
              <Modal.Body className="mt3">
              <div style={{margin: 'auto'}}>
                <Field
                  id="message"
                  name="message"
                  component={TextareaAtom}
                  placeholder="Write here..."
                  autoFocus
                  rows="10"
                />
                <div className="tr custom-button">
                   <button
                      type="submit"
                      className="br2 pointer pv2 ph5 w-100 white btn-color mb3">
                        Submit <ButtonLoaderAtom active={commentButtonLoading} />
                    </button>
                </div>
              </div>
              </Modal.Body>
            </form>
        </Modal>
        );
    }
}

export default reduxForm({
  form: 'UnscheduledShiftModal',
  })(UnscheduledCommentModal);