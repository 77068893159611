import React from 'react';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

class WelcomeListComponent extends React.Component {

  render() {
    const { punchIn , showButton, selectTask, selectJob, isButtonLoading, profile, punchInDisabled, userPreferenceLanguage} = this.props;
    return (
      <div className={`${styles['welcome_screen_container']} mt5`}>
        <div className={`${styles['welcome_container_body']} center w-100-ns`}>
          <img height="105" width="160" className="pb2" src={require('./logo.png')} alt="Site Name" /><br/>
          <img height="29.5" width="170" className="pb2" src={require('./name.png')} alt="Site Name" />
          <div className="mv5">
            <h1 className="mb3">{TRMSGS.welcome}, {profile.first_name}&nbsp;{profile.last_name}</h1>
            <p>{TRMSGS.readyToStartYourDay}</p>
          </div>
          {showButton && (showButton === 'punchIn' || showButton === 'PunchIn') &&
          <button disabled={punchInDisabled} className={userPreferenceLanguage === 'bilingual' ? `${styles['button_margin']} ${styles['welcome-button-bilingual']} w-50 w-50-ns btn-color pointer` :`${styles['button_margin']} w-50 w-50-ns btn-color pointer`} onClick={() => punchIn()}>
            {TRMSGS.punchin}<ButtonLoaderAtom active={isButtonLoading} />
          </button>
          }
          {showButton === 'tasks' && 
          <button disabled={punchInDisabled} className={userPreferenceLanguage === 'bilingual' ? `${styles['welcome-button-bilingual']} w-50 w-50-ns btn-color pointer` : "w-50 w-50-ns btn-color pointer"} onClick={() => selectTask()}>
            {TRMSGS.selectTask}  <ButtonLoaderAtom active={isButtonLoading} />
          </button>
          }
          {showButton === 'jobs' && 
          <button disabled={punchInDisabled} className={userPreferenceLanguage === 'bilingual' ? `${styles['welcome-button-bilingual']} w-50 w-50-ns btn-color pointer` : "w-50 w-50-ns btn-color pointer"} onClick={() => selectJob()}>
            {TRMSGS.selectJob} <ButtonLoaderAtom active={isButtonLoading} />
          </button>
          }
        </div>
      </div>
    );
  }
}

export default WelcomeListComponent;
