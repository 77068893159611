import React from 'react';
import MealListComponent from 'components/meals/list/mealList';

class MealListSection extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.updateSelected = this.updateSelected.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    console.log('Toggle method was called..');
  }

  updateSelected() {
    console.log('Update selected method was called...');
  }

  render() {
    return (
      <div>
        <MealListComponent />
      </div>
    );
  }
}

export default MealListSection;
