import moment from 'moment';

class TimerService {

  getCurrentTime() {
    return moment().format('MMMM Do YYYY, h:mm:ss a');
  }

  timeSince(datetime) {
    const currentDateTimeObj = new Date();
    const datetimeObj = new Date(datetime);
    return this.msToTime(currentDateTimeObj - datetimeObj);
    // let format = 'HH:mm:ss';
    // if(duration._data.hours == 0) {
    //   format = 'mm:ss';
    // }
    // return moment(duration._data).format(format);
  }

  reverseCount(seconds) {
    return this.msToTime(seconds * 1000);
    // let format = 'HH:mm:ss';
    // if(duration._data.hours == 0) {
    //   format = 'mm:ss';
    // }
    // return moment(duration._data).format(format);
  }

  msToTime(duration) {
    var  minutes = parseInt((duration/(1000*60))%60)
        , hours = parseInt((duration/(1000*60*60))% (200)); // hours more than 24 allowed (200)

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;

    return  (hours !== '00') ? (hours + ':' + minutes ) : (hours + ':' + minutes);
  }

}

export default TimerService;
