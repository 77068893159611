import { createSelector } from 'reselect';
import { fetch } from 'lib/api';
import createReducer from 'lib/createReducer';
import { DATE_FORMAT_MAPPINGS } from 'constants/records';
import constants from './constants';

const GET_BASIC_SETTING = 'basicSettings/basicSettings/GET_BASIC_SETTING';
const GET_BASIC_SETTING_FULFILLED = 'basicSettings/basicSettings/GET_BASIC_SETTING_FULFILLED';


export function getBasicSetting() {
  return {
    type: GET_BASIC_SETTING,
    payload: fetch(constants.URLS.BASIC_SETTINGS),
  };
}




const defaultState = {
  detail: {},
};

function GET_BASIC_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}



const handlers = {
  [GET_BASIC_SETTING_FULFILLED]: GET_BASIC_SETTING_FULFILLED_REDUCER,
};


const basicSettingSelector = state => state.basicSettings.basicSettings;

export const basicSettingDetail = createSelector(
  basicSettingSelector,
  instance => {
    return instance.detail;
  }
);

export const dateFormat = createSelector(
  basicSettingDetail,
  instance => 
  DATE_FORMAT_MAPPINGS[instance.date_format_display],
);


export const timeFormat24Hrs = createSelector(
  basicSettingDetail,
  instance => instance.time_format_24_hrs,
);

export const timeFormat = createSelector(
  basicSettingDetail,
  instance => instance.time_format_24_hrs ? 'H:mm' : 'h:mm a',
);

export default createReducer(defaultState, handlers);
