import { createSelector } from 'reselect';

import Cookies from 'universal-cookie';
import { fetch, post , put} from 'lib/api';
import Singleton from 'lib/singleton';
import createReducer from 'lib/createReducer';
import constants from './constants';

const cookies = new Cookies();


const GET_JOBS = 'jobs/jobs/GET_JOBS';
const GET_JOBS_FULFILLED = 'jobs/jobs/GET_JOBS_FULFILLED';
const MARK_FAVOURITE_JOB = 'jobs/jobs/MARK_FAVOURITE_JOB';
const MARK_UNFAVOURITE_JOB = 'jobs/jobs/MARK_UNFAVOURITE_JOB';
const GET_JOB_NOTES = 'jobs/jobs/GET_JOB_NOTES';
const GET_JOB_NOTES_FULFILLED = 'jobs/jobs/GET_JOB_NOTES_FULFILLED';
const SAVE_JOB_NOTES = 'jobs/jobs/SAVE_JOB_NOTES';
const POST_JOB_NAME = 'jobs/jobs/POST_JOB_NAME';
const GET_JOB_NAME = 'jobs/jobs/GET_JOB_NAME';
const GET_JOB_NAME_FULFILLED = 'jobs/jobs/GET_JOB_NAME_FULFILLED';
const RESET_JOB_LABEL = 'jobs/jobs/RESET_JOB_LABEL';
const RESET_JOBS = 'jobs/jobs/RESET_JOBS';


export function getJobs(params) {
  return {
    type: GET_JOBS,
    payload: fetch(constants.URLS.JOBS, { params }),
  };
}

export function resetJobs() {
  return {
    type: RESET_JOBS,
  };
}

export function getJobName(params) {
  return {
    type: GET_JOB_NAME,
    payload: fetch(`${constants.URLS.KIOSK}${constants.URLS.ACTIVITY}/${params.activity_id}/${constants.URLS.SHIFT_LABEL}/`),
  };
}

export function resetJobLabel() {
  return {
    type: RESET_JOB_LABEL,
  };
}



export function submitJobName(params) {
  const job_label = params.job_label;
  return {
    type: POST_JOB_NAME,
    payload: put(`${constants.URLS.KIOSK}${constants.URLS.ACTIVITY}/${params.activity_id}/${constants.URLS.SHIFT_LABEL}/` , {job_label}),
  };
}

export function markFavouriteJob(id) {
  const params ={
    assign_favourite : true,
    job_ids: [id]
  };
  return {
    type: MARK_FAVOURITE_JOB,
    payload: post(`${constants.URLS.MARK_FAVOURITE_JOB}/`, {...params}),
  };
}


export function markUnFavouriteJob(id) {
  const params ={
    assign_favourite : false,
    job_ids: [id]
  };
  return {
    type: MARK_UNFAVOURITE_JOB,
    payload: post(`${constants.URLS.MARK_FAVOURITE_JOB}/`, {...params}),
  };
}

export function getJobNotes() {
  const singleton = new Singleton();
  singleton.job_id = cookies.get('job_id', { path: '/' });
  const jobId = singleton.job_id;
  return {
    type: GET_JOB_NOTES,
    payload: fetch(`${constants.URLS.JOBS}${jobId}${constants.URLS.JOB_NOTES}?paginate=false`), 
  };
}

export function saveJobNotes(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const singleton = new Singleton();
  singleton.job_id = cookies.get('job_id', { path: '/' });
  const jobId = singleton.job_id;
  return {
    type: SAVE_JOB_NOTES,
    payload: post(`${constants.URLS.JOBS}${jobId}${constants.URLS.JOB_NOTES}`, data, config), 
  };
}

const defaultState = {
  joblist: [],
  jobNotelist: [],
  detail: {},
  job_name : {},
  count: 0,
};

const handlers = {
  [GET_JOBS_FULFILLED]: GET_JOBS_FULFILLED_REDUCER,
  [GET_JOB_NOTES_FULFILLED] : GET_JOB_NOTES_FULFILLED_REDUCER,
  [GET_JOB_NAME_FULFILLED] : GET_JOB_NAME_FULFILLED_REDUCER,
  [RESET_JOB_LABEL]: RESET_JOB_LABEL_REDUCER,
  [RESET_JOBS] : RESET_JOBS_REDUCER
};


function GET_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail : (action.payload.results && action.payload.results) ||  action.payload,
    count: action.payload.count ? action.payload.count : 0,
  });
}

function GET_JOB_NAME_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    job_name : (action.payload.results && action.payload.results) ||  action.payload
  });
}

function GET_JOB_NOTES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    jobNotelist : action.payload,
  });
}

function RESET_JOB_LABEL_REDUCER(state, action) {
  return Object.assign({}, state, {
    job_name: {},
  });
}


function RESET_JOBS_REDUCER(state, action) {
  //const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    detail: [],
    count: 0,
  });
}


const jobSelector = state => state.jobs.jobs;

export const jobsList = createSelector(
  jobSelector,
  instance => instance.detail,
);

export const jobNotesList = createSelector(
  jobSelector,
  instance => instance.jobNotelist,
);

export const jobName = createSelector(
  jobSelector,
  instance => instance.job_name,
);

export const jobCount = createSelector(
  jobSelector,
  instance => instance.count,
);




export default createReducer(defaultState, handlers);
