export default {
  URLS: {
    LOGIN: '/kiosk/login/',
    LOGOUT: '/kiosk/logout/',
    PROFILE: '/t2b/accounts/me/',
    AUTHENTICATION: '/kiosk/authentication/',
    IMAGE_AUTHENTICATION: '/t2b/faces/face/image/add/',
    IMAGE_AUTHENTICATION_AT_DASHBOARD: '/t2b/faces/face/login/',
    IMAGE_AUTHENTICATION_BY_FACE: '/t2b/faces/face/login/image/',
    FACIAL_RECOGNITION: '/t2b/faces/face/details/',
    EXTRA_DATA: '/t2b/accounts/user/extra-data/',
    VERIFY_SUPERVISOR: '/kiosk/supervisor/verification/',
    RESET_FACIAL_PASSWORD: '/t2b/faces/face/reset/',
    USER_PREFERENCE: '/t2b/accounts/user_preference/view/',
    PINCODE_LOGIN: '/employee/pincode',
    PINCODE_LOGIN_AT_DASHBOARD: '/kiosk/pincode/authentication/',
  },
};
