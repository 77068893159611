import React from 'react';

import Flatpickr from 'react-flatpickr';

import { ReactComponent as CalendarIcon } from './calendar.svg';

class DatePickerAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value : props.input.value};
  }

  handleBlur() {
    this.props.input.onBlur();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.input.value !== nextProps.input.value){
      this.setState({ value: nextProps.input.value });
    }
  }
  

  handleChange(val){
    const { input , options: { onChange, ...restOptions } } = this.props;
    if (restOptions.mode === 'range' || restOptions.mode === 'multiple') {
      input.onChange(val || null);
      if (onChange) onChange(val || null);
    } else {
      input.onChange(val[0] || null);
      if (onChange) onChange(val[0] || null);
    }
  }

  render() {
    const { input , label, meta: { touched, error }, options: {noError, onChange, ...restOptions }, ...rest } = this.props;
    return (
      <div>
        {label && <label htmlFor="name" className="f6 b db mb2">{label}</label>}
        <Flatpickr
          {...rest}
          value={this.state.value}
          className="input-reset ba b--black-20 pa2 br2 br--left db dib form-control calendar-input"
          // ref={(c) => this.date = c}
          onChange={(val) => this.handleChange(val)}
          options={{
            minuteIncrement: 1,
            ...restOptions,
          }}
        />
        <div className ="dib calendar-icon ba b--black-20"> 
          <CalendarIcon width="16" height="16"/>
        </div>
        <small className="dark-red f7 black-60 db mb2 h1" style={noError ? {display: 'none'} : null}>{touched && error}</small>
      </div>
    );
  }
}

export default DatePickerAtom;