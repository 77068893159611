import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Modal from 'react-bootstrap-modal';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import KeyboardInputAtom from 'atoms/KeyboardInput';
import TRMSGS from 'constants/Translations/Localization';

/**
 * HeaderSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class PasswordModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = { inputType: 'password', isOpenKeyboard: false };
  }

  submit(data) {
    const { submitPassword } = this.props;
    return submitPassword(data)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard : !isOpenKeyboard });
  }
  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }


  render() {
    const { handleSubmit, submitting, error, isOpen, toggle } = this.props;
    const { inputType, isOpenKeyboard } = this.state;

    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id='ModalHeader'>{TRMSGS.supervisorPasswordToConfirm}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
          <Modal.Body>
            <div className="error-text">{error}</div>
            <label htmlFor="password" className="db mb1 mt1">{TRMSGS.password}</label>
            <Field
              id="password"
              type={inputType}
              name="password"
              component={KeyboardInputAtom}
              toggle={this.toggleKeyboard}
              isOpen={isOpenKeyboard}
              inputType={inputType}
              placeholder={TRMSGS.password}
              className="ba b--black-20 w-40"
              toggleInputType={this.toggleInputType}
              eyeIcon
              isModal
              autoFocus
              data-lpignore={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <button 
            className="br2 pointer mt1 w-25 pv3 mr2 white  btn-color white  btn-color f3" style={{width: 'auto'}}>
              {TRMSGS.submit} <ButtonLoaderAtom active={submitting} />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  return errors;
};

PasswordModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  submitPassword: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'PasswordFormModal',
  validate,
})(PasswordModalComponent);