import { createSelector } from 'reselect';

import { fetch, post  } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const GET_ACTIVITY_SPLIT_LAST = 'activities/activitySplit/GET_ACTIVITY_SPLIT_LAST';
const GET_ACTIVITY_SPLIT_LAST_FULFILLED = 'activities/activitySplit/GET_ACTIVITY_SPLIT_LAST_FULFILLED';
const GET_ACTIVITY_SPLITS = 'activities/activitySplit/`GET_ACTIVITY_SPLITS`';
const GET_ACTIVITY_SPLITS_FULFILLED = 'activities/activitySplit/GET_ACTIVITY_SPLITS_FULFILLED';
const PUT_ACTIVITY_SPLIT_UPDATE = 'activities/activitySplit/PUT_ACTIVITY_SPLIT_UPDATE';
const PUT_ACTIVITY_SPLIT_UPDATE_FULFILLED = 'activities/activitySplit/PUT_ACTIVITY_SPLIT_UPDATE_FULFILLED';
const POST_ACTIVITY_SPLIT_SIGN_OFF = 'activities/activitySplit/POST_ACTIVITY_SPLIT_SIGN_OFF';
const POST_ACTIVITY_SPLIT_SIGN_OFF_FULFILLED = 'activities/activitySplit/POST_ACTIVITY_SPLIT_SIGN_OFF_FULFILLED';
const POST_ACTIVITY_SPLIT_LIST = 'activities/activitySplit/POST_ACTIVITY_SPLIT_LIST'; 
const POST_UPDATED_ACTIVITY_SPLIT_LIST = 'activities/activitySplit/POST_UPDATED_ACTIVITY_SPLIT_LIST';
const GET_ACTIVITY_LAST_SPLIT = 'activities/activitySplit/GET_ACTIVITY_LAST_SPLIT';
const GET_ACTIVITY_LAST_SPLIT_FULFILLED = 'activities/activitySplit/GET_ACTIVITY_LAST_SPLIT_FULFILLED';

export function getActivitySplitLast(params) {
  return {
    type: GET_ACTIVITY_SPLIT_LAST,
    payload: fetch(constants.URLS.ACTIVITY_SPLIT_LAST, params),
  };
}

export function postActivitySplitList(data, sessionId) {
  return {
    type: POST_ACTIVITY_SPLIT_LIST,
    payload: post(`${constants.URLS.ACTVITY_SPLIT_LIST}?session_id=${sessionId}&version=new`, data),
  };
}

export function postUpdatedActivitySplitList(data) {
  return {
    type: POST_UPDATED_ACTIVITY_SPLIT_LIST,
    payload: post('/activity/activity-split/', data),
  };
}

export function getActivitySplits(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_ACTIVITY_SPLITS,
    payload: fetch(`${constants.URLS.ACTIVITY}${id}${constants.URLS.ACTIVITY_SPLIT}`, { params }),
  };
}

export function postActivitySplitUpdate(data) {
  return {
    type: PUT_ACTIVITY_SPLIT_UPDATE,
    payload: post(`${constants.URLS.ACTIVITY_SPLIT_UPDATE}` , data),
  };
}

export function postActivitySignOff(data) {
  return {
    type: POST_ACTIVITY_SPLIT_SIGN_OFF,
    payload: post(constants.URLS.ACTIVITY_SPLIT_SIGN_OFF, data),
  };
}

export function getActivityLastSplit(params) {
  return {
    type: GET_ACTIVITY_LAST_SPLIT,
    payload: fetch(`${constants.URLS.ACTIVITY_LAST_SPLIT}?session_id=${params}&context={"channel":["kiosk"]}`),
  };
}


const defaultState = {
  list: [] ,
  detail: {},
  multiValidity: [],
};

function GET_ACTIVITY_SPLIT_LAST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload ,
  });
}


function GET_ACTIVITY_SPLITS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload ,
  });
}


function PUT_ACTIVITY_SPLIT_UPDATE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ACTIVITY_SPLIT_SIGN_OFF_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload ,
  });
}

function GET_ACTIVITY_LAST_SPLIT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    multiValidity: action.payload ,
  });
}


const handlers = {
  [GET_ACTIVITY_SPLIT_LAST_FULFILLED]: GET_ACTIVITY_SPLIT_LAST_FULFILLED_REDUCER,
  [GET_ACTIVITY_SPLITS_FULFILLED]: GET_ACTIVITY_SPLITS_FULFILLED_REDUCER,
  [PUT_ACTIVITY_SPLIT_UPDATE_FULFILLED]: PUT_ACTIVITY_SPLIT_UPDATE_FULFILLED_REDUCER,
  [POST_ACTIVITY_SPLIT_SIGN_OFF_FULFILLED]: POST_ACTIVITY_SPLIT_SIGN_OFF_FULFILLED_REDUCER,
  [GET_ACTIVITY_LAST_SPLIT_FULFILLED]: GET_ACTIVITY_LAST_SPLIT_FULFILLED_REDUCER,
};


const activitySplitSelector = state => state.activities.activitySplit;

export const activitySplitDetail = createSelector(
  activitySplitSelector,
  instance => instance.detail,
);

export const activitySplitList = createSelector(
  activitySplitSelector,
  instance => instance.list,
);
export const activityLastSplit = createSelector(
  activitySplitSelector,
  instance => instance.multiValidity,
);

export default createReducer(defaultState, handlers);
