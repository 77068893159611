import React from 'react';

import PropTypes from 'prop-types';

const ButtonAtom = props => {
  const { children, color, grow, outline, size, ...rest } = props;

  let className = 'pointer ';
  if (outline) {
    switch (color) {
      case 'primary':
        className += 'ba b--dark-green bg-transparent dark-green '; break;
      case 'secondary':
        className += 'ba b--navy bg-transparent navy '; break;
      default:
        className += 'ba b--black bg-transparent '; break;
    }
  } else {
    switch (color) {
      case 'primary':
        className += 'ba b--dark-green bg-dark-green white '; break;
      case 'secondary':
        className += 'ba b--navy bg-navy white '; break;
      case 'success':
        className += 'btn-selected'; break;
      case 'inactive':
        className += 'btn-inactive'; break;  
      default:
        className += 'ba b--black bg-black white '; break;
    }
  }

  switch (size) {
    case 'lg':
      className += ' f3 ph4 pv2 '; break;
    case 'sm':
      className += ' f6 ph2 pv1 '; break;
    default:
      className += ' f5 ph3 pv2 '; break;
  }

  if (grow) className += 'grow ';

  return (
    <button
      {...rest}
      className={className}
    >
      {children}
    </button>
  );
};

ButtonAtom.defaultProps = {
  color: 'default',
  grow: true,
  outline: false,
  size: 'default',
};

ButtonAtom.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  grow: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
};

export default ButtonAtom;
