import React from "react";
import { connect } from "react-redux";
import * as ProfileDucks from "ducks/accounts/profile";
import TRMSGS from "constants/Translations/Localization";
import NumericInput from "components/common/NumericInput";
import { toast } from "react-toastify";

class ActivityPincodeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pincode: ["", "", "", ""],
      confirmPincode: ["", "", "", ""],
      pincodeSubmitting: { disable: false, loader: false },
    };
    this.pincodeLogin = this.pincodeLogin.bind(this);
    this.handlePincodeChange = this.handlePincodeChange.bind(this);
    this.handleConfirmPincode = this.handleConfirmPincode.bind(this);
  }

  componentDidMount() {
    let updatedPincode;
    let updatedConfirmPincode;
    if (Array.isArray(this.state.pincode)) {
      updatedPincode = this.state.pincode.join("");
    }
    if (Array.isArray(this.state.confirmPincode)) {
      updatedConfirmPincode = this.state.confirmPincode.join("");
    }
    if (!updatedPincode && !updatedConfirmPincode) {
      this.setState({ pincodeSubmitting: { disable: true, loader: false } });
    } else {
      this.setState({ pincodeSubmitting: { disable: false, loader: false } });
    }
  }

  handlePincodeChange(pincode) {
    this.setState({ pincode: pincode.join("") });

    if (this.props.confirmPincodeField) {
      if (pincode.join("").length === 4) {
        if (Array.isArray(this.state.confirmPincode)) {
          if (this.state?.confirmPincode?.join("").length === 4) {
            this.setState({
              pincodeSubmitting: { disable: false, loader: false },
            });
          } else {
            this.setState({
              pincodeSubmitting: { disable: true, loader: false },
            });
          }
        } else if (this.state?.confirmPincode?.length === 4) {
          this.setState({
            pincodeSubmitting: { disable: false, loader: false },
          });
        } else {
          this.setState({
            pincodeSubmitting: { disable: true, loader: false },
          });
        }
      } else {
        this.setState({ pincodeSubmitting: { disable: true, loader: false } });
      }
    } else {
      if (pincode.join("").length === 4) {
        this.setState({ pincodeSubmitting: { disable: false, loader: false } });
      } else {
        this.setState({ pincodeSubmitting: { disable: true, loader: false } });
      }
    }
  }

  handleConfirmPincode(confirmPincode) {
    this.setState({ confirmPincode: confirmPincode.join("") });
    if (confirmPincode.join("").length === 4) {
      if (Array.isArray(this.state.pincode)) {
        if (this.state?.pincode?.join("").length === 4) {
          this.setState({
            pincodeSubmitting: { disable: false, loader: false },
          });
        } else {
          this.setState({
            pincodeSubmitting: { disable: true, loader: false },
          });
        }
      } else if (this.state?.pincode?.length === 4) {
        this.setState({ pincodeSubmitting: { disable: false, loader: false } });
      } else {
        this.setState({ pincodeSubmitting: { disable: true, loader: false } });
      }
    } else {
      this.setState({ pincodeSubmitting: { disable: true, loader: false } });
    }
  }

  pincodeLogin(event) {
    event.preventDefault();
    const { pincode, confirmPincode } = this.state;
    const { confirmPincodeField, employeePincodeLogin } = this.props;
    if (confirmPincodeField && pincode !== confirmPincode) {
      toast.error("Pincodes do not match!");
      return;
    }
    this.setState({ pincodeSubmitting: { disable: true, loader: true } });
    return employeePincodeLogin(
      confirmPincodeField ? confirmPincode : pincode
    ).catch((err) => {
      console.log(err);
      this.setState({ pincodeSubmitting: { disable: false, loader: false } });
    });
  }

  render() {
    const { pincode, pincodeSubmitting, confirmPincode } = this.state;
    const { confirmPincodeField } = this.props;
    const { src } = this.props.query;
    const companyName = this.props.profile.company
      ? this.props.profile.company.name
      : null;
    return (
      <div>
        {confirmPincodeField && (
          <>
            <div
              className="tc mb4"
              style={{
                minHeight: "34px",
                backgroundColor: "rgb(226, 236, 241)",
              }}
            >
              <div
                className=" pv2 tc "
                style={{ fontSize: "18px", color: "rgb(9, 129, 154)" }}
              >
                <span>{companyName}</span>
              </div>
            </div>
            <h4
              className="tc custom-text"
              style={{
                marginTop: "15px !important",
                marginBottom: "5px !important",
              }}
            >
              {src === "activity-list"
                ? `${TRMSGS.enterPincodeToReset}`
                : `${TRMSGS.enterPincodeToSetupPincodeRecognition}`}
            </h4>
          </>
        )}

        <div className="cf w-100 flex flex-column items-center">
          <div className="pa3 pb5">
            <span>
              <form onSubmit={this.pincodeLogin}>
                <div className="tc w-100">
                  <div>
                    <div
                      className="flex mb2"
                      style={{ justifyContent: "center", fontSize: "15px" }}
                    >
                      Enter Pincode
                    </div>
                    <div className="flex" style={{ justifyContent: "center" , flexDirection: 'column' }}>
                      <NumericInput
                        length={4}
                        flag={true}
                        onChange={this.handlePincodeChange}
                        values={pincode}
                        pincodeSubmitting={pincodeSubmitting}
                        button={confirmPincodeField ? false : true}
                      />
                    </div>
                  </div>
                  {confirmPincodeField && (
                    <div>
                      <div
                        className="flex mt5 mb2"
                        style={{ justifyContent: "center", fontSize: "15px" }}
                      >
                        Confirm Pincode
                      </div>
                      <div
                        className="flex"
                        style={{ justifyContent: "center" , flexDirection: 'column' }}
                      >
                        <NumericInput
                          length={4}
                          onChange={this.handleConfirmPincode}
                          values={confirmPincode}
                          confirmPincodeField={confirmPincodeField}
                          pincodeSubmitting={pincodeSubmitting}
                          button={true}
                          pincode={pincode}
                          confirmPincode={confirmPincode}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: ProfileDucks.profile(state),
});

export default connect(mapStateToProps)(ActivityPincodeSection);
