export const EN_TRANS = {
  useCamera: "Use Camera",
  search: "Search",
  login: "Login",
  reset: "Reset",
  enterEmployeeName: "Enter employee name",
  setupBiometricLogin: "Setup Biometric Login",
  setupPincodeLogin: "Setup Pincode Login",
  searchName: "Search Name",
  alignYourFacewithCameraToLogin: "Please align your face with the camera to login",
  enterPasswordToLogin: "Enter password to login",
  enterPincodeToLogin: "Enter pincode to login",
  loginFailed: "Login failed",
  unableToLoginUsingFacialRecognition: "If unable to login using facial recognition? You may use your password.",
  unableToLoginUsingPincode: "If unable to login using pincode? You may use your password.",
  retryFacialLogin: "Retry Facial Login",
  retryPincodeLogin: "Retry Pincode Login",
  enterPassword: "Enter Password",
  enterPincode: "Enter Pincode",
  password: "Password",
  pincode: "Pincode",
  accountIsInactiveOrIncorrectPassword: "Your account is inactive or maybe password is wrong.",
  resetFacialRecognition: "Reset Facial  Recognition",
  resetPincode: "Reset Pincode",
  trackYourDay: "Track your day",
  logout: "Logout",
  submit: "Submit",
  enterPasswordToConfirm: "Enter Password To Confirm",
  feedbackResponse: "Feedback Response",
  cannotPunchInAndContactYourSupervisor: "You cannot punch in. Please contact your supervisor.",
  thanksForYourFeedback: "Thanks for your feedback",
  welcome: "Welcome",
  readyToStartYourDay: "Ready to start your day?",
  selectJob: "Select Job",
  jobs: "Jobs",
  enterJobName: "Enter Job Name",
  totalRecords: "Total Records",
  tasks: "Tasks",
  enterTaskName: "Enter Task Name",
  workShift: "Work Shift",
  workShiftTime: "Work Shift Time",
  jobNotes: "Job Notes",
  switch: "Switch",
  mealBreak: "Meal Break",
  punchout: "Punchout",
  addNameToYourShift: "Add a name to your shift : Optional (100 words)",
  save: "Save",
  cancel: "Cancel",
  enterShiftLabel: "Enter a shift label",
  shiftLabelCondition: "Shift label should not be greater than 100 characters",
  addComment: "Add a Comment",
  enterComment: "Please enter a comment",
  toalMealBreakTime: "Total Meal Break Time",
  endMealBreak: "End Meal Break",
  endYourMealBreak: "You can end your meal break in",
  nowEndYourMealBreak: "You can now end your meal break",
  mealEndedAutomatically: "Your meal has been automatically ended",
  shiftOverView: "Shift Overview",
  edit: "Edit",
  approve: "Approve",
  submitCorrection: "Submit Correction",
  assign: "Assign",
  select: "Select",
  assignJobTasks: "Assign Job/Tasks",
  editJobTaskTime: "Edit Job And Task Time",
  editingTimeAdjustJobTaskTime: "Editing the time would automatically adjust the end & start times of both the jobs and tasks.",
  enterValidTime: "Enter valid time",
  cannotOverlapNextJob: "You cannot completely overlap the next job",
  cannotOverlapPreviousJob: "You cannot completely overlap the previous job",
  selectValidPunchoutTime: "Select a valid punch out time",
  punchinLessThanPunchout: "Punch in time cannot be more than punch out time",
  selectJobAndTask: "Please select job and task",
  comment: "Comment",
  changesToSchedule: "It looks like you made changes to your schedule.", 
  leaveComment: "Please leave a comment.*",
  enterCommentToContinue: "Please enter a comment to continue",
  sessionMessageLength: "Session message should be less than 500 characters",
  sessionAlreadyEnded: "The session is already ended",
  resumeLastJobOrSwitchToNewOne: "You can resume your last job or switch to new one",
  resume: "Resume",
  viewAllYourTimecardsHere: "View all your timecards here!",
  timecardDetails: "TimeCard Details",
  payrollName: "Payroll Name",
  payrollCycle: "Payroll Cycle",
  payroll: "Payroll",
  startDate: "Start Date", 
  endDate: "End Date",
  apply: "Apply",
  total: "Total",
  regularTime: "Regular Time",
  noRecordsFound: "No Records Found",
  customDateRange: "Custom Date Range",
  overtime: "Overtime",
  doubleOvertime: "Double Overtime",
  selectStartDate: "Select Start Date",
  selectEndDate: "Select End Date",
  startDateEndDateComparison: "The Start date should be less than or equal to end date",
  date: "Date",
  workDayTotal: "Work Day Total",
  shift: "Shift",
  mealPeriod: "Meal Period",
  moreInfo: "More Info",
  adjustmentActivity: "Adjustment Activity",
  totalWeeklyHours: "Total Weekly Hours",
  employeeActivatedSucessfully: "Employee activated sucessfully",
  signIn: "Sign in",
  username: "Username",
  quickMode: "Quick Mode",
  standardMode: "Standard Mode",
  enterUsername: "Enter Username",
  enterCompanyName: "Enter Company Name",
  welcomeToWorksanaKiosk: "Welcome to Worksana Kiosk",
  viewYouremployeeDailyTimecards: "Go view your employee's daily timecards!",
  goToLogin: "Go to Login",
  approveMyShift: "I approve my shift",
  goBack: "Go Back",
  submitYourCommentForCorrectionRequest: "Please submit your comment for correction request",
  youHaveBeenPunchedOut: "You have been punched out",
  ok: "OK",
  wantToEndMeal: "Are you sure you want to end meal?",
  no: "No",
  yes: "Yes",
  wantToStartMeal: "Are you sure you want to start meal?",
  wantToPunchIn: "Are you sure you want to punch in?",
  wantToPunchOut: "Are you sure you want to punch out?",
  approveYourShift: "Approve your shift",
  correction: "Correction",
  activateYourAccount: "Activate Your Account",
  activationCode: "Activation Code",
  confirmPassword: "Confirm Password",
  confirmPincode: "Confirm Pincode",
  enterActivationCode: " Enter Activation Code",
  passwordLength: "Password should contain atleast 6 characters",
  passwordLengthValidation: "Password should have at least 6 characters, 1 capital alphabet and 1 numeric character",
  advanceBrowserCheck: "Advance Browser Check",
  cameraPermission: "Camera Permission",
  browseNotSupported: "Browser Not Supported!",
  upgradeWebBrowser: "You are using an old version of the web browser please upgrade it to the latest version.",
  allowCameraAccess: "Please allow camera access",
  browserNotCompatibleWithWebcamAnduseSafari: "This browser is not compatible with webcam. We recommend you to use safari or a different web browser.",
  initiateLoginAttempt: "Initiate the login attempt",
  alignFaceToSetupFacialRecognition: "Please align your face with the camera and look straight into it to setup your facial recognition",
  enterPincodeToSetupPincodeRecognition: "Please enter pincode to setup your pincode recognition",
  email: "Email",
  register: "Register",
  enterEmail: "Enter e-mail",
  enterConfirmPassword: "Enter Confirm Password",
  passwordMismatch: "Password mismatch ",
  timecards: "Timecards",
  dailyTimecard: "Daily Timecard",
  timecardApproval: "This daily timecard requires approval or correction from the administrator.",
  timecardApproved: "This daily timecard is approved by the administrator.",
  department: "Department",
  team : "Team",
  hours: "HOURS",
  minutes: "MINUTES",
  small_hours: "Hours",
  small_minutes: "Minutes",
  searchAndLogin: "Please select or search your name and login to record your time.",
  hour:"Hour",
  minute: "Minute",
  enterComments: "Enter Comments",
  companyName:"Company Name",
  supervisorPasswordToConfirm: "Enter Supervisor Password To Confirm",
  punchin: "Punch In",
  selectTask: "Select Task",
  resetBiometricPassword: "Reset the biometric password",
  snap: "Snap",
  confirm: "Confirm",
  alignFaceWithCamTo: "Please align your face with the camera to",
  mealStart: "Meal Start",
  enableCameraPermission: "Please enable your camera permissions",
  English: "English",
  Spanish: "Spanish",
  Bilingual: "Bilingual",
  chooseLanguage: "Choose the language",
  language: "Languages",
  languageSettings: "Language Settings",
  alignFaceWithCamToResetBiometricPassword: "Please align your face with the camera to reset the biometric password",
  enterPincodeToReset: "Please enter pincode to reset",
  biometricSetup: "Biometric Setup",
  pincodeSetup: "Pincode Setup",
  maxCharacters: "Max 500 Characters",
  unallocatedTask: "UNALLOCATED TASK",
  thanks: "Thanks",
  clockedIn: "You are clocked-in",
  onBreak: "You are on break",
  clockedOut: "You are clocked-out",
  switchJobs: "You have switched jobs",
  allDepartments: "All Departments",
  allTeams: "All Teams",
  correctionRequest: "Correction Request",
};

export default EN_TRANS;