import React from 'react';
import styles from './styles.module.scss';

import reduxForm from 'redux-form/lib/reduxForm';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import KeyboardTextareaAtom from 'atoms/KeyboardTextarea';
import TRMSGS from 'constants/Translations/Localization';

class JobCommentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpenKeyboard: false };
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard : !isOpenKeyboard });
  }

  render() {
    const { submit , handleSubmit, isButtonLoading, profile, saveDisabled} = this.props;
    const { isOpenKeyboard } = this.state;
    return (
      <div>
        <form onSubmit={handleSubmit(submit)}>
          <article className="w-70-l center-l w-100-ns mb4">
            <div className={styles['job_comment_user_container']}>
              <p>{profile.first_name}&nbsp;{profile.last_name}</p>
              <p>
                {TRMSGS.changesToSchedule} <br/> {TRMSGS.leaveComment}
              </p>
            </div>
            <div className="w-70-lg w-100-ns" style={{
                margin: 'auto'
              }}>
              <Field
                id="message"
                name="message"
                component={KeyboardTextareaAtom}
                toggle={this.toggleKeyboard}
                isOpen={isOpenKeyboard}
                label={TRMSGS.comment}
                placeholder={TRMSGS.leaveComment}
                className="ba b--black-20 w-40"
                autoFocus
                rows="14"
            />
            </div>

            <div className={`${styles['comment_btn_container']} w-70-lg w-100-ns center`}>
              <button disabled={saveDisabled} className="btn-color pv3 white br2 w-100 back-arrow" type='submit'>
              {TRMSGS.submitCorrection} <ButtonLoaderAtom  active={isButtonLoading} />
              </button>
            </div>
          </article>
        </form>
      </div>
    );
  }
}

const JobCommentForm = reduxForm({
  form: 'JobCommentForm',
  enableReinitialize: true,
})(JobCommentsComponent);

// const selector = formValueSelector('JobNotesFormSection');

export default connect(
)(JobCommentForm);
