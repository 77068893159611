export const FILTER_FORM_LAYOUT = {
  size: 'sm',
};

export const FORM_LAYOUT = {
  labelCol: { sm: 12 },
  wrapperCol: { sm: 12 },
};

export default { FILTER_FORM_LAYOUT, FORM_LAYOUT };
