import React from 'react';

import {ReactComponent as ClearIcon} from './KeyboardInput/close.svg';



class FilterAtom extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { value: props.input && props.input.value };
    this.update = this.update.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.clearText = this.clearText.bind(this);
  }

  update() {
    const { submit, toggle, clear } = this.props;
    const { value } = this.state;
    if (submit) {
      if (value) return submit({search: value});
      else return clear();
    }
    toggle();
  }

  updateSearch (){
    const { submit , clear } = this.props;
    const { value } = this.state;
    if (submit) {
      if (value) return submit({search: value});
      else return clear();
    }
  }

  clearText (event) {
    const { clear } = this.props;
    this.setState({value: ''});
    clear();
  }

  render() {
    const {
      input,
      isOpen,
      label,
      searchIcon,
      toggle,
      inputType,
      toggleInputType,
      eyeIcon,
      isModal,
      clearIcon,
      meta: { touched, error },
      full_width,
      ...rest
    } = this.props;

    return (
      <div className={full_width ? 'w-100' : 'mw9'}>
        <div className="cf">
          <div className='w-100 relative'>
            <input
              {...input}
              {...rest}
              onInput={e => {
                this.setState({ value: e.target.value });
              }}
              className='pa2 w-100 ba b--black-20 br2 truncate'
              value={ this.state.value }
              ref={input => { this.inputNode = input;}}
            />
            {clearIcon &&
              <ClearIcon
                className="filter-clear-icon"
                height="10" width="10"
                onClick={this.clearText}
              />
            }
          </div>
          <small className="dark-red db h1 mt1 mb2">{touched && error}</small>
        </div>
      </div>
    );
  }
}

export default FilterAtom;
