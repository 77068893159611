import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import JobOverViewSection from 'sections/jobs/overview/OverView.jsx';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class JobShiftOverViewPage extends React.PureComponent {


  constructor(props) {
    super(props);
    this.state = { isLoading: true, isTimeCardIcon: true, showReloadButton: false, isCompMsgOpens: false};
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.singleton = new Singleton();
    this.overviewRef = null;
  }

  componentWillMount() {
    const {location: { query } } = this.props;
    this.props.ProfileDucks.getProfile()
    .then(() => {
        this.fetchData({ ...query});
        /*this.startTimer();*/
     });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      // this.fetchData({});
      this.fetchData(nextQuery);
    }
  }

  enablePageScrolling(){
    this.setState({pageScroll : true});
  }

  disablePageScrolling(){
    this.setState({pageScroll : false});
  }

  resetTimer(goBack){
    const { isCompMsgOpens } = this.state;
    if(goBack){
      this.setState({isCompMsgOpens: false});
    }
    else{
      this.setState({isCompMsgOpens:true});
    }
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   startTimer() {
    const { router: { history } } = this.context;
    const {isCompMsgOpens} = this.state;
    let time = isCompMsgOpens ? 25000 : 32000;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, time);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }



  fetchData(params){
    const { profile } = this.props;
    this.setState({ isLoading: true });
    this.props.SessionDucks.getCurrentSession();
    this.props.UserDucks.getExtraData();
    this.props.SessionDucks.getActivityData(profile.employee_id)
    .then((res)=>{
      const sessionId = res && res.value && res.value.results && res.value.results[0] && res.value.results[0].data && res.value.results[0].data[0] && res.value.results[0].data[0].session_id && res.value.results[0].data[0].session_id;
      this.props.ActivitySplitDucks.getActivityLastSplit(sessionId)
      .then(()=>{
        this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 })   
        .then((res) => {
          const toBeAllocated = res && res.value.task_time_allocation && res.value.to_be_allocated;
          this.props.ActivitySplitDucks.getActivitySplitLast();
          toBeAllocated && this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false})
          .then((response) => {
            if (toBeAllocated && response && response.value && response.value.length === 1) {
              this.props.TaskDucks.getTasks({job_id : response.value[0].id, paginate: false})
              .then(() => {
                this.setState({isLoading : false, showReloadButton: false});
                  })
              .catch(() => {
                  this.setState({isLoading : false, showReloadButton: false});
            });
            }
            else{
              this.setState({isLoading : false, showReloadButton: false});
            }
          });
          !toBeAllocated && this.setState({isLoading : false, showReloadButton: false});
          clearTimeout(this._reloadTimeOut)
        });
      })
    })
  }

  reloadScreen(){
    const {location: { query } } = this.props;
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.props.ProfileDucks.getProfile()
    .then(() => {
        this.fetchData({ ...query});
     });
  }

  clickToReload(){
    this._reloadTimeOut=setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }

  
  render() {
    const { companyDetail, activityData , profile } = this.props;
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    if(Object.keys(this.props.sessionDetail).length !== 0 && !this.props.sessionDetail.id && activityData && activityData.length === 0){
      return <Redirect to="/activity/list" />;
    }
    const { isLoading, isTimeCardIcon } = this.state;
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
    return (
      <div ref={(ref) => this.overviewRef = ref} className="min-vh-100 pb4 dark-grey-bg relative bounce_disabled">
        <div className="w-100 margin-list">
          <HeaderSection 
            isTimeCardIcon={isTimeCardIcon}
            fetchData={this.fetchData}
          />
          <TimeCardHeader 
            heading={TRMSGS.shiftOverView} 
          />
        </div>
        <div >
          <JobOverViewSection 
            manualCorrection ={this.props.companyDetail.manual_entry_by_employee}
            activityData = {this.props.activityData}
            companyDetail= {companyDetail}
            resetData={this.fetchData}
            overviewRef = {this.overviewRef}
            resetTimer={this.resetTimer}
            userExtraData={this.props.userExtraData}
            firstName = {profile && profile.first_name}
            lastName = {profile && profile.last_name}
          />
        </div>
        <FooterSection 
          disableAbsolute
        />
      </div>

      
    );
  }
}

const mapStateToProps = state => 
{
  return({
  companyDetail: EmployeeDucks.companyDetail(state),
  profile: ProfileDucks.profile(state),
  activityData : SessionDucks.activityData(state),
  sessionDetail: SessionDucks.sessionDetail(state),
  userExtraData: UserDucks.userExtraData(state),
});
};

const mapActionsToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ActivitySplitDucks : bindActionCreators(ActivitySplitDucks , dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

JobShiftOverViewPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobShiftOverViewPage);
