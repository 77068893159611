import React from 'react';
import JobNotesComponent from 'components/jobs/notes/jobNotes';
import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import reduxForm from 'redux-form/lib/reduxForm';
import Field from 'redux-form/lib/Field';
import InputAtom from 'atoms/Input';
import PropTypes from 'prop-types';


import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as SendIcon } from 'images/send.svg';

import * as JobDucks from 'ducks/jobs/jobs';
import * as SessionDucks from 'ducks/activities/session';
import * as TaskDucks from 'ducks/Tasks/tasks';
import styles from './styles.module.scss';


class JobNotesSection extends React.Component {
  constructor() {
    super();
    this.onImageChange = this.onImageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = { image: null, showModal : false , files: [], noteField : null , notesLoader : false};
  }

  onImageChange(event){
    const fileArray = event.target.files;
    this.setState((state) => ({ files: fileArray, path: event.target}));
       this.setState({image: URL.createObjectURL(event.target.files[0])});
       this.setState({showModal : true});
     }

  handleChange(val){
    this.setState({noteField : val});
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.jobNotesList.length !== this.props.jobNotesList.length){
      this.setState({notesLoader: false});
    }
  }


  toggle(){
    this.setState({image: null, imageURL : null, files:[], noteField : ''});
  }

  submit(item){
    const { change } = this.props;
    this.setState({noteField : ''});
    const { files} = this.state;
    let documentData;
    if ((this.state.image!== null && this.state.image.length > 0) || item.notes !== null) {
      documentData = new FormData();      
      this.state.files[0] && documentData.append('files', files[0]);
      documentData.append('notes', item && item.note ? item.note : '');
    }
    this.toggle();
    this.setState({notesLoader : true});
    return this.props.JobDucks.saveJobNotes(documentData)
    .then(() => {
      this.props.JobDucks.getJobNotes({paginate: false});
      change('note', null);
     })
    .catch(() => {
      this.setState({notesLoader: false});
    });
  }

  render() {
    const { handleSubmit, selectedJob, jobNotesList } = this.props;
    return (
      <div>
        <p className="job_notes_text">
          { selectedJob && selectedJob}
        </p>
        <JobNotesComponent
          onImageChange={this.onImageChange}
          submit = {this.submit}          
          onSubmit={this.submit}
          jobNotesList ={jobNotesList}
          handleChange ={this.handleChange}
          value={this.state.noteField}
          isLoading= {this.state.notesLoader}
         />
        {this.state.image!== null &&
          <Modal
            show={this.state.showModal}
            aria-labelledby="ModalHeader"
            backdrop="static"
          >

            <Modal.Header className={`${styles['job_notes_modal_header']}`}>
              <BackIcon height="35" width="35" id="back" className="back-icon" onClick={() => this.toggle()}/>
            </Modal.Header>
            <Modal.Body className="gallery-modal-body">
              <div>
                <img alt="" src={this.state.image} width="100%" height="400"/>
                
              </div>

              <div className={`${styles['job_notes_comment_box']}`}>
                <form className={`${styles['post_comment_container']} w-100 mt5 pv2 ph1`} onSubmit={handleSubmit(this.submit)} >
                  <div className={`${styles['comment-box']}`}>
                    <Field component={InputAtom} placeholder="Add a comment" className="w-100 h-100 pl2 br2 bn" name='note'/>
                  </div>
                  <button type='submit'>
                    <SendIcon height="35" width="35"/>
                  </button>
                </form>
              </div>
              
            </Modal.Body>
          </Modal>
            }
      </div>
    );
  }
}



const mapStateToProps = state => 
{
  return({
  activityData : SessionDucks.activityData(state),
  jobsList: JobDucks.jobsList(state),
  taskList: TaskDucks.taskList(state),
});
};

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobNotesSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

const JobNotesFormSection = reduxForm({
  form: 'JobNotesFormSection',
  enableReinitialize: true,
})(JobNotesSection);

// const selector = formValueSelector('JobNotesFormSection');

export default connect(
  mapStateToProps,
  mapActionsToProps, 
)(JobNotesFormSection);
