import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { bindActionCreators } from "redux";
import moment from "moment";
import PropTypes from "prop-types";
import Singleton from "lib/singleton";
import Cookies from "universal-cookie";
import StorageGateway from "lib/storage-gateway";
import queryString from "query-string";

import HeaderSection from "sections/common/Header";
import TimeCardHeader from "sections/common/TimeCardHeader";
import FooterSection from "sections/common/Footer";
import SectionLoaderReloadBtnAtom from "atoms/SectionLoaderReloadButton";

import * as EmployeeDucks from "ducks/employees/employee";
import * as ProfileDucks from "ducks/accounts/profile";
import * as SessionDucks from "ducks/activities/session";
import * as JobDucks from "ducks/jobs/jobs";
import * as TaskDucks from "ducks/Tasks/tasks";
import * as UserDucks from "ducks/accounts/user";
import * as ActivitySplitDucks from "ducks/activities/activitySplit";
import ConfirmationApprovalModalComponent from "components/activities/Form/confirmationApprovalModal.jsx";
import { server } from "lib/config";

import TRMSGS from "constants/Translations/Localization";
const cookies = new Cookies();

class SurveyFeedbackPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isTimeCardIcon: true,
      showReloadButton: false,
      isApproveShiftOpen: false,
      condn: null,
      sessionData: null,
      isConfirmationButtonLoading: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.sendSessionId = this.sendSessionId.bind(this);
    this.approveShift = this.approveShift.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.approvedRequest = this.approvedRequest.bind(this);
    this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.logout = this.logout.bind(this);
    this.singleton = new Singleton();
    this.overviewRef = null;
    this.employeeId = null;
    this.uniqueUuid = null;
    this.companyId = null;
    this.roleId = null;
    this.dept_id = null;
    this.team_id = null;
  }

  componentWillMount() {
    const {
      location: { query },
    } = this.props;
    this.props.ProfileDucks.getProfile().then(() => {
      this.fetchData({ ...query });
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      this.fetchData(nextQuery);
    }
  }

  logout() {
    const {
      router: { history },
    } = this.context;
    this.singleton.employeeAuth = null;
    cookies.remove("employeeToken", { path: "/" });
    cookies.remove("job_id", { path: "/" });
    cookies.remove("responseId", { path: "/" });
    cookies.remove("userLanguagePreferenceCookie", { path: "/" });
    cookies.remove("documentId", { path: "/" });
    this.singleton.language = null;
    StorageGateway.remove("imageUrl");
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    this.props.UserDucks.resetUserPreferedLanguage();
    clearTimeout(this._timeOut);
    history.push("/dashboard");
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
      console.log('listner',data)
      if (
      data &&
      data.response_id &&
      data.action == "SUCCESS" &&
      data.screenContext == "feedback"
    ) {
      cookies.remove("responseId", { path: "/" });
      this.saveActivity();
    } else if (data.message == "Logout") {
      this.logout();
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    if (this.state.isLoading) {
      this.clickToReload();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  // componentDidMount() {
  //   window.addEventListener("message", (event) => {
  //     const data = event.data && JSON.parse(event.data)
  //     console.log('data.message1',data)
  //     if(data && data.response_id && data.action == 'SUCCESS' && data.screenContext == 'feedback'){
  //     cookies.remove('responseId', { path: '/' });
  //     this.saveActivity();
  //     }
  //   }, false);

  //   window.addEventListener("message", (event) => {
  //     const data = event.data && JSON.parse(event.data)
  //     console.log('data.message',data)
  //     const isLogoutTrue = data.message;
  //     if(isLogoutTrue == 'Logout'){
  //       this.logout();
  //     }
  //   }, false);
  //   if(this.state.isLoading){
  //     this.clickToReload();
  //   }
  // }

  fetchData(params) {
    this.setState({ isLoading: true });
    Promise.all([
      this.props.ProfileDucks.getProfile(),
      this.props.SessionDucks.getCurrentSession(),
      this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 }),
    ])
      .then(() => {
        const { profile } = this.props;
        this.props.ActivitySplitDucks.getActivitySplitLast();
        this.props.SessionDucks.getActivityData(this.props.profile.employee_id);
        this.employeeId = profile.employee_id;
        this.uniqueUuid = profile.unique_uuid;
        this.companyId = profile.company && profile.company.id;
        this.roleId = profile.role_id;
        this.dept_id =
          profile.department && profile.department.id
            ? profile.department.id
            : null;
        this.team_id =
          profile.department &&
          profile.department.team &&
          profile.department.team.id
            ? profile.department.team.id
            : null;
        this.setState({ isLoading: false, showReloadButton: false });
      })
      .catch(() =>
        this.setState({ isLoading: false, showReloadButton: false })
      );

    clearTimeout(this._reloadTimeOut);
  }

  saveActivity() {
    const { companyDetail } = this.props;

    // if(companyDetail.enable_daily_compliance){
    //   this.setState({isApproveShiftOpen : true});
    // }
    // else{
      console.log('save activity data')
    this.approveShift();
    // }
  }

  approvedRequest() {
    this.setState({ isApproveButtonLoading: true });
    this.approveShift();
  }

  approveShift() {
    const { activitySplitList, activityData, sessionDetail, profile } =
      this.props;
    const {
      router: { history },
    } = this.context;
    const mode = cookies.get("loginMode", { path: "/" });
    let activityId = [];
    activitySplitList &&
      activitySplitList.filter((item) => {
        if (item) {
          activityId.push(item.id);
        }
        return true;
      });
    const activities = activityData;
    console.log(profile, "profile");
    const newActivityData =
      activities &&
      activities.length &&
      activities.filter((item) => item.job_id && item.task_id);
    let emptyActivityData = {
      employee_id: profile.employee_id,
      data: [],
    };
    if (newActivityData && newActivityData.length) {
      this.props.ActivitySplitDucks.postActivitySplitList(
        newActivityData,
        activities[0].session_id
      )
        .then(() => {
          sessionDetail &&
            sessionDetail.create_date !== null &&
            this.props.ActivitySplitDucks.postActivitySignOff({
              split_ids: activityId,
            });
          this.props.SessionDucks.saveActivityData(emptyActivityData);
          this.confirmationModalToggle();
          this.setState({ isApproveButtonLoading: false });
          this.props.SessionDucks.resetActivityData();
          this.props.JobDucks.resetJobLabel();
          if (mode == "quick") {
            history.push({
              pathname: "/success/",
              search: queryString.stringify({
                name: profile && `${profile.first_name} ${profile.last_name}`,
                type: "punchout",
              }),
            });
          } else {
            history.push("/activity/list");
          }
        })
        .catch(() => {
          this.confirmationModalToggle();
          this.setState({ isApproveButtonLoading: false });
          this.props.SessionDucks.resetActivityData();
        });
    } else {
      sessionDetail &&
        sessionDetail.create_date !== null &&
        this.props.ActivitySplitDucks.postActivitySignOff({
          split_ids: activityId,
        });
      this.props.SessionDucks.saveActivityData(emptyActivityData);
      this.confirmationModalToggle();
      this.setState({ isApproveButtonLoading: false });
      this.props.SessionDucks.resetActivityData();
      this.props.JobDucks.resetJobLabel();
      if (mode == "quick") {
        history.push({
          pathname: "/success/",
          search: queryString.stringify({
            name: profile && `${profile.first_name} ${profile.last_name}`,
            type: "punchout",
          }),
        });
      } else {
        history.push("/activity/list");
      }
    }
  }

  reloadScreen() {
    const {
      location: { query },
    } = this.props;
    this.setState({ showReloadButton: false });
    this.clickToReload();
    this.props.ProfileDucks.getProfile().then(() => {
      this.fetchData({ ...query });
    });
  }

  clickToReload() {
    this._reloadTimeOut = setTimeout(() => {
      this.setState({ showReloadButton: true });
      clearTimeout(this._reloadTimeOut);
    }, 8000);
  }

  confirmationModalToggle() {
    const { isApproveShiftOpen } = this.state;
    this.setState({ isApproveShiftOpen: !isApproveShiftOpen });
  }

  sendSessionId() {
    const { sessionDetail } = this.props;
    const responseId = cookies.get("responseId", { path: "/" });
    if (responseId && responseId !== "0") {
      // get reference to window inside the iframe
      var wn = document.getElementById("feedback_iframe").contentWindow;
      // postMessage arguments: data to send, target origin
      wn.postMessage(
        JSON.stringify({
          session_id: sessionDetail.id,
          response_id: responseId,
        }),
        "*"
      );
      cookies.remove("responseId", { path: "/" });
    }
  }

  render() {
    const { profile, activityData } = this.props;
    const { isApproveShiftOpen, isApproveButtonLoading } = this.state;
    let sessionId = [];
    activityData &&
      activityData.length &&
      activityData.forEach((i) => {
        if (i.activity_name !== "meal" && i.sess_id) {
          sessionId.push(i.sess_id);
        }
      });
    let sessId = sessionId && sessionId.length ? sessionId[0] : undefined;
    if (sessId === undefined) {
      let newSessionId = [];
      activityData &&
        activityData.length &&
        activityData.forEach((i) => {
          if (i.activity_name !== "meal" && i.activity_id) {
            newSessionId.push(i.activity_id);
          }
        });
      sessId = newSessionId && newSessionId.length ? newSessionId[0] : "0";
    }
    const startDate =
      activityData &&
      activityData.length &&
      activityData[0].start_datetime &&
      moment(activityData[0].start_datetime).format("YYYY/MM/DD h:mm a");
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    const { isLoading, isTimeCardIcon } = this.state;
    if (isLoading)
      return (
        <SectionLoaderReloadBtnAtom
          active
          showReloadButton={this.state.showReloadButton}
          reloadScreen={this.reloadScreen}
        />
      );
    return (
      <div
        className="min-vh-100 pb4 dark-grey-bg relative bounce_disabled"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="w-100 margin-list">
          <HeaderSection
            isTimeCardIcon={isTimeCardIcon}
            fetchData={this.fetchData}
          />
          <TimeCardHeader
            heading={TRMSGS.shiftOverView}
            firstName={profile && profile.first_name}
            lastName={profile && profile.last_name}
          />
        </div>
        <div
          style={{
            height: "78vh",
            overflowX: "hidden",
            overflowY: "hidden",
            paddingBottom: "5rem",
          }}
        >
          <iframe
            id="feedback_iframe"
            width="100%"
            height="100%"
            frameBorder="0"
            key="post-punchout"
            src={`${server.feedbackHost}/?oAuthToken=${this.singleton.employeeAuth}&language=${this.singleton.language}&uniqueUuid=${this.uniqueUuid}&employeeId=${this.employeeId}&company_id=${this.companyId}&role_id=${this.roleId}&context=kiosk&dept_id=${this.dept_id}&team_id=${this.team_id}&meal_survey=true&session_id=${sessId}&start_date=${startDate}&screen_context=feedback`}
          />
        </div>
        <FooterSection disableAbsolute />
        {isApproveShiftOpen && (
          <ConfirmationApprovalModalComponent
            isOpen={isApproveShiftOpen}
            toggle={this.confirmationModalToggle}
            approvedRequest={this.approvedRequest}
            isButtonLoader={isApproveButtonLoading}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
            message={
              this.props.companyDetail &&
              this.props.companyDetail.daily_compliance_message
                ? this.props.companyDetail.daily_compliance_message
                : null
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyDetail: EmployeeDucks.companyDetail(state),
    profile: ProfileDucks.profile(state),
    sessionDetail: SessionDucks.sessionDetail(state),
    activitySplitList: ActivitySplitDucks.activitySplitList(state),
    activityData: SessionDucks.activityData(state),
    userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
  };
};

const mapActionsToProps = (dispatch) => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
});

SurveyFeedbackPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(SurveyFeedbackPage);
