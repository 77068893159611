import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';

import FilterAtom from 'atoms/filterAtom';
import {ReactComponent as SearchIcon} from './search.svg';
import TRMSGS from 'constants/Translations/Localization';

class JobFilterComponent extends React.Component {
  constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  submit(data){
    const trimSpaces = (data.search).trim();
    const { list } = this.props;
    const filtered = list && list.filter(i => i.name.indexOf(trimSpaces) > -1);
    this.props.updateList(filtered);
  }


  render() {
    const {  handleSubmit } = this.props;
    return (
    <form  onSubmit={handleSubmit(this.submit)} noValidate className="job_filter_component mb0">
      <Field
        component={FilterAtom}
        placeholder={this.props.heading=== `${TRMSGS.jobs}` ? `${TRMSGS.enterJobName}` : `${TRMSGS.enterTaskName}`}
        name='search'
        type='text'
        className="br2"
        onChange = {(search) => this.submit({search : search.target.value})}
        full_width
      />
      <button className="btn-color custom_border" type='submit'>
        <SearchIcon height = '20' width = '15'/>
      </button>
    </form>
    );
  }
}

JobFilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

JobFilterComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};


const FilterForm = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(JobFilterComponent);

const selector = formValueSelector('FilterForm');

export default connect(
  (state) => {
    const search = selector(state, 'search');
    return { search };
  },
)(FilterForm);
