import AccountsRoutes, { routes as AccountsRoutesConfig } from 'pages/accounts/Routes';
import GenericRoutes, { routes as GenericRoutesConfig } from 'pages/generics/Routes';
import ActivityRoutes from 'pages/activities/Routes';
import TimecardRoutes from 'pages/timecards/Routes';
import JobRoutes from 'pages/jobs/Routes';
import TaskRoutes from 'pages/tasks/Routes';
import MealRoutes from 'pages/meals/Routes';


export const routes = AccountsRoutesConfig.concat(
  GenericRoutesConfig).concat(ActivityRoutes).concat(TimecardRoutes);

export default [
  {
    path: '/accounts',
    component: AccountsRoutes,
  },
  {
    path: '/activity',
    component: ActivityRoutes,
  },
  {
    path: '/timecard',
    component: TimecardRoutes,
  },
  {
    path: '/jobs',
    component: JobRoutes,
  },
  {
    path: '/tasks',
    component: TaskRoutes,
  },
  {
    path: '/meals',
    component: MealRoutes,
  },
  {
    path: '/',
    component: GenericRoutes,
  },
];
