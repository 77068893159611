import LocalizedStrings from 'react-localization';
import { EN_TRANS } from 'constants/Translations/en';
import { SP_TRANS } from 'constants/Translations/sp';

let BI_TRANS = {}
Object.keys(EN_TRANS).map(i => BI_TRANS[i]=`${EN_TRANS[i]} | ${SP_TRANS[i]}`)

export const TRMSGS = new LocalizedStrings({
  english: EN_TRANS,
  spanish: SP_TRANS,
  bilingual: BI_TRANS
});

export default TRMSGS;