import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

import JobListComponent from 'components/jobs/list/jobList';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { toast } from 'react-toastify';

import * as ProfileDucks from 'ducks/accounts/profile';
import * as JobDucks from 'ducks/jobs/jobs';
import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as SessionDucks from 'ducks/activities/session';

import UnscheduledShiftConfirmationComponent from 'components/activities/List/unscheduledShiftConfirmation'

const cookies = new Cookies();

class JobListSection extends React.Component {
  constructor(props) {
    super(props);
    this.markFavourite = this.markFavourite.bind(this);
    this.markUnFavourite = this.markUnFavourite.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.toggleConfirmationPopup= this.toggleConfirmationPopup.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
    this.state = { isProcessing: false, isOpen : false, selectedJobState:null, selectedTaskState:null, commentButtonLoading:false};
  }

  toggleConfirmationPopup(){
    const { isOpen } = this.state;
    this.setState({isOpen : !isOpen });
  }

  saveMessage(data){
    const {selectedJobState , selectedTaskState, commentButtonLoading } = this.state;
    this.setState({commentButtonLoading:true});
    this.selectTask(selectedTaskState,selectedJobState,data.message);
  }

  markFavourite(item){
    const { query } = this.props.location;
    const { profile } = this.props;
    this.props.JobDucks.markFavouriteJob(item.id)
    .then(() => {
      this.props.JobDucks.getJobs({employee_id : profile.employee_id, search : query.search , page : query.page ? query.page : 1});
    });
  }

  selectJob(job){
    this.setState({isProcessing:true});
    this.props.TaskDucks.getTasks({job_id : job.id, paginate: false})
    .then(data => {
      if(data.value.length === 1){
        const task = data.value[0];
        this.selectTask(task, job);
      }else{
        const { router: { history } } = this.context;
        history.push({
          pathname: '/tasks/list/',
          search: queryString.stringify({job_id : job.id})
        });
      }
    });
  }

  selectTask(task, selectedJob, message){
    const { router: { history } } = this.context;
    const { sessionDetail, profile , activityData} = this.props;
    const { selectedJobState, selectedTaskState, commentButtonLoading} = this.state;
    const { query } = this.props.location;
    const mode = cookies.get('loginMode', { path: '/' });
    const loginType =cookies.get('loginType', { path: '/' });
    const imageDocumentId = cookies.get('documentId', { path: '/' });

    let activityDetail;
    let newActivityData = [];

    if(sessionDetail  && sessionDetail.create_date == null)
     {
      if(!message){
        this.setState({isProcessing:true});
      }
       let emptyActivityData = {
        employee_id : profile.employee_id,
        data : []
       };
       let punchInData = {
        message: message && message,
        image_doc_id : imageDocumentId && imageDocumentId
      }
      this.props.SessionDucks.saveActivityData(emptyActivityData);
      if(loginType==='Face')
      {
        this.props.SessionDucks.saveActivityLog({activity_type:'punch_in'})
      }
      this.props.SessionDucks.postPunchInSession(punchInData)
       .then((resp) =>{
          if(resp.value.punchin_restriction == true){
            return this.setState({ isOpen : true , isProcessing:false, selectedJobState: selectedJob, selectedTaskState: task});
          }
          let newDate = new Date();
          newDate.setSeconds(0,0);
          newDate.setMilliseconds(0);
          newDate.toISOString();
          this.props.SessionDucks.getCurrentSession()
          .then((res) =>{
            activityData && activityData.length && activityData.map((item, index) => {
              if (index === (activityData.length - 1)) {
                const newItem = {
                  ...item,
                  end_datetime :newDate,
                };
                return newActivityData.push(newItem);
              }
              return newActivityData.push(item);
              });
            newActivityData.push({ 
              job_id : selectedJob.id,
              task_id : task.id,
              start_datetime : newDate,
              activity_id : res.value.id,
              task_name: task.name,
              job_name : selectedJob && selectedJob.name,
              session_id : res.value.session_id,
              sess_id: res.value.id
            });
            activityDetail ={
              employee_id : profile.employee_id,
              data : newActivityData
            };
            this.setState({selectedJobState:null, selectedTaskState: null, commentButtonLoading: false});
            this.props.SessionDucks.saveActivityData(activityDetail)
            .then(() => {
               if(mode == 'quick'){
                  this.setState({isButtonLoading : false});
                  history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
                }
                else{
                  history.push({
                    pathname: '/jobs/shift/',
                    search: queryString.stringify({...query, task_id : task.id})
                  });
                }
              });
            }); 
          })
          .catch((e) => {
            e.non_field_errors && e.non_field_errors.length && toast.error(e.non_field_errors[0]);
            this.setState({isProcessing: false, selectedJobState: null, selectedTaskState: null, commentButtonLoading:false});
          });
     } 
     else{
        if(activityData && activityData.length && activityData[activityData.length -1].activity_name === 'meal' && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'){
          this.props.SessionDucks.putEndSession({id: sessionDetail.current_activity[1].id, version: 2});
        }
        let newDate = new Date();
        newDate.setSeconds(0,0);
        newDate.setMilliseconds(0);
        newDate.toISOString();
        activityData && activityData.length &&  activityData.map((item, index) => {
          if (index === (activityData.length - 1)) {
            const newItem = {
              ...item,
              end_datetime : newDate,
            };
            return newActivityData.push(newItem);
          }
          return newActivityData.push(item);
        });
        newActivityData.push( 
          { 
            job_id : selectedJob.id,
            task_id : task.id,
            start_datetime : newDate,
            activity_id : sessionDetail.id,
            task_name : task.name,
            job_name : selectedJob && selectedJob.name,
            session_id : sessionDetail.session_id,
            sess_id: sessionDetail.id
          });
        activityDetail ={
        employee_id : profile.employee_id,
        data : newActivityData};
        this.props.SessionDucks.saveActivityData(activityDetail)
          .then(() => {
            if(mode == 'quick'){
                if(activityData && activityData.length && activityData[activityData.length -1].activity_name === 'meal' && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'){
                  history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
                }
               this.setState({isButtonLoading : false});
               history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'jobswitch'}) });
            }
            else{
              history.push({
                pathname: '/jobs/shift/',
                search: queryString.stringify({...query, task_id : task.id})
            });
            }
          });
    }
  }

  markUnFavourite(item){
    const { query } = this.props.location;
    const { profile } = this.props;
    this.props.JobDucks.markUnFavouriteJob(item.id)
    .then(() => {
      this.props.JobDucks.getJobs({employee_id : profile.employee_id, search : query.search, page : query.page ? query.page : 1});
    });
  }

  render() {
    const { isLoading } = this.props;
    const { isProcessing, isOpen, commentButtonLoading } = this.state;
    if (isLoading || isProcessing) return <SectionLoaderAtom active />;
    return (
      <div>
        <JobListComponent
          jobList = {this.props.jobList}
          markFavourite = {this.markFavourite}
          markUnFavourite={this.markUnFavourite}
          selectJob={this.selectJob}
        />
        {
          isOpen ?
          <UnscheduledShiftConfirmationComponent
            isOpen={isOpen}
            toggle={this.toggleConfirmationPopup}
            saveMessage={this.saveMessage}
            commentButtonLoading={commentButtonLoading}
          />
          :
          null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return({
    activityData : SessionDucks.activityData(state),
    jobsList: JobDucks.jobsList(state),
    location: RouteDucks.location(state),
    profile: ProfileDucks.profile(state),
    sessionDetail: SessionDucks.sessionDetail(state),
});
};
const mapActionsToProps = dispatch => ({
   JobDucks: bindActionCreators(JobDucks, dispatch),
   SessionDucks: bindActionCreators(SessionDucks, dispatch),
   TaskDucks: bindActionCreators(TaskDucks, dispatch),
});


JobListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobListSection);
