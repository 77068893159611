import React from 'react';

import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

import TRMSGS from 'constants/Translations/Localization';

class BiometricPasswordFormComponent extends React.PureComponent {

  render() {
    const { cameraPermissionText, isOpen, toggle} = this.props;
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id='ModalHeader'>
            {cameraPermissionText && cameraPermissionText === 'UPDATE' && <h4 className="mt0 mb0">{TRMSGS.advanceBrowserCheck}</h4>}
            {cameraPermissionText && cameraPermissionText=== 'PERMISSION' && <h4 className="mt0 mb0">{TRMSGS.cameraPermission}</h4>}
            {cameraPermissionText && cameraPermissionText=== 'MACLIKEDEVICE' && <h4 className="mt0 mb0">{TRMSGS.browseNotSupported}</h4>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cameraPermissionText && cameraPermissionText === 'UPDATE' &&
            <div className="f4 fw4">{TRMSGS.upgradeWebBrowser}</div>
          }
          {cameraPermissionText && cameraPermissionText=== 'PERMISSION' && <div className="f4 fw4">{TRMSGS.allowCameraAccess}</div>}
          {cameraPermissionText && cameraPermissionText === 'MACLIKEDEVICE' &&
            <div className="f4 fw4">{TRMSGS.browserNotCompatibleWithWebcamAnduseSafari}</div>
          }
        </Modal.Body>
        {cameraPermissionText
          ? <Modal.Header >
            <div className="tr">
              <button type="button"
                onClick={() => toggle()}
                className="br2 pointer pa3 ph4 white btn-color f3"
              >
                {TRMSGS.ok}
              </button>
            </div>
          </Modal.Header>
          : null
        }
      </Modal>
    );
  }
}

BiometricPasswordFormComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default BiometricPasswordFormComponent;
