import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import queryString from 'query-string';

import MealBreakComponent from 'components/meals/break/mealBreak';
import MealBreakModalComponent from 'components/meals/break/mealBreakModal';
import ForcePunchOutModalComponent from 'components/activities/Form/forcePunchOutModal';
import SectionLoaderAtom from 'atoms/SectionLoader';

import * as SessionDucks from 'ducks/activities/session';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();


class MealBreakSection extends React.Component {
  constructor() {
    super();

    this.state = {
      isMealBreakModalOpen: false,
      isMealEndButtonLoading : false,
      isResumeButtonLoading: false,
      isSwitchButtonLoading: false,
      mealEndDisabled: false,
      resumeSwitchDisabled: false,
      isForcePunchOut : false,
    };

    this.mealToggle = this.mealToggle.bind(this);
    this.mealEnd = this.mealEnd.bind(this);
    this.switchJob = this.switchJob.bind(this);
    this.updateSession = this.updateSession.bind(this);
  }

  componentWillMount(){
    this.props.SessionDucks.getCurrentSession();
  }

  updateSession(){
    const { router: { history } } = this.context;
    this.setState({isForcePunchOut : false});
    history.push('/jobs/overview/');
  }

  mealToggle(){
    const { mealEndDisabled } = this.state;
    this.setState({
      isMealEndButtonLoading: false,
      mealEndDisabled: !mealEndDisabled,
    });
    this.props.SessionDucks.getCurrentSession()
    .then((res) => {
      if(res.value.create_date == null){
        this.props.UpdateforcePunchOut();
      }
      else{
        const { jobsList, taskList, companyDetail, sessionDetail} = this.props;
        const { isMealBreakModalOpen } = this.state;

        const isSwitchOption = ((jobsList && jobsList.length === 1 && taskList && taskList.length === 1) || (jobsList.length === 0) || !(companyDetail && companyDetail.task_time_allocation) || !(companyDetail && companyDetail.to_be_allocated));
        !isSwitchOption && sessionDetail && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'?   
          this.setState({
            isMealBreakModalOpen: !isMealBreakModalOpen
          })
          :
          this.mealEnd('noSwitchOption');
      }
    });    
  }

  switchJob(){
    const { router: { history } } = this.context;
    const { jobsList, taskList} = this.props;
    if (jobsList.length === 1 && taskList.length > 0) {
      history.push('/tasks/list');
    } 
   else{
    history.push('/jobs/list');
   }
  }

  mealEnd(type) {
    let newDate = new Date();
    newDate.setSeconds(0,0);
    newDate.setMilliseconds(0);
    newDate.toISOString();
    const mode = cookies.get('loginMode', { path: '/' });
    const loginType =cookies.get('loginType', { path: '/' });
    
    this.setState({isResumeButtonLoading : true, resumeSwitchDisabled: true});
    const { router: { history } } = this.context;
    const { sessionDetail, profile , activityData} = this.props;
    let activityDetail;
    let newActivityData = [];
    
    if(sessionDetail && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'){
      this.props.SessionDucks.putEndSession({id: sessionDetail.current_activity[1].id, version: 2})
      .then((res) => {
        activityData && activityData.length && activityData.map((item, index) => {
          if (res.value.activity_id === item.activity_id) {
            const newItem = {
              ...item,
              end_datetime : newDate,
            };
            return newActivityData.push({...newItem, 
              browserInfo : navigator.userAgent && navigator.userAgent.split(')') && navigator.userAgent.split(')').reverse() && navigator.userAgent.split(')').reverse().length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g) && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g).length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/'),
              employeeToken : cookies.get('employeeToken', { path: '/' }),
              funcName: 'if worked properly'});
          }
          return newActivityData.push(item);
        });
        if ( type === 'resume' || type === 'noSwitchOption') {
          let obj = {
            ...newActivityData[newActivityData.length - 2],
            start_datetime : newDate,
            browserInfo : navigator.userAgent && navigator.userAgent.split(')') && navigator.userAgent.split(')').reverse() && navigator.userAgent.split(')').reverse().length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g) && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g).length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/'),
            employeeToken : cookies.get('employeeToken', { path: '/' }),
            funcName: 'resume worked'
          };
          delete obj.end_datetime;
          newActivityData.push(obj);
        }
        activityDetail = {
        employee_id : profile.employee_id,
        data : newActivityData,
        };
        if(loginType==='Face')
        {
          this.props.SessionDucks.saveActivityLog({activity_type:'meal_end'})
        }
        activityDetail &&  this.props.SessionDucks.saveActivityData(activityDetail)
        .then(() => {
           if(mode == 'quick'){
              history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
            }
            else{
              if ( type === 'resume' || type==='noSwitchOption') {
                history.push('/jobs/shift');
              } 
            }
          this.setState({
            isSwitchButtonLoading : false,
            isResumeButtonLoading : false,
          });
        });
      })
      .catch((e) => {
        this.props.SessionDucks.getCurrentSession()
        .then((res)=>{
          const allActivity = res && res.value && res.value.all_activity;
          activityData && activityData.length && activityData.map((item, index) => {
          if(index ===  activityData.length - 1){
              const newItem = {
              ...item,
              end_datetime : allActivity && allActivity.length && allActivity[allActivity.length - 1].end_datetime
            };
            return newActivityData.push(newItem);
          }
            return newActivityData.push(item);
          });
          newActivityData.push({...activityData[activityData.length - 2], 
            start_datetime: allActivity && allActivity.length && allActivity[allActivity.length - 1].end_datetime, 
            end_datetime: null,
            browserInfo : navigator.userAgent && navigator.userAgent.split(')') && navigator.userAgent.split(')').reverse() && navigator.userAgent.split(')').reverse().length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g) && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g).length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/'),
            employeeToken : cookies.get('employeeToken', { path: '/' }),
            funcName: 'went in catch of end meal',
            error: e });
          activityDetail ={
            employee_id : profile.employee_id,
            data : newActivityData,
          };
          if(loginType==='Face')
          {    
             this.props.SessionDucks.saveActivityLog({activity_type:'meal_end'})
          }
             this.props.SessionDucks.saveActivityData(activityDetail)
          .then(() => {
              toast.success('Your meal has been automatically ended');
              history.push('/jobs/shift'); 
          });
          });
        });
    }
    else{
      activityData && activityData.length && activityData.map((item, index) => {
        if(index ===  activityData.length - 1){
            const newItem = {
            ...item,
            end_datetime : sessionDetail && sessionDetail.all_activity && sessionDetail.all_activity[sessionDetail.all_activity.length - 1].end_datetime
          };
          return newActivityData.push(newItem);
        }
          return newActivityData.push(item);
        });
      newActivityData.push({...activityData[activityData.length - 2], 
        start_datetime: sessionDetail && sessionDetail.all_activity && sessionDetail.all_activity[sessionDetail.all_activity.length - 1].end_datetime, 
        end_datetime: null,
        browserInfo : navigator.userAgent && navigator.userAgent.split(')') && navigator.userAgent.split(')').reverse() && navigator.userAgent.split(')').reverse().length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g) && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g).length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/'),
        employeeToken : cookies.get('employeeToken', { path: '/' }),
        funcName: 'went in else of end meal' });
      activityDetail ={
        employee_id : profile.employee_id,
        data : newActivityData,
      };
      if(loginType==='Face')
      {  
        this.props.SessionDucks.saveActivityLog({activity_type:'meal_end'})  
      }
        this.props.SessionDucks.saveActivityData(activityDetail)
      .then(() => {
          toast.success(`${TRMSGS.mealEndedAutomatically}`);
            if(mode == 'quick'){
              history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
            }
            else{
              if ( type === 'resume' || type==='noSwitchOption') {
                history.push('/jobs/shift');
              } 
            }
          history.push('/jobs/shift'); 
      });
    }
  }

  render() {
    const { isMealBreakModalOpen, isSwitchButtonLoading, isResumeButtonLoading , isMealEndButtonLoading, mealEndDisabled, resumeSwitchDisabled } = this.state;
    const { sessionDetail, companyDetail } = this.props;
    let FirstMealDetail;
    const mealDurationSequence = companyDetail && companyDetail.meal_duration_sequence;
    const mealCount = sessionDetail.all_activity && sessionDetail.all_activity.length && (+sessionDetail.all_activity.length);
    const mealDetail = mealCount && mealDurationSequence && mealDurationSequence.find((i) => i.sequence_of_configurations === (+sessionDetail.all_activity.length));
    FirstMealDetail = mealCount && mealDetail
        ? 
        mealDurationSequence && mealDurationSequence.find((i) => i.sequence_of_configurations === (+sessionDetail.all_activity.length))
        :
        mealDurationSequence && mealDurationSequence.find((i) => i.is_default === true);
    if (!this.props.mealStartDetail) return <SectionLoaderAtom active />;
    return (
      <div>
        {this.props.mealStartDetail &&
          <MealBreakComponent
            mealToggle={this.mealToggle}
            mealStartDetail = {this.props.mealStartDetail}
            companyDetail = {companyDetail}
            FirstMealDetail = {FirstMealDetail}
            isMealEndButtonLoading = {isMealEndButtonLoading}
            mealEndDisabled={mealEndDisabled}
            userPreferenceLanguage = {this.props.userPreferenceLanguage}
          />
        }

        <MealBreakModalComponent
          isOpen={isMealBreakModalOpen}
          toggle={this.mealToggle}
          mealEnd={this.mealEnd}
          isResumeButtonLoading={isResumeButtonLoading}
          isSwitchButtonLoading={isSwitchButtonLoading}
          resumeSwitchDisabled={resumeSwitchDisabled}
          switchJob={this.switchJob}
        />
        {this.state.isForcePunchOut &&
          <ForcePunchOutModalComponent
            isOpen={this.state.isForcePunchOut}
            toggle={this.updateSession}
            update={this.updateSession}
          />
        }
      </div>
    );
  }
}

MealBreakSection.contextTypes = {
    router: PropTypes.object.isRequired,
  };

const mapStateToProps = state => ({
  sessionDetail: SessionDucks.sessionDetail(state),
  punchInDetail: SessionDucks.punchInDetail(state),
  mealStartDetail: SessionDucks.mealStartDetail(state),
  activityData : SessionDucks.activityData(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  profile: ProfileDucks.profile(state),
  jobsList: JobDucks.jobsList(state),
  taskList: TaskDucks.taskList(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapDispatchToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MealBreakSection);
