import React from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import EditTimeModalComponent from 'components/jobs/overview/editTimeModalComponent';

// import BaseIcon from 'images/baseline.svg';


import ButtonLoaderAtom from 'atoms/ButtonLoader';
import Resizable  from 're-resizable';
import JobTaskSelectModal from 'components/generics/JobTaskSelectModal';
import TRMSGS from 'constants/Translations/Localization';

import { secondsToHHMM } from 'lib/utils';

import {ReactComponent as CorrectionIcon} from 'images/correction.svg';
import {ReactComponent as NotesIcon} from 'images/Notes.svg';
import {ReactComponent as ApproveIcon} from 'images/approve.svg';
import {ReactComponent as MealBreakIcon} from 'images/meal.svg';
import {ReactComponent as JobsIcon} from './jobs.svg';
import {ReactComponent as AddIcon} from 'images/add.svg';
import {ReactComponent as ResetIcon} from './resetIcon.svg';
import {ReactComponent as UpwardArrow} from './upward_arrow.svg';

class JobShiftOverViewComponent extends React.Component {

  constructor(props) {
    super(props);
    this.handleStopDragging = this.handleStopDragging.bind(this);
    this.handleDragging = this.handleDragging.bind(this);
    this.ResizeDiv = this.ResizeDiv.bind(this);
    this.ResizerDiv = this.ResizerDiv.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.allowEdit = this.allowEdit.bind(this);
    this.selectId = this.selectId.bind(this);
    this.transformActivities = this.transformActivities.bind(this);
    this.approveShift = this.approveShift.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleJobSelect= this.handleJobSelect.bind(this);
    this.handleTaskSelect= this.handleTaskSelect.bind(this);
    this.handleModalSubmit= this.handleModalSubmit.bind(this);
    this.handlePunchInTime = this.handlePunchInTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addMeal = this.addMeal.bind(this);
    this.splitIntoDates = this.splitIntoDates.bind(this);
    this.formatData = this.formatData.bind(this);
    this.startResizing = this.startResizing.bind(this);
    this.addJobBelow = this.addJobBelow.bind(this);
    this.addJobAbove = this.addJobAbove.bind(this);
    this.showPunchInDragger = this.showPunchInDragger.bind(this);
    this.updateId = this.updateId.bind(this);
    this.openEditPopup = this.openEditPopup.bind(this);
    this.submitTime = this.submitTime.bind(this);
    this.updateEnteredTime = this.updateEnteredTime.bind(this);
    this.fixActivityData = this.fixActivityData.bind(this);
    this.submitDate = this.submitDate.bind(this);
    this.resetActivityData = this.resetActivityData.bind(this);
    this.redirectToiFrame = this.redirectToiFrame.bind(this);
    this.setSurveyTypeValue = this.setSurveyTypeValue.bind(this);
    this.state={
      jobData :[],
      value: null,
      id:null,
      condition: 'NOEDIT',
      allowEditActivity : true,
      selectedId : null,
      isOpen: false,
      modalSelection: {
        job: null,
        task: null
      },
      punchInHours: null,
      punchInMins: null,
      editButtonDisabled: false,
      punchInEdit: null,
      punchOutEdit: null,
      showJob : true,
      isDragging: false,
      dragPunchIn : false,
      dragPunchOut : false,
      openEditModal : false,
      previousJob : null,
      nextJob : null,
      selectedJobIndex : false,
      editDate: null,
      isChanges: false,
      initialFormattedObj : []
    };
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.manualCorrection) !== JSON.stringify(nextProps.manualCorrection)) {
      this.updateEditCondition(nextProps);
    }
  }

  fixActivityData(activityData){
    let removeNullObjects = [];
    let fixedActivityData = [];
    activityData && activityData.length && activityData.forEach((item, index)=>{
        if(item.start_datetime){
          removeNullObjects.push(item);
        }
    });
    removeNullObjects && removeNullObjects.length && removeNullObjects.forEach((item, index)=>{
      if(item.start_datetime && item.end_datetime && item.start_datetime > item.end_datetime){
        fixedActivityData.push({...item, start_datetime: item.end_datetime})
      }
      else {
        fixedActivityData.push(item)
      }
    });
    this.formatData(fixedActivityData);
    this.splitIntoDates(fixedActivityData);
  }

  handleChange(e, type){
    if(type === 'start'){
      this.setState({punchInEdit : e.target.value});
    }
    else if(type === 'end'){
      this.setState({punchOutEdit : e.target.value});
    }
  }

  formatData(SessionActivityData){
    const { companyDetail } = this.props;
    const forcePunchoutTime = companyDetail && companyDetail.last_punch_in && companyDetail.last_punch_in.end_datetime;
    let copy = [];
    let modifiedTasks = JSON.parse(JSON.stringify(SessionActivityData));
    modifiedTasks.forEach( function(item, index) {
      if (!item.end_datetime) {
         const newItem = {
          ...item,
          end_datetime : forcePunchoutTime
        };
        copy.push(newItem);
      } else {
        copy.push(item);
      }
    });

    this.props.saveJobData(copy); //to save job copy data in activity

    if(companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split){
      let newActivityData = [];
      copy && copy.length && copy.map((item, index) => {
        let date;
        const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
        const divide_time = time.split(':');
        const startNewDay = new Date(item.end_datetime);        
        startNewDay.setHours(divide_time[0]);
        startNewDay.setMinutes(divide_time[1]);
        let startSameDay = new Date(item.start_datetime);
        startSameDay.setHours(divide_time[0])
        startSameDay.setMinutes(divide_time[1]);
        if(moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(startSameDay).format('YYYY/MM/DD HH:mm')){
          date = new Date(startSameDay)
        }
        else if(moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(startNewDay).format('YYYY/MM/DD HH:mm')){
          date = new Date(startNewDay)
        }
        
        if(date){ 
          date.setHours(divide_time[0]);
          date.setMinutes(divide_time[1]);
          date.setSeconds(0,0);
          date.setMilliseconds(0);
        }

        if (date && moment(item.end_datetime).format('YYYY/MM/DD HH:mm') > moment(date).format('YYYY/MM/DD HH:mm') && moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(date).format('YYYY/MM/DD HH:mm')) {
          const newItem = {
            ...item,
            end_datetime : date.toISOString()
          };
          const splitItem = {
            ...item,
            start_datetime : date.toISOString()
          };
          newActivityData.push(newItem);
         return newActivityData.push(splitItem);
        }
        return newActivityData.push(item);
        });

        const modifiedData = this.transformActivities(newActivityData);
        this.setState({initialFormattedObj : modifiedData});
    }
    else{
      const modifiedDataCopy = this.transformActivities(copy);
      this.setState({ initialFormattedObj : modifiedDataCopy});
    }
  }


  updateEnteredTime(){
    const { jobData , selectedJobIndex, editDate} = this.state;
    const {companyDetail} = this.props;
    const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
    let copy = [];
    let modifiedTasks = JSON.parse(JSON.stringify(jobData));
   
    let selectedEndTime = jobData[selectedJobIndex].end_datetime;
    if(time){
      modifiedTasks.map((item,index)=>{
        let totalSplitHours = [];
        let timearray = item.formattedStartTime.split(' ');
        if(timearray[1] === 'PM'){
          totalSplitHours = timearray[0].split(':');
          totalSplitHours[0] = parseInt(totalSplitHours[0]) + 12;      
        }else if(timearray[1] === 'AM'){
          totalSplitHours = timearray[0].split(':');
          if(totalSplitHours[0] === '12'){
            totalSplitHours[0] = parseInt('0');
          }
        }
        if(parseInt(totalSplitHours[0]) === parseInt(time.split(':')[0]) && parseInt(totalSplitHours[1]) === parseInt(time.split(':')[1]) && index>0) {
          modifiedTasks[index-1].end_datetime = modifiedTasks[index].end_datetime;
          modifiedTasks[index-1].formattedEndTime = modifiedTasks[index].formattedEndTime;
          modifiedTasks.splice(index,1);
        }
      });
    }
    let JobIndex = modifiedTasks.map( el => el.end_datetime ).indexOf(selectedEndTime)
    const nextEnd =  modifiedTasks.length === JobIndex+1 ? moment(modifiedTasks[JobIndex].end_datetime).utc() : moment(modifiedTasks[JobIndex+1].end_datetime).add(-1, 'minutes').utc();
    const modifiedEnd = moment(editDate).utc();
    const prevEnd = modifiedTasks.length === JobIndex+1 ? moment(modifiedTasks[JobIndex-1].start_datetime).add( 1, 'minutes').utc() : moment(modifiedTasks[JobIndex].start_datetime).add( 1, 'minutes').utc();
    if(modifiedEnd.isAfter(nextEnd)){
      return toast.error(`${TRMSGS.cannotOverlapNextJob}`);
    }
    if(modifiedEnd.isBefore(prevEnd)){
      return toast.error(`${TRMSGS.cannotOverlapPreviousJob}`);
    }
    modifiedTasks.forEach( function(item, index) {
      if ((modifiedTasks.length !== JobIndex+1 && index === JobIndex) || (modifiedTasks.length === JobIndex+1 && index === JobIndex-1)) {
        copy.push({...item, end_datetime : modifiedEnd.format('YYYY-MM-DDTHH:mm:ss.000\\Z') , formattedEndTime : moment(editDate).format('LT')});
      } else if((modifiedTasks.length !== JobIndex+1 && index === JobIndex + 1) || (modifiedTasks.length === JobIndex+1 && index === JobIndex)){
        copy.push({...item, start_datetime : modifiedEnd.format('YYYY-MM-DDTHH:mm:ss.000\\Z') , formattedStartTime : moment(editDate).format('LT')});
      }
      else{
        copy.push(item);
      }
    });
    this.splitIntoDates(copy);
    this.setState({isDragging: true, dragPunchIn: false, openEditModal : false});
  }

  submitTime(date){
    const newDate = this.state.editDate;
    newDate.setHours(date.getHours());
    newDate.setMinutes(date.getMinutes());
    this.setState({editDate: newDate});
  }

  submitDate(date){
    const newDate = this.state.editDate;
    newDate.setDate(date.getDate());
    newDate.setMonth(date.getMonth());
    this.setState({editDate: newDate});
  }


  startResizing(index){
    const { overviewRef } = this.props;
    overviewRef.style.overflowY = 'hidden';
    this.setState({id : index, dragPunchIn : false, dragPunchOut : false});
   /* this.props.disablePageScrolling();*/
  }

  openEditPopup(firstJob, secondJob, index){
    const { openEditModal } = this.state;
    this.setState({openEditModal : !openEditModal});
    this.setState({previousJob : firstJob});
    firstJob && this.setState({editDate: new Date(firstJob.end_datetime)})
    this.setState({nextJob: secondJob, selectedJobIndex : index});
  }

  showPunchInDragger(index){
    this.setState({dragPunchIn : true, id : null, dragPunchOut : false});
  }

  showPunchOutDragger(){
    this.setState({dragPunchOut : true, id : null, dragPunchIn : false});
  }

  updateId(index){
    this.setState({id : index, dragPunchIn : false, dragPunchOut: false});
  }

  addJobBelow(index){
    const { copyOfJD } = this.state;
    const modifiedJD = JSON.parse(JSON.stringify(copyOfJD)); 
    const sessionIdItem = copyOfJD.find(item => item.session_id);
    const unallocatedTask = {
        activity_id: index === -1 ? modifiedJD[index + 1].activity_id : modifiedJD[index].activity_id,
        job_id: 0,
        task_id: 0,
        start_datetime: index === -1 ? modifiedJD[index + 1].start_datetime : modifiedJD[index].end_datetime,
        end_datetime: index === -1 
          ?
          moment(modifiedJD[index + 1].start_datetime).add(1, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z')
          :
          moment(modifiedJD[index].end_datetime).add(1, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z'),
        task_name: 'UNALLOCATED TASK',
        job_name: ' ',
        session_id: sessionIdItem && sessionIdItem.session_id
      };
      modifiedJD[index + 1].start_datetime = index !== copyOfJD.length - 1 ? unallocatedTask.end_datetime : modifiedJD[index].start_datetime;
      modifiedJD.splice(index + 1, 0, unallocatedTask);
      const modified = this.transformActivities(modifiedJD);
      this.setState({ jobData: modified, copyOfJD: modifiedJD});
 
  }

  addJobAbove(index){
    const { copyOfJD } = this.state;
    const modifiedJD = JSON.parse(JSON.stringify(copyOfJD)); 
    const sessionIdItem = copyOfJD.find(item => item.session_id);
    const unallocatedTask = {
        activity_id: modifiedJD[index].activity_id,
        job_id: 0,
        task_id: 0,
        start_datetime: moment(modifiedJD[index].end_datetime).add(-1, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z'),
        end_datetime: modifiedJD[index].end_datetime,
        task_name: 'UNALLOCATED TASK',
        job_name: ' ',
        session_id: sessionIdItem &&  sessionIdItem.session_id
      };
      modifiedJD[index].end_datetime = unallocatedTask.start_datetime;
      modifiedJD.splice(index + 1, 0, unallocatedTask);
      const modified = this.transformActivities(modifiedJD);
      this.setState({ jobData: modified, copyOfJD: modifiedJD});
 
  }

  splitIntoDates(SessionActivityData){
    const { companyDetail } = this.props;
    const forcePunchoutTime = companyDetail && companyDetail.last_punch_in && companyDetail.last_punch_in.end_datetime;
    let copy = [];
    let modifiedTasks = JSON.parse(JSON.stringify(SessionActivityData));
    modifiedTasks.forEach( function(item, index) {
      if (!item.end_datetime) {
         const newItem = {
          ...item,
          end_datetime : forcePunchoutTime
        };
        copy.push(newItem);
      } else {
        copy.push(item);
      }
    });

    if(companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split){
      let newActivityData = [];
      copy && copy.length && copy.map((item, index) => {
        let date;
        const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
        const divide_time = time.split(':');
        const startNewDay = new Date(item.end_datetime);        
        startNewDay.setHours(divide_time[0]);
        startNewDay.setMinutes(divide_time[1]);
        let startSameDay = new Date(item.start_datetime);
        startSameDay.setHours(divide_time[0])
        startSameDay.setMinutes(divide_time[1]);
        if(moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(startSameDay).format('YYYY/MM/DD HH:mm')){
          date = new Date(startSameDay)
        }
        else if(moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(startNewDay).format('YYYY/MM/DD HH:mm')){
          date = new Date(startNewDay)
        }
        
        if(date){ 
          date.setHours(divide_time[0]);
          date.setMinutes(divide_time[1]);
          date.setSeconds(0,0);
          date.setMilliseconds(0);
        }

        if (date && moment(item.end_datetime).format('YYYY/MM/DD HH:mm') > moment(date).format('YYYY/MM/DD HH:mm') && moment(item.start_datetime).format('YYYY/MM/DD HH:mm') < moment(date).format('YYYY/MM/DD HH:mm')) {
          const newItem = {
            ...item,
            end_datetime : date.toISOString()
          };
          const splitItem = {
            ...item,
            start_datetime : date.toISOString()
          };
          newActivityData.push(newItem);
         return newActivityData.push(splitItem);
        }
        return newActivityData.push(item);
        });

        const modifiedData = this.transformActivities(newActivityData);
        this.setState({copyOfJD: modifiedData, jobData: modifiedData, selectedId: null });
    }
    else{
      const modifiedDataCopy = this.transformActivities(copy);
      this.setState({copyOfJD: modifiedDataCopy, jobData: modifiedDataCopy, selectedId: null });
    }
  }

  handlePunchInTime(e, v, type){

    const { jobData} = this.state;
    const punchInTime = e.target.value;
    const timeArray = punchInTime.split(':');
    let modifiedData = JSON.parse(JSON.stringify(jobData));
    var m = moment(modifiedData[0].start_datetime);
    
    const splitTime = punchInTime.split('');
    const shortFormat = splitTime.find((item) => item==='p' || item === 'P' || item ==='a' || item ==='A');

    if(timeArray && timeArray[1]){
      if(shortFormat){
        const min = timeArray[1].split(shortFormat);
        if(shortFormat === 'p' || shortFormat === 'P'){
          let totalHours = parseInt(timeArray[0], 10);
          if(totalHours < 12){
            totalHours = totalHours + 12;
          }          
          m.set({h : totalHours , m : min[0] });
        }
        else if(shortFormat === 'a' || shortFormat === 'A'){
          let totalMinsAm = parseInt(min[0], 10);
          m.set({h : timeArray[0], m : totalMinsAm });
        }
      }
      else{     
        m.set({h : timeArray[0], m :  timeArray[1]});
      }
    }
    else{
      if(shortFormat){
        const splitPunchIn = punchInTime.split(shortFormat);
        if(shortFormat === 'p' || shortFormat === 'P'){
          let totalSplitHours = parseInt(splitPunchIn[0], 10);
          if(totalSplitHours < 12){
            totalSplitHours = totalSplitHours + 12;
          }            
          m.set({h : totalSplitHours , m : 0 });
        }
        else if(shortFormat === 'a' || shortFormat === 'A'){
           m.set({h : splitPunchIn[0], m : 0 });
        }
      }
      else{
      m.set({h : e.target.value , m : 0});
      }
    }

    if(type === 'end'){
      if(moment(m) < moment(jobData[0].start_datetime)){
        return toast.error(`${TRMSGS.selectValidPunchoutTime}`);
      }
      if(moment(m) > moment(jobData[jobData.length - 1].end_datetime)){
        const unallocatedTask = {
          activity_id: jobData[jobData.length - 1].activity_id,
          job_id: 0,
          task_id: 0,
          start_datetime: jobData[jobData.length - 1].end_datetime, 
          end_datetime: m.utc().format(),
          task_name: 'UNALLOCATED TASK',
          job_name: ' '
        };   
        modifiedData.splice(jobData.length , 0, unallocatedTask);
      }
      else if(moment(m) < moment(jobData[jobData.length - 1].end_datetime)){ // GOING UP
        jobData.forEach((item,index) => {
          var outstart = moment.utc(m, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
          var outend = moment.utc(item.start_datetime, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
          modifiedData[jobData.length - 1].end_datetime = new Date(outstart);
          if (outstart.isBefore(outend)) {
            modifiedData[index].toBeDeleted = true;
            modifiedData[index - 1].end_datetime = new Date(outstart);
          }
        });
      }
    }
    else if(type === 'start'){
      if(moment(m) > moment(jobData[jobData.length - 1].end_datetime)){
        return toast.error(`${TRMSGS.punchinLessThanPunchout}`);
      }
      if(moment(m) < moment(jobData[0].start_datetime)){
        const unallocatedTask = {
          activity_id: jobData[0].activity_id,
          job_id: 0,
          task_id: 0,
          start_datetime:  m.utc().format(),
          end_datetime: jobData[0].start_datetime,
          task_name: 'UNALLOCATED TASK',
          job_name: ' '
        };      
        modifiedData.splice(0, 0, unallocatedTask);
      }
      else if(moment(m) > moment(jobData[0].start_datetime)){ // GOING DOWN
        jobData.forEach((item,index) => {
          var instart = moment.utc(m, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
          var inend = moment.utc(item.end_datetime, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
          modifiedData[index].start_datetime =  new Date(instart);
          if (instart.isAfter(inend)) {
            modifiedData[index].toBeDeleted = true;
            modifiedData[index+1].start_datetime =  new Date(instart);
          }
        });
      }
    }
  
    const newData = this.transformActivities(modifiedData.filter(item => !item.toBeDeleted));
    this.setState({ jobData : newData, copyOfJD : newData});


  }

  addMeal(selectedId){
    const { jobData } = this.state;
    let modifiedData = JSON.parse(JSON.stringify(jobData));

    const copy = [];
    modifiedData.forEach(function(item, index) {
      if (index === selectedId) {
        copy[index] = {
          activity_id:jobData[selectedId].activity_id,
          activity_name: 'meal',
          start_datetime : jobData[selectedId].start_datetime,
          end_datetime : jobData[selectedId].end_datetime,
          formattedEndTime: jobData[selectedId].formattedEndTime,
          formattedStartTime: jobData[selectedId].formattedStartTime,
          duration: jobData[selectedId].duration,
          height: jobData[selectedId].height,
          isFixed: jobData[selectedId].isFixed,
        };
      } else {
        copy.push(item);
      }
    });

    modifiedData = this.transformActivities(copy);
    this.setState({copyOfJD: copy, jobData: copy, selectedId: null});
  }


  handleModalSubmit() {
    const { copyOfJD, modalSelection, selectedId } = this.state;
    if (!modalSelection.job || !modalSelection.task) {
      toast.error(`${TRMSGS.selectJobAndTask}`);
      return;
    }
    let modifiedData = JSON.parse(JSON.stringify(copyOfJD));

    modifiedData[selectedId] = {
      ...modifiedData[selectedId],
      job_id: modalSelection.job.id,
      task_id: modalSelection.task.id,
      task_name: modalSelection.task.name,
      job_name: modalSelection.job.name
    };

    const copy = [];
    modifiedData.forEach( function(item, index) {
      copy.push(item);
    });

    modifiedData = this.transformActivities(copy);
    this.toggleEditModal();
    this.setState({copyOfJD: modifiedData, jobData: modifiedData, selectedId: null, showJob: true});
  }

  toggleEditModal(addId) {
    this.setState({showJob: true});
    const { jobData, selectedId } = this.state;
    const { jobList, taskList , companyDetail} = this.props;
    const copy = [];
    let modifiedData = JSON.parse(JSON.stringify(jobData));
    if(((jobList && jobList.length > 1) || (jobList && jobList.length === 1 && taskList && taskList.length >1)) && (companyDetail.task_time_allocation)){
      this.setState({
        isOpen: !this.state.isOpen,
          job: jobData[selectedId] ? { id: jobData[selectedId].job_id, name : jobData[selectedId].job_name } : null,
          task: jobData[selectedId] ? { id: jobData[selectedId].task_id, name : jobData[selectedId].task_name } : null,
        }
      );
      }
       else{
        modifiedData.forEach(function(item, index) {
          if (index === addId) {
            copy[index] = {
              activity_id:jobData[addId].activity_id,
              activity_name: 'punch_in',
              start_datetime : jobData[addId].start_datetime,
              end_datetime : jobData[addId].end_datetime,
              formattedEndTime: jobData[addId].formattedEndTime,
              formattedStartTime: jobData[addId].formattedStartTime,
              duration: jobData[addId].duration,
              height: jobData[addId].height,
              isFixed: jobData[addId].isFixed,
            };
          } else {
            copy.push(item);
          }
        });

      modifiedData = this.transformActivities(copy);
      this.setState({copyOfJD: copy, jobData: copy, selectedId: null});
    }
  }

  handleJobSelect(job) {
    this.setState({modalSelection: { job: job, task: null}});
    return this.props.getTasks(job)
    .then(() => {
      this.setState({showJob : false});
    });
  }

  handleTaskSelect(task) {
    this.setState({modalSelection: {...this.state.modalSelection, task: task}});
  }

  saveActivity(buttonType) {
    const { jobData , condition} = this.state;
    this.props.saveActivity(jobData, buttonType, condition);
  }

  saveFeedbackActivity(buttonType) {
    const { jobData , condition} = this.state;
    this.props.saveFeedbackActivity(jobData, buttonType, condition);
  }

  approveShift() {
    const { jobData } = this.state;
    // prepare data to post
    this.props.approveShift(jobData);
  }

  updateEditCondition(props){
    const { manualCorrection } = props;
    const { SessionActivityData, jobList, taskList, activityLastSplit, userExtraData } = this.props;
    let condn = 'ALLOWFULLEDIT';
    if (manualCorrection === 'Correction Request') {
      condn = 'ALLOWNOTESEDIT';
    } else if (manualCorrection === 'Allow Edit of Activities') {
      condn = 'ALLOWFULLEDIT';
    } else {
      condn = 'NOEDIT';
    }
    this.setState({ condition: condn });
    //to disable edit feature for no job no task
    if(!((jobList.length > 1 || taskList.length > 1) || (jobList.length === 1 && taskList.length > 1))){
      this.setState({allowEditActivity: false});
    }
    // if((activityLastSplit && activityLastSplit.multi_validity && activityLastSplit.multi_validity.length) && (userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.meal_questionnaire)){
    //   this.setState({allowEditActivity: false});
    // }
  }

  componentDidMount(){
    const { SessionActivityData, jobList, taskList } = this.props;
    Promise.all([
      this.updateEditCondition(this.props)
    ])
    .then(() => {
      this.fixActivityData(SessionActivityData);
      this.setState({punchInEdit : moment(SessionActivityData[0].start_datetime).format('LT')});
      this.setState({punchOutEdit : moment(SessionActivityData[SessionActivityData.length - 1].end_datetime).format('LT')});
    });
    
  }

  setSurveyTypeValue(){
    const {activityLastSplit } = this.props;
    const data = activityLastSplit && activityLastSplit.multi_validity && activityLastSplit.multi_validity.length && activityLastSplit.multi_validity;
    if(!data){
      return false
    }
    if(JSON.stringify(data) == JSON.stringify(['delayed_meal'])){
        return 'Delayed Meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['missing_meal'])){
      return 'Missing Meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out'])){
      return 'Force Punch Out';
    }
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out', 'delayed_meal'])){
      return 'Force Punch Out and Delayed Meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out', 'missing_meal'])){
      return 'Force Punch Out and Missing Meal';
    }
  }

 componentDidUpdate(prevProps, prevState){
  const { isChanges, jobData, initialFormattedObj } = this.state;
  if((jobData.length && initialFormattedObj.length !== jobData.length) && !isChanges){
    this.setState({isChanges: true})
  }
  else{
    initialFormattedObj && jobData.length && initialFormattedObj.forEach((item,index)=>{
      const jobStartDate = new Date( jobData[index].start_datetime).setSeconds(0,0)
      const itemStartDate = new Date( item.start_datetime).setSeconds(0,0)
      
      const jobEndDate = new Date( jobData[index].end_datetime).setSeconds(0,0)
      const itemEndDate = new Date( item.end_datetime).setSeconds(0,0)


      if(jobData[index].job_id !== item.job_id && !isChanges){
        this.setState({isChanges: true});
      }
      else if(jobData[index].task_id !== item.task_id && !isChanges){
        this.setState({isChanges: true});
      }
      else if(jobEndDate && itemEndDate && jobEndDate !== itemEndDate && !isChanges){
        this.setState({isChanges: true});
      }
      else if(jobStartDate && itemStartDate && jobStartDate !== itemStartDate && !isChanges){
        this.setState({isChanges: true});
      }
    })
  }
 }

  transformActivities(jobs, i, height) { 
    const { condition } = this.state;
    const { taskList, jobList } = this.props;
    const newJobsObj = [];
    jobs.forEach( function(item, index) {
      // statements
      let stc = moment(item.start_datetime).seconds(0).milliseconds(0).toISOString();
      let etc = moment(item.end_datetime).seconds(0).milliseconds(0).toISOString();
      let diff = (moment(etc).diff(moment(stc)))/1000;
      let dur = secondsToHHMM(diff);
      // let divHeight;

      let divHeight;
      // let divHeight = ((diff/3600)*60);
      if(((diff/3600)*60) < 60) {
        divHeight = 60;
      }
      else if(((diff/3600)*60) > 60 && ((diff/3600)*60) < 150){
        divHeight = ((diff/3600)*60);
      }
      else{
        divHeight = 150;
      }

      const formattedStartTime = moment(item.start_datetime).format('LT');
      const formattedEndTime = moment(item.end_datetime).format('LT');

      let isFixed = true;
      let showMenu = false;
      if((condition === 'ALLOWNOTESEDIT' || condition === 'NOEDIT' || condition === 'ALLOWFULLEDIT' )){
        if ((item.activity_name === 'meal' || item.activity_name === 'punch_in'))
          isFixed = true;
        else if (index === (jobs.length - 1))
          isFixed = true;
        else if (jobs[index+1].activity_name === 'meal')
          isFixed = true;
        else if(moment(jobs[index].end_datetime).format('YYYY/MM/DD') < moment(jobs[index + 1].start_datetime).format('YYYY/MM/DD')){
          isFixed = true;
        }
      }

      if (index === (jobs.length - 1)){
        isFixed = true;
      }

      if((condition === 'ALLOWNOTESEDIT' || condition === 'NOEDIT'|| condition === 'ALLOWFULLEDIT' ) && ((jobList.length === 1 && taskList.length === 1) || (jobList.length === 0))){
        if ((item.activity_name === 'meal' || item.activity_name === 'punch_in'))
          showMenu = true;
        else if (index === (jobs.length - 1))
          showMenu = true;
        else if (jobs[index+1].activity_name === 'meal')
          showMenu = true;
      }

      if(item.task_name === 'UNALLOCATED TASK' || item.activity_name === 'meal'){
        showMenu = true;
      }

      if(diff>0 && moment.utc(diff*1000).format('mm') === "00" && moment.utc(diff*1000).format('hh') !== "00"){
        diff = (parseInt(moment.utc(diff*1000).format('hh'))*60).toString();
      }else{
        diff = moment.utc(diff*1000).format('mm')
      }

      newJobsObj.push({
        ...item,
        start_datetime: stc,
        end_datetime: etc,
        duration: dur,
        durationMins : diff,
        height: divHeight,
        formattedStartTime,
        formattedEndTime,
        isFixed,
        showMenu
      });
    });

    return newJobsObj;
  }


  allowEdit(condition){
    const { copyOfJD } = this.state;
    const {  editButtonDisabled} = this.state;
    let startIndex = [];
    copyOfJD.forEach(function(item, index) {
      if(item.isFixed !== true){
        startIndex.push(index);
      }});
    this.setState({condition : condition, allowEditActivity: true, editButtonDisabled : !editButtonDisabled, id : startIndex[0]});
  }

  handleDragging(e, dire, ref, d, item, index){
    const { copyOfJD } = this.state;
    const modifiedJD = JSON.parse(JSON.stringify(copyOfJD));

    if (d.height > 0 && copyOfJD[index+1].duration === '00:01 ') return;
    if (d.height < 0 && copyOfJD[index].duration === '00:01 ') return;

  /*  if (d.height < 0 && modifiedJD[index+1].task_id !== 0 && modifiedJD[index].task_id !== 0) {
      const unallocatedTask = {
        activity_id: modifiedJD[index].activity_id,
        job_id: 0,
        task_id: 0,
        start_datetime: modifiedJD[index].end_datetime,
        end_datetime: modifiedJD[index].end_datetime,
        task_name: 'UNALLOCATED TASK',
        job_name: ' '
      };
      modifiedJD.splice(index + 1, 0, unallocatedTask);
    }*/

    modifiedJD[index].end_datetime = moment(modifiedJD[index].end_datetime).add(d.height, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z');

    var end = moment.utc(modifiedJD[index].end_datetime, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
    var nextEnd = moment(modifiedJD[index+1].end_datetime).add(-1, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z');
    const upTo = moment(modifiedJD[index].start_datetime).add(1, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.000\\Z');

    if (end.isBefore(upTo)) {
      modifiedJD[index].end_datetime = upTo;
    } else if (end.isAfter(nextEnd)) {
      modifiedJD[index].end_datetime = nextEnd;
    }

    modifiedJD[index+1].start_datetime = modifiedJD[index].end_datetime;

    let modifiedData = this.transformActivities(modifiedJD, index, d.height);
    this.setState({jobData: modifiedData, isDragging: true, dragPunchIn: false});
  }


  handleStopDragging(e, dire, ref, d, item, index){
    const { jobData } = this.state;
    const newData = this.transformActivities(jobData.filter(item => !item.toBeDeleted));
    this.setState({copyOfJD: newData, jobData: newData, isDragging: false, dragPunchIn : false});
    const { overviewRef } = this.props;
    overviewRef.style.overflowY = 'auto';
    /*this.props.enablePageScrolling();*/
  }

  selectId(item){
    const { selectedId } = this.state;
    this.setState({ selectedId: selectedId === item ? null : item });
  }

  ResizeDiv(index) {
    const { jobData } = this.state;
    const { companyDetail } = this.props;
    let endTime = jobData[index].formattedEndTime;
    const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
    let momentDate;
    if(time){
      let totalSplitHours = [];
      let timearray = index + 1 !== jobData.length && jobData[index + 1].formattedStartTime.split(' ');
      if(timearray[1] === 'PM'){
        totalSplitHours = timearray[0].split(':');
        totalSplitHours[0] = totalSplitHours[0] === '12' ? parseInt(totalSplitHours[0]) : parseInt(totalSplitHours[0]) + 12;
      }else if(timearray[1] === 'AM'){
        totalSplitHours = timearray[0].split(':');
        if(totalSplitHours[0] === '12'){
          totalSplitHours[0] = parseInt('0');
        }
      }
      if(parseInt(totalSplitHours[0]) === parseInt(time.split(':')[0]) && parseInt(totalSplitHours[1]) === parseInt(time.split(':')[1])) {
        momentDate = moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
      }
    }
    const momentNextStartDate = index + 1 !== jobData.length && moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
    return (
      <div>
      {momentNextStartDate === momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split ?
        <div class="row resizeHandler text_select_disabled">
          <div class="overview-Div">
            <div className="resizeArrowNextDay">
               {moment(jobData[index + 1].start_datetime).format('LT')}
            </div>
          </div>
           <span className="arrow_div absolute w-100" style={{bottom : '-25px'}}>
            <i className=' upward_arrow resizer_arrow'/> 
            <div>
              <hr className="overview-move"/>
            </div>
            <i className=' downward_arrow resizer_arrow'/> 
          </span> 
        </div>
        :
         <div class="row resizeHandler text_select_disabled"> 
          <div class="overview-Div">
            <div className="resizeArrow">
              {endTime}
            </div>
          </div>
          <span className="arrow_div absolute w-100" style={{bottom : '-25px'}}>
            <i className='resizer_arrow upward_arrow'/>
            <div>
              <hr className="overview-move"/>
            </div>
            <i className='resizer_arrow downward_arrow'/> 
          </span>
        </div>
      }
      </div>
    );
  }

  ResizerDiv(index){
    const { jobData } = this.state;
    const { companyDetail } = this.props;
    let endTime = jobData[index].formattedEndTime;
    const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
    let momentDate;
    if(time){
      let totalSplitHours = [];
      let timearray = index + 1 !== jobData.length && jobData[index + 1].formattedStartTime.split(' ');
      if(timearray[1] === 'PM'){
        totalSplitHours = timearray[0].split(':');
        totalSplitHours[0] = totalSplitHours[0] === '12' ? parseInt(totalSplitHours[0]) : parseInt(totalSplitHours[0]) + 12;     
      }else if(timearray[1] === 'AM'){
        totalSplitHours = timearray[0].split(':');
        if(totalSplitHours[0] === '12'){
          totalSplitHours[0] = parseInt('0');
        }
      }
      if(parseInt(totalSplitHours[0]) === parseInt(time.split(':')[0]) && parseInt(totalSplitHours[1]) === parseInt(time.split(':')[1])) {
        momentDate = moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
      }
    }
    const momentNextStartDate = index + 1 !== jobData.length && moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
    return (
      <div className='text_select_disabled'>
        {momentNextStartDate === momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split ?
          <div className={styles['divider_container']} style={{paddingTop: '27px'}}>
            <UpwardArrow height="5" width="5"/>
            <span className={styles['divider_text']}>{moment(jobData[index + 1].start_datetime).format('LT')}</span>
            <span className="arrow_div">
              <i className='arrow upward_arrow ml4'/> 
              <div className={styles['divider']} />
              <i className='arrow downward_arrow ml4'/> 
            </span>        
          </div>
          :
          <div className={styles['divider_container']}>
            <span className={styles['divider_text']}>{endTime}</span>
            <span className="arrow_div">
              <i className='arrow upward_arrow ml4'/> 
              <div className={styles['divider']} />
              <i className='arrow downward_arrow ml4'/> 
            </span>
          </div>
        }
      </div>
    );
  }

  deleteJob(index){
    const { copyOfJD } = this.state;
    let modifiedData = JSON.parse(JSON.stringify(copyOfJD));
    const sessionIdItem = copyOfJD.find(item => item.session_id);
    modifiedData[index] = {
      activity_id: modifiedData[index].activity_id,
      job_id: 0,
      task_id: 0,
      start_datetime: modifiedData[index].start_datetime,
      end_datetime: modifiedData[index].end_datetime,
      task_name: 'UNALLOCATED TASK',
      job_name: ' ',
      session_id : sessionIdItem && sessionIdItem.session_id
    };

    modifiedData = this.transformActivities(modifiedData);
    this.setState({copyOfJD: modifiedData, jobData: modifiedData, selectedId: null});
  }

  //will call on reset button
  resetActivityData(){
    this.setState({isChanges: false});
    this.props.resetData();
  }

  redirectToiFrame(){
    const { router: { history } } = this.context;
    history.push('/jobs/feedback');
  }

  render() {
    const { jobData , id, allowEditActivity, selectedId, condition, isOpen, modalSelection, isChanges } = this.state;
    const { goToUrl ,approveDisabled, taskList, jobList , companyDetail,isConfirmationButtonLoading, userPreferenceLanguage, sessionDetail, activityLastSplit, userExtraData,firstName,lastName} = this.props
    const editTimeline = allowEditActivity === true && jobData.length && !(jobData[0].task_name === 'UNALLOCATED TASK') && jobData[0].durationMins > 1;
    const addBeforePunchout = allowEditActivity === true && jobData.length && !(jobData[jobData.length - 1].task_name === 'UNALLOCATED TASK') && jobData[jobData.length - 1].durationMins > 1;
    const time =  companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.work_day_start_time;
    let momentDate, selectedEndTime;
    const isMealSurvey = activityLastSplit && activityLastSplit.multi_validity && activityLastSplit.multi_validity.length ? true : false;
    const isMealVoilation = activityLastSplit && activityLastSplit.multi_validity && activityLastSplit.multi_validity?.length === 1 && !activityLastSplit.multi_validity.includes("force_punch_out") ? true : false;
    const isFeedbackExist = userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.meal_questionnaire ? true : false;
   

    
    return (
      <div>
       <div className="w-100 margin-list">
              { 
               (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT')  && isMealSurvey && activityLastSplit && activityLastSplit.template_exists && isFeedbackExist &&
               <div className="border-box tc" style={{ minHeight: '34px' ,backgroundColor:'#ff0e0e'}}>
                <div style={{width:'100%', textAlign:"center", fontSize: '18px', color:"white",fontWeight:"bold"}} className="pv2 tc gray dib">{this.setSurveyTypeValue()}</div>
               </div>  
              }
              {firstName&&
                <div className="border-box tc" style={{ minHeight: '34px' ,backgroundColor:'#E2ECF1',backgroundSize:"auto"}}>
                <div className=" pv2 tc gray dib" style={{ fontSize: '18px',color:'#09819A'}}>
                    <span>{firstName}&nbsp;{lastName}</span>
                    
                </div>
                </div>            
              }
        </div> 
      <div className="w-70-l w-80-ns margin-list pa2 pb5 overview_screen_width">
      <article className="w-100">
        <div className="relative">
        {jobData && jobData.map((item, index) => {
          const {formattedStartTime, formattedEndTime} = item;
          const nextStartDate = index + 1 !== jobData.length && moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD');
          const momentNextStartDate = index + 1 !== jobData.length && moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
          const durationArray = item && (item.duration).split(':');
          const durationMins = item && (durationArray && durationArray[1]).split(' ');
          const formattedDuration = `${durationArray[0]}h ${durationMins[0]}m`;
                  
          if(time){
            let totalSplitHours = [];
            let timearray = index + 1 !== jobData.length && jobData[index + 1].formattedStartTime.split(' ');
            if(timearray[1] === 'PM'){
              totalSplitHours = timearray[0].split(':');
              totalSplitHours[0] = totalSplitHours[0] === '12' ? parseInt(totalSplitHours[0]) : parseInt(totalSplitHours[0]) + 12;   
            }else if(timearray[1] === 'AM'){
              totalSplitHours = timearray[0].split(':');
              if(totalSplitHours[0] === '12'){
                totalSplitHours[0] = parseInt('0');
              }
            }
            if(parseInt(totalSplitHours[0]) === parseInt(time.split(':')[0]) && parseInt(totalSplitHours[1]) === parseInt(time.split(':')[1])) {
              momentDate = moment(jobData[index + 1].start_datetime).format('YYYY/MM/DD HH:mm');
              selectedEndTime = jobData[index + 1].start_datetime;
            }
          }
          let momentIndex = jobData.map( el => el.end_datetime).indexOf(selectedEndTime);
          const isAddJobBelow =  jobData[index + 1] && !(jobData[index + 1].task_name === 'UNALLOCATED TASK') && !(jobData[index + 1].activity_name && jobData[index + 1].activity_name === 'meal') && jobData[index + 1].durationMins > 1 && allowEditActivity === true;
          const isAddJobAbove = !(momentNextStartDate == momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split &&  nextStartDate) && jobData[index + 1] && jobData[index + 1].activity_name === 'meal' && item.durationMins > 1 && allowEditActivity === true;
          return(
            <div className='text_select_disabled'>
              
              {(index === 0) &&
                (<div className={styles['divider_container']} style={{width: '100%'}}>                  
                  <span className={styles['divider']} style={{backgroundColor: '#09819A'}}/> 
                  <span className={styles['divider_text']} style={{color : '#001E2E'}}>{moment(jobData[0].start_datetime).format('YYYY/MM/DD')}</span>  
                  <span className={styles['divider']} style={{backgroundColor: '#09819A'}}/>                  
                </div>)
              }
              {(index === 0 && this.state.dragPunchIn) &&
                <div class="resizeHandler back-arrow text_select_disabled margin_for_top"> 
                  <div class="overview-Div">
                    <div className="disabledResizeArrow" style={{top : '11px'}}>
                      {formattedStartTime}
                    </div>
                  </div>
                  <span className="arrow_div absolute w-100" style={{bottom : '-25px'}}>
                    <div style={{textAlign : 'left'}}>
                      <hr className="overview-move" style={{bottom: '1px', border: '1px solid #919090'}}/>
                      {editTimeline && allowEditActivity ?
                        <img src={require('./addNew.png')} height ="20" width ="20" onClick={() => this.addJobBelow(-1)} className="back-arrow showDisabledDragger" alt=''/>
                        : <img src={require('./plusDisabled.png')} height ="20" width ="20" className="showDisabledDragger"  alt=''/>
                      }
                    </div>
                  </span>
                </div>
              }
              {(index === 0 && ( condition=== 'ALLOWNOTESEDIT' || condition==='NOEDIT' || !allowEditActivity || condition === 'ALLOWFULLEDIT')) && !this.state.dragPunchIn &&
                (<div className={!allowEditActivity ? `${styles['divider_container']} text_select_disabled` : `${styles['divider_container']} back-arrow text_select_disabled`} onClick ={allowEditActivity ? () => this.showPunchInDragger(0) : null}>
                  <span className={styles['divider_text']}>{formattedStartTime}</span>
                  <span className={styles['divider']} />                
                </div>)
              }
              <div className={`${styles['time_row']} text_select_disabled`}></div>
              <Resizable
                size={{ height: item.height }}
                onResize={(e, direction, ref, d) => {
                  this.handleDragging(e, direction, ref, d, item, index);
                }}
                onResizeStop={(e, direction, ref, d) => {
                  this.handleStopDragging(e, direction, ref, d, item, index);
                }}
                handleComponent={
                  id === index
                  ? { bottom: () => this.ResizeDiv(index) }
                  : { bottom: () => this.ResizerDiv(index) }
                }
                className={
                  momentNextStartDate === momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split && (item.task_id !== 0 || item.isFixed) && allowEditActivity ?
                  'mt3 mb4 text_select_disabled' :
                  item.task_id !== 0 && !allowEditActivity
                  ? 'mt3 mb2 text_select_disabled'
                  : item.task_id === 0 && !allowEditActivity
                    ? 'mt3 mb2 text_select_disabled'
                      : allowEditActivity && index === jobData.length -1 && item.task_id !== 0 ? 'mt3 mb3 text_select_disabled'
                        :allowEditActivity && index === jobData.length -1 && item.task_id === 0 ? 'mt3 mb3 text_select_disabled'
                          : allowEditActivity && item.isFixed && item.task_id !== 0 ? 'mt3 mb2 text_select_disabled' 
                            : allowEditActivity && item.isFixed && item.task_id === 0 ? 'mt3 mb2 text_select_disabled' 
                              : allowEditActivity && item.task_id !== 0 
                                ? 'mt3 mb4 ipadBottom text_select_disabled' :  allowEditActivity && item.task_id === 0 ? 'mt3 mb4 ipadBottom text_select_disabled' 
                                  : null
                }
                onResizeStart={() =>  this.startResizing(index)}
                enable={{
                  bottom : allowEditActivity && !item.isFixed ? true : false
                }}
              >
                <div className="div-height text_select_disabled">

                <div className={`${styles['duration_display']} text_select_disabled`}>{formattedDuration}</div>
                  <div className={`${styles['job_title_container']} text_select_disabled`} style={item.activity_name === 'meal' ? {borderLeft: '10px solid #FFBA00'} : item.job_id === 0 ? {borderLeft: '10px solid #9c9a9a'} : {borderLeft: '10px solid #001e2e'} }>
                    <div className={item.task_name === 'UNALLOCATED TASK' ? `${styles['unallocatedArea']}` : `${styles['overview_item_allignment']}`}>
                      <div class="mw9 center ph2-ns">
                        <div class="cf ph1-ns">
                          <div onClick ={item.task_name === 'UNALLOCATED TASK' ? () => {this.selectId(index); this.toggleEditModal(index);} : null } className = {item.task_name === 'UNALLOCATED TASK' ? `${styles['unallocatedTime']} w-100 w-80-ns pt1 back-arrow` : 'fl w-100 w-80-ns pt1'}>
                            {item.job_id === 0 &&
                              <p className="flex mt1">
                                <p className="mt2">
                                  <AddIcon
                                    height="21"
                                    width="21"
                                    className="mr3 button-role grey-color"
                                    onClick={() => {this.selectId(index); this.toggleEditModal(index);}}
                                  />
                                </p>
                                <p style={{marginTop : '10px' , color : '#757579'}}>
                                  {TRMSGS.assignJobTasks}
                                </p>
                              </p>
                            }
                            {item.job_id !== 0 &&
                              <div>
                                {item.activity_name === 'meal' &&
                                  <div className="flex pt2">
                                    <p className="mt0 ml1 fill-green">
                                      <MealBreakIcon height="22" width="22" />
                                    </p>
                                    <p style={{marginTop: '2px', marginLeft: '10px'}}>Meal Break</p>
                                  </div>
                                }
                                {item.activity_name === 'punch_in' &&
                                  <div className="flex">
                                    <p className="mt0">
                                      <JobsIcon height="20" width="20" />
                                    </p>
                                    <p style={{marginTop: '2px', marginLeft: '4px'}}>
                                      Shift Time
                                    </p>
                                  </div>
                                }
                                {item.activity_name === 'shift' &&
                                  <div className="flex">
                                    <p className="mt0">
                                      <JobsIcon height="20" width="20" />
                                    </p>
                                    <p style={{marginTop: '2px', marginLeft: '4px'}}>
                                      Shift Time
                                    </p>
                                  </div>
                                }
                                {!item.activity_name &&
                                  <div className="flex">
                                      <div className="mr1 custom_ipad_width" style={{width: '2%'}}>
                                        <p className="mt2 mb0 ml2">
                                          <JobsIcon height="16" width="16" />
                                        </p>
                                      </div>
                                      <div className='w-100'>
                                        <div className='textOverflow w-100 ml3 mt2' style={{fontSize : '15px',fontWeight : '500'}}>
                                          {item.job_name ? item.job_name : item.job_id}
                                        </div>
                                        <div className = 'textOverflow w-100 fw4 ml3' style = {{fontSize : '13px',fontWeight : '300'}}>
                                          {item.task_name ? item.task_name : item.task_id}                                      
                                        </div>
                                      </div>
                                  </div>
                                }
                              </div>
                            }
                           
                          </div>
                          <div class="fl w-100 w-20-ns pa2 tr">
                            <div>
                              {allowEditActivity && item.showMenu !== true  ?
                                <div className={`${styles['drop_down_option_images']} dropdown-options`}>
                                {selectedId !== index
                                  ? <span className="pointer" onClick={() => this.selectId(index)}>
                                    <img src={require('./blue_menuIcon.png')} alt="logo" height="30" width="30" className="menu-option"/>
                                  </span>
                                  : <div className = "arrow_box">                       
                                        <span>
                                          <img className="mr3 mb2" src={require('./delete.png')} alt="logo" height="16" width="16" onClick={() => this.deleteJob(index)}/>
                                          {(((jobList && jobList.length > 1) || (jobList && jobList.length === 1 && taskList && taskList.length >1)) && (companyDetail.task_time_allocation)) && (companyDetail.to_be_allocated) &&
                                            <img src={require('./editIcon.png')} onClick={() => this.toggleEditModal()} alt="logo" height="16" width="16" className="mb2"/>
                                          }
                                        </span>
                                      <img src={require('./blue_menuIcon.png')} onClick={() => this.selectId(index)} alt="logo" height="30" width="30"/>
                                    </div>
                               }
                                </div> : null
                              }
                            </div>
                            <div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(!(isAddJobBelow || isAddJobAbove) && id === index && index!==jobData.length - 1 && allowEditActivity) ?
                    <div className='newAllocation back-arrow text_select_disabled' style={momentNextStartDate === momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split && allowEditActivity ? {bottom:'-53px'} : null}><img src={require('./plusDisabled.png')} height="20" width="20" alt=''/></div>
                  :
                  ((isAddJobBelow || isAddJobAbove) && id === index && allowEditActivity) ?
                  <div className='newAllocation back-arrow text_select_disabled' onClick={isAddJobBelow ? () => this.addJobBelow(index) : () => this.addJobAbove(index)} style={momentNextStartDate === momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split && allowEditActivity ? {bottom:'-53px'} : null}><img src={require('./addNew.png')} height="20" width="20" alt=''/></div>
                 :
                 null
                }
              </Resizable>
              {momentNextStartDate == momentDate && companyDetail && companyDetail.company_payroll_setting && companyDetail.company_payroll_setting.daily_split &&  nextStartDate &&
                  <div className={`${styles['divider_container']} text_select_disabled`} style={allowEditActivity? {width: '100%', 'marginTop':'-15px'} : {width:'100%'}}>
                  <span className={styles['divider']}  style={{backgroundColor : '#09819A'}}/>
                  <span className={styles['divider_text']}  style={{color : '#001E2E'}}>{nextStartDate}</span>
                  <span className={styles['divider']}  style={{backgroundColor : '#09819A'}} />
                </div>
              }
              {(index + 1 !== jobData.length) &&
                (allowEditActivity && item.isFixed) && id === index ?
                  momentIndex === index ?
                  (<div class="resizeHandler back-arrow text_select_disabled"> 
                  <div class="overview-Div">
                    <div className="disabledResizeArrow" style={{top : '-14px'}}>
                      {moment(jobData[index + 1].start_datetime).format('LT')}
                    </div>
                  </div>
                  <span className="arrow_div absolute w-100" style={{bottom : '0px'}}>
                    <div style={{textAlign : 'left'}}>
                       <hr className="overview-move" style={{bottom: '1px', border: '1px solid #919090'}}/>
                    </div>
                  </span>
                </div>)
                  :
                (<div class="resizeHandler back-arrow text_select_disabled" onClick = {() => this.openEditPopup(item, jobData[index + 1], index)}> 
                  <div class="overview-Div">
                    <div className="resizeArrow" style={{top : '-14px'}}>
                      {moment(jobData[index + 1].start_datetime).format('LT')}
                    </div>
                  </div>
                  <span className="arrow_div absolute w-100" style={{bottom : '0px'}}>
                    <div style={{textAlign : 'left'}}>
                      <hr className="overview-move" style={{bottom: '1px'}}/>
                    </div>
                  </span>
                </div>)
                : (!allowEditActivity || item.isFixed) && (index + 1 !== jobData.length) ?
                (<div className={allowEditActivity ? `${styles['divider_container']} back-arrow text_select_disabled` : `${styles['divider_container']}`} onClick ={() => this.updateId(index)}>
                  <span className={styles['divider_text']}>{moment(jobData[index + 1].start_datetime).format('LT')}</span>
                  <span className={styles['divider']} />
                </div>)
                :
                null
              }
               {(index + 1 === jobData.length) && (condition=== 'ALLOWNOTESEDIT' || condition==='NOEDIT' || !allowEditActivity || condition === 'ALLOWFULLEDIT') && !this.state.dragPunchOut &&
                (<div className={!allowEditActivity ? `${styles['divider_container']}` : `${styles['divider_container']} back-arrow`} onClick = {allowEditActivity ? () => this.showPunchOutDragger() : null}>
                  <span className={styles['divider_text']}>{formattedEndTime}</span>
                  <span className={styles['divider']} />                  
                </div>)
              }
              {(index === jobData.length - 1 && this.state.dragPunchOut) &&
                <div class="resizeHandler back-arrow text_select_disabled"> 
                  <div class="overview-Div">
                    <div className="disabledResizeArrow" style={{top : '-14px'}}>
                      {formattedEndTime}
                    </div>
                  </div>
                  <span className="arrow_div absolute w-100" style={{bottom : '0px'}}>
                    <div style={{textAlign : 'left'}}>
                      <hr className="overview-move" style={{bottom: '1px', border: '1px solid #919090'}}/>
                      
                      {addBeforePunchout && allowEditActivity ?
                        <img src={require('./addNew.png')} alt='' height ="20" width ="20" onClick={() => this.addJobAbove(jobData.length - 1)} className="back-arrow showDisabledDragger"/>
                        : allowEditActivity ? <img src={require('./plusDisabled.png')} alt='' height ="20" width ="20" className="showDisabledDragger"/>
                        :
                        null
                      }
                    </div>
                  </span>
                </div>
              }
            </div>
            
        );})}
        </div>
        {
          condition === 'NOEDIT' &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container_edit_bilingual']} ${styles['custom_btn_container']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
            <div style={{float : 'right'}}>
              <button
              disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
              onClick={() => this.saveActivity('approve', jobData)}
              className="w-100 pointer btn-color  white"
            >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            <div className ="fl">
              <button
                className={'w-100 pointer btn-color white'} onClick={() => goToUrl('correction')}>
                <span><CorrectionIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.correction}
              </button>
            </div>
          </div>
        }
        {/*{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && !allowEditActivity  &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container_edit_bilingual']} ${styles['custom_btn_container']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
            <div style={((jobList.length > 1 && taskList.length > 1) || (jobList.length === 1 && taskList.length > 1)) ? {float : 'right'} : {float : 'right'}}>
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            {((jobList.length > 1 || taskList.length > 1) || (jobList.length === 1 && taskList.length > 1)) ?
                <div className ="fl">
                  <button
                    className={'w-100 pointer btn-color white'}
                    onClick={() => this.allowEdit(condition)}
                    disabled ={this.state.editButtonDisabled}>
                      <span><CorrectionIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                      {TRMSGS.edit}
                  </button>
                </div>
                :
                <div className ="fl">
                  <button
                    className={'w-100 pointer btn-color white'}
                    onClick={() => goToUrl('correction')}>
                      <span><CorrectionIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                      {TRMSGS.correction}
                  </button>
                </div>
            }
            </div>

        }*/}
        {/*{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && allowEditActivity  &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
            <div className='fr'>
              <button
                className="w-100 pointer btn-color  white"
                onClick={() => this.props.resetData()}
              >
                <span><ResetIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.reset}
             </button>
            </div>
              <div className ="fl">
                <button
                  className={'w-100 pointer btn-color white'}
                  onClick={() => this.saveActivity('submit')}
                  disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                  >
                    <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                    {TRMSGS.submitCorrection}
                </button>
              </div>
          </div>
        }*/}


{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT')  && !isChanges && isMealVoilation && (isFeedbackExist) &&    
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
    
          
            {!activityLastSplit?.template_exists ?
           <>
            <div className="fr">
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            <div className="fl">
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </> :
          <button
          className={'w-100 pointer btn-color white'}
          onClick={() => this.redirectToiFrame()}>
            <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
            {TRMSGS.submitCorrection}
        </button>
             
          }
          </div>
        }
        
{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && !isChanges && isMealVoilation && (!isFeedbackExist) &&    
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
                      <div className="fr">
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            <div className="fl">
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </div>
        }
{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && !allowEditActivity  && !isChanges && isMealSurvey && !isMealVoilation && !isFeedbackExist &&    
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
           
        
            <div style={{margin:'auto'}}>
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </div>
        }

        {/*this block of code will run when no job no task ie. allowEditActivity==false && isChanges==false */}
        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && !allowEditActivity  && !isChanges && !isMealSurvey && 
             
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
           
         
            
            <div className="fr">
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            <div className="fl">
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </div>
        }


        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && allowEditActivity  && !isChanges && !isMealSurvey &&    
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
   
         
            
            <div className="fr">
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
            <div className="fl">
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </div>
        }
        
        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && !allowEditActivity  && !isChanges && !activityLastSplit?.template_exists && ((isMealSurvey && !isMealVoilation && isFeedbackExist)) &&    
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
           
       
            {/* <div className="fr">
           { console.log((isMealSurvey && !isFeedbackExist))}
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div> */}
            <div style={{margin:'auto'}}>
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => goToUrl('correction')}>
                  <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.submitCorrection}
              </button>
            </div>
          </div>
        }

        {/*this block of code will run when job and task is present ie. allowEditActivity==true && isChanges==false */}
        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && allowEditActivity  && !isChanges && isMealSurvey && !isMealVoilation && !isFeedbackExist  &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
            
      
          
            {/* <div className='fr'>
            {console.log("2")}
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('approve', jobData)}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.approve} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div> */}
              <div style={{margin:'auto'}}>
                <button
                  className={'w-100 pointer btn-color white'}
                  onClick={() => this.saveActivity('submit')}
                  disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                  >
                    <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                    {TRMSGS.submitCorrection}
                </button>
              </div>
          </div>
        }

        {/*this block of code will run when we change the job activity ie. allowEditActivity==true && isChanges==true */}
        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && allowEditActivity  && isChanges && (!isMealSurvey || (isMealSurvey && !isFeedbackExist)) &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
           
           
            <div className='fr'>
              <button
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
                onClick={() => this.saveActivity('submit')}
                className="w-100 pointer btn-color  white"
              >
                <span><ApproveIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.submit} <ButtonLoaderAtom active ={isConfirmationButtonLoading}/>
             </button>
            </div>
              <div className ="fl">
                <button
                  className="w-100 pointer btn-color  white"
                  onClick={() => this.resetActivityData()}
                >
                  <span><ResetIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                  {TRMSGS.reset}
                </button>
              </div>
          </div>
        }
        {
         (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT')  && isMealSurvey && !isMealVoilation && activityLastSplit && activityLastSplit.template_exists && isFeedbackExist &&
         <>
         <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
       
        
          {allowEditActivity && isChanges ?
           <>
          
          

           <div className='fl'>
              <button
                className="w-100 pointer btn-color  white"
                onClick={() => this.props.resetData()}
              >
                <span><ResetIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.reset}
              </button>
            </div>
            <div className ="fr">
              <button
                className="w-100 pointer btn-color  white"
                onClick={() => {
                  this.saveFeedbackActivity('approve', jobData);
                  this.redirectToiFrame()
                }}
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
              >
               <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.submitCorrection}
              </button>
            </div>
          </> :
          
              <button
                className="w-100 pointer btn-color  white"
                onClick={() => {
                  // this.saveFeedbackActivity('approve', jobData);
                  this.redirectToiFrame()
                }}
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
              >
               <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.submitCorrection}
              </button>
          }
          </div>
          {/*<div style={{width:'100%', textAlign:"center", fontSize: '15px'}} className="pt2">({this.setSurveyTypeValue()})</div>
          */}
          </>
        }
        {
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && isMealSurvey && activityLastSplit && !activityLastSplit.template_exists && isFeedbackExist &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
        
        
          {allowEditActivity && isChanges &&
          
           <>
          
           <div className='fl'>
              <button
                className="w-100 pointer btn-color  white"
                onClick={() => this.props.resetData()}
              >
                <span><ResetIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.reset}
             </button>
            </div>
            <div className ="fr">
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => {
                  this.saveFeedbackActivity('approve', jobData);
                  goToUrl('correction')}
                }
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
              >
                <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.submitCorrection}
              </button>
            </div>
            </> 
          }
          </div>
        }

{
          (condition === 'ALLOWNOTESEDIT' || condition === 'ALLOWFULLEDIT') && allowEditActivity && !isChanges && isMealSurvey && !isMealVoilation && activityLastSplit && !activityLastSplit.template_exists && isFeedbackExist &&
          <div className={userPreferenceLanguage === 'bilingual' ? `${styles['custom_btn_container']} ${styles['custom_btn_container_bilingual']} w-100 cf` : `${styles['custom_btn_container']} w-100 cf`}>
          
          
           <>
            <div style={{margin:"auto"}}>
              <button
                className={'w-100 pointer btn-color white'}
                onClick={() => {
                  this.saveFeedbackActivity('approve', jobData);
                  goToUrl('correction')}
                }
                disabled={approveDisabled || jobData.find(item => item.job_id === 0)}
              >
                <span><NotesIcon height="40" width="40" className={ `${styles['job-overview-button-img']}`}/></span>
                {TRMSGS.submitCorrection}
              </button>
            </div>
            </> 
          
          </div>
        }


        <JobTaskSelectModal
          jobList={jobList}
          taskList={taskList}
          selectJob={this.handleJobSelect}
          handleTaskSelect={this.handleTaskSelect}
          isOpen={isOpen}
          toggleEditModal={this.toggleEditModal}
          selected={modalSelection}
          submit={this.handleModalSubmit}
          markFavourite={this.props.markFavourite}
          markUnFavourite={this.props.markUnFavourite}
          markUnFavouriteTask={this.props.markUnFavouriteTask}
          markFavouriteTask={this.props.markFavouriteTask}
          showJob={this.state.showJob}
        />
        {this.state.openEditModal &&
          <EditTimeModalComponent
            isOpen={this.state.openEditModal}
            toggle={this.openEditPopup}
            previousJob ={this.state.previousJob}
            nextJob = {this.state.nextJob}
            initialValues={
            { end_date:  moment(this.state.previousJob.end_datetime).format('h:mm a'),
              start_date: this.state.nextJob.start_datetime}}
            submitEditedTime={this.submitTime}
            submitEditedDate={this.submitDate}
            updateEnteredTime = {this.updateEnteredTime}
        />}
      </article>
      </div>
      </div>
    );
  }
}

/*const mapStateToProps = state => 
{
  return({
  profile: ProfileDucks.profile(state),
});
};*/
JobShiftOverViewComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};
/*export default connect(
  mapStateToProps,
)(JobShiftOverViewComponent);*/
export default JobShiftOverViewComponent;
