import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'react-bootstrap-modal';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import TRMSGS from 'constants/Translations/Localization';

/**
 * ActivityListPage -> ACtivityListSection -> ConfirmationApprovalShiftModalComponent
 *
 * Props:
 *    - update
 */
 const ConfirmationApprovalModalComponent = (props) =>  {
  const { isOpen, toggle, approvedRequest, isButtonLoader , message, userPreferenceLanguage } = props;

  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      onHide={() => toggle()}
      backdrop="static"
    >
      <Modal.Header className='custom-modal'>
        <Modal.Title id='ModalHeader' style={{fontWeight: '600'}}>
          {TRMSGS.approveMyShift}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ml4 mr4 pl0 pt4 pb4 pr0">
        <div style={{'font-size':'16px','line-height':'24px','white-space':'pre-line'}}>{message}</div>
      </Modal.Body>
      <Modal.Footer className="cf">
        <button 
          className={userPreferenceLanguage ==='bilingual' ? "br2 pointer mr3 pb3 pt3 ph4 f4 white btn-color mb1" :"br2 pointer mr3 pb3 pt3 ph4 f3 white btn-color mb1"}
          onClick={() => approvedRequest('APPROVE')}
        >
          {TRMSGS.approveMyShift} 
          <img
            className="di pl2 mr2 v-btm"
            src={require('./approve-icon.png')} alt="Site Name"
          />
          <ButtonLoaderAtom className="di pl2 v-btm ml3" active={isButtonLoader} />
        </button>
        <button 
          className={userPreferenceLanguage === 'bilingual' ? "br2 pointer pb3 pt3 ph4 mr3 f4 white btn-blue" :"br2 pointer pb3 pt3 ph4 mr3 f3 white btn-blue"}
          onClick={() => toggle()}
        >
          {TRMSGS.goBack}
          <img
            className="di pl2 mr2 v-btm"
            src={require('./back-icon.png')} alt="Site Name"
          />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationApprovalModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  approvedRequest: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  isButtonLoader: PropTypes.bool.isRequired,
};

export default ConfirmationApprovalModalComponent;


