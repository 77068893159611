import React from 'react';
import styles from './styles.module.scss';
import {ReactComponent as CheckIcon} from 'images/check.svg';
import { ReactComponent as WhiteFavoriteIcon } from 'images/favoriteWhite.svg';
import {ReactComponent as BlueFavoriteIcon} from 'images/favoriteGreen.svg';
import TRMSGS from 'constants/Translations/Localization';

class JobListComponent extends React.Component {

  render() {
    const { jobList, markFavourite, markUnFavourite, selectJob, selected, popupOpen } = this.props;
    return (
    jobList && jobList.length !==0 ?
    <div className={popupOpen && 'scroll_list mt3'}>
    {jobList && jobList.length && jobList.map(item => {
      return(
          <div
            className={!popupOpen ? styles.job_list_component : `${styles.jobs_in_popup} ${styles.job_list_component}`}
          >
            <div style={popupOpen ? {width : '80%'} : {width: '90%'}} onClick={() => selectJob(item)} >
              {!popupOpen ?
                <h2 className="ml4 textOverflow w-100">{item.name}</h2>
                :
                <h3 className='textOverflow'>{item.name}</h3>

              }
            </div>
            <div className="flex">
              {+selected === item.id &&
                <CheckIcon height="30" width="30" className="mr3 button-role" fill="#000" />
              }
              {markFavourite && markUnFavourite &&
                (item && item.is_favourite ?
                <BlueFavoriteIcon height="30" width="30" className="mr4 button-role svg-fill"  onClick={() => markUnFavourite(item)}/>
                :
                <WhiteFavoriteIcon height="30" width="30" className="mr4 button-role" onClick={() => markFavourite(item)}/>
              )}
            </div>           
          </div>
          );})
        }
    </div> :
    <div>
      <div className={styles.job_list_component_empty}  >
      {TRMSGS.noRecordsFound}
      </div>
    </div>
    );
  }
}

export default JobListComponent;
