import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';

import KeyboardInputAtom from 'atoms/KeyboardInput';
import TRMSGS from 'constants/Translations/Localization';

/**
 * DashboardListPage -> DashboardFilterSection -> DashboardFilterFormComponent
 *
 * Props:
 *    None
 */

const DashboardFilterFormComponent = (props,context) => {
  const {
    handleSubmit,
    toggle,
    updateSelected,
    isOpen,
    change,
    location,
    search,
    userPreferenceLanguage
  } = props;

  const {query, pathname} = location;
  const { ...rest } = query;
  const { router: { history } } = context;

  const clear = () => {
    if(rest){
      delete rest.search;
      delete rest.page;
    }
    change('search', null);
    history.push({ pathname, search: queryString.stringify(rest) });
  };

  {userPreferenceLanguage && TRMSGS.setLanguage(userPreferenceLanguage)}

  return (
    <form onSubmit={handleSubmit(updateSelected)} className="mb4 filter-component dashboard-filter-component" noValidate>
      <Field
        component={KeyboardInputAtom}
        submit={updateSelected}
        toggle={toggle}
        isOpen={isOpen}
        placeholder={TRMSGS.enterEmployeeName}
        name='search'
        type='text'
        clearIcon = { search ? true : false}
        clear={clear}
        style={{boxShadow:'0px 2px 5px #00000029', height:'37px'}}
        dashboard={'dashboardkeyboard'}
      />
      <button className={userPreferenceLanguage ==='bilingual' ? "btn-color white custom_border_bilingual dashboard-search" :"btn-color white custom_border dashboard-search"}>
        {TRMSGS.search}
      </button>
    </form>
  );
};

DashboardFilterFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  updateSelected: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};


DashboardFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const dashboardFilterForm = reduxForm({
  form: 'DashboardFilterForm',
  enableReinitialize: true,
})(DashboardFilterFormComponent);

const selector = formValueSelector('DashboardFilterForm');

export default connect(
  (state) => {
    const search = selector(state, 'search');
    return { search };
  },
)(dashboardFilterForm);
