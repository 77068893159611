import React from 'react';

import HeaderSection from 'sections/common/Header';
import FooterSection from 'sections/common/Footer';
import MealListSection from 'sections/meals/list/List';

class MealListPage extends React.PureComponent {

  render() {
    return (
      <div className="min-vh-100 pb4 dark-grey-bg relative">
        <div className="w-100 margin-list">
          <HeaderSection />
        </div>
        <div className="w-70-ns margin-list pa2">
          <MealListSection />
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default MealListPage;
