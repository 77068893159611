import React from 'react';

import { Switch, Route } from 'react-router-dom';

import TimecardDetailPage from './Detail';


export const routes = [
  // {
  //   component: TimecardListPage,
  //   exact: true,
  //   name: 'timecard.historic',
  //   path: '/timecard',
  // },
  {
    component: TimecardDetailPage,
    exact: true,
    name: 'timecard.daily',
    path: '/timecard',
  },
  {
    component: TimecardDetailPage,
    exact: true,
    name: 'timecard.daily',
    path: '/timecard/:id/daily-timecard/',
  },
];

const GenericRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default GenericRoutes;
