import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Singleton from 'lib/singleton';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as RouteDucks from 'ducks/routes';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as UserDucks from 'ducks/accounts/user';

import JobShiftOverViewComponent from 'components/jobs/overview/overView';
import JobShiftOverViewComponentV1 from 'components/jobs/overview/overViewV1';
import ConfirmationApprovalModalComponent from 'components/activities/Form/confirmationApprovalModal.jsx';

const cookies = new Cookies();

class JobShiftOverViewSection extends React.Component {
  constructor() {
    super();
    this.approveShift = this.approveShift.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.markFavourite = this.markFavourite.bind(this);
    this.markUnFavourite = this.markUnFavourite.bind(this);
    this.markFavouriteTask = this.markFavouriteTask.bind(this);
    this.markUnFavouriteTask = this.markUnFavouriteTask.bind(this);
    this.getTasks = this.getTasks.bind(this);
    this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
    this.approvedRequest= this.approvedRequest.bind(this);
    this.saveJobData = this.saveJobData.bind(this);
    this.saveFeedbackActivity = this.saveFeedbackActivity.bind(this);
    this.state={
      isApproveButtonLoading : false,
      approveDisabled : false,
      isApproveShiftOpen : false,
      condn : null,
      sessionData: null,
      isConfirmationButtonLoading:false,
    };
  }


  markFavourite(item){
    const { profile } = this.props;
    this.props.JobDucks.markFavouriteJob(item.id)
    .then(() => {
      this.props.JobDucks.getJobs({employee_id : profile.employee_id});
    });
  }

  markUnFavourite(item){
    const { profile } = this.props;
    this.props.JobDucks.markUnFavouriteJob(item.id)
    .then(() => {
      this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false});
    });
  }

  confirmationModalToggle(){
    const { isApproveShiftOpen } = this.state;
    this.props.resetTimer("goBack");
    this.setState({isApproveShiftOpen : !isApproveShiftOpen});
  }

  markFavouriteTask(item){
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const job_id = parseInt(singleton.job_id);
    const reqObj ={
      job_id : job_id,
      task_id : item.id,
    };
    this.props.TaskDucks.markFavouriteTasks(reqObj)
    .then(() => {
      this.props.TaskDucks.getTasks(reqObj);
    });
  }

  markUnFavouriteTask(item){
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const job_id = parseInt(singleton.job_id);
    const reqObj ={
      job_id : job_id,
      task_id : item.id,
    };
    this.props.TaskDucks.markUnFavouriteTasks(reqObj)
    .then(() => {
      this.props.TaskDucks.getTasks(reqObj);
    });
  }

  saveActivity(data, buttonType, condition) { 
    const { sessionDetail, profile, companyDetail } = this.props;
    const activityDetail = {
      employee_id : profile.employee_id,
      data : data
    };    
      this.props.SessionDucks.saveActivityData(activityDetail, sessionDetail.session_id)
      .then(() =>{ 
        if(buttonType === 'submit'){
          this.goToUrl();
        }
        else if(buttonType === 'approve'){
          if(companyDetail.enable_daily_compliance){
            this.setState({isApproveShiftOpen : true});
            this.setState({condn : condition, sessionData: data});
            this.props.resetTimer();
            /*this.approveShift(data, condition);*/
          }
          else{
            this.approveShift(data, condition);
            this.setState({isConfirmationButtonLoading : true});
          }
        }
      });
  }

  saveFeedbackActivity(data, buttonType, condition) { 
    const { sessionDetail, profile, companyDetail } = this.props;
    const activityDetail = {
      employee_id : profile.employee_id,
      data : data
    };    
      this.props.SessionDucks.saveActivityData(activityDetail, sessionDetail.session_id)
      .then(() =>{
        if(buttonType === 'submit'){
          this.goToUrl();
        }
        else if(buttonType === 'approve'){
          if(companyDetail.enable_daily_compliance){
            this.setState({isApproveShiftOpen : true});
            this.setState({condn : condition, sessionData: data});
            // this.props.resetTimer();
            /*this.approveShift(data, condition);*/
          }
          else{
            // this.approveShift(data, condition);
            this.setState({isConfirmationButtonLoading : true});
          }
        }
      });
  }

  saveJobData(data){
    const { sessionDetail, profile } = this.props;
    const activityDetail = {
      employee_id : profile.employee_id,
      data : data
    };    
    this.props.SessionDucks.saveActivityData(activityDetail, sessionDetail.session_id);
  }

  approvedRequest(){
    const { sessionData , condn} = this.state;
    this.setState({isApproveButtonLoading : true});
    this.approveShift(sessionData , condn);
  }


  getTasks(job) {
    const reqObj ={
      job_id : job.id,
      paginate: false,
    };
    return this.props.TaskDucks.getTasks(reqObj);
  }

  approveShift(data, condition) {
    const {  activitySplitList , activityData , sessionDetail, profile} = this.props;
    const { router: { history } } = this.context;
    const mode = cookies.get('loginMode', { path: '/' });
     let activityId = [];
    activitySplitList.filter((item) => {
      if (item) {
        activityId.push(item.id);
      }
      return true;
    });
    const activities =  data ? data : activityData;
    const newActivityData = activities.filter(item => (item.job_id && item.task_id));
    let emptyActivityData = {
      employee_id : profile.employee_id,
      data : []
    };
      if(newActivityData.length){
      this.props.ActivitySplitDucks.postActivitySplitList(newActivityData, activities[0].session_id)
      .then(() => {
        sessionDetail && sessionDetail.create_date !== null && this.props.ActivitySplitDucks.postActivitySignOff({split_ids: activityId});
        this.props.SessionDucks.saveActivityData(emptyActivityData);
        this.confirmationModalToggle();
        this.setState({isApproveButtonLoading : false, isConfirmationButtonLoading: false});
        this.props.SessionDucks.resetActivityData();
        this.props.JobDucks.resetJobLabel();
        if(mode == 'quick'){
          history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchout'}) });
        }
        else{
          history.push('/activity/list');
        }

      })      
      .catch(() => {
        this.confirmationModalToggle();
        this.setState({isApproveButtonLoading : false, isConfirmationButtonLoading: false});
       });
      } else {
          sessionDetail && sessionDetail.create_date !== null && this.props.ActivitySplitDucks.postActivitySignOff({split_ids: activityId});
          this.props.SessionDucks.saveActivityData(emptyActivityData);
          this.confirmationModalToggle();
          this.setState({isApproveButtonLoading : false, isConfirmationButtonLoading: false});
          this.props.SessionDucks.resetActivityData();
          this.props.JobDucks.resetJobLabel();
          if(mode == 'quick'){
            history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchout'}) });
          }
          else{
            history.push('/activity/list');
          }
    }
  }

  goToUrl() {
    const { router: { history } } = this.context;
    const { profile } = this.props;
    this.props.SessionDucks.getActivityData(profile && profile.employee_id);
    history.push('/jobs/comment') ;
  }

  componentWillReceiveProps() {
    const { router: { history } } = this.context;
    const { profile , activityData} = this.props;
    const mode = cookies.get('loginMode', { path: '/' });
    if(profile.employee_id && activityData.length == null && mode !== 'quick'){
      this.props.SessionDucks.getActivityData(profile.employee_id)
      .then((res) => {
        const activitydata = res && res.value && res.value.results &&  res.value.results.lenghth && res.value.results[0] && res.value.results[0].data || [];
        if(activitydata.length === 0){
          history.push('/activity/list');
        }
      })
    }    
  }


  render() {
    const { isApproveButtonLoading, approveDisabled, isApproveShiftOpen} = this.state;
    const { activityData , taskList, jobList} = this.props;
    return (
      <div>
      {activityData && activityData.length != null && 

      (this?.props?.companyDetail?.allow_kiosk_new_ui=== true ?

      ( <div>
          <JobShiftOverViewComponent 
            manualCorrection ={this.props.manualCorrection}
            approveShift = {this.approveShift}
            SessionActivityData = {activityData}
            goToUrl = {this.goToUrl}
            firstName = {this.props.firstName}
            lastName = {this.props.lastName}
            isApproveButtonLoading = {isApproveButtonLoading}
            approveDisabled = {approveDisabled}
            saveActivity={this.saveActivity}
            taskList={taskList}
            jobList={jobList}
            getTasks={this.getTasks}
            companyDetail ={this.props.companyDetail}
            resetData={this.props.resetData}
            overviewRef = {this.props.overviewRef}
            markFavourite={this.markFavourite}
            markUnFavourite ={this.markUnFavourite}
            markFavouriteTask={this.markFavouriteTask}
            markUnFavouriteTask={this.markUnFavouriteTask}
            isConfirmationButtonLoading={this.state.isConfirmationButtonLoading}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
            sessionDetail={this.props.sessionDetail}
            activityLastSplit={this.props.activityLastSplit}
            saveJobData = {this.saveJobData}
            userExtraData={this.props.userExtraData}
            saveFeedbackActivity={this.saveFeedbackActivity}
          />
        </div>):
        (<div>
          <JobShiftOverViewComponentV1 
            manualCorrection ={this.props.manualCorrection}
            approveShift = {this.approveShift}
            SessionActivityData = {activityData}
            goToUrl = {this.goToUrl}
            firstName = {this.props.firstName}
            lastName = {this.props.lastName}
            isApproveButtonLoading = {isApproveButtonLoading}
            approveDisabled = {approveDisabled}
            saveActivity={this.saveActivity}
            taskList={taskList}
            jobList={jobList}
            getTasks={this.getTasks}
            companyDetail ={this.props.companyDetail}
            resetData={this.props.resetData}
            overviewRef = {this.props.overviewRef}
            markFavourite={this.markFavourite}
            markUnFavourite ={this.markUnFavourite}
            markFavouriteTask={this.markFavouriteTask}
            markUnFavouriteTask={this.markUnFavouriteTask}
            isConfirmationButtonLoading={this.state.isConfirmationButtonLoading}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
            sessionDetail={this.props.sessionDetail}
            activityLastSplit={this.props.activityLastSplit}
            saveJobData = {this.saveJobData}
            userExtraData={this.props.userExtraData}
            saveFeedbackActivity={this.saveFeedbackActivity}
          />
        </div>)
      )
        }
        {isApproveShiftOpen &&
          <ConfirmationApprovalModalComponent
            isOpen={isApproveShiftOpen}
            toggle={this.confirmationModalToggle}
            approvedRequest={this.approvedRequest}
            isButtonLoader={isApproveButtonLoading}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
            message={
              this.props.companyDetail && this.props.companyDetail.daily_compliance_message
              ? this.props.companyDetail.daily_compliance_message
              : null
          }
        />}
      </div>
    );
  }
}


const mapStateToProps = state => 
{
  return({
  profile: ProfileDucks.profile(state),
  activityData : SessionDucks.activityData(state),
  sessionDetail: SessionDucks.sessionDetail(state),
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  taskList: TaskDucks.taskList(state),
  jobList: JobDucks.jobsList(state),
  location: RouteDucks.location(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
  activityLastSplit: ActivitySplitDucks.activityLastSplit(state),
});
};

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ActivitySplitDucks : bindActionCreators(ActivitySplitDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobShiftOverViewSection.contextTypes = {
  router: PropTypes.object.isRequired,
};




export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobShiftOverViewSection);
