import { createSelector } from 'reselect';

import { fetch } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const GET_TIMECARDS = 'timecards/timecard/GET_TIMECARDS';
const GET_TIMECARDS_FULFILLED = 'timecards/timecard/GET_TIMECARDS_FULFILLED';
const GET_DAILY_TIMECARD = 'timecards/timecard/GET_DAILY_TIMECARD';
const GET_DAILY_TIMECARD_FULFILLED = 'timecards/timecard/GET_DAILY_TIMECARD_FULFILLED';
const RESET_DAILY_TIMECARDS = 'timecards/timecard/RESET_DAILY_TIMECARDS';
const GET_TIMECARD_OVERVIEW = 'timecards/timecard/GET_TIMECARD_OVERVIEW';
const GET_TIMECARD_OVERVIEW_FULFILLED = 'timecards/timecard/GET_TIMECARD_OVERVIEW_FULFILLED';

export function getTimecards(params) {
  return {
    type: GET_TIMECARDS,
    payload: fetch(`${constants.URLS.KIOSK}${constants.URLS.TIMECARD}`, {params}),
  };
}

export function getDailyTimecard(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DAILY_TIMECARD,
    payload: fetch(`${constants.URLS.KIOSK}${constants.URLS.DAILY_TIMECARD}`, { params }),
  };
}

export function getAggregatedTimecardDetails(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_TIMECARD_OVERVIEW,
    payload: fetch(`${constants.URLS.KIOSK}${constants.URLS.DAILY_TIMECARD}${constants.URLS.AGGREGATE}`, { params }),
  };
}

export function resetDailyTimecards() {
  return {
    type: RESET_DAILY_TIMECARDS,
  };
}

const defaultState = {
  list: {results : []},
  dTList: [],
  dtOverview: {}
};


function GET_TIMECARDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_TIMECARD_OVERVIEW_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    dtOverview: action.payload.results.length ? action.payload.results[0] : {},
  });
}

function GET_DAILY_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    dTList:  action.payload,
  });
}

function RESET_DAILY_TIMECARDS_REDUCER(state, action) {
  return Object.assign({}, state, defaultState );
}

const handlers = {
  [GET_TIMECARDS_FULFILLED]: GET_TIMECARDS_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARD_FULFILLED]: GET_DAILY_TIMECARD_FULFILLED_REDUCER,
  [RESET_DAILY_TIMECARDS]: RESET_DAILY_TIMECARDS_REDUCER,
  [GET_TIMECARD_OVERVIEW_FULFILLED] : GET_TIMECARD_OVERVIEW_FULFILLED_REDUCER
};


const timecardSelector = state => state.timecards.timecard;

export const timecardList = createSelector(
  timecardSelector,
  instance => instance.list,
);

export const dailyTimecardList = createSelector(
  timecardSelector,
  instance => instance.dTList
);

export const timecardOverview = createSelector(
  timecardSelector,
  instance => instance.dtOverview
);

export default createReducer(defaultState, handlers);
