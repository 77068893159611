import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import FilterComponent from 'components/common/FilterComponent';



class FilterSection extends React.Component {
  constructor() {
    super();
    this.submit= this.submit.bind(this);
  }


  submit(data){
    const { location } = this.props;
    const { query, pathname } = location;
    const { router: { history } } = this.context;
    if(query){
      delete query.page;
    }

    history.push({
      pathname, search: queryString.stringify({ ...query, search: data.search }),
    });
  }


  render() {
    const { location } = this.props;
    const { query } = location;
    return (
      <FilterComponent
        onSubmit={this.submit}
        submit={this.submit}
        location={this.props.location}
        initialValues={{search: query.search}}
        heading={this.props.heading}
        userPreferenceLanguage={this.props.userPreferenceLanguage}
      />
    );
  }
}

FilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};


export default FilterSection;