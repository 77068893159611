import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';

import * as PermissionDucks from 'ducks/access/permission';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as RouteDucks from 'ducks/routes';
import * as SessionDucks from 'ducks/activities/session';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as UserDucks from 'ducks/accounts/user';

import Singleton from 'lib/singleton';

import SectionLoaderAtom from 'atoms/SectionLoader';
import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import DailyTimecardListSection from 'sections/timecards/detail/List';
import DailyTimecardFilterSection from 'sections/timecards/detail/Filter';
import FooterSection from 'sections/common/Footer';

import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class TimecardDetailPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.state = { isLoading: false, isPayrollLoading: false ,isBackIcon: true, showReloadButton: false, showReloadTimecardButton: false};
    this.goBack = this.goBack.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.getData = this.getData.bind(this);
    this.reloadTimecardScreen = this.reloadTimecardScreen.bind(this);
    this.clickToReloadTimecards = this.clickToReloadTimecards.bind(this);
  }

  componentWillMount() {
    this.getData();
  }

  getData(){
    this.setState(({ isPayrollLoading }) => ({ isPayrollLoading: true }) );
    this.props.ProfileDucks.getProfile()
      .then(()=>{
        const { profile } = this.props;
        const departmentId = profile && profile.department && profile.department.id;
        const activePayroll = profile.company && profile.company.active_payroll;

        // there is no department payroll and no default payroll
        if(!activePayroll || !Object.keys(activePayroll).length ){
          const startDate = moment().startOf('month').format('YYYY-MM-DD');
          const endDate = moment().endOf('month').format('YYYY-MM-DD');
          this.setQuery('custom', startDate, endDate);
          this.setState({ isPayrollLoading: false, showReloadTimecardButton: false });
        }

        // there is an active payroll which is not the default payroll
        // i.e there exists a department payroll, call departmentPayroll api
        else if (!activePayroll.default){
          this.props.PayrollDucks.getPayrollsOfDepartment({ paginate: false, department_id: departmentId })
            .then(()=>{
              const { payrollList } = this.props;
              this.setQuery(payrollList[0].id);
              this.setState({ isPayrollLoading: false, showReloadTimecardButton: false });
            });
        }

        // there is an active payroll, which is default
        else {
          this.props.PayrollDucks.getAllPayrolls({ paginate: false,  default: true, valid: true})
            .then(()=>{
              const { payrollList } = this.props;
              this.setQuery(payrollList[0].id);
              this.setState({ isPayrollLoading: false, showReloadTimecardButton: false });
            });
        }
      });
  }


  setQuery(payrollId, startDate, endDate){
    const {location : { query, pathname }, profile } = this.props;
    const { router: { history }  } = this.context;
    if(!profile.company.is_default){
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: 'custom',
          start_date: moment().startOf('month').format('YYYY-MM-DD'),
          end_date: moment().endOf('month').format('YYYY-MM-DD'),
        })
      });
    }
 
    else if(!('payroll_id' in query)){
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: payrollId,
          start_date: startDate ? startDate : '',
          end_date: endDate ? endDate : '',
        })
      });
    }  
    else {
      this.fetchData(query);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }


   goToUrl(){
    const { router: { history } } = this.context;
    this.props.SessionDucks.getCurrentSession()
    .then((response) =>       
    {
      this.props.ProfileDucks.getProfile()
      .then((profile) => {
        if(profile.value.employee_id){
        this.props.SessionDucks.getActivityData(profile.value.employee_id)
          .then((res) => {
            const activitydata = res && res.value && res.value.results && res.value.results[0] && res.value.results[0].data;
            if ( response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null && response.value.current_activity[response.value.current_activity.length - 1].activity_master_name === 'Meal Period') {
              history.push('meals/break');
            } 

            else if(response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null){
              history.push('jobs/shift');
            } 

            else if((response && response.value.create_date !== null && !(response.value.current_activity !== null && response.value.current_activity.length)) || (activitydata && activitydata.length !== 0)){
              history.push('jobs/overview');
            }
            else if(response && response.value.create_date == null && activitydata && activitydata.length === 0){
              history.push('activity/list');
            }
            else
              history.push('activity/list');
          });
        }
          });
    });
    }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isPayrollLoading){
      this.clickToReloadTimecards();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    setTimeout(this._reloadTimecardTime);
    setTimeout(this._reloadTime);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }



  fetchData(query){
    const{ payroll_id, ...rest } = query;

    this.setState(({ isLoading }) => ({ isLoading: true }) );
    const payrollId = query.payroll_id === 'custom' ? '': query.payroll_id;
    if(query.payroll_id === 'custom'){
      this.props.TimecardDucks.getAggregatedTimecardDetails({...rest, paginate: false});
      this.props.TimecardDucks.getDailyTimecard({...rest, paginate:false})
        .then(() => {
          this.setState({ isLoading: false, showReloadButton:false });
          setTimeout(this._reloadTime);
          setTimeout(this._reloadTimecardTime);
          /*this.startTimer();*/
        })
        .catch(() => {
          this.setState({ isLoading: false, showReloadButton: false });
          setTimeout(this._reloadTime);
          setTimeout(this._reloadTimecardTime);
          // this.startTimer();
        });
    }
    else{
      this.props.TimecardDucks.getAggregatedTimecardDetails({payroll_id : payrollId, paginate:false})
      this.props.TimecardDucks.getDailyTimecard({ payroll_id : payrollId, paginate:false })
        .then(() => {
          this.setState({ isLoading: false, showReloadButton: false });
          setTimeout(this._reloadTime);
          setTimeout(this._reloadTimecardTime);
          /*this.startTimer();*/
        })
        .catch(() => {
          this.setState({ isLoading: false, showReloadButton: false });
          setTimeout(this._reloadTime);
          setTimeout(this._reloadTimecardTime);
          /*this.startTimer();*/
        });
    }
  }

  goBack () {
    const { router: { history } } = this.context;
    history.push('/activity/list');
  }

  reloadScreen(){
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.getData();
  }

  clickToReload(){
    this._reloadTime = setTimeout(() => {
      this.setState({showReloadButton: true});
      setTimeout(this._reloadTime);
    }, 8000);
  }

  clickToReloadTimecards(){
    this._reloadTimecardTime = setTimeout(() => {
      this.setState({showReloadTimecardButton: true});
      setTimeout(this._reloadTimecardTime);
    }, 8000);
  }

  reloadTimecardScreen(){
    this.setState({showReloadTimecardButton: false});
    this.clickToReloadTimecards();
    this.getData();
  }

  render() {
    const { isLoading, isPayrollLoading } = this.state;
    const { profile } = this.props;
    const { location: { pathname, query } } = this.props;

    if (!this.singleton.authorization) {
      return <Redirect to="/dashboard" />;
    }

    if(isPayrollLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadTimecardButton} reloadScreen={this.reloadTimecardScreen}/>;
    return (
      <div className="relative">
        <div className="cf min-vh-100 pb4 dark-grey-bg">
          <div className="w-100 margin-list">
            <HeaderSection 
              isBackIcon="isBackIcon"
              goToUrl = {this.goToUrl}
              fetchData={this.fetchData}
            />
            <TimeCardHeader 
              heading={TRMSGS.timecardDetails}
              firstName = {profile && profile.first_name}
              lastName = {profile && profile.last_name}
            />
          </div>
          <div className="w-70-l w-90-ns margin-list pa2 pb5">
            {/*
              <div className="fl w-30-l w-40-m pa3">
                <BackArrowAtom  className=" back-arrow  di v-mid" onClick={() => this.goBack()} />
                <h2 className=" ml3 di v-mid"> Timecards Details </h2>
              </div>
            */}
            <div className="fl w-100 pa2 pb5">
              {!query.show_weekly_list && !query.show_detailed_dt ?
                <DailyTimecardFilterSection isLoading={isLoading} clickToReload={this.clickToReload} showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>
                :
                null
              }
              <DailyTimecardListSection isLoading={isLoading} clickToReload={this.clickToReload} showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>
            </div>
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  permissionList: PermissionDucks.permissionList(state),
  profile: ProfileDucks.profile(state),
  payrollList: PayrollDucks.payrollList(state),
});

const mapActionsToProps = dispatch => ({
  PermissionDucks: bindActionCreators(PermissionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  JobDucks : bindActionCreators(JobDucks, dispatch),
  TaskDucks : bindActionCreators(TaskDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

TimecardDetailPage.fetchData = ({ store }) => {
  return store.dispatch(PermissionDucks.getPermissions());
};

TimecardDetailPage.propTypes = {
  TimecardDucks: PropTypes.object.isRequired,
};

TimecardDetailPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TimecardDetailPage);
