import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SectionLoaderReloadBtnAtom = (props) => {
  const { active, showReloadButton, reloadScreen } = props;
  if (!active) return null;
  return (
    <div className={styles['section-loader-reload-btn']}>
      <div className={styles['ball-pulse-sync']}>
        <div />
        <div />
        <div />
      </div>
      {showReloadButton &&
        <button
          onClick={()=>reloadScreen()}
          className="br2 pointer pa2 ph4 white btn-color f3 mt4"
        >
          Click To Reload
        </button>
      }
    </div>
  );
};

SectionLoaderReloadBtnAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default SectionLoaderReloadBtnAtom;
