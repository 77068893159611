import React from 'react';

class TextareaAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value: props.input.value };
  }

  handleBlur() {
    this.props.input.onBlur();
  }

  handleChange(e) {
    const { input } = this.props;

    input.onChange(e.target.value);
    this.setState({ value: e.target.value });
  }

  render() {
    const { input, prefix, suffix, label, meta: { touched, error }, ...rest } = this.props;
    const { value } = this.state;

    return (
      <div>
        {label && <label htmlFor="name" className="db mb2">{label}</label>}
        {prefix && (
          <textarea
            className="db border-box hover-black w-100 ba b--black-20 pa2 br2 mb2" aria-describedby="comment-desc"
          />
        )}
        <textarea
          {...input}
          {...rest}
          className="ba br2 b--black-20 pa2 mb1 db w-100"
          value={value}
          aria-describedby="name-desc"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        <small className="dark-red db h1 mb2">{touched && error}</small>
      </div>
    );
  }
}

export default TextareaAtom;
