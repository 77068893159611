import React from 'react';

import PropTypes from 'prop-types';

const TableHeaderAtom = props => {
  const { children, className, ...rest } = props;

  return (
    <th
      {...rest}
      className={className}
    >
      {children}
    </th>
  );
};

TableHeaderAtom.defaultProps = {
  className: 'pa2 bb bw1 b--light-gray tl',
};

TableHeaderAtom.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TableHeaderAtom;
