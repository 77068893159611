import React from "react";

import PropTypes from "prop-types";
import Field from "redux-form/lib/Field";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError";

import Modal from "react-bootstrap-modal";

import ButtonLoaderAtom from "atoms/ButtonLoader";
import KeyboardInputAtom from "atoms/KeyboardInput";

import styles from "./styles.module.scss";
import TRMSGS from "constants/Translations/Localization";

/**
 * DashboardListPage -> DashboardListSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class RetryBiometricFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.passwordSubmit = this.passwordSubmit.bind(this);
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = {
      inputType: "text",
      isOpenKeyboard: false,
    };
  }

  componentWillMount() {
    const { setWebcam } = this.props;
    setWebcam(false);
  }

  passwordSubmit(data) {
    const { employeeLogin, employeeDetail, disableLoader } = this.props;
    const detail = data;
    if (employeeDetail) {
      detail.username = employeeDetail.user.username;
    }
    return employeeLogin(detail).catch((err) => {
      const errObj = {
        _error: err.non_field_errors || err.detail,
        ...err,
      };
      if (err.detail) {
        setTimeout(() => {
          this.props.logoutSupervisor();
        }, 3000);
      }
      disableLoader();
      throw new SubmissionError(errObj);
    });
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard: !isOpenKeyboard });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({
      inputType: inputType === "password" ? "text" : "password",
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      isOpen,
      toggle,
      facialRecogSetup,
      errorMessage,
      retryFacialLoginHandler,
      submitButtonLoader,
      retryPincodeLogin
    } = this.props;
    const { inputType, isOpenKeyboard } = this.state;
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
        className="custom-width"
      >
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title id="ModalHeader" style={{ fontWeight: "500" }}>
            {TRMSGS.enterPasswordToLogin}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ml4 mr4">
          <form className="pt3" onSubmit={handleSubmit(this.passwordSubmit)}>
          <div className="block">
              <div className="pr0">
                <img
                  width="100%"
                  alt=""
                  height="auto"
                  src={require("./cameraIcon.svg")}
                />
              </div>
              <div className="error-text pa1">{errorMessage}</div>
              <div>
                <h3
                  className={`${styles["description-text"]} pa1`}
                  style={{ fontWeight: "400" }}
                >
                  {TRMSGS.unableToLoginUsingFacialRecognition}
                </h3>
              </div>
              {
                <div className="pl0">
                  <label
                    htmlFor="password"
                    className={`db mb2 pt3 ${styles["password-text"]}`}
                  >
                    {TRMSGS.password}
                  </label>
                  <Field
                    id="password"
                    type={inputType}
                    name="password"
                    component={KeyboardInputAtom}
                    toggle={this.toggleKeyboard}
                    isOpen={isOpenKeyboard}
                    inputType={inputType}
                    placeholder={TRMSGS.password}
                    className="ba b--black-20 w-40 pl1"
                    toggleInputType={this.toggleInputType}
                    eyeIcon
                    isModal
                    autoFocus
                    data-lpignore={true}
                  />
                  <small className="dark-red db h1 mt1 mb2">{error}</small>
                  <div className="tr mt3 mb3">
                    <div className="di tr custom-button">
                      {(facialRecogSetup === true)&& (
                        <div className="di tr pv2">
                          <button
                            type="button"
                            onClick={() => retryFacialLoginHandler()}
                            className="br2 pointer pb2 pt2 ph4 white btn-color"
                            style={{ marginBottom: "5px" }}
                          >
                            {TRMSGS.retryFacialLogin}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="dib tr ml2 custom-button">
                      <button
                        type="submit"
                        className="br2 pointer pb2 pt2 ph4 white btn-color"
                        disabled={submitButtonLoader}
                      >
                        {TRMSGS.login} <ButtonLoaderAtom active={submitButtonLoader} />
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  return errors;
};

RetryBiometricFormComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  facialRecogSetup: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  retryFacialLoginHandler: PropTypes.func.isRequired,
  setWebcam: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "RetryBiometricComponent",
  validate,
})(RetryBiometricFormComponent);
