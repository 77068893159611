import React from 'react';

import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import queryString from 'query-string';

import DropdownAtom from 'atoms/Dropdown';
import DatePickerAtom from 'atoms/DatePickerAtom';
import { toTitleCase } from 'lib/utils';
import styles from './styles.module.scss';

import TRMSGS from 'constants/Translations/Localization';

class DailyTimecardFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.data = {};
    this.handlePayrollChange = this.handlePayrollChange.bind(this);
    this.labels=['Department','Team', 'Payroll', 'Payroll Cycle', 'Start Date', 'End Date'];
  }

  submit(data){
    const { location: { pathname, query } } = this.props;
    const { router: { history } } = this.context;
    const detail = { ...data };
    const { page, payroll_id, ...rest } = query;
    const { page: pageNo, ...restDetail } = detail;
    this.props.clickToReload();
    if(data.start_date && data.end_date){
      restDetail.start_date = moment(data.start_date).format('YYYY-MM-DD');
      restDetail.end_date = moment(data.end_date).format('YYYY-MM-DD');
    }
    
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail, payroll_id : 'custom'}),
    });
  }

  componentWillReceiveProps(){
    const {  error, handleSubmit, profile, payrollList, location: { query } } = this.props;
    const data = {};
    const selectedPayroll = query && query.payroll_id ? payrollList && payrollList.find((i) => i.id == (query.payroll_id)) : payrollList && payrollList[0];
    const departmentName = profile && profile.department && profile.department.name;
    const team = profile && profile.department && profile.department.team && profile.department.team.name;
    const payrollName = profile.company && profile.company.active_payroll && profile.company.active_payroll.name  && toTitleCase(profile.company.active_payroll.name);
    if(departmentName){
      this.data[TRMSGS.department] = toTitleCase(departmentName);
    }
    if(team){
      this.data[TRMSGS.team] = toTitleCase(team);
    }
    if(payrollName){
      this.data[TRMSGS.payrollName] = toTitleCase(payrollName);
    }
    this.data[TRMSGS.payrollCycle] = null;
    this.data[TRMSGS.startDate] = null;
    this.data[TRMSGS.endDate] = null;
  }

  handlePayrollChange(val){
    const { location: { pathname, query } } = this.props;
    const { router: { history } } = this.context;
    this.props.clickToReload();
    if(query.payroll_id === val.id) return true;
    if(val.id === 'custom'){
      return history.push({
        pathname,
        search: queryString.stringify({ ...query,
          payroll_id: val.id,
          start_date:moment().startOf('month').format('YYYY-MM-DD'),
          end_date:moment().endOf('month').format('YYYY-MM-DD'),
        }),
      });
    }

    return history.push({
      pathname,
      search: queryString.stringify({ ...query,
        payroll_id: val.id,
        start_date : '',
        end_date : ''}),
    });
  }

  render(){
    const {  error, handleSubmit, profile, payrollList, location: { query }, userPreferenceLanguage } = this.props;
    const dateFormat = 'F j, Y';
    const selectedPayroll = query && query.payroll_id ? payrollList && payrollList.find((i) => i.id == (query.payroll_id)) : payrollList && payrollList[0];
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className="flex justify-between textLeft">
          <div 
            className={userPreferenceLanguage ==='bilingual'? 'mv3 ml3 v-top flex flex-column custom-space-bilingual custom-bilingual-flex text-nowrap': 'mv3 ml2 v-top flex flex-column custom-space'}
            style={{flex:1.8}}>
            {
              Object.keys(this.data) && Object.keys(this.data).length && Object.keys(this.data).map((item)=>(
                <div>{item}</div>
                ))
            }
          </div>
          <div className={userPreferenceLanguage ==='bilingual'? "mv3 v-top flex flex-column custom-space-bilingual": "mv3 v-top flex flex-column custom-space"} style={{flex:'0.7'}}>
            {
              Object.keys(this.data) && Object.keys(this.data).length && Object.keys(this.data).map((item)=>(
                <div>:</div>
                ))
            }
          </div>
          <div className="mv3 v-top flex flex-column filter-bilingual" style={{flex:'2'}}>
             {
              Object.keys(this.data) && Object.keys(this.data).length && Object.keys(this.data).map((item, idx)=>(
                <div className={userPreferenceLanguage ==='bilingual'?"custom-space-bilingual":"custom-space"}>{Object.values(this.data)[idx] !== null ? Object.values(this.data)[idx] : null}</div>
              ))
            }
            <div className="mt3 payroll-filter-width">
              {payrollList && payrollList.length > 0  && <DropdownAtom
                items={payrollList}
                itemToString={i => i && i.name}
                selectedItem={selectedPayroll}
                onChange={this.handlePayrollChange}
                timecard="timecard"
              />}
            </div>
            <div className='mt3 date-margin'>
              <Field
                component={DatePickerAtom}
                id="start_date"
                name="start_date"
                placeholder={TRMSGS.startDate}
                options = {{ dateFormat }}
                disabled = {!((query.payroll_id && query.payroll_id==='custom') || (!query.payroll_id))}
              />
            </div>
            <div className="mt1 date-margin">
              <Field
                component={DatePickerAtom}
                id="end_date"
                name="end_date"
                placeholder={TRMSGS.endDate}
                options = {{ dateFormat}}
                disabled = {!((query.payroll_id && query.payroll_id==='custom') || (!query.payroll_id))}
                className="w-100"
              />
            </div>
          </div>
        </div>            
        <div className="error-text">{error}</div>
        <div className={`${styles['btn_container']}  w-100 v-top fr mv3`}>
          <button type="submit" className ="w-100 pointer br2 mt0 pv3 white btn-color ph3" disabled = {!((query.payroll_id && query.payroll_id==='custom') || (!query.payroll_id))} >{TRMSGS.apply}</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = `${TRMSGS.selectStartDate}`;
  if (!values.end_date) errors.end_date = `${TRMSGS.selectEndDate}`;
  if(moment(values.start_date) > moment(values.end_date)) errors.start_date = `${TRMSGS.startDateEndDateComparison}`;
  return errors;
};

DailyTimecardFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

DailyTimecardFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
};

export default reduxForm({
  form: 'TimecardForm',
  enableReinitialize: true,
  validate,
})(DailyTimecardFormComponent);
