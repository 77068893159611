import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-bootstrap-modal';
import queryString from 'query-string';

import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';

import { toast } from 'react-toastify';
import styles from './styles.module.scss';

import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton'
import ActivationFormModal from 'components/generics/dashboard/Form/ActivationForm';
import ConfirmPasswordFormModal from 'components/generics/dashboard/Form/ConfirmPasswordForm';
import BiometricPasswordForm from 'components/generics/dashboard/Form/BiometricPasswordForm';
import RetryBiometricForm from 'components/generics/dashboard/Form/RetryBiometricForm';
import RetryPincodeForm from 'components/generics/dashboard/Form/RetryPincodeForm';
import PincodeForm from 'components/generics/dashboard/Form/PincodeForm';
import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class DashboardListSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.toggle=this.toggle.bind(this);
    this.employeePincodeLogin = this.employeePincodeLogin.bind(this);
    /*this.resetEmployees=this.resetEmployees.bind(this);*/
    this.confirmToggle=this.confirmToggle.bind(this);
    this.activateEmployee=this.activateEmployee.bind(this);
    this.employeeLogin=this.employeeLogin.bind(this);
    this.employeeImageLogin = this.employeeImageLogin.bind(this);
    this.setImageLoginError = this.setImageLoginError.bind(this);
    this.fetchNextData = this.fetchNextData.bind(this);
    this.loadDataHandler = this.loadDataHandler.bind(this);
    this.retryFacialLoginHandler = this.retryFacialLoginHandler.bind(this);
    this.retryPincodeLoginHandler = this.retryPincodeLoginHandler.bind(this);
    this.setWebcam = this.setWebcam.bind(this);
    this.setupBiometricLogin = this.setupBiometricLogin.bind(this);
    this.setupPincodeLogin = this.setupPincodeLogin.bind(this);
    this.checkEmployeeStatus = this.checkEmployeeStatus.bind(this);
    this.closeBrowserPopup = this.closeBrowserPopup.bind(this);
    this.disableLoader = this.disableLoader.bind(this);
    this.logoutSupervisor = this.logoutSupervisor.bind(this);
    this.state = {
      isOpen : false,
      empName: '',
      activationFormBtnText: 'Submit',
      isPassword: false,
      biometricAccess: false,
      gettingEmployee: false,
      facialRecogSetup: null,
      error: null,
      enableCameraScreen: true,
      webcam: false,
      isConfirmPasswordModalOpen: false,
      items:{},
      pageSize:20,
      showMore:true,
      indexed :null,
      isfocused: true,
      isEmployeeLoading: false,
      cameraPermissionText : false,
      submitButtonLoader : false,
      enablePincodeLogin : true,
    };
  }

  closeBrowserPopup(){
    this.setState({cameraPermissionText : false});
  }

  disableLoader(){
    this.setState({submitButtonLoader : false});
  }

  setupBiometricLogin(data){
    const { router: { history}} = this.context;
    return this.props.UserDucks.postAuthentication(data)
      .then(() => history.push('/activity/biometric'));
  }

  setupPincodeLogin(data){
    const { router: { history}} = this.context;
    return this.props.UserDucks.postAuthentication(data)
      .then(() =>  history.push({
        pathname: '/activity/pincode',
        state: { pk : this.props.employeeDetail?.user?.employee_id}
      }));
  }

  toggle(employee) {
    const { isConfirmPasswordModalOpen } = this.state;
    if(!isConfirmPasswordModalOpen){
      this.props.EmployeeDucks.getEmployee({id: employee.id});
    }
    if (!window && !window.navigator) {
      this.setState({cameraPermissionText : 'UPDATE'});
    }
    const supported = 'mediaDevices' in window.navigator;

    if (!supported) {
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if(isMacLike){
        this.setState({cameraPermissionText: 'MACLIKEDEVICE'});
      }
      else{
        this.setState({cameraPermissionText: 'UPDATE'});
      }
    }
    if (navigator.mediaDevices !== undefined) {
      this.setState({ isConfirmPasswordModalOpen: !isConfirmPasswordModalOpen });
    }
  }

 /* resetEmployees(data,index){
    const { router: { history, route: { match: { params } } } } = this.context;
    const { items, pageSize, isClicked, isfocused, isEmployeeLoading } = this.state;
    const { location: { pathname, query }} = this.props;
    this.setState({isfocused: false, isEmployeeLoading: true});
    delete query.name_start;
    history.push({
      pathname,
      search: queryString.stringify({...query }),
    });
  }*/

  getEmployeeByAlphabet(data,index){
    const { router: { history } }  = this.context;
    const { location: { pathname, query }, startResetTimer} = this.props;
    this.setState({indexed :index, isfocused: true });
    
    history.push({
      pathname,
      search: queryString.stringify({...query, name_start: data}),
    });
    startResetTimer();
  }

  checkEmployeeStatus(){
    const { router: { history } } = this.context;
    this.props.SessionDucks.getCurrentSession()
    .then((response) =>       
    {
      this.props.ProfileDucks.getProfile()
      .then((profile) => {
        if(profile.value.employee_id){
        this.props.SessionDucks.getActivityData(profile.value.employee_id)
          .then((res) => {
            const activitydata = res && res.value && res.value.results && res.value.results[0] && res.value.results[0].data;
            if ( response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null && response.value.current_activity[response.value.current_activity.length - 1].activity_master_name === 'Meal Period') {
              history.push('meals/break');
              this.setState({submitButtonLoader : false});
            } 

            else if(response && response.value.create_date !== null && response.value.current_activity !== null && response.value.current_activity.length !== 0  && response.value.create_date !== null){
              history.push('jobs/shift');
              this.setState({submitButtonLoader : false});
            } 

            else if((response && response.value.create_date !== null && !(response.value.current_activity !== null && response.value.current_activity.length)) || (activitydata && activitydata.length)){
              history.push('jobs/overview');
              this.setState({submitButtonLoader : false});
            }
            else if(response && response.value.create_date == null && activitydata && activitydata.length === 0){
              history.push('activity/list');
              this.setState({submitButtonLoader : false});
            }
            else
              history.push('activity/list');
              this.setState({submitButtonLoader : false});
          });
        }
          });
    })
    .catch((e)=>{
      e.detail && toast.error(e.detail);
      this.setState({submitButtonLoader : false});
    })
    }
  
  fetchNextData(){
    const { location: {  query }, previousData} = this.props;
    const { items, pageSize, isfocused} = this.state;
    
    let nameStart = '';
    if(isfocused){
      nameStart = previousData;
    }
    setTimeout(() => this.props.EmployeeDucks.getEmployees({ ...query, activated: true, page_size: pageSize, name_start: nameStart })
      .then(()=>{
        const { employeeList } = this.props;
        const itemsNext = employeeList && employeeList.length && employeeList.map(item =>item);
        if( itemsNext.length === items.length){
          this.setState({showMore:false});
        }
        this.setState({items : itemsNext, pageSize : pageSize + 20 });
      })
      .catch((error)=>console.log('error', error))
    , 500);
  }

  employeeLogin(data) {
    this.setState({submitButtonLoader : true});
    return this.props.UserDucks.postAuthentication(data)
      .then(() =>{
        cookies.set('loginType', 'Password', { path: '/' });
      this.checkEmployeeStatus()
    })
        
  }

  componentDidMount(){
    if(this.props.isLoading){
      this.props.clickToReload();
    }
  }

  logoutSupervisor() {
    const { router: { history}} = this.context;
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    this.singleton.authorization = null;
    cookies.remove('authToken', { path: '/' });
    cookies.remove('job_id', { path: '/' });
    cookies.remove('responseId', { path: '/' });
    StorageGateway.remove('imageUrl');
    history.push('/');
  }

  employeeImageLogin(data) {
    this.setState({submitButtonLoader : true});
    return this.props.UserDucks.postImageAuthenticationAtDashboard({image: data, username: this.selectedUsername || ''})
      .then((res) => {
        cookies.set('loginType', 'Face', { path: '/' });
        cookies.set('documentId' , res.value.image_doc_id, { path: '/' });
        this.checkEmployeeStatus();
      });
  }

employeePincodeLogin(data) {
  this.setState({submitButtonLoader : true});
    return this.props.UserDucks.postPincodeAuthenticationAtDashboard(data)
      .then((res) => {
        cookies.set('loginType', 'Pincode', { path: '/' });
        this.checkEmployeeStatus();
      }) .catch((err) => {
        console.log(err?.non_field_errors[0]);
        this.setState({
          enablePincodeLogin:false
        });
        this.disableLoader();
      });
}


  activateEmployee(data) {
    const { router: { history } } = this.context;

    if(this.state.biometricAccess) {
      history.push({
        pathname: '/activity/biometric',
        search: '?src=dashboard',
        state: data
      });
      return Promise.resolve();
    } else {
      return this.props.EmployeeDucks.postActivate(data)
        .then(()=> {
          toast.success(`${TRMSGS.employeeActivatedSucessfully}`);
          this.props.EmployeeDucks.getEmployees();
          this.toggle();
          history.push('/activity/list');
        });
    }
  }

  confirmToggle(data) {
    if(data !== undefined){
      this.props.EmployeeDucks.getEmployee({ id: data.id })
      .then((res) => {
        if(res.value.biometric_access){
        if (!window && !window.navigator) {
          return this.setState({cameraPermissionText: 'UPDATE', enableCameraScreen : false});
        }
        const supported = 'mediaDevices' in window.navigator;
        if (!supported) {
          var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
          if(isMacLike){
              this.setState({cameraPermissionText: 'MACLIKEDEVICE', enableCameraScreen : false});
          }
          else{
            this.setState({cameraPermissionText: 'UPDATE', enableCameraScreen : false});
          }
        }
        if (navigator.mediaDevices !== undefined) {
         if(data) {
          this.selectedUsername = data.user.username;
          }
          const { isPassword } = this.state;
          if (isPassword) return this.setState({ isPassword: !isPassword,
            enableCameraScreen: true });
          this.setState({gettingEmployee: true});
          return this.props.EmployeeDucks.getEmployee({id: data.id})
            .then(() => this.setState({ isPassword: !isPassword, gettingEmployee: false }))
            .catch(() => this.setState({ isPassword: !isPassword, gettingEmployee: false }));
          }   
        }
        else{
          if(data) {
            this.selectedUsername = data.user.username;
          }
          const { isPassword } = this.state;
          if (isPassword) return this.setState({ isPassword: !isPassword,
            enableCameraScreen: true });
          this.setState({gettingEmployee: true});
          return this.props.EmployeeDucks.getEmployee({id: data.id})
            .then(() => this.setState({ isPassword: !isPassword, gettingEmployee: false }))
            .catch(() => this.setState({ isPassword: !isPassword, gettingEmployee: false }));
        }
      });}
    else{
      const { isPassword } = this.state;
      this.setState({ isPassword: !isPassword, gettingEmployee: false, enableCameraScreen:true, enablePincodeLogin:true });
    }
  }

  updateSelected() {
    this.toggle();
  }

  setImageLoginError(data, val) {
    this.setState({ error: data,
      facialRecogSetup: val,
      enableCameraScreen: false,
      webcam: false });
  }

  retryFacialLoginHandler() {
    this.setState({ enableCameraScreen: true });
    setTimeout(() => {
      this.setState({webcam: true});
    }, 2500);
  }

  retryPincodeLoginHandler(){
    this.setState({enablePincodeLogin:true});
  }

  loadDataHandler(){
    setTimeout(() => {
      this.setState({webcam: true});
    }, 2500);
  }

  setWebcam(data) {
    this.setState({ webcam: data });
  }

  render () {
    const { isOpen, isPassword, activationFormBtnText   , isfocused,
      error, facialRecogSetup, enableCameraScreen, webcam, isConfirmPasswordModalOpen, items, showMore, enablePincodeLogin} = this.state;
    const { employeeList, isLoading, employeeDetail, previousData } = this.props;
    const { cameraPermissionText} = this.state;

    const alpha = Array.from('#ABCDEFGHIJKLMNOPQRSTUVWXYZ');

    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.props.showReloadButton} reloadScreen={this.props.reloadScreen}/>

    return (
      <section className={`${styles['filter-border']} mr4 pb5`}>
        {/*<div onClick={()=>this.resetEmployees()} className='reset-link'>
          <div className='tr mr1'>
            <ResetIcon height="17" width="17" /></div>
          <div className="tr mb2 f6">Reset</div>
        </div>*/}
        <div className="white-bg list-wrapper">
          <InfiniteScroll
            dataLength={items.length}
            next={this.fetchNextData}
            hasMore={true}
            height={587}
            loader={showMore?<div className="w-100 pa2 margin-list tc dark-grey-bg"><img  width="auto" height="40px" src={require('./Worksana_Logo.gif')} alt="spinner"/></div>:null}
          >
            {employeeList && employeeList.length > 0 && employeeList.map(item => (
              <div key={item.id} className="cf mh3 table-bottom">
                <p className="employee-name truncate fl w-70-l w-60-ns">{item.user.first_name}&nbsp;{item.user.last_name}</p>
                {(!item.face_setup && item.biometric_access)||(!item.pincode_setup && item.pincode_access ) ? (
  <button
    className={`${styles['employee-btn']} fr pointer mb2 employee-btn width-btn btn-color white btn-color br2 f5`}
    onClick={() => this.toggle(item)}
    disabled={this.state.gettingEmployee}
  >
    {item.biometric_access?TRMSGS.setupBiometricLogin:TRMSGS.setupPincodeLogin}
  </button>
): (
  <button
    className={`${styles['employee-btn']} fr pointer mb2 employee-btn width-btn btn-color white btn-color br2 f5`}
    disabled={this.state.gettingEmployee}
    onClick={() => this.confirmToggle(item)}
  >
    {TRMSGS.login}
    {/* <img height="14" className="di pl1 v-mid" src={require('./login.png')} alt="Site Name" /> */}
  </button>
)}

              </div>
            ))}
            {employeeList  && !employeeList.length &&
              <div className="cf pl5 pv0 pr5 br2  mb1">
                <h2 className="center mt0 lh-title mb0 mv3 fl w-100 tc">
                {TRMSGS.noRecordsFound}
                </h2>
              </div>
            }
            { isOpen &&
              <ActivationFormModal
                isOpen={isOpen}
                toggle={this.toggle}
                activateEmployee={this.activateEmployee}
                btnText={activationFormBtnText}
                initialValues={{username: this.state.empName}}
              />
            }
            { isPassword && employeeDetail && !employeeDetail.biometric_access && !employeeDetail.pincode_access &&
              <div style={{top:'40%'}}>
                <ConfirmPasswordFormModal
                  isOpen={isPassword}
                  toggle={this.confirmToggle}
                  employeeLogin={this.employeeLogin}
                  employeeImageLogin={this.employeeImageLogin}
                  employeeDetail={employeeDetail}
                  submitButtonLoader = {this.state.submitButtonLoader}
                  disableLoader = {this.disableLoader}
                  logoutSupervisor = {this.logoutSupervisor}
                />
              </div>
            }
            { isPassword && employeeDetail && employeeDetail.biometric_access && enableCameraScreen &&
              <BiometricPasswordForm
                isOpen={isPassword}
                toggle={this.confirmToggle}
                employeeImageLogin={this.employeeImageLogin}
                employeeDetail={employeeDetail}
                setImageLoginError={this.setImageLoginError}
                webcam={webcam}
                loadDataHandler={this.loadDataHandler}
                submitButtonLoader = {this.state.submitButtonLoader}
                disableLoader = {this.disableLoader}
              />
            }
            { isPassword && employeeDetail && (employeeDetail.biometric_access) && (!enableCameraScreen) &&
              <RetryBiometricForm
                isOpen={isPassword}
                toggle={this.confirmToggle}
                employeeLogin={this.employeeLogin}
                employeeDetail={employeeDetail}
                setImageLoginError={this.setImageLoginError}
                facialRecogSetup={facialRecogSetup}
                errorMessage={error}
                retryFacialLoginHandler={this.retryFacialLoginHandler}
                setWebcam={this.setWebcam}
                submitButtonLoader = {this.state.submitButtonLoader}
                disableLoader = {this.disableLoader}
                logoutSupervisor = {this.logoutSupervisor}
              />
            }
            {isPassword && employeeDetail && (employeeDetail.pincode_access) && (!enablePincodeLogin) &&
              <RetryPincodeForm
                isOpen={isPassword}
                toggle={this.confirmToggle}
                employeeLogin={this.employeeLogin}
                employeeDetail={employeeDetail}
                setImageLoginError={this.setImageLoginError}
                errorMessage={error}
                retryFacialLoginHandler={this.retryPincodeLoginHandler}
                submitButtonLoader = {this.state.submitButtonLoader}
                disableLoader = {this.disableLoader}
                logoutSupervisor = {this.logoutSupervisor}
              />}

            {isPassword && employeeDetail && employeeDetail.pincode_access && enablePincodeLogin &&
            <PincodeForm
            isOpen={isPassword}
            toggle={this.confirmToggle}
            employeePincodeLogin={this.employeePincodeLogin}
            employeeDetail={employeeDetail}
                submitButtonLoader = {this.state.submitButtonLoader}
                disableLoader = {this.disableLoader}
            />

            }
            {
              isConfirmPasswordModalOpen &&
                <ConfirmPasswordFormModal
                  isOpen={isConfirmPasswordModalOpen}
                  toggle={this.toggle}
                  employeeLogin={employeeDetail.pincode_access?this.setupPincodeLogin:this.setupBiometricLogin}
                  employeeImageLogin={this.employeeImageLogin}
                  employeeDetail={employeeDetail}
                  submitButtonLoader = {this.state.submitButtonLoader}
                  disableLoader = {this.disableLoader}
                  logoutSupervisor = {this.logoutSupervisor}
                />
            }
            {
              cameraPermissionText &&
              <Modal
                show={cameraPermissionText}
                aria-labelledby="ModalHeader"
                backdrop="static"
                onHide={this.closeBrowserPopup}
              >
                <Modal.Header closeButton  className='custom-modal'>
                  <Modal.Title id='ModalHeader'>
                      {cameraPermissionText === 'UPDATE' ? `${TRMSGS.advanceBrowserCheck}`: `${TRMSGS.browseNotSupported}`}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {cameraPermissionText && cameraPermissionText === 'UPDATE' &&
                    <div className="f4 fw4">{TRMSGS.upgradeWebBrowser}</div>
                  }
                  {cameraPermissionText && cameraPermissionText === 'MACLIKEDEVICE' &&
                    <div className="f4 fw4">{TRMSGS.browserNotCompatibleWithWebcamAnduseSafari}</div>
                  }
                  <div className="tr">
                    <button type="button"
                      onClick={() => this.closeBrowserPopup()}
                      className="br2 pointer pa2 ph4 white btn-color f3"
                    >
                      {TRMSGS.ok}
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            }
          </InfiniteScroll>
        </div>
        
          <div className="a2z-dictionary">
            {alpha.map((item, index)=>(
            <div className={index >0 ? 'pa0 ma0 tc width-dict link db b--black-10 pv1' : 'pa0 ma0 tc width-hash link db b--black-10 pv1'} role="button" tabIndex={-1} onClick={()=>this.getEmployeeByAlphabet(item,index)}>
              <span className = {(index >0 && (previousData===item) && isfocused) ? 'focus-class' : ''}>{item}</span>
            </div>
            ))}
          </div>
        
      </section>
    );
  }
}

const mapStateToProps = state => ({
  employeeList: EmployeeDucks.employeeList(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  profile: ProfileDucks.profile(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  JobDucks : bindActionCreators(JobDucks, dispatch),
  TaskDucks : bindActionCreators(TaskDucks, dispatch),
});

DashboardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardListSection);
