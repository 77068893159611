import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import queryString from 'query-string';

import TaskListComponent from 'components/tasks/list/taskList';
import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';

import { toast } from 'react-toastify';

import * as ProfileDucks from 'ducks/accounts/profile';
import * as JobDucks from 'ducks/jobs/jobs';
import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as SessionDucks from 'ducks/activities/session';

import UnscheduledShiftConfirmationComponent from 'components/activities/List/unscheduledShiftConfirmation'

const cookies = new Cookies();

class TaskListSection extends React.Component {
  constructor(props) {
    super(props);
    this.markFavouriteTask = this.markFavouriteTask.bind(this);
    this.markUnFavouriteTask = this.markUnFavouriteTask.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.state = { isProcessing: false, isForcePunchOut : false , isOpen:false, selectedTaskState:null, commentButtonLoading:false};
    this.updateSession = this.updateSession.bind(this);
    this.toggleConfirmationPopup= this.toggleConfirmationPopup.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
  }

  saveMessage(data){
    const {selectedTaskState,commentButtonLoading} = this.state;
    this.setState({commentButtonLoading:true});
    this.selectTask(selectedTaskState, data.message);
  }

  markFavouriteTask(item){
    const singleton = new Singleton();
    const { query } = this.props.location;
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const job_id = parseInt(singleton.job_id);
    const reqObj ={
      job_id : job_id,
      task_id : item.id,
      search : query.search,
      page: query.page ? query.page : 1,
    };
    this.props.TaskDucks.markFavouriteTasks(reqObj)
    .then(() => {
      this.props.TaskDucks.getTasks(reqObj);
    });
  }

  toggleConfirmationPopup(){
    const { isOpen } = this.state;
    this.setState({isOpen : !isOpen });
  }

  updateSession(){
    const { router: { history } } = this.context;
    this.setState({isForcePunchOut : false});
    history.push('/jobs/overview/');
  }

  markUnFavouriteTask(item){
    const { query } = this.props.location;  
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    const job_id = parseInt(singleton.job_id);
    const reqObj ={
      job_id : job_id,
      task_id : item.id,
      page: query.page ? query.page : 1,
      search: query.search
    };
    this.props.TaskDucks.markUnFavouriteTasks(reqObj)
    .then(() => {
      this.props.TaskDucks.getTasks(reqObj);
    });
  }

  selectTask(item,message){
  const { router: { history } } = this.context;
  const { sessionDetail, profile , activityData, jobsList} = this.props;
  const {selectedTaskState, commentButtonLoading} = this.state;
  const imageDocumentId = cookies.get('documentId', { path: '/' });
  const { query } = this.props.location;
  const singleton = new Singleton();
  const mode = cookies.get('loginMode', { path: '/' });
  const loginType =cookies.get('loginType', { path: '/' });
  let activityDetail;
  singleton.job_id = cookies.get('job_id', { path: '/' });
  let newActivityData = [];

  const selectedJob =  jobsList && jobsList.length && jobsList.filter((item) => (item.id && singleton.job_id && parseInt(item.id) === parseInt(singleton.job_id)) || (item.id && query.job_id && parseInt(item.id) === parseInt(query.job_id)));
  if(!message){
    this.setState({isProcessing: true});
  }
   if(sessionDetail  && sessionDetail.create_date == null)
   {
     let emptyActivityData = {
      employee_id : profile.employee_id,
      data : []
     };
     let punchInData = {
      message: message && message,
      image_doc_id : imageDocumentId && imageDocumentId
    }
     this.props.SessionDucks.saveActivityData(emptyActivityData);
    if(loginType==='Face')
    {
      this.props.SessionDucks.saveActivityLog({activity_type:'punch_in'})
    }
     this.props.SessionDucks.postPunchInSession(punchInData)
     .then((resp) =>{
        if(resp.value.punchin_restriction == true){
          return this.setState({ isOpen : true , isProcessing:false, selectedTaskState:item});
        }
        let newDate = new Date();
        newDate.setSeconds(0,0);
        newDate.setMilliseconds(0);
        newDate.toISOString();
        this.props.SessionDucks.getCurrentSession()
        .then((res) =>{
          activityData && activityData.length && activityData.map((item, index) => {
            if (index === (activityData.length - 1)) {
              const newItem = {
                ...item,
                end_datetime : newDate,
              };
              return newActivityData.push(newItem);
            }
            return newActivityData.push(item);
            });
          newActivityData.push({ 
            job_id : singleton.job_id,
            task_id : item.id,
            start_datetime : newDate,
            activity_id : res.value.id,
            task_name: item.name,
            job_name : selectedJob && selectedJob.length &&  selectedJob[0].name,
            session_id : res.value.session_id,
            sess_id: res.value.id
          });
          activityDetail ={
            employee_id : profile.employee_id,
            data : newActivityData
          };
          this.setState({selectedTaskState:null, commentButtonLoading:false});
          this.props.SessionDucks.saveActivityData(activityDetail)
            .then(() => {
              if(mode == 'quick'){
                history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
              }
              else{
                history.push({
                  pathname: '/jobs/shift/',
                  search: queryString.stringify({...query, task_id : item.id})
                });
              }
            });
        });
    })
    .catch((e) => {
      e.non_field_errors && e.non_field_errors.length && toast.error(e.non_field_errors[0]);
      this.setState({isProcessing: false, selectedTaskState: null,commentButtonLoading:false});
    });
   } 
   else{
      if(activityData && activityData.length && activityData[activityData.length -1].activity_name === 'meal' && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'){
        this.props.SessionDucks.putEndSession({id: sessionDetail.current_activity[1].id, version: 2});
      }
      let newDate = new Date();
      newDate.setSeconds(0,0);
      newDate.setMilliseconds(0);
      newDate.toISOString();
      activityData && activityData.length &&  activityData.map((item, index) => {
        if (index === (activityData.length - 1)) {
          const newItem = {
            ...item,
            end_datetime : newDate,
          };
          return newActivityData.push(newItem);
        }
        return newActivityData.push(item);
      });
      newActivityData.push( 
        { 
          job_id : singleton.job_id,
          task_id : item.id,
          start_datetime : newDate,
          activity_id : sessionDetail.id,
          task_name : item.name,
          job_name : selectedJob &&  selectedJob.length && selectedJob[0].name,
          session_id : sessionDetail.session_id,
          sess_id: sessionDetail.id
        });
      activityDetail ={
      employee_id : profile.employee_id,
      data : newActivityData};
      this.props.SessionDucks.saveActivityData(activityDetail)
        .then(() => {
          if(mode == 'quick'){
            if(activityData && activityData.length && activityData[activityData.length -1].activity_name === 'meal' && sessionDetail.current_activity[sessionDetail.current_activity.length - 1].activity_master_name === 'Meal Period'){
              history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
            }
            else{
              history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'jobswitch'}) });
            }
          }
          else{
            history.push({
              pathname: '/jobs/shift/',
              search: queryString.stringify({...query, task_id : item.id})
            });
          }
      });
  }

      
  }


  render() {
    const { isLoading } = this.props;
    const { isProcessing, isOpen, commentButtonLoading } = this.state;
    if (isLoading || isProcessing) return <SectionLoaderAtom active />;
    return (
      <div>
        <TaskListComponent
          taskList = {this.props.taskList}
          markFavouriteTask = {this.markFavouriteTask}
          markUnFavouriteTask = {this.markUnFavouriteTask}
          selectTask={this.selectTask}
        />
        {this.props.taskCount && this.props.taskCount > 0 ?
          <PaginationComponent
            count={this.props.taskCount}
            location={this.props.location}
          />
          :
          null
        }
        {
          isOpen ?
          <UnscheduledShiftConfirmationComponent
            isOpen={isOpen}
            toggle={this.toggleConfirmationPopup}
            saveMessage={this.saveMessage}
            commentButtonLoading={commentButtonLoading}
          />
          :
          null
        }  
      </div>
    );
  }
}

const mapStateToProps = state => {
  return({
    location: RouteDucks.location(state),
    profile: ProfileDucks.profile(state),
    jobsList: JobDucks.jobsList(state),
    sessionDetail: SessionDucks.sessionDetail(state),
    activityData : SessionDucks.activityData(state),
    taskCount: TaskDucks.taskCount(state),
});
};

const mapActionsToProps = dispatch => ({
   JobDucks: bindActionCreators(JobDucks, dispatch),
   TaskDucks: bindActionCreators(TaskDucks, dispatch),
   SessionDucks: bindActionCreators(SessionDucks, dispatch),
});


TaskListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TaskListSection);
