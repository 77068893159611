import React from 'react';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Cookies from 'universal-cookie';

import SubmissionError from 'redux-form/lib/SubmissionError';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

import TRMSGS from 'constants/Translations/Localization';


/**
 * LoginPage -> LoginFormSection -> LoginFormComponent
 *
 * Props:
 *    - login
 */


const cookies = new Cookies();

class LoginFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.selectMode = this.selectMode.bind(this);
    this.state = { inputType: 'password', mode: 'standard' , languageType:  'english' };
  }

  componentWillMount(){
    const mode = cookies.get('loginMode', { path: '/' });
    const languageCookie = cookies.get('userLanguagePreferenceCookie', { path: '/' });
    if(mode){
      this.setState({mode: mode})
    }else{
      cookies.set('loginMode','standard', { path: '/' });
    }
    if(languageCookie){
      this.setState({languageType: languageCookie});
      TRMSGS.setLanguage(languageCookie);
    }
    else{
      TRMSGS.setLanguage('english');
    }   
  }

  submit(data) {
    const { login } = this.props;
    const detail = data;
    detail.device = 'WEB';
    return login(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors || err.detail,

          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  selectMode(type){
    cookies.set('loginMode', type, { path: '/' });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  render() {
    const { handleSubmit, submitting, error, showCompany } = this.props;
    const { inputType, mode, languageType } = this.state;
    return (
      <div class={`w-100 flex items-center ${styles['card']}`}>
        <div className={`${styles['logo_div']} tc w-100`}>
          <img
            className={`pl2 v-btm ${styles['logo']}`}
            height="85"
            width="130"
            src={require('./logo.png')} alt="Site Name"
          /><br/>
          <img
            className="pl2 v-btm"
            height="26px"
            width="150px"
            src={require('./name.png')} alt="Site Name"
          />
        </div>
        <form class="w-100 pl3 pr3" onSubmit={handleSubmit(this.submit)} noValidate>
          <h1 class={`mt0 ml4 mr4 pl2 pr2 ${styles['title']}`}>{TRMSGS.signIn}</h1>
          <div className="ml4 mr4 pt4 pl2 pr2">
            {error && <div className="error-text mb2">{error}</div>}
            <Field
              id="username"
              name="username"
              component={InputAtom}
              label={TRMSGS.username}
              placeholder={TRMSGS.username}
              type="text"
              data-lpignore={true}
            />
            <Field
              id="password"
              type={inputType}
              name="password"
              component={InputAtom}
              label={TRMSGS.password}
              placeholder={TRMSGS.password}
              data-lpignore={true}
            />
            {inputType === 'password'
              ? (<EyeOpenIconAtom
                className="input-icon fr"
                height="22" width="22"
                onClick={this.toggleInputType}
              />)
              : (<EyeCloseIconAtom
                className="input-icon fr"
                height="22" width="22"
                onClick={this.toggleInputType}
              />)
            }
          </div>
          { showCompany && <Field
            id="company_name"
            name="company_name"
            component={InputAtom}
            label={TRMSGS.companyName}
            placeholder={TRMSGS.companyName}
            type="text"
          />}
          <div className="ml4 mr4 pt3 pb4 pl2 pr2">
            <div className={languageType === 'bilingual' ? "w-100 mb3" : "dib w-50"}>
              <input type="radio" id="quick" name="mode" value="mode" defaultChecked={mode == 'quick'} onClick={()=> this.selectMode('quick')}/>
              <label for="quick" className="ml2">{TRMSGS.quickMode}</label>
            </div>
            <div className={languageType === 'bilingual' ? "w-100" : "dib w-50"}>
              <input type="radio" id="standard" name="mode" value="mode" defaultChecked={mode == 'standard'} onClick={()=> this.selectMode('standard')}/> 
              <label for="standard" className="ml2">{TRMSGS.standardMode}</label>
            </div>
          </div>    
          <div className="tr mr4 pl2 pr2">
            <button type="submit" color="secondary" className={`white btn-color br2 ${styles['accounts-button']}`} size={FORM_LAYOUT.size}>
              {TRMSGS.signIn} <ButtonLoaderAtom active={submitting} />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.username) errors.username = `${TRMSGS.enterUsername}`;
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  if (!values.company_name) errors.company_name = `${TRMSGS.enterCompanyName}`;
  return errors;
};

export default reduxForm({
  form: 'LoginForm',
  validate,
})(LoginFormComponent);

