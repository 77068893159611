import React from 'react';

import { Switch, Route } from 'react-router-dom';

import DashboardPage from './Dashboard';
import HomePage from './Home';
import ListingPage from './Listing';
import QuickModePage from './QuickModeSetup';
import ErrorPage from './ErrorPage';

export const routes = [
  {
    component: DashboardPage,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    component: ListingPage,
    exact: true,
    name: 'listing',
    path: '/listing',
  },
  {
    component: HomePage,
    exact: true,
    name: 'home',
    path: '/',
  },
   {
    component: QuickModePage,
    exact: true,
    name: 'quickMode',
    path: '/success',
  },
  {
    name: '404',
    component: ErrorPage,
    path : '*'
  },
];

const GenericRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default GenericRoutes;
