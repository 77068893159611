export const SORT_ORDERS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DATE_FORMAT_MAPPINGS = {
  'YYYY/MM/DD': 'Y/MM/DD',
  'YYYY/DD/MM': 'Y/DD/MM',
  'MM/DD/YYYY': 'MM/DD/Y',
  'DD/MM/YYYY': 'DD/MM/Y',
  'MMMM DD, YYYY': 'MMMM DD, Y',
  'MMM DD, YYYY': 'MMM DD, Y',
  'ddd, DD MMM YYYY': 'ddd, DD MMM Y',
};



export default { SORT_ORDERS, DATE_FORMAT_MAPPINGS };
