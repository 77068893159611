import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

class HomePage extends React.PureComponent {
  render() {
    return (
      <section>
        <div className={styles.wrapper}>
          <Helmet>
            <title>Worksana | Kiosk</title>
          </Helmet>
          <div className="pt1 pb3">
            <img height="85" width="130" className={styles.logo} src={require('./logo.png')} alt="Site Name" />
            <img height="26px" width="150px" className="mb3" src={require('./name.png')} alt="Site Name" />
          </div>
          <h1 className="mb2 text-color b mt0 fw4 welcome-text">{TRMSGS.welcomeToWorksanaKiosk}</h1>
          <p className={styles.description}>{TRMSGS.viewYouremployeeDailyTimecards} </p>
          <br/>
          <Link to="/accounts/login" className="white btn-color br2 no-underline fw6 gotologin-button">{TRMSGS.goToLogin}</Link>
        </div>
      </section>
    );
  }
}

export default HomePage;
