import axios from 'axios';
import Cookies from 'universal-cookie';

import Singleton from 'lib/singleton';

const axiosInstance = axios.create();
const cookies = new Cookies();

// Request interceptor
axiosInstance.interceptors.request.use(
  (configuration) => {
    const config = configuration;
    const singleton = new Singleton();
    const languageCookie = cookies.get('userLanguagePreferenceCookie', { path: '/' });
    config.headers['language'] = singleton.language ? singleton.language : languageCookie ? languageCookie : 'english';
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['X-XSS-Protection'] = '1; mode=block';
    config.headers['Strict-Transport-Security'] = 'max-age=31536000';
    config.headers['X-Content-Type-Options'] = 'nosniff';
    if (singleton.employeeAuth && !config.headers.Authorization) {
      config.headers.mobile = true;
      config.headers.Authorization=`Token ${singleton.employeeAuth}`;
    }else if (singleton.authorization) {
      config.headers.Authorization = `Token ${singleton.authorization}`;
    }
    config.headers.kiosk = true;
    return config;
  },
  error => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  response => response.data,
  (error) => {
    if (error.response && error.response.status === 401) {
      const singleton = new Singleton();
      singleton.authorization = null;
      cookies.remove('authToken', { path: '/' });
    }
    return Promise.reject(error?.response?.data);
  },
);

export default axiosInstance;
