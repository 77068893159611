import React from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import Modal from 'react-bootstrap-modal';
import StorageGateway from 'lib/storage-gateway';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as SessionDucks from 'ducks/activities/session';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as UserDucks from 'ducks/accounts/user';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';

import Singleton from 'lib/singleton';
import { server } from 'lib/config';

import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton'
import WelcomeSection from 'sections/activities/list/Welcome';
import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import PasswordModalComponent from 'components/activities/List/PasswordModal.jsx';
import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class ActivityListPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.logout = this.logout.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.togglePasswordModal = this.togglePasswordModal.bind(this);
    this.getAssociatedJobs = this.getAssociatedJobs.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.getAssociatedTasks = this.getAssociatedTasks.bind(this);
    this.fetchData=this.fetchData.bind(this);
    this.setupFacialRecognition = this.setupFacialRecognition.bind(this);
    this.reloadScreen= this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.state = { openAlert : false, alertMessage : null , responseId : cookies.get('responseId', { path: '/' }) ,  cameraPermissionText : false, isLoading: true, isButtonLoading: false, isOpen: false , showButton : null, job_id : null, isTimeCardIcon: true, showReloadButton: false,isFeedbackOpen: false};
    this._timeOut = null;
    this.submitPassword = this.submitPassword.bind(this);
    this.employeeId = null;
    this.uniqueUuid = null;
    this.companyId = null;
    this.roleId = null;
    this.dept_id = null;
    this.team_id = null;
  }

  componentWillMount() {
    this.fetchData();
   /* this.startTimer();*/
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
      console.log('listner',data)

      if(data && data.response_id && data.action == 'SUCCESS' && data.screenContext == 'welcome'){
        cookies.set('responseId' , data.response_id, { path: '/' });
        this.setState({responseId : data.response_id, isFeedbackOpen: false})
       }
       if(data.response_id !== '0' && data.screenContext == 'welcome'){
        this.setState({openAlert : true , alertMessage : data.message, isFeedbackOpen: false});
       }
       if(data.message == 'Logout'){
        this.logout();
        this.setState({isFeedbackOpen: false});
      }

  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.singleton.language = null;
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    // window.addEventListener("message", (event) => {
    //    const data = event.data && JSON.parse(event.data)
    //    if(data && data.response_id && data.action == 'SUCCESS' && data.screenContext == 'welcome'){
    //     cookies.set('responseId' , data.response_id, { path: '/' });
    //     this.setState({responseId : data.response_id, isFeedbackOpen: false})
    //    }
    //    if(data.response_id !== '0' && data.screenContext == 'welcome'){
    //     this.setState({openAlert : true , alertMessage : data.message, isFeedbackOpen: false});
    //    }
    // }, false);

    window.addEventListener("message", this.eventListnerFunction, false);
    // window.addEventListener("message", (event) => {
    //   const data = event.data && JSON.parse(event.data)
    //   const isLogoutTrue = data.message;
    //   if(isLogoutTrue == 'Logout'){
    //     this.logout();
    //     this.setState({isFeedbackOpen: false});
    //   }
    // }, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }


  
  pageClick(e) {
    const {isFeedbackOpen} = this.state;
    if(!isFeedbackOpen){
      clearTimeout(this._timeOut);
      this.startTimer();
    }
  }

  toggleAlert(){
    this.setState({openAlert : false , alertMessage : null})
  }



   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
    window.removeEventListener("message", this.eventListnerFunction);
    this.setState({isFeedbackOpen: false});
  }

  getAssociatedTasks(){
    this.props.TaskDucks.getTasks({job_id : this.state.job_id, paginate: false})
    .then((response) => {
        if(response.value.length === 1){
          this.setState({showButton: 'PunchIn'});
        }
        else if(response.value.length > 1){
          this.setState({showButton: 'tasks'});
        }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }


  getAssociatedJobs(){
    const { profile } = this.props;
    this.props.EmployeeDucks.getEmployee({ id: profile.employee_id });
    this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false})
    .then((response) => {
       if(response.value.length === 0){
          this.setState({showButton: 'punchIn'});
        }
       else if(response.value.length === 1){
          this.setState({job_id : response.value[0].id});
          cookies.set('job_id', response.value[0].id, { path: '/' });
          this.getAssociatedTasks(response);
        }
       else{
         this.setState({showButton: 'jobs'}); 
       }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  fetchData(params) {
    this.props.SessionDucks.resetActivityData();
    this.setState({ isLoading: true });
    Promise.all([
      this.props.ProfileDucks.getProfile(),
      /*this.props.ProfileDucks.getFaceDetails(),*/
      this.props.SessionDucks.getCurrentSession(),
      this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 }),
      // getJobs
      //this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false}),
      // getTasks
      //this.props.TaskDucks.getTasks({job_id : this.props.job_id, paginate: false}),
    ])
      .then(() => {
        const { companyDetail , profile } = this.props;
        this.employeeId = profile.employee_id;
        this.uniqueUuid = profile.unique_uuid;
        this.companyId = profile.company && profile.company.id;
        this.roleId = profile.role_id;
        this.dept_id = profile.department && profile.department.id ? profile.department.id : null;
        this.team_id = profile.department && profile.department.team && profile.department.team.id ?  profile.department.team.id : null;
        if(companyDetail.task_time_allocation && companyDetail.to_be_allocated) {
          this.getAssociatedJobs();
        } else {
          this.setState({showButton: 'punchIn'});
        }
        this.setState({ isLoading: false, showReloadButton: false });
      })
      .catch(() => this.setState({ isLoading: false, showReloadButton: false }));

      clearTimeout(this._reloadTimeOut);
}

  logout() {
    const { router: { history } } = this.context;
    this.singleton.employeeAuth = null;
    cookies.remove('employeeToken', { path: '/' });
    cookies.remove('job_id', { path: '/' });
    cookies.remove('responseId', { path: '/' });
    cookies.remove('userLanguagePreferenceCookie', { path: '/' });
    cookies.remove('documentId', { path: '/' });
    this.singleton.language = null;
    StorageGateway.remove('imageUrl');
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    this.props.UserDucks.resetUserPreferedLanguage();
    clearTimeout(this._timeOut);
    history.push('/dashboard');
  }

  togglePasswordModal() {
    if (!window && !window.navigator) {
      this.setState({cameraPermissionText : 'UPDATE'});
    }
    const supported = 'mediaDevices' in window.navigator;

    if (!supported) {
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if(isMacLike){
        this.setState({cameraPermissionText: 'MACLIKEDEVICE'});
      }
      else{
        this.setState({cameraPermissionText: 'UPDATE'});
      }
    }
    if (navigator.mediaDevices !== undefined) {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
    }
  }

  submitPassword(data){
    const { authorization,employeeDetail } = this.props;
    return this.props.UserDucks.postSupervisorPassword(data, authorization)
      .then(() => {
        if(employeeDetail.biometric_access)
          {
            this.togglePasswordModal();
            this.props.history.push({
            pathname: '/activity/biometric',
            search: '?src=activity-list&edit=true'
             })
          }
        else{
          this.togglePasswordModal();
            this.props.history.push({
            pathname: '/activity/pincode',
            search: `?reset_pincode=true`,
            state: { pk : employeeDetail?.user?.employee_id , reset_pincode:true },
             })
        }  
      });
  }

  setupFacialRecognition(){
    this.props.history.push({
      pathname: '/activity/biometric',
      search: '?src=activity-list'
    });
  };

  reloadScreen(){
    this.setState({showReloadButton: false});
    this.fetchData();
    this.clickToReload();
  }

  clickToReload(){
    this._reloadTimeOut = setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }

  //resets the this._timeout to stop the timer
  stopTimer(){
    clearTimeout(this._timeOut);
    this.setState({isFeedbackOpen: true});
  }

  render() {
    const {cameraPermissionText, isLoading, isOpen, isButtonLoading, isTimeCardIcon , alertMessage , openAlert } = this.state;
    const { employeeDetail, jobsList, taskList , sessionDetail , companyDetail } = this.props;
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/> ;
    if(sessionDetail && sessionDetail.current_activity && sessionDetail.current_activity.length){
      return <Redirect to ='/jobs/shift/' />;
    }
    return (

      <div className="relative">
        <div className="cf min-vh-100 pb4 dark-grey-bg relative" style={(companyDetail.feedback && this.state.responseId == undefined) ? {height: '100vh', overflow: 'hidden'} : null}>
          <div className="w-100 margin-list">
            <HeaderSection 
              isTimeCardIcon={isTimeCardIcon}
              fetchData={this.fetchData}
            />
            <TimeCardHeader 
              showReset = {true}
              heading={TRMSGS.trackYourDay}
              togglePasswordModal = {this.togglePasswordModal}
              showResetLink = {employeeDetail && employeeDetail.biometric_access}
              showPincodeReset = {employeeDetail && employeeDetail.pincode_access}
            />
          </div>
          {
            (companyDetail.feedback && this.state.responseId !== undefined) || (!companyDetail.feedback)  ? 
            <div className="w-70-l w-90-ns margin-list pa2 pt4 pb5">
              <WelcomeSection 
                taskList={taskList}
                jobsList={jobsList}
                job_id = {this.state.job_id}
                showButton={this.state.showButton}
                isButtonLoading={isButtonLoading}
              />
            </div>
            :
            <div style={{height : '80vh', overflowX : 'hidden', overflowY : 'hidden', paddingBottom: '5rem'}}>
              <iframe id='feedback_iframe' width="100%" height="100%" frameBorder="0" key="pre-punchin" onLoad={()=>this.stopTimer()} src={`${server.feedbackHost}/?oAuthToken=${this.singleton.employeeAuth}&language=${this.singleton.language}&uniqueUuid=${this.uniqueUuid}&employeeId=${this.employeeId}&company_id=${this.companyId}&role_id=${this.roleId}&context=kiosk&dept_id=${this.dept_id}&team_id=${this.team_id}&meal_survey=false&screen_context=welcome`}/> 
            </div>
          }
          {isOpen &&
            <PasswordModalComponent
              toggle={this.togglePasswordModal}
              isOpen={isOpen}
              submitPassword={this.submitPassword}
          />}
          {
            cameraPermissionText &&
              <Modal
                show={cameraPermissionText}
                aria-labelledby="ModalHeader"
                backdrop="static"
              >
                <Modal.Header closeButton  className='custom-modal'>
                  <Modal.Title id='ModalHeader'>
                      {cameraPermissionText === 'UPDATE' ? `${TRMSGS.advanceBrowserCheck}`: `${TRMSGS.browseNotSupported}`}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {cameraPermissionText && cameraPermissionText === 'UPDATE' &&
                    <div className="f4 fw4">{TRMSGS.upgradeWebBrowser}</div>
                  }
                  {cameraPermissionText && cameraPermissionText === 'MACLIKEDEVICE' &&
                    <div className="f4 fw4">{TRMSGS.browserNotCompatibleWithWebcamAnduseSafari}</div>
                  }
                </Modal.Body>
              </Modal>
            }
            {
            openAlert && alertMessage &&
              <Modal
                show={openAlert}
                aria-labelledby="ModalHeader"
                backdrop="static"
                onHide={() => this.toggleAlert()}
              >
                <Modal.Header closeButton toggle={this.toggleAlert} className='custom-modal'>
                  <Modal.Title id='ModalHeader'>
                      {TRMSGS.feedbackResponse}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="f4 fw4 pb3 pt3">{alertMessage}</div>
                </Modal.Body>
              </Modal>
            }
          <FooterSection />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return({
  faceInfo: ProfileDucks.faceInfoDetail(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  authorization: UserDucks.isLoggedIn(state),
  profile: ProfileDucks.profile(state),
  jobsList: JobDucks.jobsList(state),
  taskList: TaskDucks.taskList(state),
  sessionDetail: SessionDucks.sessionDetail(state),
});
};

const mapActionsToProps = dispatch => ({
  JobDucks : bindActionCreators(JobDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
});

ActivityListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivityListPage);
