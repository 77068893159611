import React from 'react';
import PropTypes from 'prop-types';
import reduxForm from 'redux-form/lib/reduxForm';
import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Field from 'redux-form/lib/Field';
import { toast } from 'react-toastify';

import SectionLoaderAtom from 'atoms/SectionLoader';
import CommentAtom from 'atoms/CommentAtom';

import styles from './styles.module.scss';
import {ReactComponent as AddIcon} from 'images/add.svg';
import {ReactComponent as SendIcon} from 'images/send.svg';
import TRMSGS from 'constants/Translations/Localization';

class JobNotesComponent extends React.Component {
   constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  submit(){
    const { change } = this.props;
    if(this.props.note ){
      this.props.submit();
      change('note', null);
    }
    else {
      toast.error(`${TRMSGS.enterComment}`);
    }
  }

  
  render() {
    const { handleSubmit , jobNotesList, handleChange, isLoading} = this.props;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
      { jobNotesList && jobNotesList.length !==0 &&
        <article className="ba b--black-10 w-100 bg-white enableScroll">          
            <div className={`${styles['job_note_container']} flex w-100 pl3`}>
              <div className="w-100">
                { jobNotesList && jobNotesList.length !==0 && jobNotesList.map( item => {
                  return(
                  <div className={`${styles['job_notes_div']}`} >
                    <div>
                      <p style={{display: 'inline-block'}}>{item.user.full_name}</p>
                      <p style={{display: 'inline-block', float: 'right', marginRight: '11px'}}>{item.create_date_display}</p>
                      <p>{item.notes_display}</p>
                      {item.documents && item.documents.length !== 0 && item.documents.map( newItem => (
                        <img alt="" src={newItem.file} height="150" width="150" />
                      ))}
                    </div>
                  </div>
                );})}
              </div>
            </div>
        </article>
      }
      {jobNotesList && jobNotesList.length === 0 &&
        <article className="ba b--black-10 w-100 bg-white">   
            <div>
              <div className={styles.job_notes_component_empty}  >
              {TRMSGS.noRecordsFound}
              </div>
            </div>
        </article>   
      }
        <form onSubmit={handleSubmit(this.submit)} className={`${styles['post_comment_container']} w-100  pv2 ph1`}>
            <div>
            <label className="custom-file-upload d-none" >
              <AddIcon height="27" width="30" id="add" className="button-role mt-2"/>
              <input name="df" type="file" accept="image/*" className={`${styles['addPhoto']}`} onChange={this.props.onImageChange}/>
            </label>
            </div>
            <div className={`${styles['comment-box']}`}>
              <Field
                onChange={(val ,e) => handleChange(e)}
                component={CommentAtom}
                placeholder={TRMSGS.addComment}
                className="w-100 h-100 pl2 br2 bn"
                name="note"
                value1={this.props.value}
              />
            </div>
            <button type='submit'>
              <SendIcon height="35" width="35"/>
            </button>
        </form>
      </div>
    );
  }
}

JobNotesComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

const jobNotesForm = reduxForm({
  form: 'jobNotesForm',
  enableReinitialize: true,
})(JobNotesComponent);

const selector = formValueSelector('jobNotesForm');

export default connect(
  (state) => {
    const search = selector(state, 'search');
    const note = selector(state, 'note');
    return { search, note };
  },
)(jobNotesForm);
