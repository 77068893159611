import React from 'react';

import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import ButtonAtom from 'atoms/Button';
import InputAtom from 'atoms/Input';


/**
 * RegisterPage -> RegisterFormSection -> RegisterFormComponent
 *
 * Props:
 *    - register
 */
const RegisterFormComponent = (props) => {
  const {  error, handleSubmit, register } = props;

  const submit = data => register(data)
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className="measure center mt5">
      <div className="error-text">{error}</div>
      <Field
        component={InputAtom}
        id="email"
        label="Email"
        name="email"
        placeholder="Email"
        type="email"
      />
      <Field
        component={InputAtom}
        id="password"
        label="Password"
        name="password"
        placeholder="Password"
        type="password"
        data-lpignore={true}
      />
      <Field
        component={InputAtom}
        id="confirm_password"
        label="Confirm Password"
        name="confirm_password"
        placeholder="Confirm Password"
        type="password"
        data-lpignore={true}
      />
      <ButtonAtom type="submit">Register</ButtonAtom>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.email) errors.email = 'Enter Email';
  if (!values.password) errors.password = 'Enter Password';
  if (!values.confirm_password) errors.confirm_password = 'Enter Confirm Password';
  if ((values.password !== values.confirm_password) &&
    (values.password && values.confirm_password)) {
    errors.confirm_password = 'Password mismatch';
  }
  return errors;
};

RegisterFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'RegisterForm',
  validate,
})(RegisterFormComponent);
