import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';

class DropdownAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.state = { isOpen: false };
  }

  handleSelect(selectedItem) {
    const { onChange } = this.props;
    onChange(selectedItem);

  }

  handleToggleMenu() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const {
      selectedItem, id, itemToString, label, initialLabel,timecard, items, disabled
    } = this.props;
    const { isOpen } = this.state;
    return (
      <Downshift
        itemToString={itemToString}
        onSelect={this.handleSelect}
        onFocus={this.handleFocus}
        isOpen={isOpen}
        defaultInputValue={itemToString(selectedItem)}
        defaultSelectedItem={selectedItem}
        onOuterClick={this.handleToggleMenu}
        render={({
          getButtonProps,
          getItemProps,
          getLabelProps,
          isOpen,
          highlightedIndex,
          clearSelection,
        }) => {
          return (<div className={timecard?'relative dropdown tc-dropdown-width':'relative dropdown dropdown-width'}>
            {label &&
              <label {...getLabelProps({
                htmlFor: id,
                className: 'f6 db mb1 mid-gray lh-title dib',
              })}>
                {label}
              </label>
            }
            <button
              {...getButtonProps({
                className: timecard ? 'ba b--black-20 relative ph2 pv2 lh-custom br2 tl pointer bg-white truncate tc-dropdown-width timecardDropdown':'ba b--black-20 relative ph2 pv2 lh-custom br2 tl pointer bg-white truncate dropdown-width dropdown-button',
                onClick: this.handleToggleMenu,
                disabled: disabled
              })}
            >
              {itemToString(selectedItem)}
            </button>
            {isOpen ? (
              <div className="ba b--black-20 w-100 absolute z-3 br2 overflow-x-auto max-h4 bg-white">
                {initialLabel ? <div
                  style={{
                        backgroundColor:'white',
                        fontWeight: 'normal',
                        lineHeight: '1.1',
                        fontSize: '14px',
                        color: '#777'
                      }}
                      className="pa2 font-size-map">{initialLabel}</div> : null}
                {items
                  .map((item, index) => {
                    return (<div
                      key={item.id}
                      {...getItemProps({
                        item,
                        onClick: this.handleToggleMenu,
                      })}
                      style={{
                        backgroundColor:
                          highlightedIndex === index ? '#EEEEEE' : 'white',
                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                        lineHeight: '1.1',
                        fontSize: '14px',
                      }}
                      className="pa2 pointer font-size-map"
                    >
                      {itemToString(item)} 
                    </div>
                    );
                  })}
              </div>
            ) : null}
          </div>);
        }}
      />
    );
  }
}

DropdownAtom.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
  selectedItem: PropTypes.object,
  label: PropTypes.string,
  initialLabel: PropTypes.string,
  disabled: PropTypes.bool
};

export default DropdownAtom;