import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'react-bootstrap-modal';

import { ReactComponent as CrossIcon } from 'images/cross.svg';

import TRMSGS from 'constants/Translations/Localization';


 const InfoModal = (props) =>  {
  const { isOpen,toggle,isNeedCorrection,formatDate,dtData} = props;
  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      backdrop="static"
      hide={() => toggle()}
      toggle={()=>toggle()}
    >
      <Modal.Header>
        <div className="f3 flex justify-between">
          <p className="mb0 mt3">{TRMSGS.dailyTimecard}</p>
          <p className="mb0 mt3 back-arrow" onClick={()=>toggle()}><CrossIcon height="14" width="14"/></p>
        </div>

      </Modal.Header>
      <Modal.Body className="mt3 mb3 ph4">
        {dtData?.activity_split?.length > 0 &&
          dtData.activity_split.map((items, itemIndex) => (
            items?.message?.length ===0 ?
            (<p style={{marginBottom: "-1rem"}}>"No Comments"</p>)
            :
            (items?.message?.map((item, messageIndex) => (
              <div key={`${itemIndex}-${messageIndex}`} style={{    marginBottom: "2rem"}}>
                <p style={{fontWeight:"bold",marginBottom: "-1rem"}}>By {('is_admin' in item && item.is_admin === true) ?"Admin": item.user }</p>
                <p style={{marginBottom: "-1rem"}}>{formatDate(item.timestamp)}</p>
                <p style={{marginBottom: "-1rem"}}>{item.message}</p>
              </div>
            )))
          ))
        }
      </Modal.Body>
    </Modal>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isNeedCorrection: PropTypes.bool,
  dtData: PropTypes.object.isRequired,
};

export default InfoModal;
