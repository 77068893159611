import React from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TimerService from 'lib/TimerService';

import styles from './styles.module.scss';

import { ReactComponent as MealBreakIcon } from 'images/mealIcon.svg';

import MealTimerComponent from 'components/activities/List/mealTimer';
import ReverseMealTimerComponent from 'components/activities/List/reverseMealTimer';
import TRMSGS from 'constants/Translations/Localization';

class MealBreakComponent extends React.Component {
  constructor(props) {
    super(props);
    this._interval = null;
    this.timerService = new TimerService();
    const timerService1 = new TimerService();
    this.mealPeriodTime = props.mealStartDetail.start_datetime;
    this.state = {
      duration: this.updateReverseCount(),
      running_time: timerService1.timeSince(this.mealPeriodTime),
      meal_running_time: 0,
     // totalDuration: this.updateReverseCount(),
      totalDuration: 90
    };
  }

  updateReverseCount() {
    const { FirstMealDetail, mealStartDetail } = this.props;
    if(Boolean(FirstMealDetail) === false) return;

    const mealStartTime = new Date(mealStartDetail.start_datetime);
    const currentTime = new Date();
    const timeConsumed = (currentTime.getTime() - mealStartTime.getTime())/60000;
    const timerDuration = FirstMealDetail.duration - timeConsumed;
    this.setState({duration: timerDuration, meal_running_time: timeConsumed, totalDuration:90});
    return timerDuration;
  }

  componentDidMount() {
    const timerService1 = new TimerService();
    this._interval = setInterval(() => {
      this.updateReverseCount();
      this.setState({
        running_time: timerService1.timeSince(this.mealPeriodTime),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    const { mealToggle, mealStartDetail, companyDetail, FirstMealDetail, isMealEndButtonLoading, mealEndDisabled } = this.props;
   const { duration, meal_running_time, totalDuration } = this.state;
    return (
      <div className="center">
        <div className="tc">
          {/* <p className={styles['job_shift_text']}>{TRMSGS.toalMealBreakTime}</p> */}
          <div style={{marginTop: '6rem', marginBottom: '2rem'}}>
           <div style={{ width: 200, height: 200, margin: 'auto' }}>
            {/*<CircularProgressbarWithChildren
              value={meal_running_time}
              strokeWidth={3}
              maxValue={totalDuration}
              initialAnimation={true}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'butt',
                pathTransitionDuration: 0.5,
                // pathTransition: 'none',
                // counterClockwise: false
              })}
            >
              <div className={this.props.userPreferenceLanguage ==='bilingual' ? `${styles['job_shift_text_bilingual']}` : this.props.userPreferenceLanguage ==='spanish' ? `${styles['job_shift_text_spanish']}` : styles['job_shift_text']}>
                {TRMSGS.toalMealBreakTime}
              </div>
            </CircularProgressbarWithChildren>*/}
            <div className={styles["loader-container"]}>
              <div className={styles["loader"]}></div>
               <div className={this.props.userPreferenceLanguage ==='bilingual' ? `${styles['job_shift_text_bilingual']}` : this.props.userPreferenceLanguage ==='spanish' ? `${styles['job_shift_text_spanish']}` : styles['job_shift_text']}>
                {TRMSGS.toalMealBreakTime}
              </div>
            </div>
           </div>
          </div>
            <span className={styles['job_shift_timings']}>
              <MealTimerComponent
                mealActivity={mealStartDetail}/>
            </span>
            <div className={styles['job_shift_hour_container']}>
              <span className="mr2 ml2">
                {TRMSGS.hours}
              </span>
              <span className="ml2 pl2">
                {TRMSGS.minutes}
              </span>
            </div>
            {/* <MealBreakIcon height="170" width="320" className="mt4-l mt5-ns mb0"/> */}
            <div className={`${styles['meal_break_btn_container']} w-100`}>
              <ReverseMealTimerComponent
                mealActivity={mealStartDetail}
                settings={companyDetail}
                FirstMealDetail={FirstMealDetail}
                isMealEndButtonLoading= {isMealEndButtonLoading}
                mealEndDisabled= {mealEndDisabled}
                onClick={mealToggle}
                userPreferenceLanguage = {this.props.userPreferenceLanguage}
              />
            </div>
        </div>
      </div>
    );
  }
}

export default MealBreakComponent;
