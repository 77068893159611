import { createSelector } from 'reselect';

import Cookies from 'universal-cookie';
import { fetch, post } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const cookies = new Cookies();


const GET_TASKS = 'jobs/jobs/GET_TASKS';
const GET_TASKS_FULFILLED = 'jobs/jobs/GET_TASKS_FULFILLED';
const RESET_TASKS = 'jobs/jobs/RESET_TASKS';
const RESET_ALL_TASKS = 'jobs/jobs/RESET_ALL_TASKS';
const MARK_FAVOURITE_TASK = 'jobs/jobs/MARK_FAVOURITE_TASK';
const MARK_UNFAVOURITE_TASK = 'jobs/jobs/MARK_UNFAVOURITE_TASK';
const GET_ALL_TASKS = 'jobs/jobs/GET_ALL_TASKS';
const GET_ALL_TASKS_FULFILLED = 'jobs/jobs/GET_ALL_TASKS_FULFILLED';


export function getTasks(params) {
  cookies.set('job_id', params.job_id, { path: '/' });
  return {
    type: GET_TASKS,
     payload: fetch(constants.URLS.TASKS, {params }),
  };
}

export function getAllTasks(params) {
  cookies.set('job_id', params.job_id, { path: '/' });
  return {
    type: GET_ALL_TASKS,
     payload: fetch(constants.URLS.TASKS, {params }),
  };
}

export function resetTasks() {
  return {
    type: RESET_TASKS,
  };
}

export function resetAllTasks() {
  return {
    type: RESET_ALL_TASKS,
  };
}

export function markFavouriteTasks(data) {
  const params = {
    assign_favourite : true,
    task_ids: [data.task_id],
    job_id : data.job_id
  };
  return {
    type: MARK_FAVOURITE_TASK,
    payload: post(`${constants.URLS.MARK_FAVOURITE_TASK}/`, {...params}),
  };
}


export function markUnFavouriteTasks(data) {
  const params ={
    assign_favourite : false,
    task_ids: [data.task_id],
    job_id : data.job_id
  };
  return {
    type: MARK_UNFAVOURITE_TASK,
    payload: post(`${constants.URLS.MARK_FAVOURITE_TASK}/`, {...params}),
  };
}



const defaultState = {
  detail : [],
  count: 0,
  allTasks : [],
  allTasksCount : 0,
};

const handlers = {
  [GET_TASKS_FULFILLED]: GET_TASKS_FULFILLED_REDUCER,
  [RESET_TASKS] : RESET_TASKS_REDUCER,
  [GET_ALL_TASKS_FULFILLED] : GET_ALL_TASKS_FULFILLED_REDUCER,
  [RESET_ALL_TASKS] : RESET_ALL_TASKS_REDUCER
};


function GET_TASKS_FULFILLED_REDUCER(state, action) {
  //const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    detail: (action.payload.results && action.payload.results) ||  action.payload,
    count: action.payload.count ?  action.payload.count : 0,
  });
}

function GET_ALL_TASKS_FULFILLED_REDUCER(state, action) {
  //const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    allTasks: action.payload,
    allTasksCount: action.payload.count ?  action.payload.count : 0,
  });
}

function RESET_TASKS_REDUCER(state, action) {
  //const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    detail: [],
    count: 0,
  });
}


function RESET_ALL_TASKS_REDUCER(state, action) {
  //const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    allTasks: [],
    allTasksCount: 0,
  });
}

const taskSelector = state => state.tasks.tasks;

export const taskList = createSelector(
  taskSelector,
  instance => instance.detail,
);

export const taskAll = createSelector(
  taskSelector,
  instance => instance.allTasks,
);

export const taskAllCount = createSelector(
  taskSelector,
  instance => instance.allTasksCounts,
);


export const taskCount = createSelector(
  taskSelector,
  instance => instance.count,
);


export default createReducer(defaultState, handlers);
