import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';


import { Collapse } from 'react-collapse';
import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as PayrollDucks from 'ducks/payrolls/payroll';

import styles from './styles.module.scss';

import { ReactComponent as ExpandIcon } from 'images/expand.svg';
import { ReactComponent as CollapseIcon } from 'images/collapse.svg';
import { ReactComponent as LeftArrow } from 'images/left-arrow.svg';

import TimecardOverviewSection from './TimecardOverview';
import InfoModal from './InfoModal';

import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import { secondsToHHMM } from 'lib/utils';

import TRMSGS from 'constants/Translations/Localization';
/**
 * DashboardListPage -> TimecardListSection
 *
 * Components:
 *    -
 *
 * State:
 *    - route params
 *
 */
class DailyTimecardListSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isOpen: false, isToggleOpen: true, collapseOpenIndex: false, weeklyTimeCardLists: [] , isNeedCorrection: null, isInfoModal: false, dtData:{}};
    this.toggle = this.toggle.bind(this);
    this.expandButton = this.expandButton.bind(this);
    this.splitWeekwise = this.splitWeekwise.bind(this);
    this.getWeekEndsBetween = this.getWeekEndsBetween.bind(this);
    this.minutesAndHours = this.minutesAndHours.bind(this);
    this.showWeeklyList = this.showWeeklyList.bind(this);
    this.getDetailedTimecard = this.getDetailedTimecard.bind(this);
    this.goBackToUrl = this.goBackToUrl.bind(this);
    this.setDTInfo = this.setDTInfo.bind(this);
    this.toggleInfoModal = this.toggleInfoModal.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }


 formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const year = date.getFullYear();
    
    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero
  
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hour is 0, set it to 12 (midnight)
  
    // Format the final string
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }
  

  toggleInfoModal(){
    const { isInfoModal, isNeedCorrection } = this.state;
    this.setState({isInfoModal : !isInfoModal});
  }

  setDTInfo(key) {
    this.setState({dtData:key});
    const { isInfoModal, isNeedCorrection} = this.state;
    this.setState({isInfoModal : !isInfoModal});
    {key.need_correction ?
      this.setState({isNeedCorrection:true})
      :
      this.setState({isNeedCorrection:false});
    }
  }

  getDetailedTimecard(weeklyLists) {
    if (weeklyLists.length <= 0) return;
    const {  collapseOpenIndex } = this.state;

    return weeklyLists.map((item, index) => {
       const cardsJsx =  <section key={item.id} className="bg-white">
          <div className={`${styles['card_header']} ph2 pv1 cf`}>
            <p className="fl">
              {item.need_correction ?
                <img
                  height="20"
                  className='fr mt1 back-arrow'
                  src={require('./info.png')}
                  alt="Logo"
                  onClick={()=>this.setDTInfo(item)}
                />
                :
                <img
                  height="20"
                  className='fr mt1 back-arrow'
                  src={require('./approvedIcon.png')}
                  alt="Logo"
                  onClick={()=>this.setDTInfo(item)}
                />
              }
            </p>
            <p className="pl2 fl f4">{TRMSGS.date} : {moment(item.date).format('LL')}</p>
          </div>
          <li className='flex items-center pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2', lineHeight: '3rem' }}>
            <div className="ph3 flex-auto">
              <div className="flex justify-between items-center custom_black_font">
                <span className="fl db f4">{TRMSGS.workDayTotal}</span>
                <span className="fr tr db">
                  <span className="f4"><b>{item.total_time.split('hh')[0]}</b>hh <b>{item.total_time.split('hh')[1].split('mm')[0]}</b>mm</span><br />
                </span>
              </div>
            </div>
          </li>
          {item && item.activity_split && item.activity_split.map(activityItem => (
            <li className='flex items-center lh-copy pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2' }} key={activityItem.id}>
              {activityItem.job_label
                ? (<div className="ph3 flex-auto">
                  <div className="flex justify-between items-center custom_black_font">
                    <span className="fl db f4">{activityItem.activity_name}</span>
                    <span className="fr tr db">
                      <span className="f4"><b>{activityItem.total_time_display.split('hh')[0]}</b>hh <b>{activityItem.total_time_display.split('hh')[1].split('mm')[0]}</b>mm</span><br />
                      <span className='db gray'>{moment(activityItem.start_datetime).format('hh:mm A')} - {moment(activityItem.end_datetime).format('hh:mm A')}</span>
                    </span>
                  </div>
                </div>)
                : (<div className="ph3 flex-auto">
                  <div className="flex justify-between items-center custom_black_font">
                    <span className="fl db f4">{activityItem.activity_name}</span>
                    <span className="fr tr db">
                      <span className="f4">{activityItem.total_time_display.split('hh')[0]}hh {activityItem.total_time_display.split('hh')[1].split('mm')[0]}mm</span><br />
                      <span className='db gray'>{moment(activityItem.start_datetime).format('hh:mm A')} - {moment(activityItem.end_datetime).format('hh:mm A')}</span>
                    </span>
                  </div>
                </div>)
              }
            </li>
          ))}
          <div className="ph2 pv1 cf bb b--black-10">
            <h3 className="custom_black_font fw5 di fl ml2">{TRMSGS.moreInfo}</h3>
            <span className="di mt3 fr black f4 mr2">
              {(index) === collapseOpenIndex ?
                <ExpandIcon className="pointer" height="25" width="25" onClick={() => this.expandButton(false)} />
                :
                <CollapseIcon className="pointer" height="25" width="25" onClick={() => this.expandButton(index)} />
              }
            </span>
          </div>
          {/* Collapsable content statrs from here */}
          <Collapse isOpened={(index) === collapseOpenIndex}>
            <div>
              {/* All contents after the drop down button */}
              <div>
                <div>
                  <div className="ph2 pv1 cf">                
                    <div className="w-100 custom_black_font">
                      <p className="center fw5 " style={{ width: '97%', fontSize: '16px', borderBottom: '1px solid #f2f2f2', lineHeight: '3rem'  }} >{TRMSGS.adjustmentActivity}</p>
                        {item && item.adjustments && item.adjustments.length ? item.adjustments.map(adjItem => (
                          <div className="ph3 flex-auto "  style={{borderBottom: '1px solid #f2f2f2', lineHeight: '3rem'  }}>
                            <div className="flex justify-between items-center custom_black_font">
                              <span className="fl db">{adjItem.name}</span>
                              <span className="fr tr db">
                                <span><b>{adjItem.total_adjustment_time_display.split(':')[0]}</b>hh <b>{adjItem.total_adjustment_time_display.split(':')[1].split('mm')[0]}</b>mm</span>
                              </span>
                            </div>
                          </div>
                        ))
                      :
                      <div className="ph3 flex-auto "  style={{borderBottom: '1px solid #f2f2f2', lineHeight: '3rem'  }}>
                        <div className="flex justify-center items-center custom_black_font tc">
                            {TRMSGS.noRecordsFound}
                        </div>
                       </div>
                    }
                </div>
                </div>
                
              </div>
                
              </div>
            </div>
          </Collapse>
        </section>

      let totalWeeklyTime = 0;

      weeklyLists.forEach(listItem => {
        totalWeeklyTime += listItem.total_time_seconds;
      });
      return (<div><div>{cardsJsx}</div><div className="w-100 white flex justify-between" style={{ backgroundColor: '#077289', fontWeight: '500' }}>
        <p className="pv1 pl3 f4">{TRMSGS.totalWeeklyHours}</p>
        <p className="pv1 pr3 f4">{this.minutesAndHours(totalWeeklyTime)}</p>
      </div></div>);
            });

  }

  goBackToUrl(type){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    if(type==='showWeeklyList'){
      const {  show_detailed_dt , dt_date ,...rest } = query;
      history.push({ pathname, search: queryString.stringify({...rest , show_weekly_list : true}) });
    }
    else{
      const { show_detailed_dt , dt_date, show_weekly_list ,...rest } = query;
      history.push({ pathname, search: queryString.stringify({...rest}) });
    }

  }


  expandButton(index) {
    this.setState({ collapseOpenIndex: index });
  }

  showWeeklyList(type, item){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    this.props.clickToReload();
    if(type==='showDatewiseDt'){
      const { show_weekly_list , ...rest } = query;
      history.push({ pathname, search: queryString.stringify({...rest, show_detailed_dt: true, dt_date: item.date}) });
    }
    else{
      history.push({ pathname, search: queryString.stringify({...query, show_weekly_list: true}) });
    }
  }

  toggle() {
    const { isToggleOpen } = this.state;
    this.setState({ isToggleOpen: !isToggleOpen });
  }

  getWeekEndsBetween(startDate, endDate, weekEnd) {
    const start = moment(startDate);
    const end = moment(endDate);

    const result = [];
    let current = start.clone();

    while (current.day(7 + weekEnd).isBefore(end)) {
      result.push(current.clone());
    }
    return result;
  }

  getDaysBetweenDates(start, end, day) {
    start = new Date(start);
    end = new Date(end);

    const result = [];
    const current = new Date(start);
    current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
    while (current < end) {
      result.push(new Date(+current).getTime());
      current.setDate(current.getDate() + 7);
    }
    return result;
  }

  splitWeekwise(timeCards, profile) {
    if (Boolean(timeCards) === false || Boolean(profile.company) === false) return [];
    if (timeCards.length === 0) return [];

    const { week_day } = profile.company.default_payroll_settings;
    const weeklyLists = [];
    const weekEnds = this.getDaysBetweenDates(timeCards[0].date, timeCards[timeCards.length - 1].date, week_day);

    weekEnds.splice(0, 0, 0);
    weekEnds.push(Number.MAX_SAFE_INTEGER);


    for (let i = 0; i < weekEnds.length - 1; i++) {
      weeklyLists.push([]);
      timeCards.forEach(tc => {
        const time = new Date(tc.date).getTime();
        if (time < weekEnds[i + 1] && time >= weekEnds[i]) {
          weeklyLists[i].push(tc);
          // weeklyLists.splice(i, 0, tc);
        }
      });
    }

    return weeklyLists;
  }

  minutesAndHours(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h === 1 ? ` ${TRMSGS.hour} ` : ` ${TRMSGS.small_hours} `) : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ` ${TRMSGS.minute} ` : ` ${TRMSGS.small_minutes} `) : '';
    return hDisplay + mDisplay;
  }

  getJsx(weeklyLists) {
    if (weeklyLists.length <= 0) return;
    const {  collapseOpenIndex } = this.state;
    return weeklyLists.map((weeklyList, weekIndex) => {
      if(weeklyList.length <= 0) return <div></div>;
      else{
       const cardsJsx = weeklyList.map((item, itemIndex) => (
        <section key={item.id} className="bg-white">
          <div className={`${styles['card_header']} ph2 pv1 cf`}>
             <p className="fl">
              {item.need_correction ?
                <img
                  height="20"
                  className='fr mt1 back-arrow'
                  src={require('./info.png')}
                  alt="Logo"
                  onClick={()=>this.setDTInfo(item)}
                />
                :
                <img
                  height="20"
                  className='fr mt1 back-arrow'
                  src={require('./approvedIcon.png')}
                  alt="Logo"
                  onClick={()=>this.setDTInfo(item)}
                />
              }
            </p>
            <p className="pl2 fl f4">{TRMSGS.date} : {moment(item.date).format('LL')}</p>
            <p className="fr pr3 back-arrow"><img src={require('./rightArrow.png')} alt="logo" height="20" width = "20" onClick={()=>this.showWeeklyList('showDatewiseDt', item)}/></p>
          </div>
            <ul className="list pl0 ml0 center ba b--light-silver br2 mt0">
              {item.regular_time > 0 ?
                <li className='flex items-center lh-copy pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2' }}>
                  <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                    <p className="f4">{TRMSGS.regularTime}</p> 
                    <span className="fr tr db">
                      <span className="f4"><b>{item.regular_time_display.split(':')[0]}</b>hh <b>{item.regular_time_display.split(':')[1].split('mm')[0]}</b>mm</span><br />
                    </span>
                  </div>
                </li>
                :
                null
              }
              {item.over_time > 0 ?
                 <li className='flex items-center lh-copy pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2' }}>
                  <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                    <p className="f4">{TRMSGS.overtime}</p> 
                     <span className="fr tr db">
                      <span className="f4"><b>{item.over_time_display.split(':')[0]}</b>hh <b>{item.over_time_display.split(':')[1].split('mm')[0]}</b>mm</span><br />
                    </span>
                  </div>
                </li>
                :
                null
              }
              {item.double_over_time > 0 ?
                <li className='flex items-center lh-copy pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2' }}>
                  <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                    <p className="f4">{TRMSGS.doubleOvertime}</p> 
                    <span className="fr tr db">
                      <span className="f4"><b>{item.double_over_time_display.split(':')[0]}</b>hh <b>{item.double_over_time_display.split(':')[1].split('mm')[0]}</b>mm</span><br />
                    </span>
                  </div>
                </li>
                :
                null
              }
              {item && item.adjustments && item.adjustments.length ? item.adjustments.map(adjItem => (
               <li className='flex items-center lh-copy pa1 ph0-l' style={{ borderBottom: '1px solid #f2f2f2' }}>
                  <div className="flex justify-between items-center custom_black_font ph3 flex-auto">
                    <p className="f4">{adjItem.name}</p> 
                    <span className="fr tr db">
                      <span className="f4"><b>{adjItem.total_adjustment_time_display.split(':')[0]}</b>hh <b>{adjItem.total_adjustment_time_display.split(':')[1].split('mm')[0]}</b>mm</span><br />
                    </span>
                  </div>
                </li>
                ))
                :
                null
              }
            </ul>
        </section>
      ));

      let totalWeeklyTime = 0;

      weeklyList.forEach(listItem => {
        totalWeeklyTime += listItem.regular_time + listItem.over_time + listItem.double_over_time + listItem.total_adjustment_time;
      });
      return (<div><div>{cardsJsx}</div><div className="w-100 white flex justify-between" style={{ backgroundColor: '#09819A', fontWeight: '500' }}>
        <p className="pv1 pl3 f4 fw6">{TRMSGS.totalWeeklyHours}</p>
        <p className="pv1 pr3 f4 fw6">{this.minutesAndHours(totalWeeklyTime)}</p>
      </div></div>);
    }
    });
  }

  render() {
    const { collapseOpen  } = this.state;
    const { dailyTimecardList, isLoading , profile } = this.props;
    const { location: { pathname, query } } = this.props;

    // TODO: add Memoization
    const weeklyTimeCardLists = this.splitWeekwise(dailyTimecardList, profile);
    const detailedDt =  dailyTimecardList.filter((item) => item.date == query.dt_date);
 /*   let count = 0;
    dailyTimecardList.map((i) => {
      count += i.total_time_seconds;
      return count;
    });

    const displayTotal = count ? secondsToHHMM(count) : '00:00';*/

    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.props.showReloadButton} reloadScreen={this.props.reloadScreen}/>;

    return (
      <div className="w-100">
        <div className="cf">
          <div className="pa2">
            <div>
              {!query.show_weekly_list && !query.show_detailed_dt ?
               <TimecardOverviewSection dailyTimecardList = { dailyTimecardList } showWeeklyList ={this.showWeeklyList} timecardOverview={this.props.timecardOverview}/>
               :
               null
              }
              {query.show_weekly_list && !query.show_detailed_dt ?
                 <article>
                  <div className="w-100 white flex" style={{ backgroundColor: '#077289', fontWeight: '500' }}>
                    <p className="pv1 pl3 f4" onClick={()=> this.goBackToUrl()}>
                      <LeftArrow
                        height="20"
                        width="20"
                        className="fl ml2 back-arrow"
                      />
                    </p>
                    <p className="pv1 pl3 f4">{TRMSGS.total}</p>
                    
                  </div>
                  <ul className="list pl0 ml0 center ba b--light-silver br2">
                     {this.getJsx(weeklyTimeCardLists, true)}
                  </ul>
                 </article>
                 :
                 null
              }
              {query.show_detailed_dt && !query.show_weekly_list ?
                 <article>
                  <div className="w-100 white flex " style={{ backgroundColor: '#077289', fontWeight: '500' }}>
                    <p className="pv1 pl3 f4" onClick={()=> this.goBackToUrl('showWeeklyList')}>
                      <LeftArrow
                        height="20"
                        width="20"
                        className="fl ml2 back-arrow"
                      />
                    </p>
                    <p className="pv1 pl3 f4">{TRMSGS.total}</p>
                  </div>
                  <ul className="list pl0 ml0 center ba b--light-silver br2">
                     {this.getDetailedTimecard(detailedDt, true)}
                  </ul>
                 </article>
                 :
                 null
              }
              {
                this.state.isInfoModal ?
                <InfoModal
                  isOpen = {this.state.isInfoModal}
                  toggle={this.toggleInfoModal}
                  dtData={this.state.dtData}
                  isNeedCorrection = {this.state.isNeedCorrection}
                  formatDate={this.formatDate}
                />
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dailyTimecardList: TimecardDucks.dailyTimecardList(state),
  profile: ProfileDucks.profile(state),
  payrollList: PayrollDucks.payrollList(state),
  timecardOverview : TimecardDucks.timecardOverview(state)
});

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
});


DailyTimecardListSection.propTypes = {
  dailyTimecardList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DailyTimecardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DailyTimecardListSection);
