import React from 'react';

import TimerService from 'lib/TimerService';

class JobTaskTimerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {shiftDuration : '00:00'};
  }


  componentDidMount() {
    const { activityData } = this.props;
    const shiftData = activityData && activityData.length && activityData[activityData.length - 1];
    const startTime = shiftData && shiftData.start_datetime;
    const timerService = new TimerService();
    this._interval = setInterval(() => {
      this.setState({
        shiftDuration: timerService.timeSince(startTime),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    return (
      <section>
        <div className="JobTaskTimeText">
          {this.state.shiftDuration} <br/>
        </div>
      </section>
    );
  }
}

JobTaskTimerComponent.propTypes = {
};

JobTaskTimerComponent.contextTypes = {};

export default JobTaskTimerComponent;
