import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import moment from 'moment';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

import JobShiftComponent from 'components/jobs/shift/jobShift';
import AddShiftNameModalComponent from 'components/jobs/shift/addShiftNameModalComponent';
import TRMSGS from 'constants/Translations/Localization';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as RouteDucks from 'ducks/routes';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as UserDucks from 'ducks/accounts/user';

const cookies = new Cookies();


class JobShiftSection extends React.Component {
  constructor() {
    super();
    this.state = {isMealStartButtonLoading: false, isPunchOutButtonLoading : false, mealBreakDisabled: false, punchOutDisabled: false , openEditJobLabel : false};
    this.punchOut = this.punchOut.bind(this);
    this.mealStart = this.mealStart.bind(this);
    this.showJobNotes = this.showJobNotes.bind(this);
    this.switchJob = this.switchJob.bind(this);
    this.editJobLabel = this.editJobLabel.bind(this);
    this.closeJobLabelModal = this.closeJobLabelModal.bind(this);
    this.submit = this.submit.bind(this);
  }


 
  componentWillReceiveProps() {
    const { profile, activityData} = this.props;
    if (Boolean(activityData) === false) return;
    profile.employee_id && activityData.length == null  && this.props.SessionDucks.getActivityData(profile.employee_id);
  }


  editJobLabel(){
    this.setState({openEditJobLabel : true});
  }

  closeJobLabelModal(){
    this.setState({openEditJobLabel : false});
  }

  submit(data){
    const { sessionDetail } = this.props;
    if(!(data.shift_label)){
      this.setState({openShiftName : false, isOpen: false});
      return toast.error(`${TRMSGS.enterShiftLabel}`);
    }
    if(data.shift_label && (data.shift_label).length > 100){
      return toast.error(`${TRMSGS.shiftLabelCondition}`);
    }
    const detail = {
      activity_id: sessionDetail && sessionDetail.current_activity[0].id,
      job_label : data.shift_label
    };
    this.props.JobDucks.submitJobName(detail)
    .then(() => {
      this.props.JobDucks.getJobName({activity_id : sessionDetail && sessionDetail.current_activity[0].id});
      this.setState({openEditJobLabel : false});
    });
  }

  punchOut() {
    const { companyDetail } = this.props;
    this.setState({isPunchOutButtonLoading : true, punchOutDisabled: true});
    const mode = cookies.get('loginMode', { path: '/' });
    const loginType =cookies.get('loginType', { path: '/' });
    
    this.props.SessionDucks.getCurrentSession()
    .then((res) => {
      if(res.value.create_date == null){
        this.props.UpdateforcePunchOut();
      }
      else {
        this.setState({isPunchOutButtonLoading : true, punchOutDisabled: true});
        let newDate = new Date();
        newDate.setSeconds(0,0);
        newDate.setMilliseconds(0);
        const { sessionDetail , activityData, profile } = this.props;
        const startDateTime = activityData && activityData.length ? new Date(activityData[activityData.length - 1].start_datetime) : new Date()
        const startDate = moment(startDateTime).format('YYYY/MM/DD');
        startDateTime.setHours(23);
        startDateTime.setMinutes(59);
        startDateTime.setSeconds(0,0);
        startDateTime.setMilliseconds(0);
        const endDate = moment(newDate).format('YYYY/MM/DD');
        const { router: { history } } = this.context;    
        let newActivityData = [];
        let activityDetail = [];
        activityData && activityData.length ? activityData.map((item, index) => {
            if(index === (activityData.length -1 )){
              const newItem = {
              ...item,
              end_datetime :newDate.toISOString(),
              session_id : sessionDetail.session_id
            };
            return newActivityData.push(newItem);
          }
            return newActivityData.push(item);
          })
        :
        newActivityData.push({
          start_datetime : sessionDetail && sessionDetail.current_activity && sessionDetail.current_activity.length && sessionDetail.current_activity[0] && (sessionDetail.current_activity[0].start_datetime),
          activity_id : null,
          end_datetime : newDate.toISOString(),
          activity_name : 'shift',
          session_id : sessionDetail.session_id
        });
        activityDetail ={
          employee_id : profile.employee_id,
          data : newActivityData
        }; 
        
        if(loginType==='Face')
        { 
          this.props.SessionDucks.saveActivityLog({activity_type:'punch_out'})
        }
        this.props.SessionDucks.saveActivityData(activityDetail)
        .then(() => {
          this.props.SessionDucks.putEndSession({id: sessionDetail.current_activity[0].id, version: 2})
          .then(() => {
            this.props.ActivitySplitDucks.getActivitySplitLast()
              .then((res) => {
                let activityId = [];
                (res.value).filter((item) => {
                  if (item) {
                    activityId.push(item.id);
                  }
                  return true;
                });
                this.props.ActivitySplitDucks.postActivitySignOff({split_ids: activityId});
                this.setState({isPunchOutButtonLoading : false});
                history.push('/jobs/overview/');
              });
            });})
          .catch(() => {
            this.setState({isPunchOutButtonLoading : false});
          });
      }});
  }

  switchJob() {
    const { jobsList, taskAll} = this.props;
    const { query } = this.props.location;
    const { router: { history } } = this.context;
    this.props.SessionDucks.getCurrentSession()
    .then((res) => {
      if(res.value.create_date == null){
        this.props.UpdateforcePunchOut();
      }
      else{
        if(jobsList && jobsList.length === 1) {
          this.selectJob(jobsList[0]);
          return;
        }
        if ( jobsList.length === 1 && taskAll.length > 0 ) {
          history.push({
          pathname: '/tasks/list/',
          search: queryString.stringify({...query})
        });
        }else if ( jobsList.length > 1) {
          history.push({
          pathname: '/jobs/list/',
          search: queryString.stringify({...query})
        });
        }
          }
      });
  }

  selectJob(job){
    const { router: { history } } = this.context;
    history.push({
      pathname: '/tasks/list/',
      search: queryString.stringify({job_id : job.id})
    });
  }
  mealStart() {
    const mode = cookies.get('loginMode', { path: '/' });
    const loginType =cookies.get('loginType', { path: '/' });
    this.props.SessionDucks.getCurrentSession()
    .then((res) => {
      if(res.value.create_date == null){
        this.props.UpdateforcePunchOut();
    }});
    let newDate = new Date();
    newDate.setSeconds(0,0);
    newDate.setMilliseconds(0);
    newDate.toISOString();
    this.setState({isMealStartButtonLoading : true, mealBreakDisabled: true});
    const { profile , activityData} = this.props;
    const { router: { history } } = this.context;
    this.props.SessionDucks.postMealPeriodSession()
    .then((res) => {
      let activityDetail;
      let newActivityData = [];
      activityData && activityData.length && activityData.map((item, index) => {
        if (index === (activityData.length - 1)) {
          const newItem = {
            ...item,
            end_datetime : newDate,
          };
          return newActivityData.push(newItem);
        }
        return newActivityData.push(item);
      });
      newActivityData.push({ 
        activity_name : 'meal',
        start_datetime : newDate,
        activity_id : res.value.activity_id,
        browserInfo : navigator.userAgent && navigator.userAgent.split(')') && navigator.userAgent.split(')').reverse() && navigator.userAgent.split(')').reverse().length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g) && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g).length && navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/'),
        employeeToken : cookies.get('employeeToken', { path: '/' }),
        funcName: 'meal started'
      });
      activityDetail ={
        employee_id : profile.employee_id,
        data : newActivityData,
      };  

      this.props.SessionDucks.saveActivityData(activityDetail);
      if(loginType==='Face')
      {
        this.props.SessionDucks.saveActivityLog({activity_type:'meal_start'})
      }

      if(mode == 'quick'){
        this.setState({isMealStartButtonLoading : false});
        history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'mealstart'}) });
      }
      else{
        this.setState({isMealStartButtonLoading : false});
        history.push('/meals/break');
      }
    })
    .catch(() => {
      this.setState({isMealStartButtonLoading : false});
    });
  }

  showJobNotes(){
    const { router: { history } } = this.context;
    this.props.JobDucks.getJobNotes({paginate: false})
    .then(() => history.push('/jobs/notes'));
  }

  render() {
    const { activityData , jobsList, taskAll } = this.props;
    const { isMealStartButtonLoading, isPunchOutButtonLoading, mealBreakDisabled, punchOutDisabled , openEditJobLabel } = this.state;
    const selectedJob = activityData && activityData.length && activityData[activityData.length - 1].job_name;
    const selectedTask = activityData && activityData.length && activityData[activityData.length - 1].task_name;
    return (
      <div>
        <JobShiftComponent
          punchOut={this.punchOut}
          mealStart={this.mealStart}
          showJobNotes={this.showJobNotes}
          punchInDetail={this.props.punchInDetail}
          sessionDetail={this.props.sessionDetail}
          activityData = {this.props.activityData}
          switchJob = {this.switchJob}
          isMealStartButtonLoading ={isMealStartButtonLoading}
          isPunchOutButtonLoading = {isPunchOutButtonLoading}
          mealBreakDisabled = {mealBreakDisabled}
          punchOutDisabled = {punchOutDisabled}
          selectedJob={selectedJob}
          selectedTask={selectedTask}
          taskList={taskAll}
          jobsList={jobsList}
          companyDetail={this.props.companyDetail}
          jobLabel = {this.props.jobName && this.props.jobName.job_label}
          editJobLabel={this.editJobLabel}
          userPreferenceLanguage={this.props.userPreferenceLanguage}
        />
        {
          openEditJobLabel && 
          <AddShiftNameModalComponent
            isOpen={openEditJobLabel}
            toggle={this.closeJobLabelModal}
            submit={this.submit}
            onSubmit={this.submit}
            closeModal={this.closeJobLabelModal}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
            initialValues={{shift_label: this.props.jobName.job_label}}
          />
        }
      </div>
    );
  }
}


const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  activityData : SessionDucks.activityData(state),
  profile: ProfileDucks.profile(state),
  sessionDetail: SessionDucks.sessionDetail(state),
  punchInDetail: SessionDucks.punchInDetail(state),
  mealStartDetail: SessionDucks.mealStartDetail(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  jobsList: JobDucks.jobsList(state),
  taskAll: TaskDucks.taskAll(state),
  jobName : JobDucks.jobName(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapDispatchToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  ActivitySplitDucks : bindActionCreators(ActivitySplitDucks, dispatch),
});

JobShiftSection.contextTypes = {
   router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobShiftSection);
