import { createSelector } from 'reselect';
import { merge } from 'ramda';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';

import { post, fetch, put } from 'lib/api';
import createReducer from 'lib/createReducer';
import Singleton from 'lib/singleton';
import constants from './constants';

const cookies = new Cookies();

// ActionsNames - <VERB>/<VERB>_<NOUN>
const LOGIN = 'accounts/user/LOGIN';
const LOGIN_FULFILLED = 'accounts/user/LOGIN_FULFILLED';
const REGISTER = 'accounts/user/REGISTER';
const REGISTER_FULFILLED = 'accounts/user/REGISTER_FULFILLED';
const ACTIVATE = 'accounts/user/ACTIVATE';
const REQUEST_PASSWORD_RESET = 'accounts/user/REQUEST_PASSWORD_RESET';
const RESET_PASSWORD = 'accounts/user/RESET_PASSWORD';
const CHANGE_PASSWORD = 'accounts/user/CHANGE_PASSWORD';
const CHANGE_EMAIL = 'accounts/user/CHANGE_EMAIL';
const LOGOUT = 'accounts/user/LOGOUT';
const LOGOUT_FULFILLED = 'accounts/user/LOGOUT_FULFILLED';
const RESET_STATE = 'accounts/user/RESET_STATE';
const AUTHENTICATION = 'accounts/user/AUTHENTICATION';
const AUTHENTICATION_FULFILLED = 'accounts/user/AUTHENTICATION_FULFILLED';
const IMAGE_AUTHENTICATION = 'accounts/user/IMAGE_AUTHENTICATION';
const IMAGE_AUTHENTICATION_AT_DASHBOARD = 'accounts/user/IMAGE_AUTHENTICATION_AT_DASHBOARD';
const IMAGE_AUTHENTICATION_AT_DASHBOARD_FULFILLED = 'accounts/user/IMAGE_AUTHENTICATION_AT_DASHBOARD_FULFILLED';
const IMAGE_AUTHENTICATION_BY_FACE = 'accounts/user/IMAGE_AUTHENTICATION_BY_FACE';
const IMAGE_AUTHENTICATION_BY_FACE_FULFILLED = 'accounts/user/IMAGE_AUTHENTICATION_BY_FACE_FULFILLED';
const GET_EXTRA_DATA = 'accounts/user/GET_EXTRA_DATA';
const GET_EXTRA_DATA_FULFILLED = 'accounts/user/GET_EXTRA_DATA_FULFILLED';

const POST_SUPERVISOR = 'accounts/user/POST_SUPERVISOR';
const POST_SUPERVISOR_FULFILLED = 'accounts/user/POST_SUPERVISOR_FULFILLED';
const RESET_FACIAL_PASSWORD = 'accounts/user/RESET_FACIAL_PASSWORD';
const RESET_FACIAL_PASSWORD_FULFILLED = 'accounts/user/RESET_FACIAL_PASSWORD_FULFILLED';

const PUT_LANGUAGE_PREFERENCE = 'accounts/user/PUT_LANGUAGE_PREFERENCE';
const PUT_LANGUAGE_PREFERENCE_FULFILLED = 'accounts/user/PUT_LANGUAGE_PREFERENCE_FULFILLED';
const GET_LANGUAGE_PREFERENCE = 'accounts/user/GET_LANGUAGE_PREFERENCE';
const GET_LANGUAGE_PREFERENCE_FULFILLED = 'accounts/user/GET_LANGUAGE_PREFERENCE_FULFILLED';

const RESET_USER_PREFERED_LANGUAGE = 'accounts/user/RESET_USER_PREFERED_LANGUAGE';
const PINCODE_LOGIN = 'accounts/user/PINCODE_LOGIN';
const PINCODE_LOGIN_AT_DASHBOARD = 'accounts/user/PINCODE_LOGIN_AT_DASHBOARD';
const PINCODE_LOGIN_AT_DASHBOARD_FULFILLED = 'accounts/user/PINCODE_LOGIN_AT_DASHBOARD_FULFILLED';

// Action creator name: <VERB><NOUN>
// Export actions
export function postLoginUser(data) {
  return {
    type: LOGIN,
    payload: post(constants.URLS.LOGIN, data),
  };
}

export function postRegisterUser(data) {
  return {
    type: REGISTER,
    payload: post(constants.URLS.REGISTER, data),
  };
}

export function postActivateUser(data) {
  return {
    type: ACTIVATE,
    payload: post(constants.URLS.ACTIVATE, data),
  };
}

export function postRequestPasswordResetUser(data) {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: post(constants.URLS.REQUEST_PASSWORD_RESET, data),
  };
}

export function postResetPasswordUser(data) {
  return {
    type: RESET_PASSWORD,
    payload: post(constants.URLS.RESET_PASSWORD, data),
  };
}

export function postChangePasswordUser(data) {
  return {
    type: CHANGE_PASSWORD,
    payload: post(constants.URLS.CHANGE_PASSWORD, data),
  };
}

export function postChangeEmailUser(data) {
  return {
    type: CHANGE_EMAIL,
    payload: post(constants.URLS.CHANGE_EMAIL, data),
  };
}

export function postLogoutUser(data) {
  const singleton = new Singleton();
  const config = {headers:  {
    'Authorization': `Token ${singleton.employeeAuth}`,
    }
  };
  return {
    type: LOGOUT,
    payload: post(constants.URLS.LOGOUT, data, config),
  };
}

export function postAuthentication(data) {
  return {
    type: AUTHENTICATION,
    payload: post(constants.URLS.AUTHENTICATION, data),
  };
}

export function postImageAuthentication(data) {
  return {
    type: IMAGE_AUTHENTICATION,
    payload: post(constants.URLS.IMAGE_AUTHENTICATION, data),
  };
}

export function postPincodeAuthentication(data){
  return {
    type: PINCODE_LOGIN,
    payload: put(`${constants.URLS.PINCODE_LOGIN}/${data.pk}/`, { pincode: data.pincode }),
  }
}


export function postImageAuthenticationAtDashboard(data) {
  return {
    type: IMAGE_AUTHENTICATION_AT_DASHBOARD,
    payload: post(constants.URLS.IMAGE_AUTHENTICATION_AT_DASHBOARD, data),
  };
}

export function postPincodeAuthenticationAtDashboard(data){
  return{
    type: PINCODE_LOGIN_AT_DASHBOARD,
    payload: post(constants.URLS.PINCODE_LOGIN_AT_DASHBOARD,data),
  }
}

export function postImageAuthenticationByFace(data) {
  return {
    type: IMAGE_AUTHENTICATION_BY_FACE,
    payload: post(constants.URLS.IMAGE_AUTHENTICATION_BY_FACE, data),
  };
}

export function postSupervisorPassword(data) {
  const singleton = new Singleton();
  const config = {
    headers: {
      'Authorization': `Token ${singleton.authorization}`
    }
  };
  return {
    type: POST_SUPERVISOR,
    payload: post(constants.URLS.VERIFY_SUPERVISOR, data, config),
  };
}

export function resetFacialPassword(data) {
  return {
    type: RESET_FACIAL_PASSWORD,
    payload: post(constants.URLS.RESET_FACIAL_PASSWORD, data),
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function getExtraData() {
  return {
    type: GET_EXTRA_DATA,
    payload: fetch(constants.URLS.EXTRA_DATA),
  };
}

export function putLanguagePreference(data) {
  return {
    type: PUT_LANGUAGE_PREFERENCE,
    payload: put(`${constants.URLS.USER_PREFERENCE}?preference_type=${data.preference_type}`, {...data}),
  };
}

export function getLanguagePreference(params) {
  return {
    type: GET_LANGUAGE_PREFERENCE,
    payload: fetch(constants.URLS.USER_PREFERENCE, { params }),
  };
}

export function resetUserPreferedLanguage(){
  return {
    type: RESET_USER_PREFERED_LANGUAGE,
  };
}

// state name - <NOUN>
const defaultState = {
  authorization: null,
  extra_data: {},
  userPreferenceLanguage:null,
};

// Reducers - <NOUN>_<VERB>_REDUCER
function LOGIN_FULFILLED_REDUCER(state, action) {
  const singleton = new Singleton();
  singleton.authorization = action.payload.auth_token;
  if(action.payload.auth_token){
    cookies.set('authToken', action.payload.auth_token, { path: '/' });
  }
  return merge(state, {
    authorization: action.payload.auth_token,
  });
}

function REGISTER_FULFILLED_REDUCER(state, action) {
  return merge(state, {
    authorization: action.payload.auth_token,
  });
}

function AUTHENTICATION_FULFILLED_REDUCER(state, action) {
  const singleton = new Singleton();
  singleton.employeeAuth = action.payload.auth_token;
  cookies.set('employeeToken', action.payload.auth_token, { path: '/' });
  return merge(state, {
    authorization: action.payload.auth_token,
  });
}

function LOGOUT_FULFILLED_REDUCER(state, action) {
  cookies.remove('employeeToken', {  path: '/' });
  cookies.remove('job_id', { path: '/' });
  cookies.remove('responseId', { path: '/' });
  StorageGateway.remove('imageUrl');

  return Object.assign({}, state, defaultState);
}

function IMAGE_AUTHENTICATION_BY_FACE_FULFILLED_REDUCER(state, action) {
  const singleton = new Singleton();
  singleton.employeeAuth = action.payload.auth_token;
  cookies.set('employeeToken', action.payload.auth_token, { path: '/' });
  return merge(state, {
    authorization: action.payload.auth_token,
  });
}

function GET_EXTRA_DATA_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    extra_data: action.payload,
  });
}

function RESET_FACIAL_PASSWORD_FULFILLED_REDUCER(state, action) {
  return merge(state, defaultState);
}

function POST_SUPERVISOR_FULFILLED_REDUCER(state, action) {
  return merge(state, defaultState);
}

function GET_LANGUAGE_PREFERENCE_FULFILLED_REDUCER(state,action){
  let cookieKey = cookies.get('userLanguagePreferenceCookie', { path: '/' });
  let preferenceLanguage = action.payload.data && action.payload.data.language ? action.payload.data.language : cookieKey ? cookieKey : 'english';
  return Object.assign({}, state, {
    userPreferenceLanguage: preferenceLanguage,
  });  
}

function RESET_USER_PREFERED_LANGUAGE_REDUCER(state, action) {
  return Object.assign({}, state, {
    userPreferenceLanguage: null,
  });
}

const handlers = {
  [LOGIN_FULFILLED]: LOGIN_FULFILLED_REDUCER,
  [REGISTER_FULFILLED]: REGISTER_FULFILLED_REDUCER,
  [AUTHENTICATION_FULFILLED]: AUTHENTICATION_FULFILLED_REDUCER,
  [IMAGE_AUTHENTICATION_AT_DASHBOARD_FULFILLED]: AUTHENTICATION_FULFILLED_REDUCER,
  [PINCODE_LOGIN_AT_DASHBOARD_FULFILLED]:AUTHENTICATION_FULFILLED_REDUCER,
  [IMAGE_AUTHENTICATION_BY_FACE_FULFILLED]: IMAGE_AUTHENTICATION_BY_FACE_FULFILLED_REDUCER,
  [GET_EXTRA_DATA_FULFILLED]: GET_EXTRA_DATA_FULFILLED_REDUCER,
  [POST_SUPERVISOR_FULFILLED]: POST_SUPERVISOR_FULFILLED_REDUCER,
  [RESET_FACIAL_PASSWORD_FULFILLED]: RESET_FACIAL_PASSWORD_FULFILLED_REDUCER,
  [LOGOUT_FULFILLED]: LOGOUT_FULFILLED_REDUCER,
  [GET_LANGUAGE_PREFERENCE_FULFILLED] : GET_LANGUAGE_PREFERENCE_FULFILLED_REDUCER,
  [RESET_USER_PREFERED_LANGUAGE] : RESET_USER_PREFERED_LANGUAGE_REDUCER,
};


// Selectors
const userSelector = state => state.accounts.user;


export const userExtraData = createSelector(
  userSelector,
  instance => instance.extra_data,
);

export const isLoggedIn = createSelector(
  userSelector,
  instance => instance.authorization,
);

export const userPreferenceLanguage = createSelector(
  userSelector,
  instance => instance.userPreferenceLanguage,
);

// export reducers as default
export default createReducer(defaultState, handlers);
