import React from 'react';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import TRMSGS from 'constants/Translations/Localization';

import Modal from 'react-bootstrap-modal';


const MealBreakModalComponent = (props) => {
  const { isOpen, toggle, mealEnd, isResumeButtonLoading, isSwitchButtonLoading, resumeSwitchDisabled , switchJob} = props;

  return (
    <Modal
      show={isOpen}
      aria-labelledby="ModalHeader"
      onHide={() => toggle()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <div className="f4 ba0"> {TRMSGS.resumeLastJobOrSwitchToNewOne} </div>
      </Modal.Header>
      <Modal.Footer className="cf flex justify-around custom-button resume-switch-button">
        <button
          className="br2 pointer fr w-20 pv3 mr2 white b btn-color f4"
          disabled={resumeSwitchDisabled}
          onClick={() => mealEnd('resume')}
          style={{width: 'auto'}}
        >
          {TRMSGS.resume}  <ButtonLoaderAtom active={isResumeButtonLoading} />
        </button>
        <button
          className="br2 pointer fr w-20 pv3 mr2 white b btn-color f4"
          disabled={resumeSwitchDisabled}
          onClick={() => switchJob('switch')}
          style={{width: 'auto'}}
          >
          {TRMSGS.switch} <ButtonLoaderAtom active={isSwitchButtonLoading} />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MealBreakModalComponent;
