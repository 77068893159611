import React from 'react';

import SideNavItemComponent from 'components/common/SideNavItem';

const SideNavSection = (props) => (
  <section>
    {props.nav.map((item) => <SideNavItemComponent key={item.id} item={item} />)}
  </section>
);

SideNavSection.defaultProps = {
  nav: [
    {
      id: 1,
      label: 'Overview',
      name: 'dashboard',
      to: '/dashboard',
      children: [],
    },
    {
      id: 2,
      label: 'Dummy Listing',
      name: 'listing',
      to: '/listing',
      children: [],
    },
  ],
};

export default SideNavSection;
