import React from 'react';

import PropTypes from 'prop-types';

import TimerService from 'lib/TimerService';


class MealTimerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.mealPeriodTime = props.mealActivity.start_datetime;
    const timerService = new TimerService();
    this.state = {
      duration: timerService.timeSince(this.mealPeriodTime),
    };
  }

  componentDidMount() {
    const timerService = new TimerService();
    this._interval = setInterval(() => {
      this.setState({
        duration: timerService.timeSince(this.mealPeriodTime),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    return (
      <section>
        <div>
          {this.state.duration} <br/>
        </div>
      </section>
    );
  }
}

MealTimerComponent.propTypes = {
  mealActivity: PropTypes.object,
};

MealTimerComponent.contextTypes = {};

export default MealTimerComponent;
