import React from 'react';


import Keyboard, {KeyboardButton, LatinLayout} from 'react-screen-keyboard';

import Modal from 'react-bootstrap-modal';

import {ReactComponent as KeyBoardIcon} from './keyboard.svg';
import TRMSGS from 'constants/Translations/Localization';

class KeyboardTextAreaAtom extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { value:''};
    this.update = this.update.bind(this);
  }


  update() {
    const { submit, toggle } = this.props;
    const { value } = this.state;

    if (submit) {
      if (value) return submit({employee: value});
    }
    toggle();
  }

  render() {
    const {
      input,
      isOpen,
      label,
      toggle,
      inputType,
      toggleInputType,
      meta: { touched, error }, 
      ...rest 
    } = this.props;

    return (
      <div className="mw9">
        <div className="cf">
          <div className='w-100 relative'>
            <textarea
              {...input}
              {...rest}
              onInput={e => {
                this.setState({ value: e.target.value });
              }}
              className='pa3 w-100 ba b--black-20 br2 pr4'
              value={ this.state.value }
              ref={input => { this.inputNode = input;}}
              maxlength="500"
              rows={this.props.rows}
            />
            <KeyBoardIcon
              height="26"
              width="30"
              className="text-keyboard-icon"
              onClick={() => { toggle();}}
            />
            {isOpen &&
              <Modal
                show={isOpen}
                aria-labelledby="ModalHeader"
                onHide={() => toggle()}
                className="keyboard-modal"
              >
                <Modal.Header  className="keyboard-modal-header" closeButton>
                </Modal.Header>
                <Modal.Body className="keyboard-modal-body">
                  <Keyboard
                    inputNode={this.inputNode}
                    leftButtons={[
                      <KeyboardButton
                        onClick={() => { this.setState({ value: ''}); }}
                        value="clear"
                      />
                    ]}
                    rightButtons={[
                      <KeyboardButton
                        onClick={() => this.update()}
                        value="Submit"
                        classes="keyboard-submit-button"
                      />
                    ]}
                    layouts={[LatinLayout]}
                  />
                </Modal.Body>
              </Modal>
            }
          </div>
          <div className="f6 fr gray">({TRMSGS.maxCharacters})</div>
          <small className="dark-red db h1 mt1 mb1">{touched && error}</small>
        </div>
      </div>
    );
  }
}

export default KeyboardTextAreaAtom;
