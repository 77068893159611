import React from 'react';

import PropTypes from 'prop-types';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import queryString from 'query-string';

import Webcam from 'react-webcam';
import styles from './styles.module.scss';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import failedLogin from 'images/FailedLogin.png';
import { ReactComponent as NameIcon } from './SearchByName.svg';
import TRMSGS from 'constants/Translations/Localization';

class BiometricFaceSearchComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.imageLogin = this.imageLogin.bind(this);
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.stream = null;
    // this.loadDataHandler = this.loadDataHandler.bind(this);
    this.webcam = null;
    this.state = {
      inputType: 'password',
      isOpenKeyboard: false,
      screenshot: null,
      cameraEnable: true,
      imageSubmitting: false,
      imageLoginErrorMessage: '',
      modalTitle: 'Please align your face with the camera to login',
      cameraPermissionText: '',
      cameraConnected: false,
      loginAttempt : 0
    };
  }


  componentDidMount() {
    if (!window && !window.navigator) {
      return;
    }
    const supported = 'mediaDevices' in window.navigator;
    if (!supported) {
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if(isMacLike){
          this.setState({cameraPermissionText: 'MACLIKEDEVICE'});
      }
      else{
        this.setState({cameraPermissionText: 'UPDATE'});
      }
    }
    const constraints = {video: true };
    if (navigator.mediaDevices !== undefined) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this.stream = stream;
          this.setState({cameraConnected: true});
        })
        .catch(() => {
          this.setState({cameraPermissionText: 'PERMISSION'});
          this.setState({cameraConnected: false});
        });
    }
  }

  componentWillUnmount() {
    this.webcam = null;
    this.setState({cameraEnable : false, cameraConnected : false})
    this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
  }


  imageLogin() {
    const { employeeImageLogin, setImageLoginError, location } = this.props;
    const { query, pathname } = location;
    const { loginAttempt } = this.state;
    const { router: { history } } = this.context;
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ screenshot: imageSrc, imageSubmitting: true });
    if (!imageSrc) {
      this.setState({ cameraEnable: false });
      return;
    }
    return employeeImageLogin(imageSrc)
      .catch(err => {
        let errMsg;
        history.push({ pathname, search: queryString.stringify({...query, login_attempt: +(loginAttempt) + 1}) });
        this.setState({loginAttempt :  +(loginAttempt) + 1})
        if (err.non_field_errors) {
          errMsg = err.non_field_errors;
          setImageLoginError(errMsg, true);
        } else if (err.image) {
          errMsg = err.image;
          if(!errMsg[0].indexOf('ERRORCODE_61404')) {
          setImageLoginError(errMsg[0], false);
          } else {
          setImageLoginError(errMsg[0], true);
          }
        } else {
          errMsg = err.username;
          setImageLoginError(errMsg[0], true);
        }
        this.setState({
          imageSubmitting: false,
        });
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard: !isOpenKeyboard });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  // loadDataHandler(){
  //   const { webcam, count } = this.state;
  //   setTimeout(() => {
  //     this.setState({webcam: true});
  //   }, 2500);
  // }

  render() {
    const { handleSubmit, error, webcam, loadDataHandler , location, userPreferenceLanguage } = this.props;
    const { cameraEnable,
      imageSubmitting , cameraConnected } = this.state;
    const { query, pathname } = location;
    return (
      <div className={`${styles['biometric']} m-auto`}>
        <form onSubmit={handleSubmit(this.imageLogin)}>
          <div className="flex mb4 pt1">

            <div className={!cameraEnable ? 'pr2' : null} style={{ flex: '1' , textAlign : 'center'}}>

              {cameraConnected && cameraEnable && <Webcam
                audio={false}
                width='100%'
                height='auto'
                screenshotFormat="image/jpeg"
                className="ba b--white bw2 custom-width-video"
                ref={(webcam) => { this.webcam = webcam;}}
                onLoadedData={loadDataHandler()}
                onUserMediaError={(err)=>console.log(err,'media error')}
                muted
              />}

              {/*{this.state.screenshot` && !cameraEnable ? <img width="100%" height="auto" src={require('images/no-camera.png')} /> : null}*/}

              <div className="pa1"></div>
              {cameraEnable && cameraConnected && webcam && <div className="pt2">
                <button
                  type="submit"
                  className="br2 pointer ph4 white fw4 btn-color f3 pv3 w-100">
                  {TRMSGS.login} <ButtonLoaderAtom active={imageSubmitting} />
                </button>
              </div>}
              {error ?
                <div className="w-100 login-error mt2 br2">
                  <span><img className={`${styles['loginFailed-image']}`} src={failedLogin} /></span>
                  <span className="login-text mt2 mb2 fw4 tc">{error}</span>
                </div>
                :
                null
              }
              {query.login_attempt >= 2 && error ?
                  <div className="w-100 mt3">
                    <button className={`${styles['logout_right-button']} br2 pointer ph4 white fw4 btn-color f3 pv2 w-100`} onClick={()=>this.props.searchByFace(1)}>
                      <div className={userPreferenceLanguage === 'bilingual' ? `${styles['logout_right-button-div']} ${styles['logout_right-button-div-bilingual']}` :`${styles['logout_right-button-div']}`}>
                        <div style={{display:'flex'}}>
                          <span className={userPreferenceLanguage === 'bilingual' ? `${styles['logout_right-button-logo']} ${styles['logout_right-button-logo-bilingual']}` : `${styles['logout_right-button-logo']}`}><NameIcon width="35" height="35"/></span>
                          <span className={userPreferenceLanguage === 'bilingual' ? `${styles['logout_right-button-text']} ${styles['logout_right-button-bilingual-text']}` : userPreferenceLanguage=== 'spanish' ? `${styles['logout_right-button-text']} ${styles['logout_right-button-spanish-text']}` :`${styles['logout_right-button-text']}`}>{TRMSGS.searchName}</span>
                        </div>
                      </div>
                    </button>
                  </div>
                  :
                null
              }

            </div>
          </div>
        </form>
      </div>
    );
  }
}

BiometricFaceSearchComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  return errors;
};

BiometricFaceSearchComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  loadDataHandler: PropTypes.func.isRequired,
  web: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'BiometricFaceSearchComponent',
  validate,
})(BiometricFaceSearchComponent);
