import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';

import Modal from 'react-bootstrap-modal';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import {ReactComponent as CheckIcon} from 'images/check.svg';
import TRMSGS from 'constants/Translations/Localization';


class LanguageSettingModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.language=['english','spanish','bilingual'];
  }

  render() {
    const { isOpen, toggle, selectLanguage, languageType, languageSubmit, isLanguageButtonLoader } = this.props;
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
        className="language-custom-modal"
      >
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title id='ModalHeader' style={{fontWeight: '500'}}>{TRMSGS.language}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {TRMSGS.chooseLanguage}
          </div>
          <div className="language-setting-container mt2 mb5 ml3 mr3">
            {this.language.map((item)=>(
              <div className="language-setting-content pointer" onClick={()=>selectLanguage(item)}>
                {(item === 'english') ? `${TRMSGS.English}`: (item === 'spanish') ? `${TRMSGS.Spanish}`: (item === 'bilingual') ? `${TRMSGS.Bilingual}` : null}
                {languageType === item &&
                  <CheckIcon height="25" width="20" className="ml3 mt2 button-role checkicon-position" fill="#000" />
                }
              </div>))
            }
          </div>
          <div className="tr ml2 mr3">
            <button onClick={()=>languageSubmit()} className="br2 pointer pv2 pr4 pl4 white btn-color" style={{fontSize:'18px'}}>
              {TRMSGS.submit}  <ButtonLoaderAtom active={isLanguageButtonLoader} />
            </button>
           </div>
        </Modal.Body>
      </Modal>
    );
  }
}


export default LanguageSettingModalComponent;
