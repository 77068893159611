import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import ButtonGroupAtom from 'atoms/ButtonGroup';
import Cookies from 'universal-cookie';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as DepartmentDucks from 'ducks/departments/department';
import * as TeamDucks from 'ducks/teams/team';
import DropdownAtom from 'atoms/Dropdown';
import DashboardFilterFormComponent from 'components/generics/dashboard/List/FilterForm';
import CameraPermissionModalComponent from 'components/generics/dashboard/Form/CameraPermissionModal';
import { toTitleCase } from 'lib/utils';

import { ReactComponent as FacialRecognitionActiveIcon } from './active-by-face.svg';
import { ReactComponent as FacialRecognitionInactiveIcon } from './inactive-by-face.svg';
import { ReactComponent as NameActiveIcon } from './active-by-name.svg';
import { ReactComponent as NameInactiveIcon } from './inactive-by-name.svg';
import styles from './styles.module.scss';

import TRMSGS from 'constants/Translations/Localization';
/**
 * DashboardListPage -> DashboardFilterSection
 *
 * Components:
 *    - 
 *
 * State:
 *    - route params
 *
 */
const cookies = new Cookies();

class DashboardFilterSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.updateSelected = this.updateSelected.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.isCameraToggle = this.isCameraToggle.bind(this);
    this.onTeamSelect = this.onTeamSelect.bind(this);
    this.searchByFace = this.searchByFace.bind(this);
    this.stream = null;
    this.state = { isOpen: false, isModalOpen: false, selectedType: 'name', isCameraPermissionOpen:false, isCameraPermission: null};
  }

  componentWillMount(){
    const { location: { query }} = this.props;
    const searchPreference = cookies.get('search_pref_dashboard', { path: '/' });
    if(searchPreference){
      this.setState({selectedType: searchPreference});
    }
    this.props.TeamDucks.getTeams({ department_id: query.department_id, paginate: false });
  }

  componentWillUnmount() {
    this.webcam = null;
    this.setState({cameraEnable : false, cameraConnected : false})
    this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen : !isOpen});
  }

  isCameraToggle() {
    const { isCameraPermissionOpen } = this.state;
    this.setState({ isCameraPermissionOpen : !isCameraPermissionOpen});
  }

  // searchByFace(data){
  //   const { isModalOpen, selectedType } = this.state;
  //   this.setState({ isModalOpen: !isModalOpen, selectedType: data });
  // }

  searchByFace(val) {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    var isCamera = false;
    if (val === 1) {
      const { view, ...rest } = query;
      history.push({ pathname, search: queryString.stringify({ ...rest }) });
      this.setState({selectedType: 'name'})
      cookies.set('search_pref_dashboard','name', { path: '/' });
    } else {
      if (!window && !window.navigator) {
        return;
      }
      const supported =  !!(
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        );

      const constraints = {video: true };
      if (!supported) {
        var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        if(isMacLike){
          this.setState({isCameraPermissionOpen: true});
          this.setState({isCameraPermission: 'MACLIKEDEVICE'});
        }
        else{
          this.setState({isCameraPermissionOpen: true});
          this.setState({isCameraPermission: 'UPDATE'});
        }
      }
      if (navigator.mediaDevices !== undefined) {
        navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            this.stream = stream;
            this.setState({cameraConnected: true});
            isCamera = true;
            if(isCamera) {
              history.push({
                pathname, search: queryString.stringify({ ...query, view: 'face' }),
              });
              this.setState({selectedType: 'face'})
              cookies.set('search_pref_dashboard','face', { path: '/' });
            }
          })
          .catch(() => {
            this.setState({isCameraPermissionOpen: true});
            this.setState({isCameraPermission: 'PERMISSION'});
            this.setState({cameraConnected: false});
            isCamera = false;
          });
      }
    }
  }

  onDepartmentSelect(item) {
    let id = null;
    let nullDept = null;
    if (item.id === "true") {
      nullDept = true;
    } else {
      id = item.id;
    }  
    const { location: { pathname, query }} = this.props;
    const { router: { history } } = this.context;

    history.push({
      pathname,
      search: queryString.stringify({ department_id: id,null_department: nullDept?nullDept:undefined, team_id: '', name_start: query.name_start }),
    });
    this.props.TeamDucks.getTeams({ department_id: id, paginate: false });
  }

  onTeamSelect(item) {
    const { location: { pathname, query }} = this.props;
    const { router: { history } } = this.context;
    if(query){
      const { page, ...rest } = query;
      delete rest.page_size;

      history.push({
        pathname,
        search: queryString.stringify({ ...rest, team_id: item.id, name_start: query.name_start }),
      });
    }
    else {
      history.push({
        pathname,
        search: queryString.stringify({ team_id: item.id, name_start: query.name_start }),
      });
    }
  }

  updateSelected(data){
    const { isOpen } = this.state;
    const { location } = this.props;
    const { query, pathname } = location;
    const { router: { history } } = this.context;
    if(query){
      delete query.page;
    }
    if(!data.search){
      delete query.search;
      return history.push({
        pathname, search: queryString.stringify(query),
      });
    }
    history.push({
      pathname, search: queryString.stringify({ ...query, search: data.search }),
    });
    if (isOpen)this.toggle();
  }

  render() {
    const { isOpen , isCameraPermission, isCameraPermissionOpen, selectedType } = this.state;
    const { location, departmentList, teamList , userExtraData } = this.props;
    const { query } = location;
    if (selectedType === 'face') {
      return (
        <div></div>
      );
    }
    let departmentOptions = departmentList;
    let teamOptions = teamList && teamList.length ? [...teamList] : [];

    let selectedTeam;
    let selectedDepartment;

    departmentOptions = departmentList.length ? departmentList.map(item => ({
      id: item.id, name: toTitleCase(item.name),
    })) : [];
  
      departmentOptions.splice(0, 0, { id: '', name: `${TRMSGS.allDepartments}` });
      const nullDeptExists = departmentOptions.some(department => department.id === 0);
    
      if (nullDeptExists) {
        departmentOptions.pop();
        departmentOptions.splice(1, 0, { id: "true", name: "Null Department" });
      }
   
    teamOptions = teamList.length ? teamList.map(item => ({
      id: item.id, name: toTitleCase(item.name),
    })) : [];

      teamOptions.splice(0, 0, { id: '', name: `${TRMSGS.allTeams}` });

 
    if((query && query.team_id === '') || !(query && query.team_id)){
      selectedTeam = teamOptions && teamOptions.length && teamOptions[0];
    }

    if((((query && query.department_id === '') || !(query && query.department_id)) && !query.null_department)){
      selectedDepartment = departmentOptions && departmentOptions.length && departmentOptions[0];
    }

    if((query && query.null_department === "true")){
      selectedDepartment = departmentOptions && departmentOptions.length && departmentOptions[1];
    }

    if(query && query.team_id ){
      selectedTeam = teamOptions.filter((item) => parseInt(item.id) === parseInt(query.team_id))[0];
      if(!selectedTeam){
        selectedTeam = teamOptions[0];
      }
    }
    if(query && query.department_id){
      selectedDepartment = departmentOptions.filter((item) => parseInt(item.id) === parseInt(query.department_id))[0];
      if(!selectedDepartment){
        selectedDepartment = departmentOptions[0];
      }
    }

    return (
      <div className={`${styles['filter-border']}`}>
          
{/*          <div className="w-100 f4 fw4 pb1 tc">
          <div className="dib mt2">
            <ButtonGroupAtom
              options={[
                { value: 2, label: 'Use Camera', icon : <FacialRecognitionInactiveIcon height="40"/>},
                { value: 1, label: 'Search Name', icon : <NameActiveIcon height="40"/>},
              ]}
              onToggle={this.searchByFace}
              active={1}
            />
          </div>
        </div>*/}
        <div>
          { userExtraData.company_permissions && userExtraData.company_permissions.manage_organization ?
          <div className="cf justify-center">
            <div className="mt3 dib fl page-size department_button">
              <DropdownAtom
                items={departmentOptions}
                selectedItem={selectedDepartment}
                itemToString={i => i && i.name}
                onChange={this.onDepartmentSelect}
              />
            </div>
            <div className="mt3 dib fl page-size team_button ml3">
              <DropdownAtom
                items={teamOptions}
                selectedItem={selectedTeam}
                itemToString={i => i && i.name}
                onChange={this.onTeamSelect}
              />
            </div>
          </div> : null}
        </div>
        <div>
          <DashboardFilterFormComponent
            updateSelected={this.updateSelected}
            toggle={this.toggle}
            location={location}
            isOpen={isOpen}
            initialValues={query}
            userPreferenceLanguage={this.props.userPreferenceLanguage}
          />
          {isCameraPermissionOpen && <CameraPermissionModalComponent
            isOpen={isCameraPermissionOpen}
            cameraPermissionText={isCameraPermission}
            toggle={this.isCameraToggle}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  departmentList: DepartmentDucks.departmentList(state),
  teamList: TeamDucks.teamList(state),
  userExtraData: UserDucks.userExtraData(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});


DashboardFilterSection.propTypes = {
  departmentList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DashboardFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardFilterSection);
