import { createSelector } from 'reselect';

import { fetch } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const GET_ALL_PAYROLLS = 'payrolls/payroll/GET_ALL_PAYROLLS';
const GET_ALL_PAYROLLS_FULFILLED = 'payrolls/payroll/GET_ALL_PAYROLLS_FULFILLED';
const GET_PAYROLLS_OF_DEPARTMENT = 'payrolls/payroll/GET_PAYROLLS_OF_DEPARTMENT';
const GET_PAYROLLS_OF_DEPARTMENT_FULFILLED = 'payrolls/payroll/GET_PAYROLLS_OF_DEPARTMENT_FULFILLED';

export function getPayrollsOfDepartment(params) {
  return {
    type: GET_PAYROLLS_OF_DEPARTMENT,
    payload: fetch(constants.URLS.DEPARTMENT_PAYROLL, { params }),
  };
}

export function getAllPayrolls(params) {
  return {
    type: GET_ALL_PAYROLLS,
    payload: fetch(constants.URLS.PAYROLL, { params }),
  };
}

const defaultState = {
  list: {},
};

function GET_ALL_PAYROLLS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_PAYROLLS_OF_DEPARTMENT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}



const handlers = {
  [GET_ALL_PAYROLLS_FULFILLED]: GET_ALL_PAYROLLS_FULFILLED_REDUCER,
  [GET_PAYROLLS_OF_DEPARTMENT_FULFILLED]: GET_PAYROLLS_OF_DEPARTMENT_FULFILLED_REDUCER,
};


const payrollSelector = state => state.payrolls.payroll;

export const payrollList = createSelector(
  payrollSelector,
  instance => instance.list,
);

export default createReducer(defaultState, handlers);
