import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import StorageGateway from 'lib/storage-gateway';
import ReactTooltip from "react-tooltip";

import Singleton from 'lib/singleton';

import * as UserDucks from 'ducks/accounts/user';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as BasicSettingsDucks from 'ducks/basicSettings/basicSettings';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as JobDucks from 'ducks/jobs/jobs';

import moment from 'moment';
import styles from './styles.module.scss';

import TRMSGS from 'constants/Translations/Localization';

const cookies = new Cookies();

class HeaderSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.logout = this.logout.bind(this);
    this.setTimer = this.setTimer.bind(this);
    this.state = { isOpen: false };
    this.employeeLogout = this.employeeLogout.bind(this);
    this.state = {
      currentDateTime: '',
      timeFormat: '',
    };
  }

  componentDidMount() {
    this.singleton = new Singleton();
    if (this.singleton.authorization) {
      this.props.ProfileDucks.getProfile();
    }
    this.props.BasicSettingsDucks.getBasicSetting()
      .then(() => this.setTimer());
  }

  setTimer() {
    const { dateFormat, timeFormat } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        currentDateTime: moment().format(dateFormat),
        timeFormat: moment().format(timeFormat),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  employeeLogout() {
    const { router: { history } } = this.context;
    this.singleton.employeeAuth = null;
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    this.props.UserDucks.resetUserPreferedLanguage();
    cookies.remove('employeeToken', { path: '/' });
    cookies.remove('job_id', { path: '/' });
    cookies.remove('responseId', { path: '/' });
    cookies.remove('userLanguagePreferenceCookie', { path: '/' });
    cookies.remove('documentId', { path: '/' });
    cookies.remove('loginType' ,{path:'/'});
    this.singleton.language = null;
    StorageGateway.remove('imageUrl');
    clearTimeout(this._timeOut);
    history.push('/dashboard');
  }

  logout(data) {
    const { router: { history } } = this.context;
    return this.props.UserDucks.postLogoutUser(data)
      .then(() => {
        this.singleton.authorization = null;
        this.props.TaskDucks.resetTasks();
        this.props.TaskDucks.resetAllTasks();
        this.props.JobDucks.resetJobs();
        this.props.JobDucks.resetJobLabel();
        this.props.ProfileDucks.resetFaceDetails();
        cookies.remove('authToken', { path: '/' });
        cookies.remove('job_id', { path: '/' });
        cookies.remove('responseId', { path: '/' });
        StorageGateway.remove('imageUrl');
        cookies.remove('documentId', { path: '/' });
        history.push('/');
      });
  }

  render() {
    const { showResetLink , firstName , lastName, userPreferenceLanguage, showPincodeReset} = this.props;
    return (
      <div>
          {((this.singleton.employeeAuth && this.props.showReset && (showResetLink||showPincodeReset)) || (this.singleton.employeeAuth && !this.props.hideLogout) || this.props.heading) &&
          <div className="border-box tc" style={{ minHeight: '34px', position: 'relative', backgroundColor:'#09819A' }}>
            {this.singleton.employeeAuth && this.props.showReset && (showResetLink||showPincodeReset) &&
              <div>
                <div className = {userPreferenceLanguage != 'english' ? `${styles['logout_left']} ${styles['facial-container']} fl` : `${styles['logout_left']} fl`}>
                  <p  data-tip data-for="resetFace" className= {userPreferenceLanguage != 'english' ? `${styles['facial-text']} pv2 mv0 mr2 ml1` : "pv2 mv0 mr2 ml1" } onClick={this.props.togglePasswordModal}><span className={userPreferenceLanguage === 'bilingual' ? `${styles['logout_link']} ${styles['logout_link_responsive']}` : styles['logout_link']}>{showPincodeReset?TRMSGS.resetPincode:TRMSGS.resetFacialRecognition}</span></p>
                </div>
                <ReactTooltip id="resetFace" place="top" effect="solid">
                {showPincodeReset?TRMSGS.resetPincode:TRMSGS.resetFacialRecognition}
                </ReactTooltip>
              </div>
            }
            {this.singleton.employeeAuth && !this.props.hideLogout && 
              <div>
                <div data-tip data-for="logout" className = {`${styles['logout_right']} fr`}>
                  <p className="pv2 mv0 mr2" onClick={this.employeeLogout}><span className={this.props.showReset && (showResetLink||showPincodeReset)&& this.props.heading && userPreferenceLanguage === 'bilingual' ? `${styles['logout_link']} ${styles['logout_link_responsive']}` : styles['logout_link']}>{TRMSGS.logout}</span></p>
                </div>
                <ReactTooltip id="logout" place="top" effect="solid">
                    {TRMSGS.logout}
                </ReactTooltip>
              </div>
            }
            {this.props.heading && 
              <div>
                <div data-tip data-for="heading" className={this.singleton.employeeAuth && this.props.showReset && (showResetLink||showPincodeReset) && userPreferenceLanguage === 'bilingual' ? `${styles['heading-container']} tc gray dib center heading-padding` : userPreferenceLanguage==='bilingual' && (!showResetLink||!showPincodeReset) ? `${styles['heading-container-alignment']} gray dib` : "tc gray dib center"} style={{ fontSize: '17px', color:'white'}}>
                  <p className= {userPreferenceLanguage === 'bilingual' ? `${styles['heading-text']} white center pv2 mv0 mr2` : userPreferenceLanguage === 'spanish' && (showResetLink||showPincodeReset) ? `${styles['heading-text-padding']} white center pv2 mv0 mr2` :"white center pv2 mv0 mr2" }>{this.props.heading}</p>
                </div>
                <ReactTooltip id="heading" place="top" effect="solid">
                  {this.props.heading}
                </ReactTooltip>
              </div>
            }
          </div>
          }
          {firstName &&
            <div className="border-box tc" style={{ minHeight: '34px' ,backgroundColor:'#E2ECF1'}}>
              <div className=" pv2 tc gray dib" style={{ fontSize: '18px',color:'#09819A'}}>
                <span>{firstName}&nbsp;{lastName}</span>
              </div>
            </div>            
          }
          </div>
    );
  }
}

const mapStateToProps = state => ({
  dateFormat: BasicSettingsDucks.dateFormat(state),
  timeFormat: BasicSettingsDucks.timeFormat(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  userCompany: ProfileDucks.userCompany(state),
  basicSettingDetail: BasicSettingsDucks.basicSettingDetail(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  BasicSettingsDucks: bindActionCreators(BasicSettingsDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

HeaderSection.propTypes = {
  userCompany: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

HeaderSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(HeaderSection);
