import React from 'react';
import WelcomeListComponent from 'components/activities/List/Welcome.jsx';
import Singleton from 'lib/singleton';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as SessionDucks from 'ducks/activities/session';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as UserDucks from 'ducks/accounts/user';

import UnscheduledShiftConfirmationComponent from 'components/activities/List/unscheduledShiftConfirmation'


const cookies = new Cookies();

class WelcomeListSection extends React.Component {

  constructor(props) {
    super(props);
    this.punchIn = this.punchIn.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.toggleConfirmationPopup= this.toggleConfirmationPopup.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
    this.state = { isButtonLoading: false, punchInDisabled: false , isOpen : false, commentButtonLoading: false};
  }

  toggleConfirmationPopup(){
    const { isOpen } = this.state;
    this.setState({isOpen : !isOpen });
  }

  saveMessage(data){
    const {commentButtonLoading} = this.state;
    this.setState({commentButtonLoading:true});
    this.punchIn(data.message);
  }

  punchIn(message) {
    const { profile, taskList, jobsList } = this.props;
    const { router: { history } } = this.context;
    const mode = cookies.get('loginMode', { path: '/' });
    const loginType =cookies.get('loginType', { path: '/' });
    
    const imageDocumentId = cookies.get('documentId', { path: '/' });
    if(!message){
      this.setState({isButtonLoading : true, punchInDisabled : true});
    }
    let emptyActivityData = {
      employee_id : profile.employee_id,
      data : []
    };
    let punchInData = {
      message: message && message,
      image_doc_id : imageDocumentId && imageDocumentId
    }
    this.props.SessionDucks.saveActivityData(emptyActivityData);
    if(loginType==='Face')
    {
      this.props.SessionDucks.saveActivityLog({activity_type:'punch_in'})
    }
    this.props.SessionDucks.postPunchInSession(punchInData)
    .then((res) => {
      if(res.value.punchin_restriction == true){
        return this.setState({ isOpen : true , isButtonLoading : false, punchInDisabled : false});
      }
      this.props.SessionDucks.getCurrentSession()
      .then((currentResponse) => {
        let newActivityData = [];
        let activityDetail = [];
        const currentActivity = currentResponse.value.current_activity[0];
        let startDate = currentActivity.start_datetime;
        let newDate = new Date(startDate);
        newDate.setSeconds(0,0);
        newDate.setMilliseconds(0);
        newDate.toISOString();
        // if job == 1, push newActivityData
        // if task == 1, push newActivityData
        if ((jobsList && jobsList.length === 1) && (taskList && taskList.length ===1)) {
          newActivityData.push({
            job_id : jobsList[0].id,
            task_id : taskList[0].id,
            start_datetime : newDate,
            activity_id : res.value.activity_id,
            task_name: taskList[0].name,
            job_name : jobsList[0].name,
            session_id :  currentResponse.value.session_id,
            sess_id: currentResponse.value.id
          });
          activityDetail ={
            employee_id : profile.employee_id,
            data : newActivityData
          };
          this.props.SessionDucks.saveActivityData(activityDetail);
        } else {
          newActivityData.push({
            activity_name : 'punch_in',
            start_datetime : newDate,
            activity_id : res.value.activity_id,
            session_id : currentResponse.value.session_id,
            sess_id: currentResponse.value.id
          });
          activityDetail ={
            employee_id : this.props.profile.employee_id,
            data : newActivityData
          };
          this.props.SessionDucks.saveActivityData(activityDetail);
        }
        this.setState({commentButtonLoading:false})
        if(mode == 'quick'){
          this.setState({isButtonLoading : false});
          history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchin'}) });
        }
        else{
          this.setState({isButtonLoading : false});
          history.push('/jobs/shift');
        }
      });
    })
    .catch((e) => {
      e.non_field_errors && e.non_field_errors.length && toast.error(e.non_field_errors[0]);
      this.setState({isButtonLoading : false, punchInDisabled : false, commentButtonLoading:false});
    });
  }

  selectJob(){
    this.setState({isButtonLoading : true, punchInDisabled : true});
    const { profile } = this.props;
    this.props.JobDucks.getJobs({employee_id : profile.employee_id, paginate: false});
    this.setState({isButtonLoading : false});
    const { router: { history } } = this.context;
    history.push('/jobs/list');
  }

  selectTask(){
    const { profile } = this.props;
    const singleton = new Singleton();
    singleton.job_id = cookies.get('job_id', { path: '/' });
    this.setState({isButtonLoading : true, punchInDisabled : true });
    this.props.SessionDucks.getActivityData(profile.employee_id);
    this.props.TaskDucks.getTasks({job_id : this.props.job_id ? this.props.job_id : singleton.job_id, paginate: false})
    .then(() => {
      this.setState({isButtonLoading : false});
      const { router: { history } } = this.context;
      history.push('/tasks/list');
    })
    .catch(() => {
      this.setState({isButtonLoading : false});
    });
  }


  render() {
    const { isButtonLoading, punchInDisabled , isOpen, commentButtonLoading } = this.state;
    return (
      <div>
        <WelcomeListComponent
          showButton={this.props.showButton}
          punchIn={this.punchIn}
          selectTask = {this.selectTask}
          selectJob={this.selectJob}
          isButtonLoading={isButtonLoading}
          punchInDisabled = {punchInDisabled}
          profile = {this.props.profile}
          userPreferenceLanguage = {this.props.userPreferenceLanguage}
        />
        {
          isOpen ?
          <UnscheduledShiftConfirmationComponent
            isOpen={isOpen}
            toggle={this.toggleConfirmationPopup}
            saveMessage={this.saveMessage}
            commentButtonLoading={commentButtonLoading}
          />
          :
          null
        }  
      </div>   
    );
  }
}

const mapStateToProps = state => ({
  profile: ProfileDucks.profile(state),
  sessionDetail: SessionDucks.sessionDetail(state),
  punchInDetail: SessionDucks.punchInDetail(state),
  mealStartDetail: SessionDucks.mealStartDetail(state),
  companyDetail: EmployeeDucks.companyDetail(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
});

WelcomeListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WelcomeListSection);
