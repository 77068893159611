import React from 'react';
import * as serviceWorker from './serviceWorker';
import createHistory from 'history/createBrowserHistory';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import Cookies from 'universal-cookie';

import Singleton from 'lib/singleton';
import App from 'pages/App';
import configureStore from 'store';
import 'images/favicon.png';

import 'css/index.css';

const cookies = new Cookies();
const history = createHistory();
/* Images
 * This space is reserved for images that are required by server rendering,
 * e.g. the favicon and any other images that need to be in the base HTML file.
 */


// Creates the Redux store based on the initial state passed down by the server
// rendering.
const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState, history);

const singleton = new Singleton();
singleton.authorization = cookies.get('authToken', { path: '/' });
singleton.employeeAuth = cookies.get('employeeToken', { path: '/' });

ReactDOM.render(
	<Provider store={store}>
	    <ConnectedRouter history={history}>
	      <App />
	    </ConnectedRouter>
	</Provider>,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();