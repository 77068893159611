import React from "react";

import PropTypes from "prop-types";

import Modal from "react-bootstrap-modal";
import queryString from "query-string";
import TRMSGS from "constants/Translations/Localization";
import PincodeForm from "components/common/PincodeForm";

/**
 * DashboardListPage -> DashboardListSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class PincodeLoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.passwordSubmit = this.passwordSubmit.bind(this);
    this.state = {
      pincodeSubmitting: false,
      error: null,
    };
  }

  passwordSubmit(data) {
    const {
      employeePincodeLogin,
      employeeDetail,
      disableLoader,
      updateParentState,
      enablePincodeLogin,
    } = this.props;
    const detail = { pincode: data };

    if (employeeDetail) {
      detail.username = employeeDetail.user.username;
    }

    return employeePincodeLogin(detail)
  }

  render() {
    const { isOpen, toggle } = this.props;
    const qs = queryString.parse(this.props?.location?.search);
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
      >
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title id="ModalHeader" style={{ fontWeight: "500" }}>
            {TRMSGS.enterPincodeToLogin}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ml2 mr2">
          <PincodeForm employeePincodeLogin={this.passwordSubmit} query={qs} />
        </Modal.Body>
      </Modal>
    );
  }
}

PincodeLoginForm.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PincodeLoginForm;
