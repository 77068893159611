export function roundOffSeconds(actualSecs) {
  const remainder = actualSecs % 60;

  let minutes = Math.floor(actualSecs / 60);
  if (remainder > 30) minutes += 1;
  const roundOffSecs = Math.floor(minutes * 60);
  return roundOffSecs;
}

export function secondsToHHMM(seconds) {
  let hours = Math.floor(+seconds / 3600);
  let minutes = Math.floor((+seconds % 3600) / 60);
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
}

export function toTitleCase(name){
  return name[0].toUpperCase() +  name.slice(1);
}

export default {
  roundOffSeconds,
  secondsToHHMM,
  toTitleCase,
};