import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Modal from 'react-bootstrap-modal';

import InputAtom from 'atoms/Input';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import KeyboardInputAtom from 'atoms/KeyboardInput';

/**
 * DashboardListPage -> DashboardListSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class ActivationFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleUsernameKeyboard = this.toggleUsernameKeyboard.bind(this);
    this.toggleActivateKeyboard = this.toggleActivateKeyboard.bind(this);
    this.togglePasswordKeyboard = this.togglePasswordKeyboard.bind(this);
    this.toggleConfirmKeyboard = this.toggleConfirmKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = {
      inputType: 'password',
      isUsernameKeyboard: false,
      isActivationKeyboard: false,
      isPasswordKeyboard: false,
      isConfirmKeypassword: false,
    };
  }

  submit(data) {
    const detail = data;
    if(!detail.repeat_passsword) detail.repeat_password = detail.password;
    return this.props.activateEmployee(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  toggleUsernameKeyboard() {
    const { isUsernameKeyboard } = this.state;
    this.setState({ isUsernameKeyboard : !isUsernameKeyboard });
  }

  toggleActivateKeyboard() {
    const { isActivationKeyboard } = this.state;
    this.setState({ isActivationKeyboard : !isActivationKeyboard });
  }

  togglePasswordKeyboard() {
    const { isPasswordKeyboard } = this.state;
    this.setState({ isPasswordKeyboard : !isPasswordKeyboard });
  }

  toggleConfirmKeyboard() {
    const { isConfirmKeypassword } = this.state;
    this.setState({ isConfirmKeypassword : !isConfirmKeypassword });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }


  render() {
    const { handleSubmit, submitting, error, isOpen, toggle, btnText } = this.props;
    const {
      inputType,
      isActivationKeyboard,
      isPasswordKeyboard,
      isConfirmKeypassword,
    } = this.state;

    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        bacdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id='ModalHeader'>Activate Your Account </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Modal.Body>
            <div className="error-text tc">{error}</div>
            <label htmlFor="name" className="db mb1 mt1 ">Username</label>
            <div className="w-100">
              <Field
                component={InputAtom}
                name="username"
                placeholder="Enter Username"
                type="text"
                // toggle={this.toggleUsernameKeyboard}
                // isOpen={isUsernameKeyboard}
                className="ba b--black-20 w-10"
                // isModal
                disabled
              />
            </div>
            <label htmlFor="name" className="db mb1 mt1 ">Activation Code</label>
            <div className="w-100">
              <Field
                component={KeyboardInputAtom}
                name="activation_code"
                placeholder="Enter Activation Code"
                type="text"
                toggle={this.toggleActivateKeyboard}
                isOpen={isActivationKeyboard}
                className="ba b--black-20 w-10"
                isModal
                autoFocus
              />
            </div>
            <label htmlFor="name" className="db mb1 mt1">Password</label>
            <Field
              id="password"
              type={inputType}
              name="password"
              component={KeyboardInputAtom}
              toggle={this.togglePasswordKeyboard}
              isOpen={isPasswordKeyboard}
              inputType={inputType}
              placeholder="Password"
              className="ba b--black-20 w-40"
              toggleInputType={this.toggleInputType}
              eyeIcon
              isModal
              data-lpignore={true}
            />
            
            {inputType === 'password' &&
              (<div>
                <label htmlFor="name" className="db mb1 mt1 ">Confirm Password</label>
                <Field
                  component={KeyboardInputAtom}
                  name="repeat_password"
                  placeholder="Confirm Password"
                  type="password"
                  toggle={this.toggleConfirmKeyboard}
                  isOpen={isConfirmKeypassword}
                  isModal
                />
              </div>)
            }
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="br2 pointer pa3 white btn-color f3">
              {btnText} <ButtonLoaderAtom active={submitting} />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if(!values.username) errors.username = 'Enter Username';
  if(!values.activation_code) errors.activation_code = 'Enter Activation Code';
  if (!values.password) errors.password = 'Enter Password';
  if (values.password && values.password.length < 6) errors.password = 'Password should contain atleast 6 characters';
  if (values.password && values.password.length >= 6 && values.password && !values.password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\\d$@$!%*#?&]{6,123}$')) errors.password = 'Password should have at least 6 characters, 1 capital alphabet and 1 numeric character';
  if (!values.repeat_password) errors.repeat_password = 'Enter Confirm Password';
  if ((values.password !== values.repeat_password) &&
    (values.password && values.repeat_password)) {
    errors.repeat_password = 'Password mismatch';
  }
  return errors;
};

ActivationFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ActivationFormModal',
  validate,
  enableReinitialize: true  
})(ActivationFormModalComponent);
