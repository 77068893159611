import React from 'react';

import {ReactComponent as ClockIcon} from './clock.svg';

import TimeKeeperAtom from 'atoms/TimeKeeperAtom';
import TimeInputAtom from 'atoms/TimeInputAtom';

class TimePickerAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.closeTimePicker = this.closeTimePicker.bind(this);
    this.state = { showTimePicker : false , initialTime : props.values.end_date, initialDate: props.values.start_date};
  }

   handleDateChange(date) {
    this.setState({initialTime : date.formatted}); 
    const startNewDay = new Date(this.state.initialDate);
    startNewDay.setHours(date.hour24);
    startNewDay.setMinutes(date.minute);
    this.props.submitEditedTime(new Date(startNewDay));
  };

  toggleTimePicker(){
    const { showTimePicker} = this.state;
    const { previousJob , nextJob } = this.props;
    if(!((previousJob.activity_name && previousJob.activity_name === 'meal') || (nextJob.activity_name && nextJob.activity_name === 'meal'))){
      this.setState({showTimePicker : !showTimePicker});
    }
  }

  closeTimePicker(){
    const { showTimePicker } = this.state;
    this.setState({showTimePicker : !showTimePicker});
  }


  render() {
    const { input , label , previousJob , nextJob} = this.props;
    return (
      <div className ="pointer" style={{flex:'1'}}>
        {label && <label htmlFor="name" className="f6 b db mb2">{label}</label>}
          <TimeInputAtom 
            input={input}
            value={this.state.initialTime}
            previousJob={previousJob}
            nextJob={nextJob}
          />
        <div style={{height : '32px'}} className ="dib calendar-icon ba b--black-20" onClick={() => this.toggleTimePicker()}> 
          <ClockIcon width="16" height="16"/>
        </div>
        {this.state.showTimePicker &&
          <TimeKeeperAtom
            isOpen={this.state.showTimePicker}
            toggle={this.closeTimePicker}
            input={this.state.initialTime}
            updateTime={this.handleDateChange}
          />
        }
      </div>
    );
  }
}

export default TimePickerAtom;