import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';
import DailyTimecardFormComponent from 'components/timecards/Form/form';
import TRMSGS from 'constants/Translations/Localization';

class DailyTimecardFilterSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }


  render() {
    const { isLoading, location, profile, payrollList } = this.props;
    const {  query  } = location;
    let payrollOptions = [];

    if(payrollList.length){
      payrollOptions = payrollList.map(item =>  ({ id: item.id, name: `${item.start_date_display} to ${item.end_date_display}` }));
    }
    
    payrollOptions.unshift({id: 'custom', name: `${TRMSGS.customDateRange}`});

    if(isLoading)return <SectionLoaderReloadBtnAtom active showReloadButton={this.props.showReloadButton} reloadScreen={this.props.reloadScreen}/>;
    return (
      <div className="cf">
        <div className="pa2">
          <div className="collapse-div">
            <DailyTimecardFormComponent 
              profile={profile} 
              initialValues={query.start_date && query.end_date  ?
                { end_date: moment(query.end_date).toISOString(),
                  start_date: moment(query.start_date).toISOString()} : null}
              payrollList={payrollOptions}
              location={location}
              userPreferenceLanguage={this.props.userPreferenceLanguage}
              clickToReload={this.props.clickToReload}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dailyTimecardList: TimecardDucks.dailyTimecardList(state),
  profile:ProfileDucks.profile(state),
  payrollList: PayrollDucks.payrollList(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
});

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});


DailyTimecardFilterSection.propTypes = {
  dailyTimecardList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DailyTimecardFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DailyTimecardFilterSection);
