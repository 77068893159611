import { createSelector } from 'reselect';

import { fetch, post, put  } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const POST_PUNCH_IN_SESSION = 'activities/session/POST_PUNCH_IN_SESSION';
const POST_PUNCH_IN_SESSION_FULFILLED = 'activities/session/POST_PUNCH_IN_SESSION_FULFILLED';
const POST_MEAL_PERIOD_SESSION = 'activities/session/POST_MEAL_PERIOD_SESSION';
const POST_CONTINUE_SESSION = 'activities/session/POST_CONTINUE_SESSION';
const PUT_END_SESSION = 'activities/session/PUT_END_SESSION';
const GET_CURRENT_SESSION = 'activities/session/GET_CURRENT_SESSION';
const GET_CURRENT_SESSION_FULFILLED = 'activities/session/GET_CURRENT_SESSION_FULFILLED';
const POST_MESSAGE_SESSION = 'activities/session/POST_MESSAGE_SESSION';
const SAVE_ACTIVITY_DATA = 'activities/session/SAVE_ACTIVITY_DATA';
const SAVE_ACTIVITY_LOG = 'activities/session/SAVE_ACTIVITY_LOG';
const GET_ACTIVITY_DATA = 'activities/session/GET_ACTIVITY_DATA';
const GET_ACTIVITY_DATA_FULFILLED = 'activities/session/GET_ACTIVITY_DATA_FULFILLED';
const RESET_ACTIVITY_DATA = 'activities/activityTrack/RESET_ACTIVITY_DATA';

export function postPunchInSession(params) {
  return {
    type: POST_PUNCH_IN_SESSION,
    payload: post(constants.URLS.PUNCH_IN_SESSION, params),
  };
}

export function postMealPeriodSession(params) {
  return {
    type: POST_MEAL_PERIOD_SESSION,
    payload: post(constants.URLS.MEAL_PERIOD_SESSION, params),
  };
}

export function resetActivityData() {
  return {
    type: RESET_ACTIVITY_DATA,
  };
}

export function saveActivityData(activityDetail) {
  return {
    type: SAVE_ACTIVITY_DATA,
    payload: post(constants.URLS.ACTIVITY_DATA, {...activityDetail}),
  };
}

export function saveActivityLog(activityDetail) {
  return {
    type: SAVE_ACTIVITY_LOG,
    payload: post(constants.URLS.ACTIVITY_LOG, {...activityDetail}),
  };
}

export function getActivityData(params) {
  return {
    type: GET_ACTIVITY_DATA,
    payload: fetch(`${constants.URLS.ACTIVITY_DATA}?employee_id=${params}`),
  };
}

export function postContinueSession(params) {
  return {
    type: POST_CONTINUE_SESSION,
    payload: post(`${constants.URLS.CONTINUE_SESSION}${params.id}/`),
  };
}
export function putEndSession(parameters) {
  const { id } = parameters;
  return {
    type: PUT_END_SESSION,
    payload: put(`${constants.URLS.END_SESSION}${id}/`,{}, {
      params: {
        version: '2'
      }}),
  };
}

export function getCurrentSession(params) {
  return {
    type: GET_CURRENT_SESSION,
    payload: fetch(constants.URLS.CURRENT_SESSION, params),
  };
}

export function postMessageSession(data) {
  return {
    type: POST_MESSAGE_SESSION,
    payload: post(`${constants.URLS.MESSAGE_SESSION}/?session_id=${data.sessionId}`, data),
  };
}



const defaultState = {
  list: { results: [] },
  detail: {},
  activitiesData : {}
};

function GET_CURRENT_SESSION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
     detail: action.payload,
  });
}

function POST_PUNCH_IN_SESSION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    activityId: action.payload,
  });
}

function GET_ACTIVITY_DATA_FULFILLED_REDUCER(state, action) {
  const shiftData = (action && action.payload.results.length && action.payload.results[0].data && action.payload.results[0].data.length) ? action.payload.results[0].data : [];
  return Object.assign({}, state, {
    activitiesData: shiftData,
  });
}

function RESET_ACTIVITY_DATA_REDUCER(state, action) {
  return Object.assign({}, state, {
    activitiesData: {},
  });
}

const handlers = {
  [GET_CURRENT_SESSION_FULFILLED]: GET_CURRENT_SESSION_FULFILLED_REDUCER,
  [POST_PUNCH_IN_SESSION_FULFILLED]: POST_PUNCH_IN_SESSION_FULFILLED_REDUCER,
  [GET_ACTIVITY_DATA_FULFILLED] : GET_ACTIVITY_DATA_FULFILLED_REDUCER,
  [RESET_ACTIVITY_DATA]: RESET_ACTIVITY_DATA_REDUCER,
};


const sessionSelector = state => state.activities.session;

export const sessionDetail = createSelector(
  sessionSelector,
  instance => {
    return instance.detail;
  }
);

export const activityData = createSelector(
  sessionSelector,
  instance => {
    return instance.activitiesData;
  }
);

export const punchInDetail = createSelector(
  sessionDetail,
  instance => {
    if(!(instance.current_activity)) {
      return null;
    }
    if (instance.current_activity.length === 0) {
      return null;
    }
    return instance.detail;
  },
);

export const mealStartDetail = createSelector(
  sessionDetail,
  instance => {
    if(!(instance.current_activity)) {
      return null;
    }
    if (instance.current_activity.length < 2) {
      return null;
    }
    return instance.current_activity[1];
  },
);

export default createReducer(defaultState, handlers);
