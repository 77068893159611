import React from 'react';

import { Switch, Route } from 'react-router-dom';

import LoginPage from './Login';
import RegisterPage from './Register';

export const routes = [
  {
    component: LoginPage,
    exact: true,
    name: 'accounts.login',
    path: '/accounts/login',
  },
  {
    component: RegisterPage,
    exact: true,
    name: 'accounts.register',
    path: '/accounts/register',
  },
];

const AccountsRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default AccountsRoutes;
