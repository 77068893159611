import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

import * as RouteDucks from 'ducks/routes';
import * as SessionDucks from 'ducks/activities/session';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as EmployeeDucks from 'ducks/employees/employee';

import JobCommentsComponent from 'components/jobs/comments/jobComments';
import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class JobCommentsSection extends React.Component {
  constructor(props) {
    super(props);

    this.submit= this.submit.bind(this);
    this.state = { isButtonLoading: false, saveDisabled: false};
  }

  componentWillUnmount() {
    const { profile } = this.props;
    this.props.SessionDucks.getActivityData(profile.employee_id);
  }

	submit(data){
    const { companyDetail } = this.props;
    const forcePunchoutTime = companyDetail && companyDetail.last_punch_in && companyDetail.last_punch_in.end_datetime;
    const newData = data && data.message && (data.message).trim();
    const mode = cookies.get('loginMode', { path: '/' });
    if(!newData){
      return toast.error(`${TRMSGS.enterCommentToContinue}`);
    }
    if(newData && (newData).length > 500){
      return toast.error(`${TRMSGS.sessionMessageLength}`);
    }
    this.setState({isButtonLoading: true, saveDisabled: true});
    const {  activitySplitList , activityData , sessionDetail , profile} = this.props;
    const { router: { history } } = this.context;
     let activityId = [];
    activitySplitList.filter((item) => {
      if (item) {
        activityId.push(item.id);
      }
      return true;
    });
    let emptyActivityData = {
      employee_id : profile.employee_id,
      data : []
    };
    let activities =  [];
    activityData.forEach( function(item, index) {
       var forcepunchoutTime = moment.utc(forcePunchoutTime, 'YYYY-MM-DDTHH:mm:ss.000\\Z');
      if (!item.end_datetime) {
         const newItem = {
          ...item,
          end_datetime : forcepunchoutTime
        };
        activities.push(newItem);
      } else {
        activities.push(item);
      }
    });
    let session_id = activities && activities[0].session_id;
    const newActivityData = activities && activities.length && activities.filter(item => (item.job_id && item.task_id));
      this.props.SessionDucks.postMessageSession({message: newData, sessionId : session_id})
      .then(() => {
      if(newActivityData.length){
      this.props.ActivitySplitDucks.postActivitySplitList(newActivityData, activities[0].session_id)
      .then(() => {
        sessionDetail && sessionDetail.create_date !== null && this.props.ActivitySplitDucks.postActivitySignOff({split_ids: activityId});
        this.props.SessionDucks.saveActivityData(emptyActivityData);
        this.setState({isButtonLoading : false});
        this.props.SessionDucks.resetActivityData();
        if(mode == 'quick'){
          history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchout'}) });
        }
        else{
          history.push('/activity/list');
        }

      })        
      .catch(() => {
        this.setState({isButtonLoading : false});
       });
      } else {
          sessionDetail && sessionDetail.create_date !== null && this.props.ActivitySplitDucks.postActivitySignOff({split_ids: activityId});
          this.props.SessionDucks.saveActivityData(emptyActivityData);
          this.setState({isButtonLoading : false});
          this.props.SessionDucks.resetActivityData();
          if(mode == 'quick'){
            history.push({ pathname : '/success/' , search: queryString.stringify({name : profile && `${profile.first_name} ${profile.last_name}`, type: 'punchout'}) });
          }
          else{
            history.push('/activity/list');
          }

      }})
      .catch((err) => {
        toast.error(`${TRMSGS.sessionAlreadyEnded}`);
        history.push('/activity/list');
      });

	}

  render() {
    const { isButtonLoading, saveDisabled } = this.state;
    return (
      <JobCommentsComponent
				submit = {this.submit} 
				onSubmit={this.submit} 
        isButtonLoading = {isButtonLoading}
        saveDisabled={saveDisabled}
        profile = {this.props.profile}
      />
    );
  }
}

const mapStateToProps = state => {
  return({
    location: RouteDucks.location(state),
    profile: ProfileDucks.profile(state),
    activitySplitList: ActivitySplitDucks.activitySplitList(state),
    activityData : SessionDucks.activityData(state),
    sessionDetail: SessionDucks.sessionDetail(state),
    companyDetail: EmployeeDucks.companyDetail(state),
});
};

const mapActionsToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  ActivitySplitDucks : bindActionCreators(ActivitySplitDucks, dispatch),
});


JobCommentsSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobCommentsSection);

