import React from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import * as EmployeeDucks from "ducks/employees/employee";
import * as UserDucks from "ducks/accounts/user";

import HeaderSection from "sections/common/Header";
import TimeCardHeader from "sections/common/TimeCardHeader";
import FooterSection from "sections/common/Footer";
import PincodeForm from "components/common/PincodeForm";
import TRMSGS from "constants/Translations/Localization";

class ActivityPincodePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isBackIcon: true };
    this.goToUrl = this.goToUrl.bind(this);
    this.backButtonHandler = this.backButtonHandler.bind(this);
    this.employeePincodeLoginHandler =
      this.employeePincodeLoginHandler.bind(this);
  }

  backButtonHandler() {
    this.props.history.goBack();
  }

  goToUrl() {
    const {
      router: { history },
    } = this.context;
    history.push("/dashboard");
  }

  employeePincodeLoginHandler(pincode) {
    const {
      router: { history },
    } = this.context;
      const pk = this.props?.location?.state;
      return this.props.UserDucks.postPincodeAuthentication({
        pincode: pincode,
        pk: pk?.pk,
      }).then(() => history.push("/activity/list"));
   
  }

  render() {
    const qs = queryString.parse(this.props.location.search);
    {
      this.props.userPreferenceLanguage
        ? TRMSGS.setLanguage(this.props.userPreferenceLanguage)
        : TRMSGS.setLanguage("english");
    }
    return (
      <div className="relative">
        <div className="cf min-vh-100 pb4 dark-grey-bg relative">
          <div className="fl w-100">
            <HeaderSection isBackIcon="isBackIcon" goToUrl={this.goToUrl} />
          </div>
          <TimeCardHeader heading={TRMSGS.pincodeSetup} />
          <div className="fl w-100">
            <PincodeForm
              backButtonHandler={this.backButtonHandler}
              employeePincodeLogin={this.employeePincodeLoginHandler}
              query={qs}
              confirmPincodeField={true}
            />
          </div>
          <FooterSection />
        </div>
      </div>
    );
  }
}

ActivityPincodePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
});

const mapActionsToProps = (dispatch) => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

export default connect(mapStateToProps, mapActionsToProps)(ActivityPincodePage);
