import React from 'react';

class InputAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value: props.input.value };
  }

  handleBlur() {
    this.props.input.onBlur();
  }

  handleChange(e) {
    const { input } = this.props;

    input.onChange(e.target.value);
    this.setState({ value: e.target.value });
  }

  render() {
    const { input, prefix, suffix, label, meta: { touched, error }, ...rest } = this.props;

    return (
      <div>
        {label && <label htmlFor="name" className="db mb2" style={{color:'#001E2E'}}>{label}</label>}
        {prefix && (
          <input
            className="f6 f5-l button-reset fl pv3tc bn bg-animat bg-black-70 hover-bg-black white pointer w-100 w-25-m w-20-l br2-ns br--right-ns"
          />
        )}
        <input
          {...input}
          {...rest}
          className="ba br2 b--black-20 pa2 mb1 db w-100"
          value={this.props.value1}
          aria-describedby="name-desc"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        <small className="dark-red db h1 mb2">{touched && error}</small>
      </div>
    );
  }
}

export default InputAtom;
