import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';
import StorageGateway from 'lib/storage-gateway';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import JobCommentsSection from 'sections/jobs/comments/Comment.jsx';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import * as JobDucks from 'ducks/jobs/jobs';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as RouteDucks from 'ducks/routes';
import * as SessionDucks from 'ducks/activities/session';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class JobCommentsPage extends React.PureComponent {

   constructor(props) {
    super(props);
    this.state = { isLoading: true, showReloadButton: false};
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.singleton = new Singleton();
  }

  componentWillMount() {
    const { location: { query } } = this.props;
    this.props.ProfileDucks.getProfile()
    .then((resp) => {
        this.fetchData({ profile: resp.value, ...query});
       /* this.startTimer();*/
     });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery }, profile } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      // this.fetchData({});
      this.fetchData({profile, ...nextQuery});
    }
  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 32000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }


  fetchData(params){
    const { profile } = params;
    this.setState({ isLoading: true });
    this.props.EmployeeDucks.getEmployeeCompanySetting({ version: 2 });
    this.props.SessionDucks.getActivityData(profile ? profile.employee_id : params.res.value.employee_id);
    this.props.SessionDucks.getCurrentSession()
    .then((res) => {
      this.setState({currentDetail : res.value});
      this.setState({isLoading : false, showReloadButton: false});
      clearTimeout(this._reloadTimeOut)
   })
   .catch(() => {
      this.setState({isLoading : false, showReloadButton: false});
      clearTimeout(this._reloadTimeOut)
   });
  }

  reloadScreen(){
    const { location: { query } } = this.props;
    this.setState({showReloadButton: false});
    this.clickToReload();
    this.props.ProfileDucks.getProfile()
    .then((resp) => {
        this.fetchData({ profile: resp.value, ...query});
     });
  }

  clickToReload(){
    this._reloadTimeOut=setTimeout(() => {
      this.setState({showReloadButton: true});
      clearTimeout(this._reloadTimeOut)
    }, 8000);
  }

  goToUrl() {
    const { router: { history } } = this.context;
    history.push('/jobs/overview');
  }

 
  render() {
    const { isLoading , currentDetail} = this.state;
    const { activityData,profile } = this.props;
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    if(currentDetail && currentDetail.create_date == null && activityData && activityData.length === 0){
      return <Redirect to="/activity/list/" />;
    }
    
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
    return (
      <div className="relative">
        <div className="cf min-vh-100 pb4 dark-grey-bg">
          <div className="w-100 margin-list">
            <HeaderSection 
              fetchData={this.fetchData}
              isBackIcon="isBackIcon"
              goToUrl = {this.goToUrl}
            />
            <TimeCardHeader 
              heading={TRMSGS.correctionRequest}
              firstName = {profile && profile.first_name}
              lastName = {profile && profile.last_name}
            />
          </div>
          <div className="w-70-l w-90-ns margin-list pa2 pb5">
            <JobCommentsSection />
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return({
    activityData : SessionDucks.activityData(state),
    location: RouteDucks.location(state),
    profile: ProfileDucks.profile(state),
});
};

const mapActionsToProps = dispatch => ({
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  TaskDucks : bindActionCreators(TaskDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

JobCommentsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(JobCommentsPage);

