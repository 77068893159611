import React from 'react';

import PropTypes from 'prop-types';
import reduxForm from 'redux-form/lib/reduxForm';

import Modal from 'react-bootstrap-modal';
import Webcam from 'react-webcam';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import TRMSGS from 'constants/Translations/Localization';

/**
 * DashboardListPage -> DashboardListSection -> ResetPasswordFormModalComponent
 * 
 * Props:
 *    - update
 */
class BiometricPasswordFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.imageLogin = this.imageLogin.bind(this);
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.stream = null;
    // this.loadDataHandler = this.loadDataHandler.bind(this);
    this.webcam = null;
    this.state = {
      inputType: 'password',
      isOpenKeyboard: false,
      screenshot: null,
      cameraEnable: true,
      imageSubmitting: false,
      imageLoginErrorMessage: '',
      // modalTitle: 'Please align your face with the camera to login',
      cameraPermissionText: '',
      cameraConnected: false,
    };
  }


  componentDidMount() {
    if (!window && !window.navigator) {
      return;
    }
    const supported = 'mediaDevices' in window.navigator;
    if (!supported) {
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if(isMacLike){
        this.setState({isCameraPermission: 'MACLIKEDEVICE'});
      }
      else{
        this.setState({cameraPermissionText: 'UPDATE'});
      }
    }
    const constraints = {video: true };
    if (navigator.mediaDevices !== undefined) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this.stream = stream;
          this.setState({cameraConnected: true});
        })
        .catch(() => {
          this.setState({cameraPermissionText: 'PERMISSION'});
          this.setState({cameraConnected: false});
        });
    }
  }

  
  componentWillUnmount() {
    this.webcam = null;
    this.setState({cameraEnable : false, cameraConnected : false})
    this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
  }

  imageLogin() {
    const { employeeImageLogin, setImageLoginError , disableLoader} = this.props;
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ screenshot: imageSrc, imageSubmitting: true });
    if (!imageSrc) {
      this.setState({ cameraEnable: false });
      return;
    }
    return employeeImageLogin(imageSrc)
      .catch(err => {
        this.stream && this.stream.getTracks() && this.stream.getTracks().length && this.stream.getTracks().forEach(track => track.stop());
        let errMsg;
        if (err.non_field_errors) {
          errMsg = err.non_field_errors;
          setImageLoginError(errMsg, true);
        } else if (err.image) {
          errMsg = err.image;
          if(!errMsg[0].indexOf('ERRORCODE_61404')) {
          setImageLoginError(errMsg[0], false);
          } else {
          setImageLoginError(errMsg[0], true);
          }
        } else {
          errMsg = err.username;
          setImageLoginError(errMsg[0], true);
        }
        this.setState({
          imageSubmitting: false,
          cameraEnable: false,
        });
        disableLoader();
      });
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard: !isOpenKeyboard });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  // loadDataHandler(){
  //   const { webcam, count } = this.state;
  //   setTimeout(() => {
  //     this.setState({webcam: true});
  //   }, 2500);
  // }

  render() {
    const { handleSubmit , isOpen, toggle, webcam, loadDataHandler , submitButtonLoader} = this.props;
    const {  modalTitle , cameraEnable,
      imageSubmitting , cameraPermissionText, cameraConnected } = this.state;
    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
        className = 'face_detector'
      >
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title id='ModalHeader' style={{fontWeight: '500'}}>
            {cameraPermissionText && cameraPermissionText === 'UPDATE'
              ? `${TRMSGS.advanceBrowserCheck}`
              : cameraPermissionText && cameraPermissionText=== 'PERMISSION' ?  `${TRMSGS.cameraPermission}` 
              : cameraPermissionText && cameraPermissionText=== 'MACLIKEDEVICE' ?  `${TRMSGS.browseNotSupported}` 
              : `${TRMSGS.alignYourFacewithCameraToLogin}`
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ml4 mr4">
          {cameraPermissionText && !cameraConnected
            ? <div>
              {cameraPermissionText && cameraPermissionText === 'UPDATE' &&
                <div className="f4 fw4">{TRMSGS.upgradeWebBrowser}</div>
              }
              {cameraPermissionText &&  cameraPermissionText=== 'PERMISSION' &&
                <div className="f4 fw4">{TRMSGS.allowCameraAccess}</div>
              }
              {cameraPermissionText && cameraPermissionText === 'MACLIKEDEVICE' &&
                <div className="f4 fw4">{TRMSGS.browserNotCompatibleWithWebcamAnduseSafari}</div>
              }
            </div>
            :<form onSubmit={handleSubmit(this.imageLogin)}>
              <div className="flex">

                <div className={!cameraEnable ? 'pr2' : null} style={{ flex: '1' }}>

                  {cameraConnected && cameraEnable && <Webcam
                    audio={false}
                    width='100%'
                    height='auto'
                    screenshotFormat="image/jpeg"
                    ref={(webcam) => { this.webcam = webcam;}}
                    onLoadedData={loadDataHandler()}
                  />}

                  {/*{this.state.screenshot` && !cameraEnable ? <img width="100%" height="auto" src={require('images/no-camera.png')} /> : null}*/}

                  <div className="pa1"></div>
                  {cameraEnable && cameraConnected && webcam && <div className="tr custom-button">
                    <button
                      type="submit"
                      className="br2 pointer pv2 ph5 w-100 white btn-color"
                      disabled = {submitButtonLoader}
                    >
                      {TRMSGS.login} <ButtonLoaderAtom active={imageSubmitting} />
                    </button>
                  </div>}

                </div>
              </div>
            </form>
          }
        </Modal.Body>
        {cameraPermissionText
          ? <Modal.Header >
            <div className="tr">
              <button type="button"
                onClick={() => toggle()}
                className="br2 pointer pa2 ph4 white btn-color f3"
              >
                {TRMSGS.ok}
              </button>
            </div>
          </Modal.Header>
          : null
        }
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  return errors;
};

BiometricPasswordFormComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  loadDataHandler: PropTypes.func.isRequired,
  web: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'BiometricPasswordFormComponent',
  validate,
})(BiometricPasswordFormComponent);
