import React from 'react';

import { Switch, Route } from 'react-router-dom';

import TaskListPage from './List';

export const routes = [
  {
    component: TaskListPage,
    exact: true,
    name: 'task.list',
    path: '/tasks/list',
  },
];

const GenericRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default GenericRoutes;
