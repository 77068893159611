import { createSelector } from 'reselect';
import { merge } from 'ramda';

import { fetch } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const GET_SIDEMENU = 'access/sidemenu/GET_SIDEMENU';
const GET_SIDEMENU_FULFILLED = 'access/sidemenu/GET_SIDEMENU_FULFILLED';


export function getSidemenu() {
  return {
    type: GET_SIDEMENU,
    payload: fetch(constants.URLS.SIDEMENU),
  };
}

const defaultState = {
  list: [],
};

function GET_SIDEMENU_FULFILLED_REDUCER(state, action) {
  return merge(state, {
    list: action.payload,
  });
}

const handlers = {
  [GET_SIDEMENU_FULFILLED]: GET_SIDEMENU_FULFILLED_REDUCER,
};


const sidemenuSelector = state => state.access.sidemenu;

export const sidemenu = createSelector(
  sidemenuSelector,
  instance => instance.list,
);

export default createReducer(defaultState, handlers);
