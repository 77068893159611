import React from 'react';

import { Switch, Route } from 'react-router-dom';

import MealListPage from './List';
import MealBreakPage from './MealBreak';

export const routes = [
  {
    component: MealListPage,
    exact: true,
    name: 'meal.list',
    path: '/meals/list',
  },
  {
    component: MealBreakPage,
    exact: true,
    name: 'meal.break',
    path: '/meals/break',
  },
];

const GenericRoutes = () => (
  <Switch>
    {routes.map(route => <Route key={route.name} {...route} />)}
  </Switch>
);

export default GenericRoutes;
