import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Singleton from 'lib/singleton';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';
import StorageGateway from 'lib/storage-gateway';

import HeaderSection from 'sections/common/Header';
import TimeCardHeader from 'sections/common/TimeCardHeader';
import FooterSection from 'sections/common/Footer';
import JobNotesSection from 'sections/jobs/notes/Notes.jsx';
import SectionLoaderReloadBtnAtom from 'atoms/SectionLoaderReloadButton';

import * as JobDucks from 'ducks/jobs/jobs';
import * as SessionDucks from 'ducks/activities/session';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as UserDucks from 'ducks/accounts/user';

import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class JobNotesPage extends React.PureComponent {


  constructor(props) {
    super(props);
    this.state = { isLoading: true, isBackIcon : false, showReloadButton: false};
    this.fetchData = this.fetchData.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.singleton = new Singleton();
    this.goToUrl = this.goToUrl.bind(this);
    this.reloadScreen = this.reloadScreen.bind(this);
    this.clickToReload = this.clickToReload.bind(this);
  }

  componentWillMount() {
    const { location: { query } } = this.props;
    this.props.ProfileDucks.getProfile()
    .then((res) => {
        this.fetchData({ ...query, res});
        /*this.startTimer();*/
     });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)) {
      // this.fetchData({});
      this.fetchData(nextQuery);
    }
  }


  goToUrl() {
    const { router: { history } } = this.context;
    history.push('/jobs/shift');
  }


  fetchData(params){
    this.setState({ isLoading: true });
    this.props.SessionDucks.getActivityData(params.res.value.employee_id);
    this.props.JobDucks.getJobNotes()
    .then(() => {
    this.setState({isLoading : false, showReloadButton: false});
    setTimeout(this._reloadTime);
   })
   .catch(() => {
      this.setState({isLoading : false, showReloadButton: false});
      setTimeout(this._reloadTime);
   });
   
  }

  startTimer() {
    const { router: { history } } = this.context;
    this._timeOut = setTimeout(() => {
      this.singleton.employeeAuth = null;
      this.singleton.language = null;
      cookies.remove('employeeToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      cookies.remove('userLanguagePreferenceCookie', { path: '/' });
      cookies.remove('documentId', { path: '/' });
      StorageGateway.remove('imageUrl');
      this.props.TaskDucks.resetTasks();
      this.props.TaskDucks.resetAllTasks();
      this.props.JobDucks.resetJobs();
      this.props.JobDucks.resetJobLabel();
      this.props.ProfileDucks.resetFaceDetails();
      this.props.UserDucks.resetUserPreferedLanguage();
      clearTimeout(this._timeOut);
      history.push('/dashboard');
    }, 21000);
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener('mousedown', this.pageClick, false);
    window.addEventListener('mouseup', this.pageClick, false);
    window.addEventListener('mousemove', this.pageClick, false);
    window.addEventListener('keypress', this.pageClick, false);
    window.addEventListener('keydown', this.pageClick, false);
    window.addEventListener('touchmove', this.pageClick, false);
    window.addEventListener('onscroll', this.pageClick, false);
    if(this.state.isLoading){
      this.clickToReload();
    }
  }

  pageClick(e) {
    clearTimeout(this._timeOut);
    this.startTimer();
  }

   componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener('mousedown', this.pageClick, false);
    window.removeEventListener('mouseup', this.pageClick, false);
    window.removeEventListener('mousemove', this.pageClick, false);
    window.removeEventListener('keypress', this.pageClick, false);
    window.removeEventListener('keydown', this.pageClick, false);
    window.removeEventListener('touchmove', this.pageClick, false);
    window.removeEventListener('onscroll', this.pageClick, false);
  }

  reloadScreen(){
    this.setState({showReloadButton: false});
    const { location: { query } } = this.props;
    this.props.ProfileDucks.getProfile()
    .then((res) => {
        this.fetchData({ ...query, res});
     });
    this.clickToReload();
  }

  clickToReload(){
    this._reloadTime = setTimeout(() => {
      this.setState({showReloadButton: true});
      setTimeout(this._reloadTime);
    }, 8000);
  }



  render() {
    if (!this.singleton.employeeAuth) {
      return <Redirect to="/dashboard" />;
    }
    const{ isLoading} = this.state;
    const { activityData ,  profile } = this.props;
    const selectedJob = activityData && activityData.length && activityData[activityData.length - 1].job_name;
    const selectedTask = activityData && activityData.length && activityData[activityData.length - 1].task_name;
    if (isLoading) return <SectionLoaderReloadBtnAtom active showReloadButton={this.state.showReloadButton} reloadScreen={this.reloadScreen}/>;
    return (
      <div className="min-vh-100 pb4 dark-grey-bg relative">
        <div className="w-100 margin-list">
          <HeaderSection 
            isBackIcon="isBackIcon"
            goToUrl = {this.goToUrl}
            fetchData={this.fetchData}
           />
          <TimeCardHeader 
            heading={TRMSGS.jobNotes}
            firstName = {profile && profile.first_name}
            lastName = {profile && profile.last_name}
          />
        </div>
        <div className="w-70-l w-90-ns margin-list pa2 pb5 job-margin-responsive">
          <JobNotesSection
            jobNotesList ={this.props.jobNotesList}
            selectedJob={selectedJob}
            selectedTask={selectedTask}
          />
        </div>
        <FooterSection />
      </div>
    );
  }
}

const mapStateToProps = state => 
{
  return({
  location: RouteDucks.location(state),
  jobNotesList: JobDucks.jobNotesList(state),
  profile: ProfileDucks.profile(state),
  activityData : SessionDucks.activityData(state),
});
};

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  TaskDucks : bindActionCreators(TaskDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

JobNotesPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(JobNotesPage);

