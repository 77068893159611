import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';

const TablePlaceholderComponent = props => {
  const { rows } = props;

  return (
    <table className="w-100 center" cellSpacing="0">
      <tbody>
        {range(0, rows).map(item => (
          <tr key={item}>
            <td className="bb b--white bg-near-white pt3 pb4 animated-background"></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TablePlaceholderComponent.defaultProps = {
  rows: 10,
};

TablePlaceholderComponent.propTypes = {
  row: PropTypes.number,
};

export default TablePlaceholderComponent;
