import React from 'react';

import PropTypes from 'prop-types';

import TimerService from 'lib/TimerService';


class WorkShiftTimerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {shiftDuration: '00:00'};
  }

  componentDidMount() {

    let currentDatetime = new Date();
    currentDatetime.setSeconds(0,0);
    currentDatetime.setMilliseconds(0);
    currentDatetime.toISOString();

    let createDateTime = this.props.sessionDetail && this.props.sessionDetail.current_activity  && this.props.sessionDetail.current_activity[0] && this.props.sessionDetail.current_activity[0].create_date;

    createDateTime = new Date(createDateTime);
    createDateTime.setSeconds(0,0);
    createDateTime.setMilliseconds(0);
    createDateTime.toISOString();

    this.punchInTime = createDateTime ? createDateTime : currentDatetime;

    // TODO - get Company settings, Meal is payable or not.    

    this.shiftTime = (Date.parse(this.punchInTime)) + this.props.sessionDetail.work_shift_unpaid_meal_ms;
    const timerService = new TimerService();
    this.setState({
      shiftDuration: timerService.timeSince(this.shiftTime),
    });
    this._interval = setInterval(() => {
      this.setState({
        shiftDuration: timerService.timeSince(this.shiftTime),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    return (
      <section>
        <div>
          {this.state.shiftDuration} <br/>
        </div>
      </section>
    );
  }
}

WorkShiftTimerComponent.propTypes = {
  punchInDetail: PropTypes.object,
  sessionDetail: PropTypes.object,
};

WorkShiftTimerComponent.contextTypes = {};

export default WorkShiftTimerComponent;
