import React from 'react';

import {ReactComponent as CalendarIcon} from './calendar.svg';

import moment from 'moment';
import CalenderModalAtom from 'atoms/CalenderModalAtom';
import TimeInputAtom from 'atoms/TimeInputAtom';

class CalenderAtom extends React.Component {

  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.closeTimePicker = this.closeTimePicker.bind(this);
    this.state = { showTimePicker : false , initialDate : props.values.start_date};
  }

  handleDateChange(date) {
    this.setState({initialDate :date}); 
    this.props.submitEditedDate(new Date(date));
  };

  toggleTimePicker(){
    const { showTimePicker} = this.state;
    const { previousJob , nextJob } = this.props;
    if(!((previousJob.activity_name && previousJob.activity_name === 'meal') || (nextJob.activity_name && nextJob.activity_name === 'meal'))){
      this.setState({showTimePicker : !showTimePicker});
    }
  }

  closeTimePicker(){
    const { showTimePicker } = this.state;
    this.setState({showTimePicker : !showTimePicker});
  }


  render() {
     const { input , label , previousJob , nextJob} = this.props;
    return (
        <div className ="pointer mr2" style={{flex:'1'}}>
          {label && <label htmlFor="name" className="f6 b db mb2">{label}</label>}
            <TimeInputAtom 
              input={input}
              value={moment(this.state.initialDate).format('DD-MM-YYYY')}
              previousJob={previousJob}
              nextJob={nextJob}
            />
          <div style={{height : '32px'}} className ="dib calendar-icon ba b--black-20" onClick={() => this.toggleTimePicker()}> 
            <CalendarIcon width="16" height="16"/>
          </div>
          {this.state.showTimePicker &&
            <CalenderModalAtom
              isOpen={this.state.showTimePicker}
              toggle={this.closeTimePicker}
              input={new Date(this.state.initialDate)}
              updateTime={this.handleDateChange}
            />
          }
        </div>
    )
}

}

export default CalenderAtom;