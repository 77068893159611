import { createSelector } from 'reselect';

import { fetch } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';


const GET_DEPARTMENTS = 'departments/department/GET_DEPARTMENTS';
const GET_DEPARTMENTS_FULFILLED = 'departments/department/GET_DEPARTMENTS_FULFILLED';
// const GET_EMPLOYEE_COMPANY_SETTING = 'employees/employee/GET_EMPLOYEE_COMPANY_SETTING';
// const GET_EMPLOYEE_COMPANY_SETTING_FULFILLED = 'employees/employee/GET_EMPLOYEE_COMPANY_SETTING_FULFILLED';
// const GET_EMPLOYEE = 'employees/employee/GET_EMPLOYEE';
// const GET_EMPLOYEE_FULFILLED = 'employees/employee/GET_EMPLOYEE_FULFILLED';
// const POST_EMPLOYEE_ACTIVATE = 'employees/employee/POST_EMPLOYEE_ACTIVATE';
// const POST_EMPLOYEE_ACTIVATE_FULFILLED = 'employees/employee/POST_EMPLOYEE_ACTIVATE_FULFILLED';

export function getDepartments(params) {
  return {
    type: GET_DEPARTMENTS,
    payload: fetch(constants.URLS.DEPARTMENT, { params }),
  };
}

// export function getEmployee(params) {
//   return {
//     type: GET_EMPLOYEE,
//     payload: fetch(`${constants.URLS.EMPLOYEE}${params.id}/`),
//   };
// }

// export function getEmployeeCompanySetting(params) {
//   return {
//     type: GET_EMPLOYEE_COMPANY_SETTING,
//     payload: fetch(constants.URLS.COMPANY, { params }),
//   };
// }

// export function postActivate(data) {
//   return {
//     type: POST_EMPLOYEE_ACTIVATE,
//     payload: post(constants.URLS.ACTIVATE, data),
//   };
// }

const defaultState = {
  list: { results: [] },
  detail: {},
  companyDetail: {}
};

function GET_DEPARTMENTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload ,
  });
}

// function GET_EMPLOYEE_FULFILLED_REDUCER(state, action) {
//   return Object.assign({}, state, {
//     detail: action.payload,
//   });
// }
// function GET_EMPLOYEE_COMPANY_SETTING_FULFILLED_REDUCER(state, action) {
//   return Object.assign({}, state, {
//     companyDetail: action.payload,
//   });
// }
// function POST_EMPLOYEE_ACTIVATE_FULFILLED_REDUCER(state, action) {
//   const singleton = new Singleton();
//   singleton.employeeAuth = action.payload.auth_token;
//   cookies.set('employeeToken', action.payload.auth_token, { path: '/' });
//   return merge(state, {
//     employeeToken: action.payload.auth_token,
//   });
// }
const handlers = {
  [GET_DEPARTMENTS_FULFILLED]: GET_DEPARTMENTS_FULFILLED_REDUCER,
};


const departmentSelector = state => state.departments.department;

export const departmentList = createSelector(
  departmentSelector,
  instance => instance.list,
);

export const departmentDetail = createSelector(
  departmentSelector,
  instance => instance.detail,
);

// export const companyDetail = createSelector(
//   departmentSelector,
//   instance => instance.companyDetail,
// );


export default createReducer(defaultState, handlers);
