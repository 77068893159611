import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Import your reducers here
import access from './access';
import accounts from './accounts';
import employees from './employees';
import activities from './activities';
import timecards from './timecards';
import departments from './departments';
import teams from './teams';
import payrolls from './payrolls';
import basicSettings from './basicSettings';
import jobs from './jobs';
import tasks from './Tasks';

const appReducer = combineReducers({
  form: formReducer,
  routes: routerReducer,
  access,
  accounts,
  employees,
  activities,
  timecards,
  departments,
  teams,
  payrolls,
  basicSettings,
  jobs,
  tasks
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'accounts/user/RESET_STATE') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
