import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';

import Singleton from 'lib/singleton';
import { toast } from 'react-toastify';
import StorageGateway from 'lib/storage-gateway';

import * as UserDucks from 'ducks/accounts/user';
import * as ProfileDucks from 'ducks/accounts/profile';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as BasicSettingsDucks from 'ducks/basicSettings/basicSettings';
import * as JobDucks from 'ducks/jobs/jobs';
import * as TaskDucks from 'ducks/Tasks/tasks';
import * as RouteDucks from 'ducks/routes';

import LogOutFormModalComponent from 'components/generics/dashboard/Form/LogOutForm';
import AddShiftNameModalComponent from 'components/jobs/shift/addShiftNameModalComponent';
import LanguageSettingModalComponent from 'components/common/LanguageSettingModal';

import { ReactComponent as TimeCardIcon } from 'images/timecard.svg';
import { ReactComponent as LeftArrow } from 'images/left-arrow.svg';
import { ReactComponent as JobNameIcon } from './jobnameIcon.svg';
import { ReactComponent as CameraIcon } from './UseCamera.svg';
import { ReactComponent as NameIcon } from './SearchByName.svg';

import Logout from './menuLogout.png';
import LanguageSetting from './LanguageSettings.png';

import moment from 'moment';
import styles from './styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';
const cookies = new Cookies();

class HeaderSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
    this.logout = this.logout.bind(this);
    this.singleton = new Singleton();
    this.setTimer = this.setTimer.bind(this);
    this.state={
      isOpen : false, 
      currentDateTime: '',
      timeFormat : '',
      showBackIcon: false,
      showTimeCardIcon: false,
      showLogOutIcon: false,
      openShiftName : false,
      isMenuOpen: false,
      isLanguageSettingModal: false,
      languageType: null,
      isLanguageButtonLoader: false, 
    };
    this.toggle=this.toggle.bind(this);
    this.goToTimecards = this.goToTimecards.bind(this);
    this.addShiftName = this.addShiftName.bind(this);
    this.submit = this.submit.bind(this);
    this.closeLabelPopup = this.closeLabelPopup.bind(this);
    this.menuToggle=this.menuToggle.bind(this);
    this.languageSettingToggle = this.languageSettingToggle.bind(this);
    this.selectLanguage=this.selectLanguage.bind(this);
    this.languageSubmit=this.languageSubmit.bind(this);
    this.employeeLogout = this.employeeLogout.bind(this);
  }

  setTimer() {
    const { dateFormat, timeFormat } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        currentDateTime: moment().format(dateFormat),
        timeFormat: moment().format(timeFormat),
      });
    }, 1000);
  }


  componentWillMount () {
    const employeeToken = cookies.get('employeeToken', { path: '/' });
    this.props.UserDucks.getLanguagePreference({preference_type : 'user_preference_kiosk_language'})
    .then(()=>{
      if (this.singleton.authorization) {
      this.props.ProfileDucks.getProfile();
      }
      if(employeeToken){
        this.props.ProfileDucks.getFaceDetails()
      }
      this.props.BasicSettingsDucks.getBasicSetting()
       .then(() => this.setTimer());
    })
    .catch(()=>{
      if (this.singleton.authorization) {
      this.props.ProfileDucks.getProfile();
      }
      if(employeeToken){
        this.props.ProfileDucks.getFaceDetails()
      }
      this.props.BasicSettingsDucks.getBasicSetting()
       .then(() => this.setTimer());
    });
   
  }
  languageSubmit(){
    const {location : { query, pathname } } = this.props;
    const {languageType, isLanguageSettingModal, isLanguageButtonLoader} = this.state;
    this.setState({isLanguageButtonLoader:true});
    this.props.UserDucks.putLanguagePreference({data:{language:languageType} , preference_type: 'user_preference_kiosk_language' })
    .then(()=>{
      this.singleton.language = languageType;
      cookies.set('userLanguagePreferenceCookie', languageType, { path: '/' });
      this.props.UserDucks.getLanguagePreference({preference_type : 'user_preference_kiosk_language'});
      this.props.ProfileDucks.getProfile()
        .then((res) => {
          this.props.fetchData({...query, res});
          this.setState({isLanguageSettingModal:false, isLanguageButtonLoader:false});
      });
    })
    .catch((e)=>{
      this.setState({isLanguageSettingModal:false, isLanguageButtonLoader:false});
    })
  }

  componentWillReceiveProps(nextProps){
    const { faceInfo } = nextProps;
    const imageURL = cookies.get('imageURL', { path: '/' });
    const employeeToken = cookies.get('employeeToken', { path: '/' });
    if(!imageURL && faceInfo.face_images && faceInfo.face_images.length && employeeToken){
      StorageGateway.set('imageUrl', faceInfo.face_images && faceInfo.face_images[0].image);
    }
    if(nextProps.userPreferenceLanguage !== this.props.userPreferenceLanguage && nextProps.userPreferenceLanguage){
      this.singleton.language = nextProps.userPreferenceLanguage;
      cookies.set('userLanguagePreferenceCookie', nextProps.userPreferenceLanguage, { path: '/' });
    }
   }

  addShiftName(){
    this.setState({openShiftName : true, isOpen: false});
  }

  closeLabelPopup(){
    this.setState({openShiftName : false, isOpen: false});
  }
 
  submit(data){
    const { currentActivity } = this.props;
    const detail = {
      activity_id: currentActivity && currentActivity[0].id,
      job_label : data.shift_label
    };
    if(!(data.shift_label)){
      this.setState({openShiftName : false, isOpen: false});
      return toast.error(`${TRMSGS.enterShiftLabel}`);
    }
    if(data.shift_label && (data.shift_label).length > 100){
      return toast.error(`${TRMSGS.shiftLabelCondition}`);
    }
    this.props.JobDucks.submitJobName(detail)
    .then(() => {
      this.props.JobDucks.getJobName({activity_id : currentActivity && currentActivity[0].id});
      this.setState({openShiftName : false, isOpen: false});
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  menuToggle() {
    const {isMenuOpen} = this.state;
    this.setState({isMenuOpen: !isMenuOpen});
  }

  languageSettingToggle(){
    const { isLanguageSettingModal } = this.state;
    if(!isLanguageSettingModal){
      this.props.UserDucks.getLanguagePreference({preference_type : 'user_preference_kiosk_language'})
      .then(()=>{
        this.setState({isLanguageSettingModal: true, languageType:this.props.userPreferenceLanguage});
      })
    }
    else{
      this.setState({isLanguageSettingModal: false});
    }
  }

  toggle() {
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen});
  }

  goToTimecards(){
    const { router: { history } } = this.context;
    return history.push({
      pathname: '/timecard',
    });
  }

  selectLanguage(type){
    const {languageType}= this.state;
    this.setState({languageType: type});
  }

  logout(data) {
    const { router: { history}} = this.context;
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    return this.props.UserDucks.postLogoutUser(data)
    .then(() => {
      this.singleton.authorization = null;
      cookies.remove('authToken', { path: '/' });
      cookies.remove('job_id', { path: '/' });
      cookies.remove('responseId', { path: '/' });
      this.singleton.language = null;
      StorageGateway.remove('imageUrl');
      this.props.UserDucks.resetUserPreferedLanguage();
      history.push('/');
    });
  }

  employeeLogout() {
    const { router: { history } } = this.context;
    this.singleton.employeeAuth = null;
    this.props.TaskDucks.resetTasks();
    this.props.TaskDucks.resetAllTasks();
    this.props.JobDucks.resetJobs();
    this.props.JobDucks.resetJobLabel();
    this.props.ProfileDucks.resetFaceDetails();
    cookies.remove('employeeToken', { path: '/' });
    cookies.remove('job_id', { path: '/' });
    cookies.remove('responseId', { path: '/' });
    cookies.remove('userLanguagePreferenceCookie', { path: '/' });
    cookies.remove('documentId', { path: '/' });
    StorageGateway.remove('imageUrl');
    clearTimeout(this._timeOut);
    history.push('/dashboard');
  }


  render(){
    const {isMenuIcon, isBackIcon, isTimeCardIcon, goToUrl, isShiftNameicon, isLogo , selectedType, companyDetail } = this.props;
    const { isOpen, openShiftName, isMenuOpen, isLanguageSettingModal, languageType, isLanguageButtonLoader} = this.state;
    {this.props.userPreferenceLanguage && TRMSGS.setLanguage(this.props.userPreferenceLanguage)}
    return(
      <div className="w-100 flex flex-center pa3 bg-primary header-height" style={{ position: 'relative' }}>
        {isLogo && 
          <div className={`${styles['worksanaTextLogo']} tc`}>
            <img
              height="35" width="53"
              src={require('./WorksanaW.png')} alt="Site Name"
            />
          </div>
        }        
        { isBackIcon &&
          <div>
            <button
              className={`${styles['logout_left']} pointer br3 fr di ml4 pv1 white`}
              onClick={() => goToUrl()}
            >
              <LeftArrow
                height="30"
                width="30"
                className="fr mt2 di"
              />
            </button>
          </div>
        }
        { isTimeCardIcon &&
          <div>
            <button
              className={`${styles['logout_left']} pointer br3 fr di ml4 pv1 white`}
              onClick={() => this.goToTimecards()}
            >
            <TimeCardIcon
              height="40"
              width="30"
              className="fr di mr3"
            />
            </button>
          </div>
        }
          <div className={`${styles['time-heading']} pv2 tc gray dib center white`}>
            <p className={`${styles['time-heading-date']} mv0 dib`}>{this.state.currentDateTime}</p>
            <p className={`${styles['time-heading-ampm']} mv0 dib`}>{this.state.timeFormat}</p>
          </div>
          <div className="dtc v-mid mid-gray fr di link">
            {isMenuIcon &&
              <div>
                <button
                  className={`${styles['logout_right']} pointer mt1 br3 fr di pv1 white`}
                  onClick={()=> this.menuToggle()}
                >
                  <img
                    height="30"
                    className="fr di mr3 mt1"
                    src={require('./menu.png')}
                    alt="Logo"
                  />
                  {isMenuOpen &&
                    <div className={`${styles['menu-dropdown']}`}>
                      <div className={`${styles['menu-dropItems']} pb2 pt2 pl3 pr3`} onClick={()=> this.languageSettingToggle()}><div className="mr2 pt1"><img src={LanguageSetting} className={styles['menu-item-icon']}/></div><div>{TRMSGS.languageSettings}</div></div>
                      <hr className="mt0 mb0"/>
                      <div className={`${styles['menu-dropItems']} pb2 pt2 pl3 pr3`} onClick={()=> this.toggle()}> <div className="mr2 pt1"><img src={Logout} className={styles['menu-item-icon']} /></div><div>{TRMSGS.logout}</div></div>
                    </div>
                  }
                </button>
                {selectedType == 'face' &&
                  <button className={this.props.userPreferenceLanguage ==='bilingual' ? `${styles['logout_right-button']} pointer br3 fr`:`${styles['logout_right-button']} pointer mt2 br3 fr pt1`} onClick={()=>this.props.searchByFace(1)}>
                    <span className={`${styles['logout_right-button-logo']}`}><NameIcon width="25" height={this.props.userPreferenceLanguage ==='bilingual' ? "45": "25"}/></span>
                    <span className={this.props.userPreferenceLanguage ==='spanish' ? `${styles['logout_right-button-text-spanish']} ${styles['logout_right-button-text']}` : `${styles['logout_right-button-text']}`}>{TRMSGS.searchName}</span>
                  </button>
                }
                {selectedType == 'name' &&
                  <button className={this.props.userPreferenceLanguage ==='bilingual' ? `${styles['logout_right-button']} pointer br3 fr`: `${styles['logout_right-button']} pointer mt2 br3 fr pt1`} onClick={()=>this.props.searchByFace(2)}>
                    <span className={`${styles['logout_right-button-logo']}`}><CameraIcon width="25" height={this.props.userPreferenceLanguage ==='bilingual' ? "45": "25"}/></span>
                    <span className={`${styles['logout_right-button-text']}`}>{TRMSGS.useCamera}</span>
                  </button>
                }
              </div>
            }
            {!companyDetail?.disable_shift_notes && isShiftNameicon &&
              <div>
                <button
                  className={`${styles['logout_right']} pointer br3 mr5 fr di ml4 pv1 white`}
                  onClick={()=> this.addShiftName()}
                >
                  <JobNameIcon
                    height="40"
                    width = "40"
                    className="fr di mr3"
                  />
                </button>
              </div>
            }
            {!isMenuIcon &&
              <div>
                <button
                  className={`${styles['logout_right']} pointer mt1 br3 fr di pv1 white`}
                  onClick={()=> this.languageSettingToggle()}
                >
                  <img
                    height="30"
                    className="fr di mr3 mt1"
                    src={require('./settingIcon.png')}
                    alt="Logo"
                  />
                </button>
              </div>
            }

            { isOpen &&
              <LogOutFormModalComponent
                isOpen={isOpen}
                toggle={this.toggle}
                logout={this.logout}
              />
            }
            { openShiftName &&
              <AddShiftNameModalComponent
                isOpen={openShiftName}
                toggle={this.addShiftName}
                submit={this.submit}
                onSubmit={this.submit}
                closeModal={this.closeLabelPopup}
                initialValues={{shift_label: this.props.jobName.job_label}}
                userPreferenceLanguage={this.props.userPreferenceLanguage}
              />
            }
            {isLanguageSettingModal &&
              <LanguageSettingModalComponent
                isOpen={isLanguageSettingModal}
                toggle={this.languageSettingToggle}
                selectLanguage={this.selectLanguage}
                languageType={languageType}
                languageSubmit={this.languageSubmit}
                isLanguageButtonLoader={isLanguageButtonLoader}
              />
            }
          </div>
        {/*
          <a className="dtc v-mid mid-gray link w-25 " href="#" title="Home">
            <img height="40" className="logo-img" src={require('./logo.png')} alt="Site Name" />
          </a>
         <div className={`${styles['timer']} dtc v-mid w-50 tc white`}>
            {this.state.currentDateTime} {this.state.timeFormat}
          </div>
          <div className="dtc v-mid mid-gray fr di link w-75">
            <button
              className={`${styles['logout']} pointer br3 fr di  pv1 white`}
              onClick={()=> this.toggle()}
            >
              <img
                height="40"
                className="fr di mr3"
                src={require('./logout-image.png')}
                alt="Logo"
              />
            </button>
            {userCompany && userCompany.image
              ? (<img
                height="40"
                className="fr di mr3 br3 pv1"
                src={userCompany.image && userCompany.image.small.url}
                alt="Logo"
              />)
              : (<img
                height="40"
                className="fr di mr3 br3 pv1"
                src={require('./company-logo-placeholder.png')}
                alt="Logo"
              />)
            }
            { isOpen &&
              <LogOutFormModalComponent
                isOpen={isOpen}
                toggle={this.toggle}
                logout={this.logout}
              />
            }
          </div>
        */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dateFormat: BasicSettingsDucks.dateFormat(state),
  timeFormat : BasicSettingsDucks.timeFormat(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  userCompany: ProfileDucks.userCompany(state),
  basicSettingDetail: BasicSettingsDucks.basicSettingDetail(state),
  jobName : JobDucks.jobName(state),
  faceInfo: ProfileDucks.faceInfoDetail(state),
  userPreferenceLanguage: UserDucks.userPreferenceLanguage(state),
  location: RouteDucks.location(state),
  companyDetail: EmployeeDucks.companyDetail(state),
});
const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
  BasicSettingsDucks : bindActionCreators(BasicSettingsDucks, dispatch),
  JobDucks : bindActionCreators(JobDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch)
});

HeaderSection.propTypes = {
  userCompany: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

HeaderSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(HeaderSection);


