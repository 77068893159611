import { createSelector } from 'reselect';
import { merge } from 'ramda';

import { fetch, put } from 'lib/api';
import createReducer from 'lib/createReducer';
import constants from './constants';

const GET_PROFILE = 'accounts/profile/GET_PROFILE';
const GET_PROFILE_FULFILLED = 'accounts/profile/GET_PROFILE_FULFILLED';
const GET_FACE_DETAILS = 'accounts/profile/GET_FACE_DETAILS';
const GET_FACE_DETAILS_FULFILLED = 'accounts/profile/GET_FACE_DETAILS_FULFILLED';
const PUT_PROFILE = 'accounts/profile/PUT_PROFILE';
const PUT_PROFILE_FULFILLED = 'accounts/profile/PUT_PROFILE_FULFILLED';
const RESET_FACE_INFO = 'accounts/user/RESET_FACE_INFO';


export function getProfile() {
  return {
    type: GET_PROFILE,
    payload: fetch(constants.URLS.PROFILE),
  };
}

export function getFaceDetails() {
  return {
    type: GET_FACE_DETAILS,
    payload: fetch(constants.URLS.FACIAL_RECOGNITION),
  };
}


export function resetFaceDetails() {
  return {
    type: RESET_FACE_INFO,
  };
}


export function putProfile(data) {
  return {
    type: PUT_PROFILE,
    payload: put(constants.URLS.PROFILE, data),
  };
}

const defaultState = {
  detail: {},
  faceInfo:{},
};

function GET_PROFILE_FULFILLED_REDUCER(state, action) {
  return merge(state, {
    detail: action.payload,
  });
}

function PUT_PROFILE_FULFILLED_REDUCER(state, action) {
  return merge(state, {
    detail: action.payload,
  });
}

function GET_FACE_DETAILS_FULFILLED_REDUCER(state, action) {
  return merge(state, {
    faceInfo: action.payload,
  });
}

function RESET_FACE_INFO_REDUCER(state, action) {
  return Object.assign({}, state, {
    faceInfo: {},
  });
}

const handlers = {
  [GET_PROFILE_FULFILLED]: GET_PROFILE_FULFILLED_REDUCER,
  [PUT_PROFILE_FULFILLED]: PUT_PROFILE_FULFILLED_REDUCER,
  [GET_FACE_DETAILS_FULFILLED]: GET_FACE_DETAILS_FULFILLED_REDUCER,
  [RESET_FACE_INFO]: RESET_FACE_INFO_REDUCER,
};


const profileSelector = state => state.accounts.profile;

export const profile = createSelector(
  profileSelector,
  instance =>instance.detail,
);

export const faceInfoDetail = createSelector(
  profileSelector,
  instance => instance.faceInfo,
);
export const userCompany = createSelector(
  profile,
  instance => instance.company,
);

export default createReducer(defaultState, handlers);
