import React from "react";

import PropTypes from "prop-types";
import Field from "redux-form/lib/Field";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError";

import Modal from "react-bootstrap-modal";

import ButtonLoaderAtom from "atoms/ButtonLoader";
import KeyboardInputAtom from "atoms/KeyboardInput";
import TRMSGS from "constants/Translations/Localization";

/**
 * DashboardListPage -> DashboardListSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class ConfirmFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.passwordSubmit = this.passwordSubmit.bind(this);
    this.toggleKeyboard = this.toggleKeyboard.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = {
      inputType: "text",
      isOpenKeyboard: false,
    };
  }

  passwordSubmit(data) {
    const { employeeLogin, employeeDetail, disableLoader } = this.props;
    const detail = data;
    if (employeeDetail) {
      detail.username = employeeDetail.user.username;
    }
    return employeeLogin(detail).catch((err) => {
      const errObj = {
        _error: err.non_field_errors || err.detail,
        ...err,
      };
      if (err.detail) {
        setTimeout(() => {
          this.props.logoutSupervisor();
        }, 3000);
      }
      disableLoader();
      throw new SubmissionError(errObj);
    });
  }

  toggleKeyboard() {
    const { isOpenKeyboard } = this.state;
    this.setState({ isOpenKeyboard: !isOpenKeyboard });
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({
      inputType: inputType === "password" ? "text" : "password",
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      isOpen,
      toggle,
      submitButtonLoader,
    } = this.props;
    const { inputType, isOpenKeyboard } = this.state;

    return (
      <Modal
        show={isOpen}
        aria-labelledby="ModalHeader"
        onHide={() => toggle()}
        backdrop="static"
      >
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title id="ModalHeader" style={{ fontWeight: "500" }}>
            {TRMSGS.enterPasswordToLogin}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ml2 mr2">
          <form onSubmit={handleSubmit(this.passwordSubmit)}>
            <div className="flex">
              <div style={{ flex: "1" }}>
                <div className="mh3">
                  <label htmlFor="password" className="db mb1 mv2">
                    {TRMSGS.password}
                  </label>
                  <Field
                    id="password"
                    type={inputType}
                    name="password"
                    component={KeyboardInputAtom}
                    toggle={this.toggleKeyboard}
                    isOpen={isOpenKeyboard}
                    inputType={inputType}
                    placeholder={TRMSGS.password}
                    className="ba b--black-20 w-40 ph2"
                    toggleInputType={this.toggleInputType}
                    eyeIcon
                    isModal
                    autoFocus
                    data-lpignore={true}
                  />
                  <small className="dark-red db h1">{error}</small>
                  <div className="tr ml2 custom-button">
                    <button
                      type="submit"
                      className="br2 pointer ph5 white btn-color"
                      disabled={submitButtonLoader}
                    >
                      {TRMSGS.login} <ButtonLoaderAtom active={submitting} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = `${TRMSGS.enterPassword}`;
  return errors;
};

ConfirmFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "ConfirmFormModal",
  validate,
})(ConfirmFormModalComponent);
